import styled, { useTheme } from 'styled-components'
import * as CSS from 'csstype'
import Container from '../Container'
import Typography from '../Typography'
import Button from '../Button'
import { zIndexes } from '../../../zIndexes'
import Card from '../Card'
import { ButtonVariants } from '../Button/Button'
import { useEffect, useMemo, useState } from 'react'
import { classNames } from '../../../styles/classNames'
import { createPortal } from 'react-dom'

const Wrapper = styled.div<{ padding?: number | string }>`
  position: fixed;
  z-index: ${zIndexes.overlay};
  width: calc(100% + 32px);
  height: calc(100% + 32px);
  padding: 156px 24px 0 24px;
  top: -16px;
  left: -16px;
  background-color: rgba(0, 0, 0, 0.7);
  ${props => {
    if (props.padding) {
      if (typeof props.padding === 'number') {
        return {
          padding: `${props.padding}px`,
        }
      } else {
        return {
          padding: `${props.padding}`,
        }
      }
    }
  }}
`

interface ConfirmProps {
  question: string
  questionTitle?: string
  onYes: () => void
  yesText: string
  noText: string
  onNo?: () => void
  yesFirst?: true
  styles?: CSS.Properties
  withWrapper?: true
  yesVariant?: ButtonVariants
  noVariant?: ButtonVariants
  visible: boolean
  hide: () => void
  noDefaultStyles?: true
}

const ConfirmPortal = (props: ConfirmProps) => {
  const theme = useTheme()
  const [state, setState] = useState<null | 'yes' | 'no'>(null)

  const className = useMemo(() => {
    if (!state && !props.visible) return classNames.displayNone
    if (props.visible && !state) return classNames.showFromRight
    if (state && !props.visible) return classNames.hideToRight
  }, [state, props.visible])

  useEffect(() => {
    if (state) {
      props.hide()
    }
  }, [state])

  const onAnimationEnd = () => {
    if (state === 'yes') {
      props.onYes()
    } else if (state === 'no') {
      props.onNo && props.onNo()
    }
    setState(null)
  }

  const defaultStyles = props.noDefaultStyles
    ? {}
    : {
        width: 'calc(100vw - 48px)',
        maxWidth: '344px',
        margin: 'auto',
      }

  const domNode = document.getElementById('portal-root')

  if (!domNode) return null

  return createPortal(
    <Wrapper className={className} onAnimationEnd={onAnimationEnd}>
      <Card
        padding={28}
        styles={{
          backgroundColor: theme.colors.background.contrast,
          ...defaultStyles,
          ...props.styles,
        }}
      >
        <Container.Flex alignItems="start" fullWidth>
          {!!props.questionTitle && (
            <Typography.Title
              color={theme.colors.text.secondary}
              textAlign="start"
              margin="0 0 24px"
            >
              {props.questionTitle}
            </Typography.Title>
          )}

          {!!props.question && (
            <Typography.Main margin="0 0 22px" textAlign="start">
              {props.question}
            </Typography.Main>
          )}

          <Container.Grid direction="row" gap={16} cols={'1fr 1fr'} fullWidth>
            {!props.yesFirst && (
              <Button
                variant={props.noVariant ?? 'outline'}
                fullWidth
                onClick={() => setState('no')}
                square
                styles={{ margin: '0' }}
              >
                {props.noText}
              </Button>
            )}
            <Button
              variant={props.yesVariant ?? 'outline'}
              fullWidth
              onClick={() => setState('yes')}
              square
              styles={{ margin: '0' }}
            >
              {props.yesText}
            </Button>
            {props.yesFirst && (
              <Button
                variant={props.noVariant ?? 'outline'}
                fullWidth
                onClick={() => setState('no')}
                square
                styles={{ margin: '0' }}
              >
                {props.noText}
              </Button>
            )}
          </Container.Grid>
        </Container.Flex>
      </Card>
    </Wrapper>, domNode
  )
}

export default ConfirmPortal
