import SVG from '../SVG'
import { IconProps } from '../types'

const StatusComfirmed = (props: IconProps) => {
  const width = props.width ?? 16;
  const height = props.height ?? 16;
  return (
    <SVG {...props} width={width} height={height} styles={{
      color: props.color ?? "#78C25E"
    }}>
      <g clipPath="url(#clip0_5337_23229)">
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="currentColor"
        />
        <path
          d="M11.2325 6.78785C11.5449 6.47543 11.5449 5.9689 11.2325 5.65648C10.9201 5.34406 10.4136 5.34406 10.1011 5.65648L11.2325 6.78785ZM7.11127 9.77772L6.54559 10.3434C6.69562 10.4934 6.8991 10.5777 7.11127 10.5777C7.32345 10.5777 7.52693 10.4934 7.67696 10.3434L7.11127 9.77772ZM5.89918 7.43426C5.58676 7.12184 5.08023 7.12184 4.76781 7.43426C4.45539 7.74668 4.45539 8.25321 4.76781 8.56563L5.89918 7.43426ZM10.1011 5.65648L6.54559 9.21204L7.67696 10.3434L11.2325 6.78785L10.1011 5.65648ZM7.67696 9.21204L5.89918 7.43426L4.76781 8.56563L6.54559 10.3434L7.67696 9.21204Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5337_23229">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </SVG>
  )
}

export default StatusComfirmed
