import { RootState } from '..'
import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import {
  OrderState,
  GetOrderRequest,
  Order,
  OrderRequest,
  DeleteItemResponseData,
  SetRegistrationResponseData,
} from './types'
import { createRoutine } from 'redux-saga-routines'
import { ApiError } from '../types'

const deleteItem = {
  loading: false,
  result: null,
  errors: [],
  data: null,
  itemNumber: null,
}

const initialState: OrderState = {
  order: {
    itemNumber: '',
    number: '',
    data: null,
    status: null,
    loading: false,
    errors: [],
  },
  deleteItem: { ...deleteItem },
  setRegistrations: {
    loading: false,
    data: null,
    result: null,
    errors: [],
  },
  showResultFor: '',
}

export const GetOrder = createRoutine('order/Get-Order')
export const CreateOrChangeOrder = createRoutine('order/Create-Or-Change-Order')
export const GetItemStatus = createRoutine('order/Get-Item-Status')
export const DeleteItem = createRoutine('order/Delete-Item')
export const SetOrderRegistration = createRoutine(
  'order/Set-Order-Registration',
)
export const UpdateItemDate = createRoutine('order/Update-Item-Data')

const orderSlice = createSlice({
  name: 'order',
  initialState: initialState,
  reducers: {
    setOrderNumber(state: OrderState, { payload }: PayloadAction<string>) {
      state.order.number = payload
    },
    setItemNumber(state: OrderState, { payload }: PayloadAction<string>) {
      state.order.itemNumber = payload
    },
    setOrderStatus(
      state: OrderState,
      { payload }: PayloadAction<'success' | 'error' | null>,
    ) {
      state.order.status = payload
    },
    setQrCode(
      state: OrderState,
      { payload }: PayloadAction<string | undefined>,
    ) {
      state.qrCode = payload
    },
    restoreOrderState(state: OrderState) {
      state = Object.assign(state, initialState)
    },
    setDeleteItemNumber(
      state: OrderState,
      { payload }: PayloadAction<string | null>,
    ) {
      state.deleteItem.itemNumber = payload
    },
    setShowResultFor(state: OrderState, { payload }: PayloadAction<string>) {
      state.showResultFor = payload
    },
    resetDeleteNumber(state: OrderState) {
      state.deleteItem = deleteItem
    },
    resetOrderErrors(state) {
      state.order.errors = []
    }
  },
  extraReducers: {
    [CreateOrChangeOrder.REQUEST](
      state,
      { payload }: PayloadAction<OrderRequest<any>>,
    ) {
      console.log(payload)
      state.order.loading = true
    },
    [CreateOrChangeOrder.FAILURE](
      state,
      { payload }: PayloadAction<ApiError[]>,
    ) {
      state.order.status = 'error'
      state.order.errors = payload
    },
    [CreateOrChangeOrder.SUCCESS](state) {
      state.order.status = 'success'
    },
    [CreateOrChangeOrder.FULFILL](state) {
      state.order.loading = false
    },
    [GetOrder.REQUEST](
      state,
      { payload }: PayloadAction<GetOrderRequest | undefined>,
    ) {
      console.log(payload)
      state.order.loading = true
      state.order.data = null
      state.order.errors = [];
    },
    [GetOrder.FAILURE](state, { payload }: PayloadAction<ApiError[]>) {
      state.order.status = 'error'
      state.order.errors = payload
    },
    [GetOrder.SUCCESS](
      state,
      { payload }: PayloadAction<{ data: Order; create?: true }>,
    ) {
      state.order.data = payload.data
    },
    [GetOrder.FULFILL](state) {
      state.order.loading = false
    },
    [GetItemStatus.REQUEST]() {
      // state.order.loading = true
    },
    [GetItemStatus.FAILURE](state, { payload }: PayloadAction<ApiError[]>) {
      state.order.errors = payload
    },

    [GetItemStatus.FULFILL](state) {
      state.order.loading = false
    },
    [DeleteItem.REQUEST](state) {
      state.deleteItem.loading = true
    },
    [DeleteItem.SUCCESS](
      state,
      { payload }: PayloadAction<DeleteItemResponseData>,
    ) {
      state.deleteItem.data = payload
    },
    [DeleteItem.FAILURE](state, { payload }: PayloadAction<string[]>) {
      state.deleteItem.errors = payload
    },
    [DeleteItem.FULFILL](state) {
      state.deleteItem.loading = false
      state.deleteItem.itemNumber = null
    },
    [SetOrderRegistration.REQUEST](state) {
      state.setRegistrations.loading = true
      state.setRegistrations.data = null
      state.setRegistrations.result = null
    },
    [SetOrderRegistration.SUCCESS](
      state,
      { payload }: PayloadAction<SetRegistrationResponseData>,
    ) {
      state.setRegistrations.data = payload
      state.setRegistrations.result = 'success'
    },
    [SetOrderRegistration.FAILURE](
      state,
      { payload }: PayloadAction<string[]>,
    ) {
      state.setRegistrations.errors = payload
      state.setRegistrations.result = 'error'
    },
    [SetOrderRegistration.FULFILL](state) {
      state.setRegistrations.loading = false
    },
    [UpdateItemDate.SUCCESS](state, { payload }: PayloadAction<Order>) {
      if (state.order.data) {
        state.order.data.items = state.order.data.items.map(item => {
          const trueItem = payload.items.find(
            el => el.itemNumber === item.itemNumber,
          )
          if (trueItem) {
            return {
              ...item,
              price: trueItem.price,
              answers: trueItem.answers,
              status: trueItem.status
            }
          } else {
            return item
          }
        })
      }
    },
  },
})

export const getOrderItemData = createSelector(
  (state: RootState) => state.order,
  order => ({
    orderData: order.order.data,
    isLoading: order.order.loading,
    errors: order.order.errors,
    showResultFor: order.showResultFor,
  }),
)

export const getOrderData = createSelector(
  (state: RootState) => state.order,
  orderData => orderData,
)

export const getOrderErrors = createSelector(
  (state: RootState) => state.order,
  order => order.order.errors,
)

export const getOrderResponseData = createSelector(
  (state: RootState) => state.order,
  order => order.order.data,
)

export const getOrderNumber = createSelector(
  (state: RootState) => state.order,
  orderData => orderData.order.number,
)

export const getItemNumber = createSelector(
  (state: RootState) => state.order,
  orderData => orderData.order.itemNumber,
)

export const getOrderPending = createSelector(
  (state: RootState) => state.order,
  order => order.order.loading,
)

export const getCreateOrderResult = createSelector(
  (state: RootState) => state.order,
  orderData => ({
    status: orderData.order.status,
    itemNumber: orderData.order.itemNumber,
    orderNumber: orderData.order.number,
  }),
)

export const getQrCode = createSelector(
  (state: RootState) => state.order,
  order => order.qrCode,
)

export const getDeleteData = createSelector(
  (state: RootState) => state.order,
  order => ({ ...order.deleteItem, orderNumber: order.order.data?.number }),
)

export const getOrderRegistrationData = createSelector(
  (state: RootState) => state.order,
  orderData => orderData.order.data,
)

export const getSetRegistrationData = createSelector(
  (state: RootState) => state.order,
  orderData => orderData.setRegistrations,
)

export const {
  setOrderNumber,
  setItemNumber,
  restoreOrderState,
  setQrCode,
  setOrderStatus,
  setDeleteItemNumber,
  setShowResultFor,
  resetDeleteNumber,
  resetOrderErrors
} = orderSlice.actions

export default orderSlice.reducer
