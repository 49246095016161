import { RootState } from '..';
import { createAction, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { AdditionActions, 
    DeviceInfo, 
    GivenAnswer, 
    ImageFile, 
    OfferState, 
    OfferSteps, 
    QuestionsResponse, 
    QuestionTree, 
    ServerError, 
    SetTreeDataProps, 
    MakeAdditionAction, 
    GivenAnswers,
    SendPhotoData,
    ErrorForm,
    TreeQuestion,
    CheckListItemData,
} from './types';
import { createSlice } from '@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';
import { ApiError, N } from '../types'

const initialState: OfferState = {
    step: "start",
    getQuestions: {
        result: null,
        errors: []
    },
    createOrder: {
        result: null,
        errors: [],
    },
    sendPhoto: {
        result: null,
        errors: []
    },
    pending: false,
    questionOrder: 0,
    maxCombinationLength: 0,
    maxProgressPosition: 0,
    minProgressPosition: 0,
    questionsData: null,
    questionsTree: null,
    previousCombitanionId: "",
    currentErrorMessage: "",
    treeQuestions: [],
    hint: "",
    images: [],
    givenAnswers: {
        answers: []
    },
    deviceInfo: null,
    changeQuestionsContent: false,
    errorForm: {
        code: "",
        data: null,
        status: null,
        pending: false,
        submitFormData: null
    },
    cardError: "",
    pauseQuestions: false,
    getitem: {
        data: null,
        pending: false,
        errors: [],
        status: null
    }
};

export const GetQuestions = createRoutine("offer/Get-Questions");
export const SendPhoto = createRoutine("offer/Send-Photo")
export const GetErrorForm = createRoutine("offer/Get-Error-Form");
export const SendErrorForm = createRoutine("offer/Send-Error-Form");
export const GetItemData = createRoutine("offer/Get-Item-Data")

export const giveAnswerRequest = createAction<GivenAnswer>("offer/Get-Question-Request");

const offerSlice = createSlice({
    name: 'offer',
    initialState: initialState,
    reducers: {
        hideQuestionContent(state: OfferState) {
            state.changeQuestionsContent = true
        },
        showQuestionContent(state: OfferState) {
            state.changeQuestionsContent = false
        },
        resetAnswers(state) {
            state.givenAnswers.answers = [];
        },
        giveAnswer(state: OfferState, { payload }: PayloadAction<GivenAnswer>) {
            const answerIndex = state.givenAnswers.answers.findIndex(
                el => el.questionId === payload.questionId,
            )
            if (answerIndex >= 0) {
                state.givenAnswers.answers[answerIndex] = payload
            } else {
                state.givenAnswers.answers.push(payload)
            }
            state.previousCombitanionId = state.givenAnswers.combinationId ?? ''
            state.changeQuestionsContent = false
        },
        setGivenAnswers(
            state: OfferState,
            { payload }: PayloadAction<GivenAnswers>,
        ) {
            const { customerCombinationId, isCopyItem } = state.givenAnswers
            state.givenAnswers = payload
            if (customerCombinationId) {
                state.givenAnswers.customerCombinationId = customerCombinationId
            }
            if (isCopyItem) {
                state.givenAnswers.isCopyItem = isCopyItem
            }
        },
        setCustomerCombinationId(
            state: OfferState,
            { payload }: PayloadAction<string | undefined>,
        ) {
            state.givenAnswers.customerCombinationId = payload
        },
        removeAnswerByBlob(
            state: OfferState,
            { payload }: PayloadAction<string>,
        ) {
            state.givenAnswers.answers = state.givenAnswers.answers.filter(
                el => el.answerName !== payload,
            )
        },
        clearLastAnswer(state: OfferState) {
            state.givenAnswers.answers.pop()
            state.givenAnswers.combinationId = state.previousCombitanionId
            state.previousCombitanionId = ''
        },
        setAddCombinationId(state: OfferState, { payload } : PayloadAction<true | undefined>) {
            state.addCombinationId = payload;
        },
        setTreeProps(
            state: OfferState,
            { payload }: PayloadAction<SetTreeDataProps>,
        ) {
            const {
                combinationId,
                offerId,
                additionalAction,
                combinationCode,
            } = payload
            if (combinationId) {
                state.givenAnswers.combinationId = combinationId
            }
            if (combinationCode)
                state.givenAnswers.combinationCode = combinationCode
            if (offerId) state.givenAnswers.offerId = offerId
            if (additionalAction)
                state.givenAnswers.additionalAction = additionalAction
        },
        setGetQuestionLoading(
            state: OfferState,
            { payload }: PayloadAction<boolean>,
        ) {
            state.pending = payload
        },
        resetAdditionActions(state: OfferState) {
            state.givenAnswers.additionalAction = undefined
        },
        setAdditionalAction(
            state: OfferState,
            { payload }: PayloadAction<AdditionActions | undefined>,
        ) {
            state.givenAnswers.additionalAction = payload
        },
        setQuestionsTree(
            state: OfferState,
            { payload }: PayloadAction<N<QuestionTree>>,
        ) {
            state.questionsTree = payload
        },
        setTreeQuestions(
            state: OfferState,
            { payload }: PayloadAction<TreeQuestion[]>,
        ) {
            state.treeQuestions = payload
        },
        resetQuestions(state: OfferState) {
            state.questionsData = null
            state.questionsTree = null
            state.getQuestions.result = null
        },
        addSeveralAnswers(
            state: OfferState,
            { payload }: PayloadAction<{ [questionId: string]: string }>,
        ) {
            Object.entries(payload).forEach(([questionId, answerName]) => {
                const answerIdx = state.givenAnswers.answers.findIndex(el => "" + el.questionId === "" + questionId);

                if (answerIdx >= 0) {
                    state.givenAnswers.answers[answerIdx].answerName = answerName;
                } else {
                    state.givenAnswers.answers.push({
                        questionKey: questionId,
                        questionId,
                        questionName: "",
                        alternateName: null,
                        saveInOrder: false,
                        answerName,
                        questionShortName: null,
                        questionGroup: null
                    })
                }
            })
        },
        setPauseQuestions(
            state: OfferState,
            { payload }: PayloadAction<boolean>,
        ) {
            state.pauseQuestions = payload
        },
        setCombinationsId(
            state: OfferState,
            { payload }: PayloadAction<string | undefined>,
        ) {
            state.givenAnswers.combinationId = payload
        },
        setCombinationCode(
            state: OfferState,
            { payload }: PayloadAction<string | undefined>,
        ) {
            state.givenAnswers.combinationCode = payload
        },
        setOfferId(
            state: OfferState,
            { payload }: PayloadAction<string | undefined>,
        ) {
            state.givenAnswers.offerId = payload
        },
        setIsCopyItem(
            state: OfferState,
            { payload }: PayloadAction<true | undefined>,
        ) {
            state.givenAnswers.isCopyItem = payload
        },
        setStepRequest(
            state: OfferState,
            { payload }: PayloadAction<OfferSteps>,
        ) {
            console.log(payload)
        },
        setStep(state: OfferState, { payload }: PayloadAction<OfferSteps>) {
            state.step = payload
        },
        setDeviceInfo(
            state: OfferState,
            { payload }: PayloadAction<DeviceInfo>,
        ) {
            state.deviceInfo = payload
        },
        restoreOffer(state: OfferState) {
            const customerCombinationId =
                state.givenAnswers.customerCombinationId
            const isCopyItem = state.givenAnswers.isCopyItem
            state = Object.assign(state, initialState)
            if (customerCombinationId) {
                state.givenAnswers = {
                    ...state.givenAnswers,
                    customerCombinationId,
                }
            }
            if (isCopyItem) {
                state.givenAnswers = { ...state.givenAnswers, isCopyItem }
            }
        },
        makeAdditionAction(
            state: OfferState,
            { payload }: PayloadAction<MakeAdditionAction>,
        ) {
            state.givenAnswers.additionalAction = undefined
        },
        uploadImage(state: OfferState, { payload }: PayloadAction<ImageFile>) {
            state.images.push(payload)
        },
        addNewDevice(state: OfferState) {
            state.step = 'start'
            state.givenAnswers = {
                answers: [],
            }
            state.questionsData = null
            state.questionsTree = null
            state.deviceInfo = null
        },
        setQuestionOrder(
            state: OfferState,
            { payload }: PayloadAction<number>,
        ) {
            state.questionOrder = payload
        },
        setCardError(state: OfferState, { payload }: PayloadAction<string>) {
            state.cardError = payload
        },
        setCurrentErrorMessage(
            state: OfferState,
            { payload }: PayloadAction<string>,
        ) {
            state.currentErrorMessage = payload
        },
        resetSendPhoto(state) {
            state.sendPhoto.errors = [];
            state.sendPhoto.result = null;
        }
    },
    extraReducers: {
        [GetQuestions.REQUEST](state) {
            state.maxCombinationLength = 0
            state.questionOrder = 0
            state.getQuestions.result = null
            state.pending = true
        },
        [GetQuestions.FAILURE](
            state,
            { payload }: PayloadAction<ServerError[]>,
        ) {
            state.getQuestions.result = 'error'
            state.getQuestions.errors = payload
        },
        [GetQuestions.SUCCESS](
            state,
            { payload }: PayloadAction<QuestionsResponse>,
        ) {
            state.getQuestions.result = 'success'
            state.getQuestions.errors = []
            if (!payload.questionsData) {
                return
            }
            if (
                !Object.keys(payload.questionsData).every(el =>
                    Object.keys(state.questionsData || {}).includes(el),
                )
            ) {
                state.questionsData = payload.questionsData
            }
            state.questionsTree = payload.questionsTree
            state.maxCombinationLength = payload.maxLengthCombination || 0
            state.maxProgressPosition = payload.maxProgressPosition || 0
            state.minProgressPosition = payload.minProgressPosition || 0
            state.addCombinationId = payload.addCombinationId;
        },
        [GetQuestions.FULFILL](state) {
            state.pending = false
            if (state.step === 'start') {
                state.step = 'questions'
            }
        },
        [SendPhoto.REQUEST](state, { payload }: PayloadAction<SendPhotoData>) {
            console.log(payload)
            state.pending = true
        },
        [SendPhoto.FAILURE](state, { payload }: PayloadAction<ServerError[]>) {
            state.sendPhoto.errors = payload
            state.sendPhoto.result = 'error'
        },
        [SendPhoto.SUCCESS](state) {
            state.sendPhoto.result = 'success'
        },
        [SendPhoto.FULFILL](state) {
            state.pending = false
        },
        [GetErrorForm.REQUEST](state, { payload }: PayloadAction<string>) {
            state.errorForm.code = payload
            state.errorForm.pending = true
        },
        [GetErrorForm.SUCCESS](state, { payload }: PayloadAction<ErrorForm>) {
            state.errorForm.status = 'success'
            state.errorForm.data = payload
        },
        [GetErrorForm.FAILURE](state, { payload }: PayloadAction<string[]>) {
            state.errorForm.status = 'error'
            state.errorForm.data = null
            state.cardError = payload.join('. ')
        },
        [GetErrorForm.FULFILL](state) {
            state.errorForm.pending = false
        },
        [SendErrorForm.REQUEST](state) {
            state.errorForm.pending = true
        },
        [SendErrorForm.SUCCESS](state, { payload }: PayloadAction<any>) {
            state.errorForm.submitFormData = payload
        },
        [SendErrorForm.FAILURE](state) {
            state.errorForm.status = 'error'
        },
        [SendErrorForm.FULFILL](state) {
            state.errorForm.pending = false
        },
        [GetItemData.REQUEST](state) {
            state.getitem.pending = true
        },
        [GetItemData.FAILURE](state, { payload }: PayloadAction<ApiError[]>) {
            state.getitem.errors = payload
            state.getitem.status = 'error'
            state.getitem.data = null
        },
        [GetItemData.SUCCESS](
            state,
            { payload }: PayloadAction<CheckListItemData>,
        ) {
            state.getitem.data = payload
            state.getitem.status = 'success'
            state.getitem.errors = []
        },
        [GetItemData.FULFILL](state) {
            state.getitem.pending = false
        },
    },
})

export const getQuestionsResult = createSelector(
    (state: RootState) => state.offer,
    offer => offer.getQuestions.result
)

export const getOfferData = createSelector(
    (state: RootState) => ({ offer: state.offer, view: state.view, order: state.order, saved: state.saved }),
    data => ({
        offer: data.offer,
        orderData: data.order.order.data,
        isLoading: data.offer.pending || data.order.order.loading,
        redirectTo: data.view.redirectTo,
        showResultFor: data.order.showResultFor,
        maxCombinationLength: data.offer.maxCombinationLength,
        maxProgressPosition: data.offer.maxProgressPosition,
        minProgressPosition: data.offer.minProgressPosition,
        errorForm: data.offer.errorForm,
        savedOrderNumber: data.saved.orderNumber
    })
)

export const getSendPhotoStatus = createSelector(
    (state: RootState) => state.offer,
    offer => offer.sendPhoto.result
)

export const getFormErrorData = createSelector(
    (state: RootState) => state.offer,
    offer => offer.errorForm
)

export const getCardError = createSelector(
    (state: RootState) => state.offer,
    offer => offer.cardError
)

export const getItemData = createSelector(
    (state: RootState) => state.offer,
    offer => offer.getitem
)

export const getCheckListOfferData = createSelector(
    (state: RootState) => ({ order: state.order, offer: state.offer}),
    data => ({
        givenAnswers: data.offer.givenAnswers
    })
)

export const {
    setStep,
    setCombinationsId,
    setQuestionsTree,
    setOfferId,
    giveAnswer,
    restoreOffer,
    setDeviceInfo,
    setTreeProps,
    makeAdditionAction,
    resetAdditionActions,
    uploadImage,
    hideQuestionContent,
    showQuestionContent,
    setAdditionalAction,
    setGivenAnswers,
    resetQuestions,
    setGetQuestionLoading,
    setCombinationCode,
    setCustomerCombinationId,
    setTreeQuestions,
    addNewDevice,
    setQuestionOrder,
    setStepRequest,
    setCardError,
    clearLastAnswer,
    setCurrentErrorMessage,
    setPauseQuestions,
    setIsCopyItem,
    addSeveralAnswers,
    removeAnswerByBlob,
    setAddCombinationId,
    resetAnswers,
    resetSendPhoto
} = offerSlice.actions

export default offerSlice.reducer;