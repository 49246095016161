import styled from 'styled-components'
import * as CSS from 'csstype'
import { Props } from '../../types'
import { getCommonProps } from '../../types'
import { ImageSrc } from '../../../types'

const Picture = styled.picture<Partial<ImgProps>>`
  ${props => ({
    width: props.width ? `${props.width}px` : 'auto',
    height: `${props.height}px`,
    ...props.styles,
  })}
  ${props => getCommonProps(props)};
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
`

type ImgProps = Props<{
  width?: number
  height?: number
  alt: string
  styles?: CSS.Properties
  src: ImageSrc
  noBasePath?: true
}>

const baseImagePath = process.env.REACT_APP_IMAGE_BASE ?? ''

const Image = (props: ImgProps) => {
  const noBasePath = process.env.NODE_ENV === 'production'

  const withBasePath = (path: string, add?: string) =>
    (noBasePath ? baseImagePath + path : path) + (add ? ' ' + add : '')

  const pictureProps = {
    width: props.width,
    height: props.height,
    styles: props.styles
  }

  return (
    <Picture {...pictureProps} >
      <source
        type="image/webp"
        srcSet={
          [withBasePath(props.src.webpX2, '2x'),
          withBasePath(props.src.webp, '1x')].join(',')
        }
      />
      <img
        srcSet={withBasePath(props.src.jpgX2, '2x')}
        src={props.src.jpg}
        alt={props.alt}
      />
    </Picture>
  )
}

export default Image
