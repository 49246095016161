import styled from "styled-components/macro";
import { TextProps } from "../types";
import { getTextAlign } from "./helpers";
import { getCommonProps, collectTypographyStyles } from "../../../types";
import { IndicatorStyles } from "..";
import Icon from "../../Icon";
import { useLink } from './useLink'

export const TitleSecondaryWrapper = styled.h2<TextProps>`
    ${(props) => getTextAlign(props)};
    ${(props) => ({
        ...props.theme.typography.title,
		color: props.theme.colors.text.secondary,
        ...props.styles,
    })};
    ${(props) => getCommonProps(props)};
    ${(props) => (props.color ? { color: props.color } : {})};
    ${(props) => collectTypographyStyles(props)};
    ${(props) => props.padding ? { padding: props.padding } : { padding: '0 4px' }}
    transition: ${props => props.noTransition ? "none" : "all 200ms ease-in"};
`;

export default function TitleSecondary (props: TextProps) {
    const { navigate } = useLink();

    return <TitleSecondaryWrapper {...props} onClick={props.onClick ?? navigate(props.link)}>
        {props.children}
        {props.withIndicator && <Icon name="color-indicator" styles={IndicatorStyles}/>}
    </TitleSecondaryWrapper>
}
