import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MenuItemKey } from '../../components/LowerMenu/LowerMenu'

type InitialState = {
  disableLinks: MenuItemKey[]
}

const initialState: InitialState = {
  disableLinks: [],
}

const lowerMenuSlice = createSlice({
  name: 'lower-menu',
  initialState,
  reducers: {
    disableLowerMenuLink: (state, { payload }: PayloadAction<MenuItemKey>) => {
      if (state.disableLinks.indexOf(payload) < 0) {
        state.disableLinks.push(payload)
      }
    },
    activateLowerMenuLink: (state, { payload }: PayloadAction<MenuItemKey>) => {
      if (state.disableLinks.indexOf(payload) >= 0) {
        const newLinks = state.disableLinks.filter(el => el !== payload)
        state.disableLinks = newLinks
      }
    },
  },
})

export const { disableLowerMenuLink, activateLowerMenuLink } =
  lowerMenuSlice.actions

export default lowerMenuSlice.reducer
