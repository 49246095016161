import { FiltersPart, useFiltersData } from './FiltersContext'
import { Container, Typography, Icon } from '../ui'
import { css, useTheme } from 'styled-components'

export const RowCss = css`
  color: ${props => props.theme.colors.text.default};
  transition: color 250ms linear;
  &:hover {
    color: ${props => props.theme.colors.link.hover};
    cursor: pointer;
  }
`

export default function FiltersMain({ filters }: { filters: FiltersPart[] }) {
  const { activeFilter, setCurrentFilter } = useFiltersData();

  const theme = useTheme()

  const goTo = (filter: FiltersPart) => {
    setCurrentFilter(filter)
  }

  return (
    <Container.Flex fullWidth verticalGap={24} styles={{paddingLeft: "4px"}}>
      {filters.map((filter) => {
        return (
          <Container.Flex
            key={filter.propertyName}
            direction="row"
            justify="between"
            fullWidth
            styles={{ cursor: 'pointer' }}
            onClick={() => goTo(filter)}
            css={RowCss}
          >
            <Typography.Main
              fromStart
              margin={0}
              withIndicator={activeFilter(filter.propertyName) || undefined}
              indicatorColor={theme.colors.info.success}
            >
              {filter.name}
            </Typography.Main>
            <Icon
              name="arrow"
              secondary
              styles={{
                transform: 'rotate(-90deg)',
              }}
            />
          </Container.Flex>
        )
      })}
    </Container.Flex>
  )
}
