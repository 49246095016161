import SVG from "../SVG";
import { IconProps } from "../types";

const List = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 24} height={props.height ?? 24}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6V18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18V6ZM4 3C2.34315 3 1 4.34315 1 6V18C1 19.6569 2.34315 21 4 21H20C21.6569 21 23 19.6569 23 18V6C23 4.34315 21.6569 3 20 3H4ZM6 9C5.44772 9 5 9.44772 5 10C5 10.5523 5.44772 11 6 11H12.8571C13.4094 11 13.8571 10.5523 13.8571 10C13.8571 9.44772 13.4094 9 12.8571 9H6ZM6 14C5.44772 14 5 14.4477 5 15C5 15.5523 5.44772 16 6 16H18C18.5523 16 19 15.5523 19 15C19 14.4477 18.5523 14 18 14H6Z"
            />
        </SVG>
    );
};

export default List;
