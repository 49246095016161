//import { useTheme } from 'styled-components'
import { Container, Loader, Overlay, Typography } from '../../../ui'
import { PERIOD_FILTER_KEY, useOrderListData } from '../OrderListContext'
import FiltersHeader from './FiltersHeader'
import PeriodFilters from './PeriodFilters'
import SelectFilters from './SelectFilters'
import ListFilters from './ListFilters'
import { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components/macro'
import cls from 'classnames'

const FilterWpapper = styled.div`
  position: relative;
  z-index: 1;
  padding: 28px;
  margin-top: 200px;
  background-color: ${props => props.theme.colors.background.contrast};
  width: 344px;
  border-radius: 20px;
  height: '100%';
  transition: all 150ms ease;
  overflow: hidden;

  @media (max-width: 1280px) {
    margin-top: 110px;
  }
  @media (max-width: 768px) {
    margin-top: 64px;
    width: 312px;
  }
`

const classes = {
  hideToRight: 'hide-filters-body-to-right',
  hideToLeft: 'hide-filters-body-to-left',
  showFromLeft: 'show-filters-body-from-left',
  showFromRight: 'show-filters-body-from-right',
  hidden: 'filters-hidden',
  item: 'filters-item',
}

export default function OrderListFilters() {
  const { closeFilters, activeFilter, filters, filterError, loadingFilters } =
    useOrderListData()

  const ref = useRef<HTMLDivElement>(null)

  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {

    if (!activeFilter) {
      if (contentRef.current) {
        contentRef.current.style.height = `${(filters.length + 1) * 44 + 52}px`
        return;
      }
    }
    if (activeFilter) {
      const filter = filters.find(el => el.propertyName === activeFilter);

      if (activeFilter === PERIOD_FILTER_KEY) {
        if (contentRef.current) {
          contentRef.current.style.height = `290px`;
        }
        return;
      }

      if (filter) {
        if (contentRef.current) {
          contentRef.current.style.height = `${filter.items.length * 44 + 52}px`;
        }
      }
    }
  }, [activeFilter, filters])

  const currentFilter = useMemo(() => {
    if (activeFilter === PERIOD_FILTER_KEY) {
      return null
    }

    if (!activeFilter) return null

    return filters.find(el => el.propertyName === activeFilter)
  }, [activeFilter])

  return (
    <Overlay close={closeFilters}>
      <FilterWpapper ref={ref}>
        {loadingFilters && <Loader />}
        {!!filterError && (
          <Typography.Error styles={{ display: 'block', marginBottom: '16px' }}>
            {filterError}
          </Typography.Error>
        )}
        <FiltersHeader />
        <Container.Flex
          className={loadingFilters ? 'hide' : ''}
          fullWidth
          styles={{ height: 'auto' }}
          alignItems="stretch"
        >
          <Container.Flex
            fullWidth
            alignItems="stretch"
            styles={{ position: 'relative' }}
            ref={contentRef}
          >
            <Container.Flex
              fullWidth
              fullHeight
              className={cls({
                [classes.item]: true,
                [classes.hideToLeft]: !!activeFilter,
                [classes.showFromLeft]: !activeFilter,
              })}
            >
              {!activeFilter && <SelectFilters />}
            </Container.Flex>

            <Container.Flex
              fullWidth
              className={cls({
                [classes.item]: true,
                [classes.showFromRight]: !!currentFilter,
                [classes.hideToRight]: !currentFilter,
              })}
            >
              {!!currentFilter && (
                <ListFilters
                  filter={currentFilter}
                  key={currentFilter.propertyName}
                />
              )}
            </Container.Flex>

            <Container.Flex fullWidth className={cls({
              [classes.item]: true,
              [classes.showFromRight]: activeFilter === PERIOD_FILTER_KEY,
              [classes.hideToRight]: !activeFilter
            })}>
              {activeFilter === PERIOD_FILTER_KEY && <PeriodFilters />}
            </Container.Flex>

          </Container.Flex>
        </Container.Flex>
      </FilterWpapper>
    </Overlay>
  )
}
