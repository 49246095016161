import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box, Container, Icon, Image, Typography } from '../ui'
import styled, { useTheme } from 'styled-components/macro'
import { OrderItem } from '../../store/orderSlice/types'
import StatusImage from '../ui/Image/StatusImage'
import { useDispatch } from 'react-redux'
import { setDeleteItemNumber } from '../../store/orderSlice'
import { useLocation } from 'react-router-dom'
import { showOfferCard } from '../../store/viewSlice'
import { GivenAnswer, QuestionsData } from '../../store/offerSlice/types'
import { useOrderContext } from '../Order/OrderContext'
import { DeviceStatuses } from '../../types'

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87px;
  height: 87px;
  border-radius: 12px;
  background-color: white;
`

const OfferDeviceWrapper = styled.div`
  margin-bottom: 0;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`

const ToggleButton = styled.button<{
  red?: true
  visible: boolean
}>`
  cursor: pointer;
  padding: 0;
  color: ${props =>
    props.red
      ? props.theme.colors.info.error
      : props.theme.colors.link.default};
  font-family: inherit;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  background-color: transparent;
  border: none;
  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
`

const OfferDevice = ({
  data,
  currency,
  showResult,
  withDelete,
  setResize,
  onOpen,
  onClose,
  withoutClose,
  initVisible: initialVisible,
  notOwner,
}: {
  data: OrderItem
  givenAnswers?: GivenAnswer[]
  questionData?: QuestionsData | null
  hidingChars?: boolean | undefined
  currency: string
  showResult: boolean
  withDelete: boolean
  resize: boolean
  onOpen?: () => void
  onClose?: () => void
  setResize?: (value: boolean) => void
  withoutClose?: true
  initVisible?: true
  notOwner?: true
}) => {
  const { image, name, answers, status, price } = data

  const location = useLocation()

  const theme = useTheme()

  const dispatch = useDispatch()

  const _setDeleteItem = useCallback(
    (itemNumber: string) => {
      dispatch(setDeleteItemNumber(itemNumber))
    },
    [dispatch],
  )

  const [initVisible, setInitVisible] = useState(initialVisible ?? false)
  const [visibleChars, setVisibleChars] = useState(false)

  const { showDissentModal } = useOrderContext()

  const cardRef = useRef<HTMLDivElement>(null)

  const hideChar = useCallback(() => {
    setVisibleChars(false)
    if (onClose) onClose()
  }, [setVisibleChars])

  useEffect(() => {
    if (initVisible) {
      showChar()
    }
  }, [initVisible, setInitVisible, hideChar])

  const showChar = useCallback(() => {
    setVisibleChars(true)
    if (onOpen) onOpen()
  }, [setVisibleChars, visibleChars, onOpen])

  useEffect(() => {
    dispatch(showOfferCard())
  }, [location, hideChar])

  const underListMargin = useMemo(() => {
    return visibleChars ? '4px' : `${4 - (answers.length - 2) * 4}px`
  }, [visibleChars, answers])

  const canBeDeleted = useMemo(() => {
    if (withDelete === false) return false
    if (data.status) {
      return [
        DeviceStatuses.Preliminary,
        DeviceStatuses.Confirming,
        DeviceStatuses.Confirmed,
      ].includes(data.status)
    }
  }, [data, withDelete])

  const popupInfo = data.popupInfo

  return (
    <Fragment>
      <OfferDeviceWrapper>
        <Container.Flex
          fullWidth
          direction="row"
          alignItems="start"
          horizontalGap={16}
          justify="start"
        >
          <ImgWrapper>
            <Image
              src={image}
              noBasePath={true}
              alt={name}
              height={75}
              styles={{
                maxWidth: '75px',
                maxHeight: '75px',
              }}
            />
          </ImgWrapper>

          <Container.Flex alignItems="stretch" styles={{ flexGrow: 1 }}>
            <Typography.Title
              textAlign="start"
              styles={{ order: 0, margin: '0' }}
            >
              {name}
            </Typography.Title>
            <Container.Flex
              ref={cardRef}
              alignItems="stretch"
              styles={{
                overflow: 'hidden',
                // transition: "height 250ms",
              }}
              onTransitionEnd={() => setResize && setResize(true)}
            >
              {answers.map((ans, key) => (
                <Container.Flex
                  direction="row"
                  key={key}
                  alignItems="start"
                  wrapped
                  styles={{
                    overflowY: 'hidden',
                    order: key > 0 ? key + 1 : key,
                    maxHeight: visibleChars
                      ? `${(ans.name + '' + ans.value).length > 22 ? 32 : 16}px`
                      : key > 1
                      ? '0px'
                      : `${
                          (ans.name + '' + ans.value).length > 22 ? 32 : 16
                        }px`,
                    opacity: visibleChars ? '1' : key > 1 ? '0' : '1',
                    transition:
                      'max-height 200ms ease-in, opacity 200ms ease-in 50ms',
                    marginTop: visibleChars ? '4px' : key > 1 ? '0' : '4px',
                  }}
                >
                  {ans.name && (
                    <Typography.Tertiary
                      margin={'0 4px 0 0'}
                      fromStart
                      styles={{ width: 'max-content' }}
                    >
                      {ans.name}
                    </Typography.Tertiary>
                  )}
                  <Typography.Small textAlign="start">
                    {ans.value}
                  </Typography.Small>
                </Container.Flex>
              ))}
              {data.savePack && (
                <Container.Flex
                  direction="row"
                  gap={4}
                  margin={'4px 0 0 0'}
                  styles={{ order: 1 }}
                >
                  <Typography.Tertiary>Сейф</Typography.Tertiary>
                  <Typography.Small textAlign="start">
                    {(data.savePack.match(/.{1,2}/g) || []).join(' ')}
                  </Typography.Small>
                </Container.Flex>
              )}
            </Container.Flex>

            <Container.Flex direction="row" justify="between">
              {!!price && !visibleChars && (
                <Container.Flex
                  direction={'row'}
                  styles={{
                    marginTop: '4px',
                  }}
                  gap={4}
                >
                  <Container.Flex direction="row" gap={4}>
                    {status === DeviceStatuses.Paid && !!popupInfo && (
                      <Icon name={DeviceStatuses.ChangedAccepted} />
                    )}
                    {status && <Icon name={status} />}
                  </Container.Flex>
                  <Typography.Small>{`${price} ${currency}`}</Typography.Small>
                </Container.Flex>
              )}
              {visibleChars && <div></div>}

              <Icon
                name={
                  popupInfo
                    ? popupInfo.read
                      ? 'dissenct-read'
                      : 'dissence-active'
                    : 'dissence'
                }
                onClick={popupInfo ? showDissentModal : undefined}
              />
            </Container.Flex>
            <Container.Flex
              direction="row"
              fullWidth
              justify={withDelete ? 'between' : 'end'}
              styles={{
                marginTop: price ? '4px' : underListMargin,
              }}
            >
              {canBeDeleted && (
                <ToggleButton
                  id="delete-button"
                  type="button"
                  red
                  onClick={() => _setDeleteItem(data.itemNumber)}
                  visible={canBeDeleted && !notOwner}
                >
                  Удалить
                </ToggleButton>
              )}
              {!canBeDeleted && <Box></Box>}
              {!withoutClose && (
                <ToggleButton
                  type="button"
                  id="open-show-button"
                  visible={true}
                  onClick={() => {
                    if (visibleChars) {
                      hideChar()
                    } else {
                      showChar()
                    }
                  }}
                >
                  {visibleChars && 'Свернуть'}
                  {!visibleChars && 'Развернуть'}
                </ToggleButton>
              )}
            </Container.Flex>
          </Container.Flex>
        </Container.Flex>
        {!!showResult && (
          <Container.Flex
            direction="row"
            justify="center"
            margin="16px auto 6px"
            padding="0 4px"
          >
            <StatusImage status={DeviceStatuses.Confirmed} result />
            <Typography.Title
              margin={0}
              color={theme.colors.link.default}
              styles={{
                fontWeight: '600',
                fontSize: '16px',
              }}
            >
              Стоимость подтверждена
            </Typography.Title>
          </Container.Flex>
        )}
      </OfferDeviceWrapper>
    </Fragment>
  )
}

export default OfferDevice
