import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Api from '../../../apinew'
import { getErrorString } from '../../../helpers/getErrorString'
import { PATHS } from '../../../pages/PATHS'
import { bp } from '../../../theme/media'
import { ApiState } from '../../../types'
import { CounterItem } from '../../ListData/ListData'
import LowerMenuContent from '../../LowerMenu/LowerMenuContent'
import { Container, Loader, Typography } from '../../ui'
import { TransportApplicationItem as TransportApplicationItemType } from './TransportApplication.types'
import TransportApplicationHeader from './TransportApplicationHeader'
import TransportApplicationInput from './TransportApplicationInput'
import TransportApplicationItem from './TransportApplicationtem'
import { TransportAppStatuses } from '../../../apinew/types'

export default function TransportApplication({
  appNumber,
}: {
  appNumber?: string
}) {
  const [pending, setPending] = useState(false)
  const [counterItems, setCounterItems] = useState<CounterItem[]>([])
  const [items, setItems] = useState<TransportApplicationItemType[]>([])

  const navigate = useNavigate()

  const [appState, setAppState] = useState<
    ApiState<{ appNumber: string | null; status: TransportAppStatuses | null }>
  >({
    pending: false,
    error: '',
    appNumber: appNumber ?? null,
    status: null,
  })

  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');

  const checkPackNumberInList = (packNumber: string) => {
    return items.some(el => el.items.some(el => el.savepackNumber === packNumber && el.status !== 'miss'))
  }

  const fetchList = useCallback(async () => {
    if (!appState.appNumber) return

    const api = new Api()
    try {
      const response = await api.transport.getAppList({
        appNumber: appState.appNumber,
      })
      if (response.status === 'success' && response.data) {
        setItems(response.data.data)
        setAppState(prev => ({
          ...prev,
          status: response.data?.application.status ?? null,
        }))
        setCounterItems(response.data.counterItems)
      }
      if (response.status === 'error') {
        setError(getErrorString(response.errors))
      }
    } catch (error: any) {
      setError(error.message)
    }
  }, [appState.appNumber, items])

  const deleteSavePack = useCallback(
    async (savePackNumber: string) => {
      if (appState.appNumber) {
        setPending(true)
        const response = await new Api().transport.deleteSavePack({
          savePackNumber,
          appNumber: appState.appNumber,
        })

        if (response.status === 'success') {
          fetchList()
        }
        if (response.status === 'error') {
          setError(getErrorString(response.errors))
        }
        setPending(false)
      }
    },
    [appState.appNumber],
  )

  useEffect(() => {
    if (appState.appNumber) {
      fetchList()
    }
  }, [appState.appNumber])

  const search = useCallback(
    async (savePackNumber: string) => {

      if (checkPackNumberInList(savePackNumber)) {
        return setWarning('Введенный пакет уже в списке')
      }

      const api = new Api()
      setPending(true)
      try {
        if (!appState.appNumber) {
          const response = await api.transport.createApp({ savePackNumber })
          if (response.status === 'success' && response.data) {
            const data = response.data
            setAppState(prev => ({
              ...prev,
              appNumber: data.applicationNumber,
            }))
            fetchList()
          }
          if (response.errors.length) {
            setError(getErrorString(response.errors))
          }
        } else {
          const response = await api.transport.addSavePack({
            savePackNumber,
            appNumber: appState.appNumber,
          })
          if (response.status === 'success' && response.data?.isSuccess) {
            fetchList()
          }
          if (response.errors.length) {
            setError(getErrorString(response.errors))
          }
        }
      } catch (error: any) {
        setError(error.message)
      } finally {
        setPending(false)
      }
    },
    [appState.appNumber, checkPackNumberInList],
  )

  useEffect(() => {
    if (!appState.appNumber && !appState.pending && !appState.error) {
      const api = new Api()
      setAppState(prev => ({ ...prev, pending: true }))
      api.transport
        .getActiveUserApp({})
        .then(response => {
          if (response.status === 'success' && response.data) {
            const appNumber = response.data.applicationNumber || null
            setAppState(prev => ({ ...prev, appNumber }))
          }
          if (response.status === 'error') {
            setAppState(prev => ({
              ...prev,
              error: getErrorString(response.errors),
            }))
          }
        })
        .catch(error => {
          setAppState(prev => ({ ...prev, error: error.message }))
        })
        .finally(() => {
          setAppState(prev => ({ ...prev, pending: false }))
        })
    }
  }, [appState])

  const confirmApplication = useCallback(() => {
    setPending(true)
    const api = new Api()
    api.transport
      .confirm({ appNumber: appState.appNumber || '' })
      .then(response => {
        if (response.data?.isSuccess) {
          navigate(PATHS.COURIER_RECEIVED_PACKETS)
        } else {
          setError('Ошибка сохранения')
        }

        if (response.errors) {
          setError(getErrorString(response.errors))
        }
      })
      .finally(() => {
        setPending(false)
      })
  }, [appState.appNumber])

  const resetError = useCallback(() => {
    setError('');
  }, [setError])

  useEffect(() => {
    if (warning) {
      setTimeout(() => setWarning(''), 2000)
    }
  }, [warning])

  if (appState.pending) {
    return <Loader />
  }

  return (
    <Container.Flex fullWidth className="relocation-courier-content">
      <Container.Flex
        fullWidth
        direction="row"
        justify="between"
        className="relocation-courier-header"
        wrapped
        gap={24}
      >
        <Container.Flex
          gap={16}
          direction="row"
          justify="between"
          breakpoints={{
            [bp.mobile]: {
              width: '100%',
            },
          }}
        >
          <Typography.Title>Перемещение</Typography.Title>
          <Typography.Link href={PATHS.stocks}>Все склады</Typography.Link>
        </Container.Flex>

        <Container.Flex
          maxWidth={300}
          fullWidth
          breakpoints={{
            [bp.mobile]: {
              maxWidth: 'none',
              width: '100%',
            },
          }}
        >
          <TransportApplicationInput
            search={search}
            pending={pending}
            afterChange={resetError}
          />
        </Container.Flex>
      </Container.Flex>

      <TransportApplicationHeader items={counterItems} />

      <Container.Flex fullWidth className="relocation-courier-list" gap={24}>
        {error && <Typography.Error>{error}</Typography.Error>}
        {warning && <Typography.Error>{warning}</Typography.Error>}
        {pending && <Loader />}

        {items.map(el => (
          <TransportApplicationItem
            key={el.number}
            data={el}
            deleteSavePack={deleteSavePack}
          />
        ))}

        <LowerMenuContent
          items={[
            {
              icon: 'check-circle',
              title: 'Принять пакеты',
              onClick: confirmApplication,
              disabled:
                appState.status !== TransportAppStatuses.complete ||
                !items.length ||
                undefined,
            },
          ]}
        />
      </Container.Flex>
    </Container.Flex>
  )
}
