import SVG from '../SVG'
import { IconProps } from '../types'

const StatusChangedAccepted = (props: IconProps) => {
  const width = props.width ?? 16
  const height = props.height ?? 16
  return (
    <SVG
      {...props}
      width={width}
      height={height}
      styles={{
        color: props.color ?? '#FF9D00',
      }}
    >
      <g clipPath="url(#clip0_5337_23230)">
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4798 11.5555C10.4798 11.9973 10.1216 12.3555 9.67976 12.3555H9.51976C9.07793 12.3555 8.71976 11.9973 8.71976 11.5555V11.3955C8.71976 10.9537 9.07793 10.5955 9.51976 10.5955H9.67976C10.1216 10.5955 10.4798 10.9537 10.4798 11.3955V11.5555ZM9.59978 9.88012C9.11377 9.88012 8.71978 9.48613 8.71978 9.00012L8.71978 4.49963C8.71978 4.01362 9.11377 3.61963 9.59978 3.61963C10.0858 3.61963 10.4798 4.01362 10.4798 4.49963V9.00012C10.4798 9.48613 10.0858 9.88012 9.59978 9.88012ZM7.27978 11.5555C7.27978 11.9973 6.92161 12.3555 6.47978 12.3555H6.31978C5.87795 12.3555 5.51978 11.9973 5.51978 11.5555L5.51978 11.3955C5.51978 10.9537 5.87795 10.5955 6.31978 10.5955H6.47978C6.9216 10.5955 7.27978 10.9537 7.27978 11.3955V11.5555ZM6.39953 9.8798C5.91352 9.8798 5.51953 9.48581 5.51953 8.9998V4.50028C5.51953 4.01427 5.91352 3.62028 6.39953 3.62028C6.88554 3.62028 7.27953 4.01427 7.27953 4.50028L7.27953 8.9998C7.27953 9.48581 6.88554 9.8798 6.39953 9.8798Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5337_23230">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </SVG>
  )
}

export default StatusChangedAccepted
