import styled from "styled-components/macro";
import { Props } from "../../types";

export type GridProps = Props<{
    twoColumns?: boolean
}>;

const GridContainer = styled.div<GridProps>`
	display: grid;
	grid-template-columns: ${props => props.twoColumns ? "1fr 1fr": "1fr 1fr 1fr"};
	grid-gap: 36px;
    align-items: start;
    width: 100%;

	@media (max-width: 1279px) {
		grid-template-columns: 1fr 1fr;
	}
	@media (max-width: 767px) {
        height: auto;
		grid-template-columns: 1fr;
		grid-auto-rows: max-content;
		grid-gap: 24px;
	}
`

const CardsGrid = (props: GridProps) => {
    return (
        <GridContainer {...props}>
            {!!props.children && props.children}
        </GridContainer>
    );
};

export default CardsGrid;
