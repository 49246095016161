import { toFormData } from "../../../apinew/helpers"
import { Session } from "../../userSlice/types";

export const collectFormData = (obj: any, session?: Session) => {

  const data = toFormData(obj);

  if (session?.auth_param_name && session?.auth_param_value) {
    data.append(session.auth_param_name, session.auth_param_value)
  }

  return data;
}