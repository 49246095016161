import { useNavigate } from 'react-router-dom'
import useQuery from '../../hooks/useQuery'
import { useAppDispatch } from '../../store/hooks'
import { setStep } from '../../store/offerSlice'
import { OrderItem } from '../../store/orderSlice/types'
import { Button, Container } from '../ui'

interface PrePriceProps {
  itemData?: OrderItem
  currency?: string
  onClick?: () => void
  itemNumber?: string
}

const OfferPrePrice = (props: PrePriceProps) => {
  const { onClick } = props

  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const { orderNumber, itemNumber } = useQuery()

  const _onClick = () => {
    if (onClick) {
      onClick()
    } else if (
      (!itemNumber && orderNumber) ||
      (props.itemNumber !== itemNumber && props.itemNumber)
    ) {
      navigate(`/order/${orderNumber}/${props.itemNumber}/`)
    } else if (itemNumber && orderNumber) {
      dispatch(setStep('questions'))
    }
  }

  return (
    <Container.Flex fullWidth alignItems="stretch">
      <Button onClick={_onClick} styles={{ textTransform: 'uppercase' }}>
        К финальной стоимости
      </Button>
    </Container.Flex>
  )
}

export default OfferPrePrice
