import { useAuthData } from '../../contexts/auth'
import { Card, Container, Typography } from '../ui'
import Icon from '../ui/Icon'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Fragment, useMemo } from 'react'
import { getProfileContent, ProfileMenuItem } from './ProfileMenuContent'
import { Link } from 'react-router-dom'

const LinkWrapper = styled.span<{ active: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 36px;
    text-decoration: none;
    color: ${props =>
        props.active
            ? props.theme.colors.link.default
            : props.theme.colors.text.secondary};
    transition: color 250ms ease-in;

    &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.link.default};
    }

    &::after {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        display: ${props => (props.active ? 'block' : 'none')};
        background-color: currentColor;
        content: '';
    }
`

const linkStyles = {
    textDecoration: "none"
}

const ProfileLink = (props: ProfileMenuItem) => {
    const { title, icon, onClick } = props

    const location = useLocation();
    const params = useParams()

    const pathName = useMemo(() => {
        let pathname = location.pathname;
        const paramsValues = Object.values(params);
        paramsValues.forEach((el) => {
            if (el) {
                pathname = pathname.replace(el, '')
            }
        })
        return pathname
    }, [location, params])

    return (
        <LinkWrapper
            onClick={onClick}
            active={props.href === pathName}
        >
            <Icon
                color="currentColor"
                name={icon}
                styles={{ marginRight: '16px' }}
            />
            <Typography.Main
                color="currentColor"
                margin={0}
                styles={{ fontWeight: '500' }}
                noTransition
            >
                <span dangerouslySetInnerHTML={{ __html: title }} />
            </Typography.Main>
        </LinkWrapper>
    )
}
const ProfileMenu = () => {
    const { logout, userData } = useAuthData()

    const links = useMemo(
        () =>
            userData?.userType
                ? getProfileContent(logout, userData?.userType)
                : [],
        [userData],
    )

    return (
        <Card padding={0} margin="0 0 24px">
            <Container.Flex
                fullWidth
                alignItems="stretch"
                styles={{ padding: '48px 0 12px 52px' }}
            >
                {links.map((props, idx) => (
                    <Fragment key={idx}>
                        {props.href ? (
                            <Link to={props.href} style={linkStyles}>
                                <ProfileLink {...props} />
                            </Link>
                        ) : (
                            <ProfileLink {...props} />
                        )}
                    </Fragment>
                ))}
            </Container.Flex>
        </Card>
    )
}

export default ProfileMenu
