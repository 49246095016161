import { ComponentType, ReactElement } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AuthWrapper from '../../contexts/auth'
import PopupImage from '../../hooks/usePopupImage'

export const withAuth =
    <T extends object>(Component: ComponentType<T>, needAuth?: true) =>
    (props: T): ReactElement => {

        return (
            <Router>
                <PopupImage>
                    <AuthWrapper>
                        <Component {...props} />
                    </AuthWrapper>
                </PopupImage>
            </Router>
        )
    }
