import { Button, Container, Input, Typography } from '../../../ui'
import { useOrderingData } from '../Ordering/OrderingContext'
import {
  useSendSmsCodeMutation,
  useValidSmsCodeMutation,
} from '../../../../store/api/api'
import { Fragment, useEffect, useRef, useState } from 'react'
import { TextInput } from './TextInput'
import InputInfo from './InputInfo'
import {
  formatPhoneValue,
  onPhoneInputChange,
  onPhoneInputFocus,
} from '../../../../helpers/formatPhoneValue'
import { onKeyEnter } from '../../../../helpers/onKeyEnter'
import { useTheme } from 'styled-components'
import { useSession } from '../../../../hooks/useSession'
import { OtpProps, withOtp } from '../../../../hooks/withOtp'

function SMSCode({ otp } : OtpProps) {
  const {
    getValues,
    smsStatus,
    register,
    errors,
    setValue,
    setSmsStatus,
    validCode,
    setValidCode,
  } = useOrderingData()


  const intervalRef = useRef<ReturnType<typeof setInterval>>()
  const theme = useTheme()
  const timerValue = useRef<number>(60)
  const timerRef = useRef<HTMLSpanElement>(null)
  const telRef = useRef<HTMLInputElement>(null);
  const [resendDisabled, setResendDisabled] = useState(false)

  const { session } = useSession()

  const [
    sendSms,
    {
      isLoading: sendSmsPending,
      data: sendSmsData,
      isError,
      isSuccess,
      error,
      reset: resetSms,
    },
  ] = useSendSmsCodeMutation()

  const [
    validSms,
    {
      isLoading: validSmsPending,
      data: validSmsData,
      error: _validSmsError,
      reset,
    },
  ] = useValidSmsCodeMutation()

  const sendSMserror = error as any
  const validSmsError = _validSmsError as any

  const codeInputRef = useRef<HTMLInputElement>(null)

  const onChange = (e: any) => {
    onPhoneInputChange(e)
    setValue('phone', e.target.value)
  }

  const sendCode = () => {
    sendSms({ data: { phone: getValues().phone ?? telRef.current?.value ?? '' }, session })
  }

  const resendCode = () => {
    setResendDisabled(false)
    resetSms()
    timerValue.current = 60;
    setSmsStatus("not-sent")
  }

  useEffect(() => {
    if (sendSmsData?.codeSent) {
      setSmsStatus('code-sent')
    }
  }, [setSmsStatus, sendSmsData?.codeSent])

  const startTimer = () => {
    setResendDisabled(true)
    intervalRef.current = setInterval(() => {
      if (timerValue.current) {
        timerValue.current -= 1
        if (timerRef.current) {
          timerRef.current.innerText = `через ${timerValue.current} сек`
        }
      }
      if (timerValue.current <= 0) {
        setResendDisabled(false)
        intervalRef.current && clearInterval(intervalRef.current)
      }
    }, 1000)
  }

  useEffect(() => {
    if (smsStatus === 'code-aproved' || smsStatus === 'not-filled' || smsStatus === "not-sent") {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [smsStatus])

  useEffect(() => {
    if (isSuccess) {
      startTimer();
    }
  }, [isSuccess])

  const checkSMS = (value: string) => {
    if (value.length === 4) {
      setValidCode(value)
      validSms({
        data: { phone: getValues('phone'), code: value },
        session,
      })
    }
  }

  const onCodeChange = (e: any) => {
    reset()
    checkSMS(e.target.value as string)
  }

  useEffect(() => {
    if (validSmsData?.isValid) {
      setSmsStatus('code-aproved')
    } else {
      if (validSmsData && !validSmsData.isValid) {
        setValidCode('')
      }
    }
  }, [validSmsData])

  useEffect(() => {
    if (/\d{4}/.test(otp)) {
      if (codeInputRef.current) {
        codeInputRef.current.value = otp
        checkSMS(otp);
      }
    }
  }, [otp])

  return (
    <Fragment>
      <Container.Flex
        fullWidth
        alignItems="end"
        relative
        styles={{
          order: 5,
        }}
      >
        <TextInput
          key={'phone'}
          type={'tel'}
          {...register('phone', {
            pattern: /\+\d{3} \(\d{2}\) \d{3} \d{2} \d{2}/,
          })}
          ref={telRef}
          disabled={smsStatus === 'code-sent'}
          onKeyDown={onKeyEnter(sendCode)}
          defaultValue={getValues().phone ?? formatPhoneValue('')}
          onFocus={onPhoneInputFocus}
          onChange={onChange}
          onClick={onPhoneInputFocus}
          error={errors['phone']}
          autoComplete={'false'}
        />
        <InputInfo
          error={isError ? sendSMserror?.data ?? 'Ошибка сервера' : ''}
        />
      </Container.Flex>
      {(smsStatus === 'not-sent' || smsStatus === 'not-filled') && !validSmsData?.isValid && (
        <Button
          fullWidth
          disabled={smsStatus === 'not-filled'}
          withLoader
          pending={sendSmsPending}
          onClick={() => {
            setValidCode('')
            sendCode()
          }}
          styles={{
            order: 5,
          }}
        >
          Отправить СМС-код
        </Button>
      )}

      {smsStatus === 'code-sent' && (
        <Container.Flex
          styles={{ order: 5 }}
          fullWidth
          direction="row"
          padding={'0 4px'}
          justify="start"
        >
          <Typography.Small
            margin={'0 4px 0 0'}
            color={
              resendDisabled
                ? theme.colors.button.disable
                : theme.colors.link.default
            }
            onClick={resendDisabled ? undefined : resendCode}
          >
            Отправить ещё раз код
          </Typography.Small>
          {timerValue.current > 0 && <Typography.Small ref={timerRef}>через 60 секунд</Typography.Small>}
        </Container.Flex>
      )}

      {(smsStatus === 'code-aproved' || smsStatus === 'code-sent' || validSmsData?.isValid) && (
        <Container.Flex fullWidth styles={{ order: 5 }}>
          <Input
            defaultValue={validCode}
            id="single-factor-code-text-field"
            ref={codeInputRef}
            autoComplete="one-time-code"
            disabled={validSmsPending || smsStatus === "code-aproved"}
            error={validSmsError?.data ?? ''}
            success={validSmsData?.message || smsStatus === 'code-aproved' || ''}
            type="tel"
            maxLength={4}
            placeholder={'Код из СМС'}
            onChange={onCodeChange}
          />
        </Container.Flex>
      )}
    </Fragment>
  )
}

export default withOtp(SMSCode)
