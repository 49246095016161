import { styled } from '../../../../helpers/styled'
import { Container, Button, Typography, Box } from '../../../ui'
import { Filter, useOrderListData } from '../OrderListContext'
import { useTheme } from 'styled-components/macro'

const CheckBox = styled.div<{ checked: boolean }>`
  position: relative;
  height: 18px;
  width: 18px;
  margin: 1px;
  color: ${props =>
    props.checked
      ? props.theme.colors.link.default
      : props.theme.colors.icon.secondary};
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: ${props =>
    props.checked ? props.theme.colors.link.default : 'transparent'};
  transition: color 100ms ease;
  &::after {
    position: absolute;
    left: 0;
    top: 2px;
    width: 13px;
    height: 11px;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='11' viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 5.5L5 9L12 1' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: ${props => (props.checked ? '1' : `0`)};
    content: '';
  }
`

interface Props {
  filter: Filter
}

export default function ListFilters({ filter }: Props) {
  const { onFilterChange, resetOneFilter, setActiveFilter, listFilterOn } =
    useOrderListData()

  const { items } = filter
  const theme = useTheme()

  return (
    <Box
      styles={{
        width: '100%',
        transition: 'max-height 150ms ease',
      }}
    >
      <Container.Flex
        alignItems="stretch"
        fullWidth
        styles={{ paddingLeft: '4px' }}
      >
        {items.map(item => {
          return (
            <Container.Flex
              direction="row"
              justify="between"
              key={item.value}
              styles={{
                marginBottom: '24px',
                color: theme.colors.text.default,
                transition: 'color 250ms linear',
              }}
              hoverStyles={{
                cursor: 'pointer',
                color: theme.colors.link.hover,
              }}
              onClick={() => {
                onFilterChange(filter.propertyName)(item.name)
              }}
            >
              <Typography.Main
                margin={0}
                styles={{ color: 'currentColor' }}
                noTransition
              >
                {item.name}
              </Typography.Main>
              <CheckBox checked={item.chosen ?? false} />
            </Container.Flex>
          )
        })}
      </Container.Flex>
      <Container.Grid cols="1fr 1fr" gap={16} fullWidth margin={'10px 0 0 0'}>
        <Button
          variant="danger"
          disabled={!listFilterOn(filter.propertyName)}
          onClick={resetOneFilter(filter.propertyName)}
        >
          Сбросить
        </Button>
        <Button
          onClick={() => {
            setActiveFilter(null)
          }}
        >
          Вернуться
        </Button>
      </Container.Grid>
    </Box>
  )
}
