import { useTheme } from 'styled-components'
import {
  Box,
  Button,
  Card,
  Confirm,
  Container,
  Loader,
  /*Info */ Price,
  Typography,
} from '../ui'
import { Order, OrderStatuses } from '../../store/orderSlice/types'
import useSetRegistrationData from '../../hooks/useSetRegistrationData'
import { useSession } from '../../hooks/useSession'
import { bp } from '../../theme/media'
import { content } from '../../content'
import { priceToNumber } from '../../helpers/priceToNumber'
import { Fragment, useMemo, useState } from 'react'
import { DeviceStatuses } from '../../types'
import { useOrderContext } from './OrderContext'
import { getSavePackageString } from '../../helpers/getSavePackageString'

interface OrderBottomCardProps {
  orderData: Order
}

const Line = ({
  label,
  amount,
  currency,
  text,
}: {
  label: string
  amount?: number
  currency?: string
  text?: string
}) => {
  const theme = useTheme()

  return (
    <Container.Flex direction="row" justify="start" fullWidth>
      <Typography.Medium
        color={theme.colors.text.tertiary}
        margin="0 4px 0 0"
        fromStart
      >
        {label}
      </Typography.Medium>

      <Price amount={amount} currency={currency} text={text} />
    </Container.Flex>
  )
}

const ProcessedButton = (props: {
  value: string
  outline: boolean
  onClick: () => void
}) => {
  const { value, outline, onClick } = props

  return (
    <Button
      fullWidth
      variant={outline ? 'outline' : 'primary'}
      onClick={onClick}
    >
      {value}
    </Button>
  )
}

const OrderBottomCard = ({ orderData }: OrderBottomCardProps) => {
  const theme = useTheme()

  const { isAdmin } = useSession()

  const { orderButton } = useOrderContext()

  const {
    amount,
    amountRansom,
    amountSelectOptionPrice,
    currency,
    currencyLang,
    button,
    status,
    registration,
  } = orderData

  const _amount = priceToNumber(amount)
  const _amountRansom = priceToNumber(amountRansom)
  const _amountSelectOptionPrice = priceToNumber(amountSelectOptionPrice)
  const [showConfirm, setShowConfirm] = useState<number | null>(null)

  const { loading, setRegistration } = useSetRegistrationData()

  // const description = useMemo(
  //   () => (button ? button.map(el => el.description) : []),
  //   [button],
  // )

  const isDealDone = useMemo(() => {
    return orderData.status === OrderStatuses.Done
  }, [orderData])

  const readyItemsCount = useMemo(() => {
    if (!orderData.items) {
      return ''
    }

    const readyItems = orderData.items.filter(
      el => el.status === DeviceStatuses.Confirmed,
    ).length

    if (readyItems === orderData.items.length) {
      return `${readyItems} шт.`
    } else {
      return `${readyItems} из ${orderData.items.length} шт.`
    }
  }, [orderData.items])

  const notReadyItems = useMemo(() => {
    if (!orderData.items) {
      return false
    }

    return orderData.items.some(el => el.status !== DeviceStatuses.Confirmed)
  }, [orderData.items])

  const rightPartTitle = useMemo(() => {
    if (!isAdmin) return ''
    if (!orderData) return ''

    const { status } = orderData

    if (status === OrderStatuses.Packed) {
      return 'Заявка запакована'
    }

    return ''
  }, [orderData, isAdmin])

  return (
    <Card
      padding={28}
      styles={{
        gridColumn: '1 / -1',
        marginTop: 'auto',
      }}
    >
      <Container.Flex
        fullWidth
        alignItems="stretch"
        gap={16}
        justify="between"
        direction="row"
        breakpoints={{
          [bp.mobile]: {
            flexDirection: 'column',
            alignItems: 'stretch',
          },
        }}
      >
        <Container.Flex
          alignItems="start"
          verticalGap={16}
          styles={{ width: isAdmin ? '270px' : '592px' }}
          breakpoints={{
            [bp.table]: {
              width: '256px',
            },
            [bp.mobile]: {
              width: '100%',
            },
          }}
        >
          <Typography.Title
            color={theme.colors.text.secondary}
            margin="0 0 8px"
            padding="0 4px"
          >
            {isDealDone
              ? content.totalCard.titleConfirm
              : content.totalCard.title}
          </Typography.Title>

          <Container.Flex verticalGap={8} padding="0 4px" fullWidth>
            <Line
              label={orderData.status === OrderStatuses.Confirm ? "Устройств" : "Оформлено устройств: "}
              text={readyItemsCount ? readyItemsCount : ' не указана'}
            />
            {_amount && !!registration && (
              <Line
                label={registration}
                amount={_amountSelectOptionPrice}
                currency={currencyLang || currency}
              />
            )}

            {!registration && (
              <Fragment>
                <Line
                  label={content.totalCard.tradeIn}
                  amount={_amount}
                  currency={currencyLang || currency}
                />
                <Line
                  label={content.totalCard.sale}
                  amount={_amountRansom}
                  currency={currencyLang || currency}
                />
              </Fragment>
            )}
          </Container.Flex>
        </Container.Flex>
        <Container.Flex
          fullWidth
          direction="column"
          alignItems="stretch"
          justify="between"
          gap={16}
          styles={{ maxWidth: 'calc(50% - 18px)' }}
          wrapped
          breakpoints={{
            [bp.table]: {
              flexDirection: 'column',
            },
            [bp.mobile]: {
              maxWidth: 'none',
              minHeight: 'auto',
            },
          }}
        >
          {!isAdmin && (
            <Box
              breakpoints={{
                [bp.mobile]: {
                  display: 'none',
                },
              }}
            ></Box>
          )}
          {!isAdmin && (
            <Button fullWidth link="/partners/">
              Выбрать партнера
            </Button>
          )}

          {isAdmin && (
            <Typography.TitleSecondary margin={0} fromStart>
              {rightPartTitle}
            </Typography.TitleSecondary>
          )}

          {isAdmin && !!orderData.savePacks && (
            <Container.Flex alignItems="start" gap={8}>
              {orderData.savePacks?.some(el => !!el) &&
                orderData.savePacks.map((el, idx) => (
                  <Container.Flex gap={4} direction="row" key={el}>
                    <Typography.Medium
                      color={theme.colors.text.tertiary}
                      margin={0}
                    >{`Сейф-пакет #${idx + 1}:`}</Typography.Medium>
                    <Typography.RublesMedium margin={0}>
                      {getSavePackageString(el)}
                    </Typography.RublesMedium>
                  </Container.Flex>
                ))}
            </Container.Flex>
          )}

          {isAdmin &&
            status === OrderStatuses.Confirm &&
            button &&
            button.map((el, i) => (
              <Fragment key={i}>
                <ProcessedButton
                  value={el.value}
                  key={i}
                  outline={i % 2 === 1}
                  onClick={() =>
                    notReadyItems ? setShowConfirm(i) : setRegistration(el.code)
                  }
                />
                <Confirm
                  yesText="Удалить"
                  noText="Отмена"
                  questionTitle="Важная информация!"
                  question="Оформить сделку можно только с устройствами, у которых подтверждена стоимость. Удалить устройства без подтверждённой стоимости?"
                  visible={showConfirm === i}
                  hide={() => setShowConfirm(null)}
                  onYes={() => setRegistration(el.code)}
                  yesVariant="danger"
                  noVariant="primary"
                  yesFirst
                />
              </Fragment>
            ))}

          {orderButton &&
            orderButton.text !== 'Отменить заявку' &&
            !orderButton.notShowOnBottom && (
              <Button
                fullWidth
                onClick={orderButton.onClick}
                styles={{ maxHeight: '40px' }}
              >
                {orderButton.text.toUpperCase()}
              </Button>
            )}
        </Container.Flex>
      </Container.Flex>

      {loading && (
        <Container.Flex
          styles={{ position: 'absolute', top: 0, left: 0 }}
          fullHeight
          fullWidth
        >
          <Loader />
        </Container.Flex>
      )}
    </Card>
  )
}

export default OrderBottomCard
