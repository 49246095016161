import { put } from "redux-saga/effects";
import { GetErrorForm, setCardError, setCurrentErrorMessage } from "../offerSlice";
import { setPageVisible } from "../viewSlice";

export default function* handleError(response: any, code?: string) {
    const errorCode = code ?? (response.errors.find((err: any) => err.customData)?.customData?.showForm ?? "default");
    const currentErrorMessage = response.errors.find((err: any) => err.message)?.message ?? "";
    if (currentErrorMessage.startsWith('timeout of')) {
        yield put(setCardError('Время ожидания исстекло.'))
        yield put(setPageVisible(true))
    } else {
        yield put(setCurrentErrorMessage(currentErrorMessage))
        yield put(GetErrorForm.request(errorCode));
        return;
    }

}