import { Container, Button, InputDate, Typography, Box } from '../../../ui'
import { useOrderListData } from '../OrderListContext'
import { styled } from '../../../../helpers/styled'
import { ChangeEvent } from 'react'
import { useTheme } from "styled-components";

export const RadioBox = styled.div<{ checked: boolean }>`
  position: relative;
  height: 20px;
  width: 20px;
  color: ${props =>
    props.checked
      ? props.theme.colors.link.default
      : props.theme.colors.icon.secondary};
  border: ${props =>
    `${props.checked ? '7' : '2'}px solid ${
      props.checked
        ? props.theme.colors.link.default
        : props.theme.colors.icon.secondary
    }`};
  border-radius: 50%;
  background-color: transparent;
  transition: all 100ms ease;
`

export default function PeriodFilters() {
  const {
    onDateFilterChange,
    dateFilters,
    dateValue,
    setDateValue,
    setActiveFilter,
    resetPeriodFilters,
    activePeriodFilter,
    periodFiltersOn
  } = useOrderListData()

  const theme = useTheme()

  return (
    <Container.Flex
      alignItems="stretch"
      fullWidth
      styles={{
        transition: 'max-height 150ms ease',
      }}
    >
      <Container.Flex alignItems="stretch" styles={{paddingLeft: "4px"}}>
        {Object.entries(dateFilters).map(([key, value]: [any, any]) => {
          return (
            <Container.Flex
              direction="row"
              justify="between"
              key={key}
              styles={{
                marginBottom: '24px',
                color: theme.colors.text.default,
                transition: "color 250ms linear"
              }}
              hoverStyles={{ cursor: 'pointer', color: theme.colors.link.hover }}
              onClick={() => {
                onDateFilterChange(key)
              }}
            >
              <Typography.Main margin={0} styles={{color: "currentColor"}} noTransition>{value.title}</Typography.Main>
              <RadioBox checked={activePeriodFilter === key} />
            </Container.Flex>
          )
        })}
      </Container.Flex>
      <Container.Flex
        direction="row"
        styles={{
          marginBottom: '32px',
        }}
      >
        <Box styles={{ width: 'calc(50% - 4px)' }}>
          <InputDate
            value={dateValue.from}
            changeHandler={(event: ChangeEvent<HTMLInputElement>) => {
              setDateValue({
                from: event.target.value ? new Date(event.target.value) : null,
                to: dateValue.to,
              })
            }}
            placeholder="от"
          />
        </Box>

        <Typography.Main margin="0 8px">-</Typography.Main>
        <Box styles={{ width: 'calc(50% - 4px)' }}>
          <InputDate
            value={dateValue.to}
            changeHandler={(event: ChangeEvent<HTMLInputElement>) => {
              setDateValue({
                from: dateValue.from,
                to: event.target.value ? new Date(event.target.value) : null,
              })
            }}
            placeholder="до"
          />
        </Box>
      </Container.Flex>

      <Container.Grid cols="1fr 1fr" gap={16}>
        <Button variant="danger" onClick={resetPeriodFilters} disabled={!periodFiltersOn}>
          Сбросить
        </Button>
        <Button
          onClick={() => {
            setActiveFilter(null)
          }}
        >
          Вернуться
        </Button>
      </Container.Grid>
    </Container.Flex>
  )
}
