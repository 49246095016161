import { useTheme } from 'styled-components'
//import { bp } from '../../theme/media'
import { useFiltersData } from '../Filters/FiltersContext'
import { Container, Typography, Input, Icon } from '../ui'
import styled from 'styled-components/macro'
import { bp } from '../../theme/media'

const Filter = styled.button`
  background-color: transparent;
  border: none;
  padding: 10px;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  flex-shrink: 0;
  margin: 0 2px;

  @media (max-width: 768px) {
    padding-right: 8px;
    margin-right: 0;
  }
`

const SearchWrapper = styled.div`
  flex-grow: 1;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 36px;

  @media (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 12px;
  }
`

export default function Search() {
  const {
    filtersOn,
    setShowFilters,
    showFilters,
    getFiltersData,
    search,
    setSearch,
    getData,
  } = useFiltersData()

  const theme = useTheme()

  const onSearch = () => {
    const requestData = {} as any
    requestData.filters = getFiltersData

    if (search) {
      requestData.search = search
    }
    getData(requestData)
  }

  return (
    <Grid>
      <Container.Flex
        direction="row"
        styles={{maxWidth: "100%", gridColumn: "span 2"}}
        breakpoints={{
          [bp.table]: {
            gridColumn: "1 / 2"
          },
          [bp.mobile]: {
            justifyContent: 'space-between',
          },
        }}
      >
        <Typography.Title margin={'0 4px'} padding={'0'}>
          Магазины-партнёры
        </Typography.Title>

        <Typography.Medium
          margin={0}
          color={theme.colors.link.default}
          styles={{ display: 'none' }}
          breakpoints={{
            [bp.mobile]: {
              display: 'block',
            },
          }}
          onClick={() => setShowFilters(!showFilters)}
        >
          Фильтр
        </Typography.Medium>
      </Container.Flex>

      <Container.Flex
        direction="row"
        justify="end"
        styles={{maxWidth: "100%"}}
      >
        <SearchWrapper>
          <Input
            type="text"
            value={search}
            placeholder="Поиск"
            icon="search"
            iconPosition="right"
            iconClick={onSearch}
            onKeyPress={(e: any) =>
              e.key === 'Enter' ? onSearch() : undefined
            }
            onChange={e => setSearch(e.target.value.replace(/ /g, ''))}
          />
        </SearchWrapper>
        <Filter onClick={() => setShowFilters(!showFilters)}>
          <Icon secondary name={filtersOn ? 'filters-on' : 'filters-off'} />
        </Filter>
      </Container.Flex>
    </Grid>
  )
}
