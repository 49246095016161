import styled from 'styled-components/macro'
import { TextProps } from '../types'
import { getTextAlign } from './helpers'
import { getCommonProps, collectTypographyStyles } from '../../../types'
import { IndicatorStyles } from '..'
import Icon from '../../Icon'
import { useLink } from './useLink'
import { forwardRef } from 'react'

export const SmallWrapper = styled.span<TextProps>`
  ${props => getTextAlign(props)};
  ${props => ({
    ...props.theme.typography.small,
    ...props.styles,
  })};
  ${props => getCommonProps(props)};
  ${props => collectTypographyStyles(props)};
  ${props => (props.color ? { color: props.color } : {})};
  ${props =>
    props.secondary ? { color: props.theme.colors.text.secondary } : {}};
  ${props => {
    return props.maxContent ? { width: 'max-content' } : {}
  }};
  transition: ${props => (props.noTransition ? 'none' : 'all 200ms ease-in')};
  ${props => (props.onClick ? { cursor: 'pointer' } : {})};
`

const Small = forwardRef<HTMLSpanElement, TextProps>((props, ref) => {
  const { navigate } = useLink()

  return (
    <SmallWrapper
      ref={ref}
      {...props}
      onClick={props.onClick ?? navigate(props.link)}
    >
      {props.children}
      {props.withIndicator && (
        <Icon name="color-indicator" styles={IndicatorStyles} />
      )}
    </SmallWrapper>
  )
})

export default Small
