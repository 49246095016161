import useQuery from '../../../hooks/useQuery'
import { Container, Typography, Box, Info, Button } from '../../ui'
import QRCode from 'react-qr-code'
import OfferLoader from '../OfferLoader'
import { useUploadFiles } from '../../../contexts/uploadFiles'
import { classNames } from '../../../styles/classNames'
import { useDispatch } from 'react-redux'
import { setResizeCard } from '../../../store/viewSlice'
import { useRef, useEffect } from 'react'
import { GetItemStatus } from '../../../store/orderSlice'

export default function ImageQrCode() {
  const { hostname, orderNumber, itemNumber } = useQuery()
  const link =
  hostname +
    'uploadFiles/' +
    '?orderNumber=' +
    orderNumber +
    '&itemNumber=' +
    itemNumber
  const { setUploadMethod } = useUploadFiles()

  const dispatch = useDispatch()

  const contentRef = useRef<HTMLDivElement>(null)
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)

  const onAnimationEnd = () => {
    if (contentRef.current) {
      if (contentRef.current.classList.contains(classNames.hideToRight)) {
        setUploadMethod(null)
      } else {
        dispatch(setResizeCard(true))
      }
    }
  }

  const back = () => {
    if (contentRef.current) {
      contentRef.current.classList.remove(classNames.showFromLeft)
      contentRef.current.classList.add(classNames.hideToRight)
    }
  }

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (orderNumber) {
        dispatch(GetItemStatus.request({ itemNumber, orderNumber }))
      }
    }, 10000)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  return (
    <Container.Flex
      className={classNames.showFromLeft}
      ref={contentRef}
      fullWidth
      alignItems="start"
      padding="0 4px"
      margin="0 0 6px"
      gap={16}
      onAnimationEnd={onAnimationEnd}
    >
      <Typography.Main textAlign="start" margin={0}>
        Отсканируйте QR-код смартфоном с помощью которого будете делать фото
        и следуйте подсказкам
      </Typography.Main>

      <Box
        padding={10}
        styles={{
          alignSelf: 'center',
          backgroundColor: '#fff',
          borderRadius: '12px',
          fontSize: '0',
          lineHeight: '0',
        }}
      >
        {link ? (
          <QRCode value={link} size={140} />
        ) : (
          <OfferLoader minHeight="100%" />
        )}
      </Box>

      <Info>
        После загрузки фото, данные на этой странице обновятся автоматически
      </Info>

      <Button variant="outline" fullWidth onClick={back}>
        НАЗАД
      </Button>
    </Container.Flex>
  )
}
