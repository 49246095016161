import cls from 'classnames'
import { ComponentType, ReactElement } from 'react'
import { useRef, MouseEvent, useEffect } from 'react'
import { Container } from '../../ui'
import { useTheme } from 'styled-components'
import './OrderModal.css'

export type OrderModalProps = {
  hide: () => void
  hidden: boolean
  noContainer?: true
  zIndexAdd?: number
  hideWrapper?: () => void
  noMargin?: true
}

export const withOrderModal =
  <T extends OrderModalProps>(Component: ComponentType<T>) =>
  (props: T): ReactElement => {
    const { hide, hidden } = props
    const theme = useTheme()
    const containerRef = useRef<HTMLDivElement>(null)

    const hideWrapper = () => {
      if (containerRef.current) {
        containerRef.current.classList.add('popup-hide')
      }
    }

    const onTransitionEnd = () => {
      if (containerRef.current) {
        if (containerRef.current.classList.contains('popup-hide')) {
          hide()
        }
      }
    }

    const onClick = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      if (e.target === containerRef.current) {
        hideWrapper()
      }
    }

    useEffect(() => {
      const onKeyDown = (e: any) => {
        if (e.key === 'Escape') {
          hideWrapper()
        }
      }

      if (typeof document !== undefined) {
        if (hidden) {
          document.addEventListener('keydown', onKeyDown)
        } else {
          document.removeEventListener('keydown', onKeyDown)
        }
      }
    }, [document, hidden, hideWrapper])

    return (
      <Container.Flex
        onClick={onClick}
        onTransitionEnd={onTransitionEnd}
        ref={containerRef}
        styles={{
          zIndex: hidden ? -1 : 10 + (props.zIndexAdd ?? 0),
          backgroundColor: `rgba(0, 0, 0, ${props.zIndexAdd ? 0.7 : 0.7})`,
        }}
        className={cls({
          'popup-wrapper': true,
          'popup-show': !hidden,
          'popup-hidden': hidden,
        })}
      >
        <Container.Flex
          margin={props.noMargin ? 0 : '154px 0 0 0'}
          padding={28}
          gap={16}
          className="popup-content"
          alignItems="stretch"
          styles={
            props.noContainer
              ? {
                  width: '100vw',
                }
              : {
                  width: "86.67%",
                  minWidth: "312px",
                  maxWidth: "344px",
                  background: theme.colors.background.contrast,
                  boxShadow: `0px 0px 25px rgba(0, 0, 0, 0.04)`,
                  borderRadius: '20px',
                }
          }
        >
          <Component {...props} hideWrapper={hideWrapper}/>
        </Container.Flex>
      </Container.Flex>
    )
  }
