import SVG from '../SVG'
import { IconProps } from '../types'

const MobileIcons24 = (props: IconProps) => {
  return (
    <SVG {...props} width={props.width ?? 24} height={props.height ?? 24}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3H17C17.5523 3 18 3.44772 18 4V20C18 20.5523 17.5523 21 17 21H7C6.44772 21 6 20.5523 6 20V4C6 3.44772 6.44772 3 7 3ZM4 4C4 2.34315 5.34315 1 7 1H17C18.6569 1 20 2.34315 20 4V20C20 21.6569 18.6569 23 17 23H7C5.34315 23 4 21.6569 4 20V4ZM12 18C11.4477 18 11 18.4477 11 19V19.002C11 19.5543 11.4477 20.002 12 20.002H12.002C12.5543 20.002 13.002 19.5543 13.002 19.002V19C13.002 18.4477 12.5543 18 12.002 18H12Z"
      />
    </SVG>
  )
}

export default MobileIcons24
