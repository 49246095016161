import SVG from '../SVG'
import { IconProps } from '../types'

const LampIcon = (props: IconProps) => {
  return (
    <SVG
      {...props}
      width={props.width ?? 24}
      height={props.height ?? 24}
      styles={{ background: 'transparent' }}
    >
      <path
        d="M3.44151 23.0034H14.4453M9.55473 23.0034L0.996216 13.2222L11.3887 6.49768M11.6846 6.58816L10.2713 5.17478C9.81259 4.71627 9.55484 4.09433 9.55473 3.44578C9.55461 2.79724 9.81214 2.17521 10.2706 1.71653C10.7292 1.25786 11.3511 1.00011 11.9996 1C12.6482 0.999885 13.2702 1.25741 13.7289 1.71592L15.1276 3.11463M16.2793 11.9935L16.5336 12.3285C17.1121 12.9013 17.8933 13.2226 18.7074 13.2226C19.5216 13.2226 20.3028 12.9013 20.8813 12.3285C22.0294 11.1877 22.0795 9.36968 21.0305 8.17149L20.583 7.72033M13.2226 14.4449C10.5963 11.8186 10.5963 7.28996 13.2226 4.66372C15.7754 2.11083 20.3653 2.25266 23.0037 4.66372L13.2226 14.4449Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SVG>
  )
}

export default LampIcon
