export enum PATHS {
  HomePage = "/",
  ChangePassword = "/change-password/",
  Create = "/create/",
  CreateOrder = "/create/:orderNumber/",
  CreateOrderItem = "/create/:orderNumber/:itemNumber/",
  MyDevices = "/my-devices/",
  EditProfile = "/edit/",
  Help = "/help/",
  Login = "/login/",
  Order = "/order/:orderNumber",
  OrderItem = "/order/:orderNumber/:itemNumber/",
  Ordering = "/ordering/:orderNumber/",
  CheckList = "/order/:orderNumber/:itemNumber/:checkList",
  Partner = "/partner/:partnerCode/",
  Profile = "/profile/",
  NewRequest = "/profile/new_request",
  OrderList = "/profile/order-list/",
  ReadyItem =  "/ready/:itemNumber/",
  RestorePassword = "/restore-password/",
  Partners = "/partners/",
  Theme = "/theme/",
  Contract = "/contract/:orderNumber/",
  stocks = '/stocks/',
  COURIER_TRANSPORT_APPLICATION = '/transport-application/',
  COURIER_RECEIVED_PACKETS = '/received-packets/',
}