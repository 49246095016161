import { useRef } from 'react'
import { styled } from '../../helpers/styled'
import { usePartnerDetail } from '../../pages/Partner'
import { Button, Container, Typography } from '../ui'
import {bp} from "../../theme/media";

const Link = styled.a`
  text-decoration: none;
  width: 100%;
`

export default function PartnerAbout() {
  const { data } = usePartnerDetail()

  const buttonRef = useRef<HTMLButtonElement>(null)

  const onButtonClick = () => {
    if (buttonRef.current) {
      buttonRef.current.blur()
    }
  }

  if (!data) return null

  return (
    <Container.Flex alignItems="start" margin="0 0 32px" styles={{
        width: "calc(50% - 18px)"
    }}
    breakpoints={{
        [bp.mobile]: {
            width: "100%"
        }
    }}
    >
      <Typography.Title margin={'0 4px 16px'} secondary>
        О партнёре
      </Typography.Title>
      {data.partnerDescription && (
        <Typography.Main margin={'0 4px 14px'} fromStart>
          {data.partnerDescription}
        </Typography.Main>
      )}
      <Link href={data.partnerSite} target="_blank">
        <Button
          ref={buttonRef}
          variant="outline"
          disabled={!data.partnerSite ? true : undefined}
          fullWidth
          onClick={onButtonClick}
        >
          Перейти на сайт партнёра
        </Button>
      </Link>
    </Container.Flex>
  )
}
