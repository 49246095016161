import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import Api from '../../apinew'
import { getErrorString } from '../../helpers/getErrorString'
import { useSession } from '../../hooks/useSession'
import { useAppDispatch } from '../../store/hooks'
import { resetSendLink, resetSMSApi } from '../../store/userSlice'
import { Session } from '../../store/userSlice/types'
import {
  LoginContextState,
  APIState,
  Steps,
  ClientLoginRequest,
} from './types'

const _LoginContext = createContext({} as LoginContextState)

export const useLoginData = () => useContext(_LoginContext)

export default function LoginContext({ children }: { children: ReactNode }) {

  const [clientLoginApi, setClientLoginApi] = useState<APIState>({
    pending: false,
    status: null,
    error: '',
  });

  const [step, setStep] = useState<Steps>('sendPhone');
  const [mode, setMode] = useState<"client" | "partner">("client");
  const [phone, setPhone] = useState<string>("");
  const [defaultPhone, setDefaultPhone] = useState("");

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const setClientLoginPending = (v: boolean) => {
    setClientLoginApi(prev => ({ ...prev, pending: v }))
  }

  const setClientLoginError = (v: string) => {
    setClientLoginApi(prev => ({ ...prev, error: v }));
    if (!v) {
      setClientLoginStatus(null)
    }
  }

  const setClientLoginStatus = (v: 'success' | 'error' | null) => {
    setClientLoginApi(prev => ({ ...prev, status: v }))
  }

  const hide = useCallback((noRedirect?: true) => {
    dispatch(resetSMSApi());
    dispatch(resetSendLink());
    if (!noRedirect) {
      navigate("/") 
    }
  }, [])

  useEffect(() => {
    if (step === "sendPhone") {
      dispatch(resetSMSApi());
      setClientLoginError("");
      setClientLoginStatus(null);
    }
    if (step === "sendCode") {
      setClientLoginError("");
      setClientLoginStatus(null);
    }

  }, [step, dispatch])

  const { session, setSession } = useSession()

  const clientLogin = useCallback((data: ClientLoginRequest) => {
    setPhone(data.phone);
    setClientLoginPending(true)
    const api = new Api()
    api
      .smsLogin(data)
      .then((result) => {
        if (result.status === 'success') {
          setClientLoginStatus("success");
          if (result.data) {
            if (result.data.codeSent) {
              setStep("sendCode")
            } else {
              const _session: Session = {
                auth_param_name: result.data.auth_param_name ?? session.auth_param_name,
                auth_param_value: result.data.auth_param_value ?? session.auth_param_value,
                isAuthorised: result.data.isAuthorised ?? session.isAuthorised,
                userType: result.data.userType ?? session.userType
              };
              setStep("success");
              setSession(_session)
            }
          }
        }
        if (result.status === 'error') {
          setClientLoginError(getErrorString(result.errors));
          setClientLoginStatus('error');
        }
      })
      .catch((error: any) => {
        setClientLoginError(error.message);
        setClientLoginStatus('error')
      })
      .finally(() => {
        setClientLoginPending(false)
      })
  }, [])

  return <_LoginContext.Provider value={{
    clientLogin,
    step,
    setStep,
    clientLoginApi,
    hide,
    mode,
    setMode,
    phone,
    setPhone,
    setClientLoginError,
    defaultPhone,
    setDefaultPhone
  }}>{children}</_LoginContext.Provider>
}
