import { styled } from '../../../../helpers/styled'

const TextArea = styled.textarea`
    position: relative;
    border: none;
    border-radius: 12px;
    padding: 10px 12px 10px 16px;
    margin-bottom: 16px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    max-width: 100%;
    min-height: 71px;
    color: ${props => props.theme.colors.text.default};
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
    outline: none;

    &::placeholder {
        color: ${props => props.theme.colors.text.secondary};
    }
`

interface Props {
    register: any
    placeholder?: string
    required: boolean
    code: string
    defaultValue?: any
}

export default function ErrorFormTextArea(props: Props) {
    const { register, placeholder, required, code, defaultValue } = props

    return (
        <TextArea
            placeholder={placeholder}
            {...register(code, {
                required: required ? { value: required } : undefined
            })}
            defaultValue={defaultValue}
        />
    )
}
