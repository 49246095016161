import { UploadFileMethod, useUploadFiles } from '../../../contexts/uploadFiles'
import { Container, Typography, Button } from '../../ui'
import { classNames } from '../../../styles/classNames'
import { useDispatch } from 'react-redux'
import { setResizeCard } from '../../../store/viewSlice'
import { useRef } from 'react'

export default function ChooseUploadMethod() {

  const contentRef = useRef<HTMLDivElement>(null)
  const methodRef = useRef<UploadFileMethod>(null);

  const { setUploadMethod } = useUploadFiles()

  const dispatch = useDispatch();

  const onAnimationEnd = () => {
    if (contentRef.current) {
      if (contentRef.current.classList.contains(classNames.hideToRight)) {
        setUploadMethod(methodRef.current)
      } else {
        dispatch(setResizeCard(true))
      }
    }
  }

  const changeStep = (method: UploadFileMethod) => {
    methodRef.current = method;
    if (contentRef.current) {
      contentRef.current.classList.remove(classNames.showFromLeft)
      contentRef.current.classList.add(classNames.hideToRight)
    }
  }

  return (
    <Container.Flex
      fullWidth
      alignItems="start"
      ref={contentRef}
      className={classNames.showFromLeft}
      fullHeight
      padding="0 4px"
      margin="0 0 6px"
      gap={16}
      onAnimationEnd={onAnimationEnd}
    >
      <Typography.Main textAlign="start" margin={0}>
        Вы можете загрузить/сделать фото с этого устройства или сгенерировать
        QR-код, для того чтобы загрузить/сделать фото с другого устройства.
      </Typography.Main>

      <Button fullWidth variant="outline" onClick={() => changeStep('qr-code')}>
        СГЕНЕРИРОВАТЬ QR-КОД
      </Button>
      <Button variant="primary" fullWidth onClick={() => changeStep('pc')}>
        {'Выбрать/сделать фото'.toUpperCase()}
      </Button>
    </Container.Flex>
  )
}
