import { useCallback, useEffect, useRef, useState } from 'react'
import Container from '../ui/Container'
import OfferQuestion from './Question/OfferQuestion'
import { Typography } from '../ui'

import {
  GivenAnswer,
  GivenAnswers,
  Question,
  ServerError,
} from '../../store/offerSlice/types'
import UploadImageSubmit from './Question/UploadImageSubmit'

interface OfferQuestionsProps {
  question: Question | null
  errors: ServerError[]
  changeContent: boolean
  givenAnswers: GivenAnswers
  giveAnswer: (answer: GivenAnswer) => void
  resetQr: () => void
  pauseQuestions: boolean
}

const OfferQuestions = ({
  question,
  errors,
  changeContent,
  givenAnswers,
  giveAnswer,
  resetQr,
  pauseQuestions,
}: OfferQuestionsProps) => {
  const [minHeight, setMinHeight] = useState(200)

  const ref = useRef<HTMLDivElement>(null)

  const resize = useCallback(() => {
    if (ref.current) {
      setMinHeight(ref.current.offsetHeight)
    }
  }, [setMinHeight])

  useEffect(resize)

  return (
    <Container.Flex
      fullWidth
      margin={'0 0 0 0'}
      styles={{
        transition: 'opacity 400ms ease-in, height 300ms ease-in',
        opacity: changeContent ? 0 : 1,
        height: `${minHeight}px`,
      }}
      onTransitionEnd={resize}
    >
      <Container.Flex ref={ref} fullWidth>
        {!!question && (
          <OfferQuestion
            questionData={question}
            givenAnswers={givenAnswers}
            giveAnswer={giveAnswer}
            back={resetQr}
            pauseQuestions={pauseQuestions}
          />
        )}
        {!!pauseQuestions && !question && <UploadImageSubmit />}
        {!!errors.length && (
          <Typography.Error>
            {errors.map(err => err.message).join('. ')}
          </Typography.Error>
        )}
      </Container.Flex>
    </Container.Flex>
  )
}

export default OfferQuestions
