import SVG from '../SVG'
import { IconProps } from '../types'

const StatusOutOfStock = (props: IconProps) => {
  const width = props.width ?? 16
  const height = props.height ?? 16
  return (
    <SVG
      {...props}
      width={width}
      height={height}
      styles={{
        color: props.color ?? '#E7372D',
      }}
    >
      <g clipPath="url(#clip0_5337_23225)">
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.37462 5.24325C6.85103 4.96174 7.40675 4.8002 8.0002 4.8002C9.76751 4.8002 11.2002 6.23288 11.2002 8.0002C11.2002 8.59364 11.0387 9.14936 10.7571 9.62577L6.37462 5.24325ZM5.24325 6.37462C4.96174 6.85103 4.8002 7.40675 4.8002 8.0002C4.8002 9.76751 6.23288 11.2002 8.0002 11.2002C8.59364 11.2002 9.14936 11.0387 9.62577 10.7571L5.24325 6.37462ZM8.0002 3.2002C5.34923 3.2002 3.2002 5.34923 3.2002 8.0002C3.2002 10.6512 5.34923 12.8002 8.0002 12.8002C10.6512 12.8002 12.8002 10.6512 12.8002 8.0002C12.8002 5.34923 10.6512 3.2002 8.0002 3.2002Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5337_23225">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </SVG>
  )
}

export default StatusOutOfStock
