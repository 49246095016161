export const getFormatRubles = (value: number | string) => {

    const n = typeof value === 'number' ? value : +value.replace(',', '.')

    if (!n) {
        return ({
            rubles: "0",
            centes: "00"
        })
    }

    const rubles = new Intl.NumberFormat('ru-RU', {
        useGrouping: true
    }).format(Math.floor(n));

    const centes = "" + (Math.floor(100 * n % Math.floor(n)));

    return ({
        rubles,
        centes: centes.length < 2 ? "0" + centes : centes 
    })
}