import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SaveOrderForm from '../Login/SaveOrderForm'
import { Overlay } from '../ui'
import { resetSMSApi, resetSendLink } from '../../store/userSlice'
import { GetOrder } from '../../store/orderSlice';

interface SaveOrderProps {
  orderNumber: string
  hide: () => void;
}

const SaveOrder = (props: SaveOrderProps) => {
  const { orderNumber, hide } = props;

  const dispatch = useDispatch()

  const _hide = useCallback(() => {
    dispatch(resetSMSApi());
    dispatch(resetSendLink());
    dispatch(GetOrder.request({ orderNumber }))
    hide()
  }, [dispatch, resetSMSApi, resetSendLink, hide]);

  return (
    <Overlay close={_hide}>
      <SaveOrderForm orderNumber={orderNumber} hide={_hide}/>
    </Overlay>
  )
}

export default memo(SaveOrder)
