import { Container, Typography, Icon, Button } from '../../../ui'
import { useTheme } from 'styled-components'
import { PERIOD_FILTER_KEY, useOrderListData } from '../OrderListContext'

const SelectFilters = () => {
  const {
    filters,
    setActiveFilter,
    getFilterIsActive,
    periodFiltersOn,
    resetAllFilters,
    closeFilters,
    filtersOn,
  } = useOrderListData()
  const theme = useTheme()

  return (
    <Container.Flex
      fullWidth
    >
      {filters &&
        filters.map(filter => (
          <Container.Flex
            key={filter.propertyName}
            fullWidth
            justify="between"
            direction="row"
            styles={{
              paddingLeft: '4px',
              marginBottom: '24px',
              color: theme.colors.text.default,
              transition: "color 250ms linear"
            }}
            hoverStyles={{ cursor: 'pointer', color: theme.colors.link.hover }}
            onClick={() => {
              setActiveFilter(filter.propertyName)
            }}
          >
            <Typography.Main
              withIndicator={getFilterIsActive(filter.propertyName)}
              indicatorColor={theme.colors.info.success}
              margin={0}
              styles={{color: "currentColor"}}
              noTransition
            >
              {filter.name}
            </Typography.Main>
            <Icon
              name="arrow"
              styles={{ transform: 'rotate(-90deg)' }}
              color={theme.colors.text.secondary}
            />
          </Container.Flex>
        ))}

      <Container.Flex
        fullWidth
        justify="between"
        direction="row"
        styles={{
          paddingLeft: '4px',
          marginBottom: '24px',
          color: theme.colors.text.default,
          transition: "color 250ms linear"
        }}
        hoverStyles={{ cursor: 'pointer', color: theme.colors.link.hover }}
        onClick={() => {
          setActiveFilter(PERIOD_FILTER_KEY)
        }}
      >
        <Typography.Main
          withIndicator={periodFiltersOn || undefined}
          indicatorColor={theme.colors.info.success}
          styles={{color: "currentColor"}}
          margin={0}
          noTransition
        >
          Период
        </Typography.Main>
        <Icon
          name="arrow"
          styles={{ transform: 'rotate(-90deg)' }}
          color={theme.colors.text.secondary}
        />
      </Container.Flex>

      <Container.Grid cols="1fr 1fr" gap={16} fullWidth margin={'8px 0 0 0'}>
        <Button
          onClick={resetAllFilters}
          variant="danger"
          disabled={(!filtersOn && !periodFiltersOn) || undefined}
        >
          Сбросить
        </Button>
        <Button variant="primary" onClick={closeFilters}>
          Закрыть
        </Button>
      </Container.Grid>
    </Container.Flex>
  )
}

export default SelectFilters
