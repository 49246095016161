import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLeading } from "redux-saga/effects";
import { API_POST, API_GET } from ".";
import { RootState } from "..";
import Api, { URLS } from "../../apinew";
import { ResponseData } from "../types";

const api = new Api()

function* apiGetRequestWorker ({payload} : PayloadAction<URLS>) {
    const state: RootState = yield select();
    const session = state.user.session;

    const caller = api._get(payload);

    const response: ResponseData<any> = yield call(caller, session)

    if (response.status === "success") {
        yield put(API_GET.success(response.data))
    };
    if (response.status === "error") {
        yield put(API_GET.failure(response.errors))
    }
}

function* apiPostRequestWorker ({payload} : PayloadAction<{url: URLS, data: any}>) {

    const caller = api._post(payload.url);

    const response: ResponseData<any> = yield call(caller, payload.data)

    if (response.status === "success") {
        yield put(API_POST.success(response.data))
    };
    if (response.status === "error") {
        yield put(API_POST.failure(response.errors))
    }
}

export default function* apiSagas () {
    yield takeLeading(API_GET.REQUEST, apiGetRequestWorker);
    yield takeLeading(API_POST.REQUEST, apiPostRequestWorker)
}