import { useTheme } from 'styled-components'
import { Typography } from '../../../ui'

const styles: any = {
  position: 'absolute',
  right: 0,
  bottom: '-12px',
  padding: '0 4px',
}

export default function InputInfo({
  error,
  success,
}: {
  error?: string
  success?: string
}) {
  const theme = useTheme()

  if (error) {
    return (
      <Typography.Micro color={theme.colors.info.error} styles={styles}>
        {error}
      </Typography.Micro>
    )
  }

  if (success) {
    return (
      <Typography.Micro color={theme.colors.info.success} styles={styles}>
        {success}
      </Typography.Micro>
    )
  }

  return null
}
