import { useEffect } from "react";
import Overlay from "../Overlay";
import Button from "../Button";
import { Scrollbars } from 'react-custom-scrollbars';
import styled from "styled-components/macro";
import { createGlobalStyle } from "styled-components";

export type PopupProps = {
    content: string;
    hidePopup: () => void;
};

const Wrapper = styled.div`
    width: 86.67%;
    min-width: 312px;
    max-width: 344px;
    padding: 28px 20px 20px 28px;
    margin-top: 64px;
	background: ${(props) => props.theme.colors.background.contrast};
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.04);
	border-radius: 20px;

    @media screen and (max-width: 450px) {
        width: 100%auto;
    }
`

const ScrollbarsInner = styled.div`
    padding-right: 8px;
    padding-bottom: 8px;
`

const GlobalStyle = createGlobalStyle`
    .popup__content {
        .title {
			${(props) => ({
    ...props.theme.typography.title
})};
			color: ${(props) => props.theme.colors.text.secondary};
            padding-left: 4px;
            margin: 0 0 24px;
        }
        
        img {
            display: inline-block;
            margin: 0 auto 16px;
            max-width: 100%;
        }
        
        p {
            padding: 0 4px 6px;
            margin: 0 0 16px;
            
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

	.scrollbar > div:nth-child(3) {
		background-color: ${props => props.theme.colors.scrollBar.background };
	}

	.scrollbar > div:nth-child(3) > div  {
		background-color: ${props => props.theme.colors.scrollBar.slider };
	}
`

const Popup = (props: PopupProps) => {
    const { content, hidePopup } = props;

    useEffect(() => {
        const close = (e: any) => {
            if (e.key === "Escape") {
                hidePopup()
            }
        };

        if (typeof document !== "undefined") {
            document.addEventListener("keypress", close)
        }

        return () => {
            document.removeEventListener("keypress", close)
        }
    }, [document])

    return (
        <>
            <GlobalStyle />
            <Overlay close={hidePopup}>
                <Wrapper>
                    <Scrollbars
                        className="scrollbar"
                        universal
                        autoHeight
                        autoHeightMin="5vh"
                        autoHeightMax="65vh"
                        style={{
                            width: "100%",
                        }}
                        hideTracksWhenNotNeeded={true}
                    >
                        <ScrollbarsInner>
                            <div className="popup__content" dangerouslySetInnerHTML={{__html: content }}/>
                            <Button fullWidth variant="danger" margin="16px 0 0" onClick={hidePopup}>Закрыть</Button>
                        </ScrollbarsInner>
                    </Scrollbars >
                </Wrapper>
            </Overlay>
        </>
    );
};

export default Popup;
