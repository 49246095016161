import styled from "styled-components/macro";
import Icon from "../Icon";
import { useTheme } from "styled-components";
import { ChangeEvent, useEffect, useState } from "react";
import { Typography } from "../index";

type DateInputProps = {
    value: Date | null
    changeHandler: (event: ChangeEvent<HTMLInputElement>) => void
    placeholder?: string
}

const Label = styled.label`
	position: relative;
    width: 100%;
    display: inline-block;
	filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.04));
    background-color: ${props => props.theme.colors.background.contrast};
`;


const Input = styled.input`
	opacity: 0;
    width: 100%;
	padding: 9px 16px;
    font-size: 14px;
    line-height: 16px;
	cursor: pointer;
	&::-webkit-calendar-picker-indicator {
		position: absolute;
		right: 0;
		top: 0;
        left: 0;
        bottom: 0;
		opacity: 0;
        width: 100%;
        height: 100%;
		cursor: pointer;
	}
`;

const formatForUser = (date: Date | null) => {
    if (!!date) {
        const formattedDate = {
            dd: "",
            mm: "",
            yy: ""
        }
        let dd = date.getDate();
        formattedDate.dd = (dd < 10) ? '0' + dd : dd.toString()

        let mm = date.getMonth() + 1;
        formattedDate.mm = (mm < 10) ? '0' + mm : mm.toString()

        let yy = date.getFullYear() % 100;
        formattedDate.yy = (yy < 10) ? '0' + yy : yy.toString()

        return `${formattedDate.dd}-${formattedDate.mm}-${formattedDate.yy}`;
    } else {
        return ""
    }
}

const formatForInput = (date: Date | null) => {
    if (!!date) {
        const formattedDate = {
            dd: "",
            mm: "",
            yy: ""
        }
        let dd = date.getDate();
        formattedDate.dd = (dd < 10) ? '0' + dd : dd.toString()

        let mm = date.getMonth() + 1;
        formattedDate.mm = (mm < 10) ? '0' + mm : mm.toString()

        let yy = date.getFullYear();
        formattedDate.yy = yy.toString();

        return `${formattedDate.yy}-${formattedDate.mm}-${formattedDate.dd}`;
    } else {
        return ""
    }
}

export default function DateInput({ value, changeHandler, placeholder }: DateInputProps) {
    const theme = useTheme();
    const [valueForUser, setValueForUser] = useState("");
    const [valueForInput, setValueForInput] = useState("");

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const targetDate = (value.length === 0 || value === "") ? null: new Date(value);
        setValueForInput(formatForInput(targetDate))
        setValueForUser(formatForUser(targetDate))
        changeHandler(event);
    }

    useEffect(() => {
        setValueForInput(formatForInput(value));
        setValueForUser(formatForUser(value));
    }, [value]);

    return (
        <Label>
            <Input type="date"
               onChange={onChange}
               value={valueForInput}
            />
            {
                !!placeholder && !valueForUser &&
                <Typography.Medium styles={{
                    position: "absolute",
                    left: "16px",
                    top: "10px",
                    margin: 0,
                    pointerEvents: "none",
                    color: theme.colors.text.secondary
                }}>{placeholder}</Typography.Medium>
            }
            {
                !!valueForUser &&
                <Typography.Medium styles={{
                    position: "absolute",
                    left: "16px",
                    top: "10px",
                    margin: 0,
                    pointerEvents: "none",
                    color: theme.colors.text.default
                }}>{valueForUser}</Typography.Medium>
            }
            <Icon name="calendar" color={theme.colors.icon.secondary} styles={{
                position: "absolute",
                top: "10px",
                right: "12px",
                backgroundColor: theme.colors.background.contrast,
                pointerEvents: "none"
            }}/>
        </Label>
    )
}
