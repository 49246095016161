import { ChangeEvent, useEffect, useState } from 'react'
import Button from '../ui/Button'
import Input from '../ui/Input'
import Container from '../ui/Container'
import Typography from '../ui/Typography'
import { useAuthData } from '../../contexts/auth'
import { useTheme } from 'styled-components'
import { LoginItemProps } from './types'
import { useAppDispatch } from '../../store/hooks'
import { resetLoginState } from '../../store/userSlice'

const Login = ({ changeLoginType, resize, noRedirect } : LoginItemProps) => {
  const [loginValue, setLoginValue] = useState('')
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const dispatch = useAppDispatch();

  const theme = useTheme()

  const { login, errors, pending } = useAuthData()

  const validateEmail = (str: string) => {

    if (/^\S+@\S+\.\S+$/.test(str)) {
      return true
    }
    setEmailError("Некорректный адрес почты")
    return false
  }

  const validatePassword = () => {
    if (password.length < 3) {
      setPasswordError("Пароль слишком короткий")
    }
  }

  const onSubmit = () => {
    if (validateEmail(loginValue)) {
      login({ login: loginValue, password: password, noRedirect })
    }
  }

  const validateAll = () => {
    validatePassword();
    validateEmail(loginValue)
  }

  useEffect(() => {
    setEmailError("")
  }, [loginValue])

  useEffect(() => {
    if (resize) resize();
  }, [errors])

  useEffect(() => {
    return () => {
      dispatch(resetLoginState());
    }
  }, [dispatch])

  const resetPasswordError = () => {
    setPasswordError("");
  }

  const resetEmailError = () => {
    setEmailError("");
  }


  const onLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
    resetEmailError();
    if (errors.login.length) {
      dispatch(resetLoginState());
    };
    setLoginValue(e.target.value)
  }

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    resetPasswordError();
    if (errors.login.length) {
      dispatch(resetLoginState());
    };
    setPassword(e.target.value)
  }

  return (
    <Container.Flex verticalGap={16} fullWidth fullHeight justify="center">
      <Container.Flex fullWidth direction="row" justify="between" alignItems="baseline" padding="0 4px">
        <Typography.TitleSecondary textAlign="start" margin={'0 0 8px'}>Вход для партнёра</Typography.TitleSecondary>
        <Typography.Small
          onClick={() => changeLoginType('client')}
          color={theme.colors.link.default}
        >
          Клиентам
        </Typography.Small>
      </Container.Flex>
      <Input
        value={loginValue}
        onChange={onLoginChange}
        onBlur={() => validateEmail(loginValue)}
        fullWidth
        placeholder="Email"
        error={emailError}
        onEnter={onSubmit}
      />

      <Input
        type="password"
        value={password}
        onChange={onPasswordChange}
        onBlur={validatePassword}
        fullWidth
        secure={true}
        placeholder="Пароль"
        error={passwordError}
        onEnter={onSubmit}
      />

      <Typography.Link
        textAlign="end"
        fullWidth
        href="/restore-password/"
        styles={{
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: '400',
          paddingRight: "4px"
        }}
      >
        Вспомнить пароль?
      </Typography.Link>
      <Button
        withLoader
        pending={pending}
        onClick={onSubmit}
        onFocus={validateAll}
        square
        fullWidth
        submit
        disabled={(!loginValue || !password.length) || undefined}
      >
        ВОЙТИ
      </Button>

      {!!errors.login.length && (
        <Typography.Error>
          {errors.login.map(err => err.message).join('. ')}
        </Typography.Error>
      )}
    </Container.Flex>
  )
}

export default Login
