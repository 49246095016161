import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Offer from '../components/Offer'
import { useUploadFiles } from '../contexts/uploadFiles'
import { restoreOffer, setStep } from '../store/offerSlice'
import { GetOrder, getOrderItemData } from '../store/orderSlice'
import PageLayout from './PageLayout'
import { Container } from "../components/ui";
import { useSession } from "../hooks/useSession";

function CreatePage() {

  const navigate = useNavigate();

  const { itemNumber, orderNumber } = useParams()
  const { isLoading, errors, orderData } = useSelector(getOrderItemData);
  const dispatch = useDispatch();

  if ((!orderData || orderData.notOwner) && orderNumber) {
    navigate("/create/")
  }

  const { setFiles } = useUploadFiles()

  useEffect(() => {
    setFiles({})
    if (!itemNumber) {
      dispatch(restoreOffer())
    }
    dispatch(setStep('questions'))
  }, [itemNumber])

  const currentItem = useMemo(() => {
    return orderData?.items.find(item => item.itemNumber === itemNumber)
  }, [orderData])

  useEffect(() => {
    if (!orderNumber) return
    if (!isLoading && !orderData && !errors.length) {
      dispatch(GetOrder.request({ itemNumber, orderNumber }))
    }
  }, [orderData, isLoading, errors, orderNumber])

  const showOffer = useMemo(() => {
    if (!itemNumber) return true
    if (isLoading) return false
    if (!currentItem) return false
    if (!itemNumber && !orderNumber) return true
    return true
  }, [isLoading, currentItem])

  const {isAdmin } = useSession()

  return (
    <PageLayout withProfile>
     <Container.CardsGrid twoColumns={isAdmin}>
        {showOffer && <Offer />}
        {/* {!!orderNumber && <Order orderNumber={orderNumber} withoutHeader withoutLoader/>} */}
      </Container.CardsGrid>
    </PageLayout>
  )
}

export default CreatePage
