import { useEffect } from 'react'
import { getFormatRubles } from '../../../helpers/getrFormatRubles'
import { useReadChangePopupMutation } from '../../../store/api/api'
import { DissentPopupInfo, OrderItem } from '../../../store/orderSlice/types'
import { Button, Container, Info, Typography } from '../../ui'
import { useOrderContext } from '../OrderContext'
import { OrderImage } from './OrderImages'
import { OrderModalProps, withOrderModal } from './WithOrderModal'

type OrderDissentContentProps = {
  data: OrderItem
  orderNumber: string
} & OrderModalProps

const OrderDissentContent = (
  props: OrderDissentContentProps & { popupInfo: DissentPopupInfo },
) => {
  const { data, popupInfo, orderNumber } = props
  const initalPrice = getFormatRubles(+data.price)
  const truePrice = getFormatRubles(+data.price)
  const padding = '0 4px';

  const [ read ] = useReadChangePopupMutation();

  useEffect(() => {

    if (!props.hidden && !popupInfo.read) {
      read({ data: { itemNumber: props.data.itemNumber, number: orderNumber }})
    }

  }, [props.hidden, popupInfo.read, read])

  const { showOrderImages } = useOrderContext()

  const showImagesPopup = (currentImage: number) => {
    const conditionImages = popupInfo.conditionImage?.map((el, idx) => ({
      src: el,
      name: `Фото #${idx + 1}`,
      idx: idx + 1,
    })) || []

    const deffectImages: OrderImage[] = popupInfo.deffectImage?.map(
      (el, idx) => ({
        src: el,
        name:
          popupInfo.deffectImage.length > 1
            ? 'Фото дефекта' + ` #${idx + 1}`
            : 'Фото дефекта',
        idx: popupInfo.conditionImage.length + idx + 1,
      }),
    ) || []

    const images: OrderImage[] = [...conditionImages, ...deffectImages]

    showOrderImages({ images, currentImage })
  }

  return (
    <Container.Flex fullWidth alignItems="stretch">
      <Typography.TitleSecondary fromStart margin="0 0 24px" padding={padding}>
        Стоимость изменена
      </Typography.TitleSecondary>
      <Info>
        <Typography.Main fromStart margin={0}>
          При проверке были зафиксированы отличия между реальным состоянием
          и указанным
        </Typography.Main>
      </Info>

      <Container.Flex direction="row" padding={padding} margin="16px 0 8px">
        <Typography.Medium fromStart margin="0 4px 0 0">
          Указанное состояние:
        </Typography.Medium>
        <Typography.RublesMedium fromStart margin={0}>
          {popupInfo.initialCondition}
        </Typography.RublesMedium>
      </Container.Flex>

      <Container.Flex
        direction="row"
        padding={padding}
        styles={{ marginBottom: '22px' }}
      >
        <Typography.Medium fromStart margin="0 4px 0 0">
          Исходная цена:
        </Typography.Medium>
        <Typography.RublesMedium margin={0} textAlign="start">
          {initalPrice.rubles}
        </Typography.RublesMedium>
        <Typography.RublesCentes margin={0} textAlign="start">
          {initalPrice.centes}
        </Typography.RublesCentes>
        &nbsp;
        <Typography.RublesMedium margin={0} textAlign="start">
          руб
        </Typography.RublesMedium>
      </Container.Flex>

      <Container.Flex
        direction="row"
        padding={padding}
        styles={{ marginBottom: '8px' }}
      >
        <Typography.Medium fromStart margin="0 4px 0 0">
          Реальное состояние:
        </Typography.Medium>
        <Typography.RublesMedium fromStart margin={0}>
          {data.condition}
        </Typography.RublesMedium>
      </Container.Flex>

      <Container.Flex
        direction="row"
        padding={padding}
        styles={{ marginBottom: '22px' }}
      >
        <Typography.Medium fromStart margin="0 4px 0 0">
          Измененная цена:
        </Typography.Medium>
        <Typography.RublesMedium fromStart margin={0}>
          {truePrice.rubles}
        </Typography.RublesMedium>
        <Typography.RublesCentes margin={0} textAlign="start">
          {truePrice.centes}
        </Typography.RublesCentes>
        &nbsp;
        <Typography.RublesMedium margin={0} textAlign="start">
          руб
        </Typography.RublesMedium>
      </Container.Flex>

      <Container.Flex
        gap={8}
        margin="0 0 22px"
        padding={padding}
        alignItems="stretch"
      >
        {popupInfo.conditionImage &&  popupInfo.conditionImage.map((_, idx) => (
          <Container.Flex direction="row" key={"conditionImage" + idx}>
            <Typography.Medium margin="0 4px 0 0">{`Фото #${idx + 1}:`}</Typography.Medium>
            <Typography.Medium
              linkStyle
              onClick={() => showImagesPopup(0)}
              margin={0}
            >
              смотреть
            </Typography.Medium>
          </Container.Flex>
        ))}

        {popupInfo.deffectImage && popupInfo.deffectImage.map((_, idx) => (
          <Container.Flex direction="row" key={`deffetImage-${idx}`}>
          <Typography.Medium margin="0 4px 0 0">
            {popupInfo.deffectImage.length > 1 ? `Фото дефекта #${idx + 1}` : 'Фото дефекта'}
          </Typography.Medium>
          <Typography.Medium
            linkStyle
            onClick={() => showImagesPopup(2)}
            margin={0}
          >
            смотреть
          </Typography.Medium>
        </Container.Flex>
        ))}

      </Container.Flex>

      {popupInfo.popupComment && (
        <>
          <Typography.TitleSecondary
            margin="0 0 24px"
            padding={padding}
            fromStart
          >
            Комментарий специалиста
          </Typography.TitleSecondary>

          <Typography.Main margin="0 0 22px" padding={padding} fromStart>
            {popupInfo.popupComment}
          </Typography.Main>
        </>
      )}

      <Button fullWidth onClick={props.hide}>
        Закрыть
      </Button>
    </Container.Flex>
  )
}

function OrderDissent({ data, hide, hidden, orderNumber }: OrderDissentContentProps) {
  if (!data.popupInfo) return null

  return (
    <OrderDissentContent
      orderNumber={orderNumber}
      data={data}
      popupInfo={data.popupInfo}
      hide={hide}
      hidden={hidden}
    />
  )
}

export default withOrderModal(OrderDissent)
