import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { SetOrderRegistration } from '../../store/orderSlice'
import { UserType } from '../../store/userSlice/types'
import { bp } from '../../theme/media'
import { Container, Typography } from '../ui'

interface IOrderHeader {
  userRole: UserType
  orderNumber: string
  orderStatus?: string
  orderStatusColor?: string
  date?: string
  setOrderReady: () => void
  showDeleteButton: boolean
}

export default function OrderHeader(props: IOrderHeader) {
  const dispatch = useDispatch()
  const isUser = props.userRole === UserType.client
  const isAdmin = !isUser
  const theme = useTheme()

  const location = useLocation();
  const navigate = useNavigate();


  const editOrder = () => {
    if (location.pathname.startsWith('/create')) {
      navigate('/order/' + props.orderNumber + '/')
    } else {
      dispatch(SetOrderRegistration.request(''))
    }
  }

  return (
    <Container.Flex fullWidth>
      <Container.Flex
        fullWidth
        direction="row"
        justify="start"
        styles={{
          position: 'relative',
          marginBottom: '12px',
        }}
        breakpoints={{
          [bp.mobile]: {
            justifyContent: 'space-between',
          },
        }}
      >
        <Typography.Title
        styles={{
          textRendering: 'optimizeSpeed'
        }}
          hoverStyles={{
            cursor: 'pointer',
            color: theme.colors.link.hover,
          }}
          onClick={editOrder}
          margin={'0 16px 0 0'}
          padding={'0 0 0 4px'}
        >{`Заявка ${props.orderNumber.toLowerCase()}`}</Typography.Title>
        {isAdmin && (
          <Typography.Link
            href="/profile/order-list/"
            styles={{ fontSize: '14px' }}
            margin={0}
          >
            Все заявки
          </Typography.Link>
        )}

      </Container.Flex>

      {isAdmin && !!props.orderStatus && (
        <Container.Flex
          fullWidth
          direction="row"
          justify="between"
          alignItems="baseline"
          margin={0}
        >
          <Typography.Micro secondary padding="0 0 0 4px">
            {props.date ?? ''}
          </Typography.Micro>

          {props.orderStatus && (
            <Typography.Medium
              color={props.orderStatusColor}
              withIndicator
              indicatorColor={props.orderStatusColor}
              margin={0}
            >
              {props.orderStatus}
            </Typography.Medium>
          )}
        </Container.Flex>
      )}
    </Container.Flex>
  )
}
