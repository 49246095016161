import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  GivenAnswer,
  GivenAnswers,
  Question,
} from '../../../store/offerSlice/types'
import { Button, Info, Container, Typography, FilesContainer } from '../../ui'
import FreeInput from './FreeInput'
import FromList from './FromList'
import QrCode from './OfferQR'
import UploadImage from './UploadImage'
import FromRadio from './FromRadio'
import { styled } from '../../../helpers/styled'
import { PopupTrigger } from '../../ui/Popup'
import usePopup from '../../../hooks/usePopup'
import { getPopupData } from '../../../helpers/getPopupData'
import { useUploadFiles } from '../../../contexts/uploadFiles'
import ChooseUploadMethod from './ChooseUploadMethod'
import ImageQrCode from './ImageQrCode'
import { useDispatch } from 'react-redux'
import { setResizeCard } from '../../../store/viewSlice'

export interface OfferQuestionProps {
  questionData: Question
  combinationId?: string
  givenAnswers: GivenAnswers
  giveAnswer: (answer: GivenAnswer) => void
  back: () => void
  pauseQuestions: boolean
}

const QuestionDescription = styled.div`
  > a {
    user-select: none;
    color: ${props => props.theme.colors.link.default};
  }
  > p {
    margin-top: 0;
  }
`

const OfferQuestion = (props: OfferQuestionProps) => {
  const { questionData, givenAnswers, giveAnswer, back } = props
  const { combinationId, combinationCode } = givenAnswers
  const {
    answerType,
    questionName,
    questionDescription,
    questionHelp,
    questionHeader,
  } = questionData


  const { showPopup } = usePopup()

  const dispatch = useDispatch();

  const contentRef = useRef<HTMLDivElement>(null)

  const [height, setHeight] = useState('auto')

  const resize = useCallback(() => {
    if (contentRef.current) {
      setHeight(`${contentRef.current.offsetHeight}px`)
    }
  }, [setHeight])

  useEffect(resize)

  const { showImageContainer, uploadMethod } = useUploadFiles()

  const popupData = useMemo(() => {
    return getPopupData(questionDescription ?? '')
  }, [questionDescription])

  const { popupContent, popupTrigger, processedHTML } = popupData

  const showCardContent = useMemo(() => {
    if (answerType !== 'upload_image') {
      return true
    }
    if (!uploadMethod) return false
    if (uploadMethod === 'qr-code') {
      return false
    }
    return true
  }, [uploadMethod, answerType])

	const showChooseUploadMethod = useMemo(() => {
		return answerType === 'upload_image' && !uploadMethod
	}, [uploadMethod, answerType])

	const showImageQrCode = useMemo(() => {
		return answerType === 'upload_image' && uploadMethod === 'qr-code'
	}, [uploadMethod, answerType])

  useEffect(() => {
    if (showCardContent) {
      dispatch(setResizeCard(true))
    }
  }, [showCardContent])

  return (
    <Container.Flex
      fullWidth
      alignItems="start"
      verticalGap={16}
      styles={{ height }}
    >
      <Container.Flex
        fullWidth
        verticalGap={16}
        alignItems="start"
        ref={contentRef}
        onTransitionEnd={resize}
        className="show"
      >
				{showChooseUploadMethod && <ChooseUploadMethod />}
				{showImageQrCode && <ImageQrCode />}
        {showCardContent && (
          <Fragment>
            {showImageContainer && <FilesContainer />}

            {!!questionHeader && (
              <Typography.TitleSecondary
                textAlign={'start'}
                styles={{
                  paddingLeft: '4px',
                  paddingRight: '4px',
                  margin: '0 0 8px',
                }}
              >
                {questionHeader}
              </Typography.TitleSecondary>
            )}

            {!!processedHTML && (
              <Container.Flex
                fullWidth
                alignItems="start"
                padding="0 4px"
                margin="0 0 6px"
              >
                <Typography.Main textAlign="start" margin={0}>
                  <QuestionDescription
                    dangerouslySetInnerHTML={{
                      __html: processedHTML,
                    }}
                  />
                </Typography.Main>

                {!!popupTrigger && (
                  <PopupTrigger
                    text={popupTrigger}
                    showPopup={() => showPopup(popupContent)}
                  />
                )}
              </Container.Flex>
            )}

            {questionName && (
              <Typography.Main
                textAlign="start"
                margin="0 0 6px"
                padding="0 4px"
              >
                {questionName}
              </Typography.Main>
            )}

            {answerType === 'from_list' && (
              <FromList {...props} combinationId={combinationId} />
            )}
            {answerType === 'free_input' && (
              <FreeInput
                {...props}
                giveAnswer={giveAnswer}
                combinationId={combinationId}
                outerResize={resize}
              />
            )}

            {answerType === 'radio_button' && (
              <FromRadio
                {...props}
                giveAnswer={giveAnswer}
                combinationId={combinationId}
              />
            )}

            {answerType === 'show_qr_link' && (
              <QrCode
                combinationCode={combinationCode}
                combinationId={combinationId}
              />
            )}

            {!!questionHelp && <Info html={questionHelp} />}

            {answerType === 'show_qr_link' && (
              <Button fullWidth variant="outline" onClick={back}>
                Назад
              </Button>
            )}

            {(answerType === 'upload_image' || props.pauseQuestions) && (
              <UploadImage {...props} giveAnswer={giveAnswer} />
            )}
          </Fragment>
        )}
      </Container.Flex>
    </Container.Flex>
  )
}

export default OfferQuestion
