import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LowerMenuItem } from './types'
import LowerMenuContent from './LowerMenuContent'
import { useAppSelector } from '../../store/hooks'
import { Order, OrderStatuses } from '../../store/orderSlice/types'
import { PATHS } from '../../pages/PATHS'
import { UserType } from '../../store/userSlice/types'
import { useAuthData } from '../../contexts/auth'

const link = {
  estimate: {
    icon: 'mobile',
    title: 'Оценить смартфон',
    link: '/create/',
  } as LowerMenuItem,
  addDevice: {
    icon: 'add-device',
    title: 'Добавить устр-во',
    link: 'handler/add-device',
    secure: true,
  } as LowerMenuItem,
  saveOrder: {
    icon: 'save-order',
    title: 'Сохранить оценку',
    link: 'handler/save-order',
    secure: true,
  } as LowerMenuItem,
  choosePartner: {
    icon: 'leaf',
    title: 'Выбрать партнера',
    link: '/partners/',
    secure: true,
  } as LowerMenuItem,
  mapPartners: {
    icon: 'map',
    title: 'Партнёры на карте',
    link: '/partners/map/',
  } as LowerMenuItem,
  courierRelocationRequest: {
    icon: 'add-plus',
    title: 'Перемеще\nние',
    link: PATHS.COURIER_TRANSPORT_APPLICATION,
  } as LowerMenuItem,
  courierReceivedPackages: {
    icon: 'user-save',
    title: 'Принятые пакеты',
    link: PATHS.COURIER_RECEIVED_PACKETS,
  } as LowerMenuItem,
  courierGetPackages: {
    icon: 'check-circle',
    title: 'Принять пакеты',
  } as LowerMenuItem,
}

const links = {
  main: ['estimate', 'choosePartner'] as MenuItemKey[],
  partners: ['estimate', 'mapPartners'] as MenuItemKey[],
  create: [] as MenuItemKey[],
  order: ['addDevice', 'choosePartner', 'saveOrder'] as MenuItemKey[],
  courier: [
    'courierRelocationRequest',
    'courierReceivedPackages',
  ] as MenuItemKey[],
}

export type MenuItemKey = keyof typeof link
export type MenuItemGroups = keyof typeof links

const getLinkByPathname = ({
  pathname,
  isAdmin,
  isLogged,
  secure,
  orderData,
  disableLinks,
  userRole,
}: {
  pathname: string
  isAdmin: boolean
  isLogged: boolean
  secure: boolean
  orderData: Order | null
  disableLinks: MenuItemKey[]
  userRole?: UserType
}): LowerMenuItem[] => {
  const getLinks = (key: MenuItemGroups) => {
    const _links = links[key].map(el => ({
      ...link[el],
      disabled: disableLinks.includes(el) || undefined,
    }))
    return _links
  }

  if (/^\/create\//.test(pathname)) {
    return getLinks('create')
  }

  if (/^\/order\/.*/.test(pathname)) {
    if (!orderData) {
      return [link.estimate]
    }
    if (isAdmin) {
      if (orderData.status) {
        if (OrderStatuses.Confirm && orderData.registration) {
          return []
        }
        if (
          [
            OrderStatuses.New,
            OrderStatuses.Checking,
            OrderStatuses.Confirm,
          ].includes(orderData.status)
        ) {
          return [link.addDevice]
        } else {
          return []
        }
      }
      return [link.addDevice]
    }
    if (orderData?.notOwner) {
      return getLinks('order').filter(el => !el.secure)
    }

    if (isLogged) {
      return getLinks('order').slice(0, 2)
    }

    return getLinks('order')
  }

  switch (pathname) {
    case '/':
      return userRole === UserType.courier
        ? getLinks('courier')
        : getLinks('main')
    case '/partners/':
      return getLinks('partners')
    case PATHS.stocks:
      return getLinks('courier')
    default:
      return []
  }
}

const LowerMenu = () => {
  const [items, setItems] = useState<LowerMenuItem[]>([])

  const { userData } = useAuthData()

  const location = useLocation()

  const secureMode = useAppSelector(state => state.view.lowerMenuSecure)
  const orderData = useAppSelector(state => state.order.order.data)

  const { disableLinks } = useAppSelector(state => state.lowerMenu)

  useEffect(() => {
    const { pathname } = location

    setItems(
      getLinkByPathname({
        pathname,
        isAdmin:
          userData?.userType === UserType.partner ||
          userData?.userType === UserType.employee,
        isLogged: !!userData,
        secure: secureMode,
        orderData,
        disableLinks,
        userRole: userData?.userType,
      }),
    )
  }, [location, orderData, userData])

  if (!items.length) return null

  if (/^\/order\/.*/.test(location.pathname) && !orderData) return null

  return <LowerMenuContent items={items} />
}

export default LowerMenu
