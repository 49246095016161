import styled from 'styled-components/macro'
import { TextProps } from '../types'
import { getTextAlign } from './helpers'
import { getCommonProps, collectTypographyStyles } from '../../../types'
import { IndicatorStyles } from '..'
import Icon from '../../Icon'
import { useLink } from './useLink'

export const MediumWrapper = styled.p<TextProps>`
  font-size: 14px;
  ${props => getTextAlign(props)};
  ${props => ({
    ...props.theme.typography.medium,
    ...props.styles,
  })};
  display: ${props => (props.withIndicator ? 'flex' : 'block')};
  ${props => getCommonProps(props)};
  ${props => collectTypographyStyles(props)};
  ${props => (props.color ? { color: props.color } : {})};
  ${props =>
    props.secondary ? { color: props.theme.colors.text.secondary } : {}};
  ${props => (props.onClick ? { cursor: 'pointer' } : {})};
  transition: ${props => (props.noTransition ? 'none' : 'all 200ms ease-in')};
  ${props =>
    props.linkStyle ? { color: props.theme.colors.link.default } : {}};
  ${props => (props.noSelection ? { userSelect: 'none' } : {})};
`

export default function Medium(props: TextProps) {
  const { navigate } = useLink()

  return (
    <MediumWrapper {...props} onClick={props.onClick ?? navigate(props.link)}>
      {props.children}
      {props.withIndicator && (
        <Icon
          name="color-indicator"
          styles={IndicatorStyles}
          color={props.indicatorColor ?? props.color ?? 'default'}
        />
      )}
    </MediumWrapper>
  )
}
