import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../components/Layout/PageLoading'
import { Typography } from '../components/ui'
import { useGetClientDevicesQuery } from '../store/api/api'
import PageLayout from './PageLayout'

export default function MyDevices() {

  const navigate = useNavigate()

  const { data, isLoading, isError } = useGetClientDevicesQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true
  })

  useEffect(() => {
    if (data?.number) {
      navigate(`/order/${data.number}/`)
    }
  }, [data, navigate])

  return (
    <PageLayout>
      {isError && <Typography.Error fullWidth>Ошибка</Typography.Error>}

      {isLoading && <PageLoader />}

      {!!data && !data.number && (
        <Typography.Title fullWidth>Устройства отсутствуют</Typography.Title>
      )}
    </PageLayout>
  )
}
