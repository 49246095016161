import { Box, Container, Typography } from "../ui";
import ShopItem from './ShopItem'
import { Partners } from '../../store/orderSlice/types'
import PageLoader from '../Layout/PageLoading'
import Search from './ShopsSearch'
import { bp/*, containerSizes*/ } from '../../theme/media'
import { styled } from "../../helpers/styled";

type Props = {
  partners: Partners | null
  title?: string
  pending: boolean
  error: string
  start?: true
  withoutCreate?: true
}


const ShopsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 36px;
  
  @media (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`
const Shops = ({
  partners,
  error,
  pending,
  start,
}: Props) => {

  if (!partners) return null

  if (pending) return <PageLoader />

  if (error) return <Typography.Error>{error}</Typography.Error>

  return (
    <Container.Flex
      alignItems="stretch"
      direction="column"
      fullWidth
      className="show"
    >
      <Box styles={{
          marginBottom: "24px"
        }}
           breakpoints={{
             [bp.mobile]: {
               marginBottom: "16px"
             }
           }}
      >
        <Search />
      </Box>

      <ShopsGrid>
        {Object.values(partners).map(partner => (
          <ShopItem data={partner} key={partner.code} />
        ))}
      </ShopsGrid>

    </Container.Flex>
  )
}

export default Shops
