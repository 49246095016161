import Flex from "./Flex"
import Grid from "./Grid"
import CardsGrid from "./CardsGrid"

const Container = {
    Flex,
    Grid,
    CardsGrid
}

export default Container