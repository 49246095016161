import { usePartnerDetail } from "../../pages/Partner";
import { bp } from "../../theme/media";
import { Container, Typography } from "../ui";

export default function PartnerDetailHeader () {

  const { data } = usePartnerDetail();
  const title = data?.name ?? ""

  return (
    <Container.Flex
      fullWidth
      direction="row"
      styles={{
        justifyContent: 'flex-start',
        marginBottom: '24px',
        paddingTop: "10px",
        paddingBottom: "10px"
      }}
      breakpoints={{
        [bp.mobile]: {
          justifyContent: 'space-between',
          paddingTop: "0",
          paddingBottom: "0"
        },
      }}
    >
      <Typography.Title margin={'0 16px 0 4px'} breakpoints={{
          [bp.mobile]: {
              marginRight: '0'
          }
      }}>{title}</Typography.Title>

      <Typography.Medium margin={0} link="/partners/">Все партнёры</Typography.Medium>
    </Container.Flex>
  )
}