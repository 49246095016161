import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Api, { URLS } from '../../../apinew'
import {
  getFrom20days,
  getFromToday,
  getFromWeek,
  getFromYesterday,
} from '../../../helpers/date'
import { collectFiltersData } from './helpers/collectFiltersData'
import { getErrorString } from '../../../helpers/getErrorString'
import { deepCopyFilters } from './helpers/deepCopyFilters'
import { DeviceStatuses, ImageSrc } from '../../../types'

type DateValue = {
  from: Date | null
  to: Date | null
}

export interface SearchRequest {
  search?: string
  dateFrom?: string
  dateTo?: string
  filters: any
}

const initalDateValue = {
  from: null,
  to: null,
}

export const PERIOD_FILTER_KEY = 'Период'

const _dateFilters = {
  today: {
    title: 'Сегодня',
    active: false,
    getValue: getFromToday,
    key: 'today',
    unique: true,
  },
  yesterday: {
    title: 'Вчера',
    active: false,
    getValue: getFromYesterday,
    key: 'yesterday',
    unique: true,
  },
  week: {
    title: 'За 7 дней',
    active: false,
    getValue: getFromWeek,
    key: 'week',
    unique: true,
  },
  twentyDays: {
    title: 'За 20 дней',
    active: false,
    getValue: getFrom20days,
    key: 'twentyDays',
    unique: true,
  },
}

export type Filter = {
  propertyName: string
  valueType: 'list'
  name: string
  items: Array<{
    name: string
    value: string
    chosen?: true
  }>
}

export type DateFilter = {
  propertyName: string
}

export enum OrderStatuses {
  Checking = 'N',
  Canceled = 'Canceled',
  Confirm = 'Confirm',
  Accept = 'Accept',
  Paid = 'Paid',
  Ready = 'Ready',
  Done = 'Done',
}

export type OrderListItem = {
  name: string
  image: ImageSrc
  status: DeviceStatuses
  itemNumber: string
  price: string
  answers: {
    [key: string]: string
  }
}

export type OrderListData = {
  amount: string
  dateCreate: string
  registration: string // Трейд-ин или Выкуп или что-то еще
  currency: string // currencyLang само по себе фигня, зачем мне два currency
  status: string
  statusColor: string
  items: OrderListItem[]
  number: string
}

interface IOrderListContext {
  filters: Filter[]
  dateFilters: typeof _dateFilters
  onFilterChange: (a: string) => (name: string) => void
  activeFilter: string | null
  //setActiveFilter: (v: string | null) => () => void
  setActiveFilter: (activeFilter: string | null) => void
  activePeriodFilter: keyof typeof _dateFilters | null
  onDateFilterChange: (key: keyof typeof _dateFilters) => void
  openFilters: () => void
  closeFilters: () => void
  getFilterIsActive: (propertyName: string) => true | undefined
  resetAllFilters: () => void
  resetOneFilter: (propertyName: string) => () => void
  resetPeriodFilters: () => void
  search: string
  setSearch: (v: string) => void
  error: string
  showFilters: boolean
  setShowFilters: (v: boolean) => void
  dateValue: DateValue
  setDateValue: (date: DateValue) => void
  filtersOn: boolean
  periodFiltersOn: boolean
  anyFiltersOn: boolean
  getFilters: () => void
  loadingFilters: boolean
  filterError: string
  orderList: OrderListData[] | null
  orderListPending: boolean
  orderListError: string
  getOrderList: () => void
  listFilterOn: (v: string) => boolean
}

const pass = () => {}

const OrderListContext = createContext({
  filters: [],
  dateFilters: _dateFilters,
  activeFilter: null,
  setActiveFilter: () => pass,
  onFilterChange: () => pass,
  onDateFilterChange: () => pass,
  openFilters: pass,
  closeFilters: pass,
  getFilterIsActive: pass,
  resetAllFilters: pass,
  resetOneFilter: () => pass,
  resetPeriodFilters: pass,
  search: '',
  setSearch: pass,
  error: '',
  showFilters: false,
  setShowFilters: pass,
  dateValue: {
    from: null,
    to: null,
  },
  setDateValue: pass,
  filtersOn: false,
  periodFiltersOn: false,
  anyFiltersOn: false,
  getFilters: pass,
  activePeriodFilter: null,
  loadingFilters: false,
  filterError: '',
  orderList: null,
  orderListError: '',
  orderListPending: false,
  getOrderList: pass,
  listFilterOn: () => true
} as IOrderListContext)

export function useOrderListData() {
  return useContext(OrderListContext)
}

export default function OrderListContextWrapper({
  children,
}: PropsWithChildren<{}>) {

  const [initialFilters, setInitialFilters] = useState<Filter[]>([])
  const [filters, setFilters] = useState<Filter[]>([])
  const [dateFilters, setDateFilters] =
    useState<typeof _dateFilters>(_dateFilters)
  const [search, setSearch] = useState('')
  const [error, setError] = useState('')
  const [dateValue, setDateValue] = useState<DateValue>({
    from: null,
    to: null,
  })
  const [showFilters, setShowFilters] = useState(false)
  const [activeFilter, setActiveFilter] = useState<string | null>(null)
  const [activePeriodFilter, setActivePeriodFilter] = useState<
    keyof typeof _dateFilters | null
  >(null)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [filterError, setFilterError] = useState('')
  const [orderList, setOrderList] = useState<OrderListData[] | null>(null)
  const [orderListError, setOrderListError] = useState('')
  const [orderListPending, setOrderListPending] = useState(false)

  const getOrderList = useCallback(() => {
    setOrderListPending(true)
    const api = new Api()
    const data = {
      filters: collectFiltersData(filters),
    } as SearchRequest
    if (search) data.search = search
    if (dateValue.from) data.dateFrom = dateValue.from.toLocaleDateString()
    if (dateValue.to) data.dateTo = dateValue.to.toLocaleDateString()
    api
      ._post<SearchRequest, OrderListData[]>(URLS.getOrderList)(data)
      .then(response => {
        if (response.status === 'success') {
          setOrderList(response.data)
          setOrderListError('')
        }
        if (response.status === 'error') {
          setOrderListError(getErrorString(response.errors))
        }
      })
      .catch((error: any) => setOrderListError(error.message))
      .finally(() => {
        setOrderListPending(false)
      })
  }, [search, filters, dateValue])

  const getFilters = useCallback(() => {
    if (filterError || (!!filters.length && !!initialFilters.length)) {
      setLoadingFilters(false)
      return
    }
    setLoadingFilters(true)

    if (!!filters.length) return
    const api = new Api()
    api
      .getOrderListFilters({})
      .then(response => {
        if (response.status === 'success') {
          if (response.data) {
            setFilters(response.data)
            setInitialFilters(deepCopyFilters(response.data))
            setFilterError('')
          }
        }
        if (response.status === 'error') {
          setFilterError(response.errors.map(err => err.message).join('. '))
        }
      })
      .catch((err: any) => {
        setError(err.message)
      })
      .finally(() => setLoadingFilters(false))
  }, [filters, filterError, loadingFilters])

  const onFilterChange = useCallback(
    (propertyName: string) => (name: string) => {
      const filterIdx = filters.findIndex(
        el => el.propertyName === propertyName,
      )

      if (!filters[filterIdx]) return pass

      const items = [...filters[filterIdx].items]

      const item = items.find(el => el.name === name)

      if (!item) return

      item.chosen = item.chosen ? undefined : true
      filters[filterIdx].items = items

      setFilters([...filters])
    },
    [setFilters, filters],
  )

  const onDateFilterChange = (key: keyof typeof _dateFilters) => {
    const active = activePeriodFilter === key
    if (active) {
      setDateValue(initalDateValue)
      setActivePeriodFilter(null)
    } else {
      setDateValue(dateFilters[key].getValue())
      setActivePeriodFilter(key)
    }
  }

  const openFilters = useCallback(() => {
    setShowFilters(true)
  }, [setShowFilters])

  const closeFilters = useCallback(() => {
    setShowFilters(false)
  }, [setShowFilters])

  const getFilterIsActive = useCallback(
    (propertyName: string) =>
      filters
        .find(el => el.propertyName === propertyName)
        ?.items.some(item => item.chosen) || undefined,
    [filters],
  )

  const resetAllFilters = useCallback(() => {
    const newFilters = deepCopyFilters(filters)
    setFilters(newFilters)
    setDateValue(initalDateValue)
    setDateFilters(_dateFilters)
    setActivePeriodFilter(null)
  }, [filters, setFilters, setDateValue])

  const resetOneFilter = useCallback(
    (propertyName: string) => () => {
      const filterIdx = filters.findIndex(
        el => el.propertyName === propertyName,
      )
      if (!filters[filterIdx]) return

      const items = filters[filterIdx].items.map(item => ({
        ...item,
        chosen: undefined,
      }))

      filters[filterIdx].items = items

      setFilters([...filters])
    },
    [filters, setFilters],
  )

  const resetPeriodFilters = useCallback(() => {
    setDateValue(initalDateValue)
    setDateFilters(_dateFilters)
    setActivePeriodFilter(null)
  }, [])

  const periodFiltersOn = useMemo(() => {
    return !!(dateValue.from || dateValue.to)
  }, [dateValue])

  const filtersOn = useMemo(() => {
    return (
      filters.some(el => el.items.some(item => item.chosen)) ||
      !!activePeriodFilter || !!periodFiltersOn
    )
  }, [filters, activePeriodFilter, periodFiltersOn])

  const anyFiltersOn = useMemo(() => {
    return filtersOn || periodFiltersOn
  }, [filtersOn, periodFiltersOn])

  const listFilterOn = useCallback(
    (propertyName: string) => {
      const filter = filters.find(el => el.propertyName === propertyName)

      if (!filter) return false

      return filter.items.some(el => el.chosen)
    },
    [filters],
  )

  useEffect(getFilters, [getFilters])

  useEffect(getOrderList, [filters, dateValue])

  return (
    <OrderListContext.Provider
      value={{
        filters,
        onFilterChange,
        openFilters,
        closeFilters,
        getFilterIsActive,
        resetAllFilters,
        resetOneFilter,
        search,
        setSearch,
        error,
        showFilters,
        setShowFilters,
        dateValue,
        setDateValue,
        dateFilters,
        onDateFilterChange,
        filtersOn,
        periodFiltersOn,
        anyFiltersOn,
        activeFilter,
        setActiveFilter,
        getFilters,
        resetPeriodFilters,
        activePeriodFilter,
        loadingFilters,
        filterError,
        orderList,
        orderListError,
        orderListPending,
        getOrderList,
        listFilterOn
      }}
    >
      {children}
    </OrderListContext.Provider>
  )
}
