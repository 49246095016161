import { Fragment, useState } from 'react'
import { useTheme } from 'styled-components'
import { Container, Icon, Typography } from '../../ui'
import { TransportApplicationItem as TransportApplicationItemType } from './TransportApplication.types'
import { ConfirmPortal } from '../../ui/Confirm'

interface ITransportApplicationItem {
  data: TransportApplicationItemType,
  deleteSavePack?: (v: string) => void
}

type Status = TransportApplicationItemType['items'][number]['status']

function PackageNumberIcon({
  status,
  packNumber,
  onClick,
}: {
  status: Status
  packNumber: string
  onClick?: () => void
}) {
  const [showConfirm, setShowConfirm] = useState(false)
  const packNumberString = (packNumber.match(/.{1,2}/g) || []).join(' ')

  const hideConfirm = () => setShowConfirm(false)

  switch (status) {
    case 'apply':
      return <Icon name="check-circle" />
    case 'inApp':
      return (
        <Fragment>
          <Icon
            name="close-circle"
            styles={{ cursor: 'pointer' }}
            onClick={() => setShowConfirm(true)}
          />
          <ConfirmPortal
            question={`Перед подтверждением удаления сейф-пакет ${packNumberString} верните его партнёру`}
            questionTitle={`Удаление пакета ${packNumberString}`}
            onYes={onClick ?? hideConfirm}
            onNo={hideConfirm}
            visible={showConfirm}
            yesText="Удалить"
            yesVariant='danger'
            yesFirst
            noText="Отмена"
            hide={hideConfirm}
          />
        </Fragment>
      )
    case 'miss':
      return <Icon name="warning" />
    default:
      return null
  }
}

function PackageNumber(props: { number: string; status: Status, onClick: () => void }) {
  const theme = useTheme()

  const chunks = props.number.match(/.{1,2}/g) || []

  return (
    <Container.Flex
      direction="row"
      relative
      padding={'2px 20px 2px 8px'}
      styles={{
        height: '24ox',
        borderRadius: '15px',
        backgroundColor:
          props.status === 'miss'
            ? theme.colors.info.error
            : theme.colors.info.success,
      }}
    >
      {chunks.map((el, idx) => (
        <Typography.Title
          margin="0 4px 0 0"
          padding={0}
          color={theme.colors.text.contrast}
          key={idx}
        >
          {el}
        </Typography.Title>
      ))}
      <Container.Flex
        styles={{
          position: 'absolute',
          right: '4px',
          top: '4px',
          color: theme.colors.text.contrast,
        }}
      >
        <PackageNumberIcon status={props.status} packNumber={props.number} onClick={props.onClick} />
      </Container.Flex>
    </Container.Flex>
  )
}

export default function TransportApplicationItem(
  props: ITransportApplicationItem,
) {
  const theme = useTheme()

  const { data, deleteSavePack } = props

  return (
    <Container.Flex
      fullWidth
      className="relocation-request-item"
      padding={28}
      gap={18}
      styles={{
        borderRadius: '25px',
        background: theme.colors.background.contrast60,
        backdropFilter: 'blur(8px)',
        boxShadow: 'rgb(0 0 0 / 4%) 0px 0px 25px',
      }}
    >
      <Container.Flex fullWidth direction="row" justify="between">
        <Typography.TitleSecondary margin={0}>
          {data.number}
        </Typography.TitleSecondary>
        <Typography.Medium margin={0} withIndicator color={data.statusColor}>
          {data.status}
        </Typography.Medium>
      </Container.Flex>

      <Container.Flex fullWidth wrapped direction="row" gap={6}>
        {data.items.map(el => (
          <PackageNumber
            key={el.savepackNumber}
            number={el.savepackNumber}
            status={el.status}
            onClick={() => deleteSavePack && deleteSavePack(el.savepackNumber)}
          />
        ))}
      </Container.Flex>
    </Container.Flex>
  )
}
