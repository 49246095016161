import PageLayout from './PageLayout'
import CourierWrapper from './CourierWrapper'
import CourierPacks from '../components/Courier/CourierPacks'
// import { getMockCourierPacks } from '../components/Courier/mockData'
import { PATHS } from './PATHS'
import { URLS } from '../apinew'

const CourierReceivedPackets = () => {
  return (
    <PageLayout withProfile customLowerMenu>
      <CourierWrapper>
        <CourierPacks
          searchPlaceholder={'Номер сейф-пакета'}
          dataURL={URLS.TRANSPORT_APPLICATION_GET_ALL_COURIER_LIST}
          title="Пакеты у меня"
          inputType="pack"
          filterBy={{
            key: 'items',
            innerKey: 'savepackNumber',
          }}
          topButton={{
            title: 'Все склады',
            href: PATHS.stocks,
          }}
          lowerMenuItems={[
            { title: 'Список складов', link: PATHS.stocks, icon: 'map' }
          ]}
        />
      </CourierWrapper>
    </PageLayout>
  )
}

export default CourierReceivedPackets
