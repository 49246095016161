import SVG from '../SVG'
import { IconProps } from '../types'

const LeafIcon24 = (props: IconProps) => {
  return (
    <SVG {...props} width={props.width ?? 24} height={props.height ?? 24}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.03747 16.4395L9.68122 12.7957C10.0717 12.4052 10.7049 12.4052 11.0954 12.7957C11.486 13.1863 11.486 13.8194 11.0954 14.21L7.4516 17.8538C8.99632 18.5881 10.419 18.8656 11.6822 18.7826C13.2397 18.6803 14.6337 18.0258 15.8003 16.8592C18.0512 14.6083 19.4932 10.3884 19.2688 4.62267C13.503 4.39827 9.28314 5.84018 7.03223 8.09108C5.8656 9.25771 5.21116 10.6517 5.10884 12.2092C5.02586 13.4724 5.30332 14.8949 6.03747 16.4395ZM4.54934 17.9276C3.46975 15.9104 2.9913 13.9329 3.11315 12.0781C3.24948 10.0029 4.133 8.16188 5.61802 6.67686C8.55686 3.73803 13.7778 2.23999 20.2863 2.6739C20.7862 2.70723 21.1843 3.1053 21.2176 3.60517C21.6514 10.1136 20.1534 15.3346 17.2146 18.2734C15.7295 19.7584 13.8886 20.6419 11.8133 20.7783C9.95841 20.9001 7.98085 20.4216 5.96347 19.3419L4.3072 20.9982C3.91668 21.3887 3.28352 21.3887 2.89299 20.9982C2.50247 20.6077 2.50247 19.9745 2.89299 19.584L4.54934 17.9276Z"
      />
    </SVG>
  )
}

export default LeafIcon24
