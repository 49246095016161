import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import Container from '../Container'
import Icon from '../Icon'
import Typography from '../Typography'
import { useTheme } from 'styled-components/macro'
import { useDelete } from '../../../contexts/delete'
import {
  UploadImagesContextWrapper,
  useUploadImages,
} from './UploadImagesContext'
import UploadFirstImage from './UploadImageFirst'
import UploadSecondImage from './UploadImageSecond'
import { classNames } from '../../../styles/classNames'
import UploadImageConfirm from './UploadImageConfirm'

const ImageContainer = styled.div<{ url: string | null }>`
  height: 64.5px;
  width: 48.77px;
  background: ${props => (props.url ? '#FFFFFF' : 'none')};
  border-radius: 6.07092px;
  border: ${props =>
    props.url ? 'none' : `1px dashed ${props.theme.colors.icon.quaternary}`};
  margin-right: 12px;
  position: relative;
  overflow: hidden;
`

const ImageItem = styled.img`
  height: 100%;
  width: auto;
  object-fit: cover;
`

const RemoveIcon = styled.div`
  width: 26px;
  height: 26px;
  background-color: ${props => props.theme.colors.background.contrast60};
  position: absolute;
  top: calc(50% - 13px);
  left: calc(50% - 13px);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`

interface ItemProps {
  url: string | null
  removeItem: () => void
  id: number
}

const Item = ({ url, removeItem, id }: ItemProps) => {
  return (
    <ImageContainer url={url}>
      {!!url && <ImageItem src={url} alt={`Фото № ${id}`} />}
      {!!url && (
        <RemoveIcon onClick={removeItem}>
          <Icon
            name="close"
            styles={{
              width: '12px',
              height: '12px',
              position: 'absolute',
              left: 'calc(50% - 6px)',
              top: 'calc(50% - 6px)',
            }}
          />
        </RemoveIcon>
      )}
    </ImageContainer>
  )
}

function UploadImagesComponent() {
  const theme = useTheme()

  const { showDelete } = useDelete()

  const { images, removeImage, resizeCard } = useUploadImages()
  const [content, setContent] = useState<JSX.Element | null>(null)

  const removeItem = (index: 0 | 1) => {
    removeImage(index)
  }

  const onRemoveItem = useCallback(
    (index: 0 | 1) => {
      showDelete({
        title: 'Удалить фото?',
        question: 'Вы точно хотите произвести удаление?',
        onDelete: () => removeItem(index),
      })
    },

    [showDelete],
  )

  const urls = useMemo(() => {
    return images.map(el => {
      if (el) {
        return window.URL.createObjectURL(el)
      }
      return el
    })
  }, [images])

  const getContent = () => {
    if (!images[0]) {
      setContent(<UploadFirstImage />)
    }
    if (images[0] && !images[1]) {
      setContent(<UploadSecondImage />)
    }

    if (images[0] && images[1]) {
      setContent(<UploadImageConfirm />)
    }
    if (contentRef.current) {
      contentRef.current.classList.remove(classNames.hideToRight)
      contentRef.current.classList.add(classNames.showFromLeft)
    }
  }

  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.classList.remove(classNames.showFromLeft)
      contentRef.current.classList.add(classNames.hideToRight)
    }
  }, [images])

  useEffect(resizeCard)

  return (
    <Container.Flex
      fullWidth
      gap={16}
      ref={contentRef}
      onAnimationEnd={getContent}
    >
      {(images[0] || images[1]) && <Container.Flex
        fullWidth
        alignItems="start"
        padding={'10px 12px'}
        styles={{
          border: `1px dashed ${theme.colors.icon.quaternary}`,
          borderRadius: '12px',
        }}
      >
        <Typography.Small margin={'0 0 12px 0'}>
          Загруженные фото
        </Typography.Small>

        <Container.Flex fullWidth direction="row">
          {urls.map((url, key) => (
            <Item
              url={
                (url || '').startsWith('/')
                  ? (process.env.REACT_APP_IMAGE_BASE ?? '') + url
                  : url
              }
              key={key}
              id={key}
              removeItem={() => onRemoveItem(key as 0 | 1)}
            />
          ))}
        </Container.Flex>
      </Container.Flex>}

      {content}
    </Container.Flex>
  )
}

export default function UploadImages({
  itemNumber,
  orderNumber,
}: {
  itemNumber: string
  orderNumber: string
}) {
  return (
    <UploadImagesContextWrapper
      itemNumber={itemNumber}
      orderNumber={orderNumber}
    >
      <UploadImagesComponent />
    </UploadImagesContextWrapper>
  )
}
