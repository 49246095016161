import SVG from '../SVG'
import { IconProps } from '../types'

const StatusPaid = (props: IconProps) => {
  const width = props.width ?? 16
  const height = props.height ?? 16
  return (
    <SVG
      {...props}
      width={width}
      height={height}
      styles={{
        color: props.color ?? '#78C25E',
      }}
    >
      <g clipPath="url(#clip0_5368_23166)">
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.8 6.7998C4.8 6.71378 4.89053 6.42054 5.52934 6.10113C6.12031 5.80565 6.9927 5.5998 8 5.5998C9.0073 5.5998 9.87969 5.80565 10.4707 6.10113C11.1095 6.42054 11.2 6.71378 11.2 6.7998C11.2 6.88583 11.1095 7.17907 10.4707 7.49848C9.87969 7.79396 9.0073 7.9998 8 7.9998C6.9927 7.9998 6.12031 7.79396 5.52934 7.49848C4.89053 7.17907 4.8 6.88583 4.8 6.7998ZM8 3.9998C6.79816 3.9998 5.67055 4.24167 4.8138 4.67005C4.0049 5.0745 3.2 5.78126 3.2 6.7998C3.2 7.28786 3.38481 7.70433 3.66631 8.05026C3.39021 8.39274 3.2 8.81284 3.2 9.2998C3.2 10.3184 4.0049 11.0251 4.8138 11.4296C5.67055 11.8579 6.79816 12.0998 8 12.0998C9.20184 12.0998 10.3294 11.8579 11.1862 11.4296C11.9951 11.0251 12.8 10.3184 12.8 9.2998C12.8 8.81284 12.6098 8.39274 12.3337 8.05026C12.6152 7.70434 12.8 7.28786 12.8 6.7998C12.8 5.78126 11.9951 5.0745 11.1862 4.67005C10.3294 4.24167 9.20184 3.9998 8 3.9998ZM11.0407 8.99932C10.2037 9.3841 9.13497 9.5998 8 9.5998C6.86503 9.5998 5.79626 9.3841 4.95929 8.99932C4.81923 9.14819 4.8 9.2524 4.8 9.2998C4.8 9.38583 4.89053 9.67907 5.52934 9.99848C6.12031 10.294 6.9927 10.4998 8 10.4998C9.0073 10.4998 9.87969 10.294 10.4707 9.99848C11.1095 9.67907 11.2 9.38583 11.2 9.2998C11.2 9.2524 11.1808 9.14819 11.0407 8.99932Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5368_23166">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </SVG>
  )
}

export default StatusPaid
