import { createContext, memo, PropsWithChildren, useCallback, useContext, useState } from "react";
import { useReadOTP } from 'react-read-otp'

type OTPContextType = {
  otp: string
  setOTP: (v: string) => void
  enableOTP: () => void
  disableOTP: () => void
  OTPenabled: boolean
}

const OTPContext = createContext({} as OTPContextType);

export const useOTP = () => useContext(OTPContext);

function OTPWrapper(props: PropsWithChildren<{}>) {

  const [otp, setOTP] = useState("");
  const [enabled, setEnabled] = useState(false)
  useReadOTP(setOTP, {
    enabled
  });

  const enableOTP = useCallback(() => {
    setEnabled(true)
  }, []);
  
  const disableOTP = useCallback(() => {
    setEnabled(true)
  }, []);

  return (
    <OTPContext.Provider value={{
      otp,
      setOTP,
      enableOTP,
      disableOTP,
      OTPenabled: enabled
    }}>
      {props.children}
    </OTPContext.Provider>
  )
}

export default memo(OTPWrapper)

