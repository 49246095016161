import { useSelector } from 'react-redux'
import Bearer from '../components/Layout/Bearer'
import PageLoader from '../components/Layout/PageLoading'
import { getUserData } from '../store/userSlice'
import { UserType } from '../store/userSlice/types'
import PageLayout from './PageLayout'
import { IconProps } from '../components/ui/Icon/types'
import Flex from '../components/ui/Container/Flex'
import { Icon, Typography } from '../components/ui'

const icons: IconProps['name'][] = [
  'add-device',
  'add-plus',
  'add-plus-circle',
  'arrow',
  'bell',
  'calendar',
  'check-circle',
  'close',
  'close-circle',
  'color-indicator',
  'dissence',
  'dissence-active',
  'dissenct-read',
  'edit',
  'employee',
  'exit',
  'eye',
  'eye-close',
  'filters-on',
  'filters-off',
  'info',
  'help',
  'hidden',
  'lamp',
  'leaf',
  'leaf24',
  'list',
  'list-add',
  'loading',
  'map',
  'mobile',
  'mobile24',
  'new-order',
  'order-list',
  'photo',
  'qr-code',
  'reports',
  'save-order',
  'search',
  'settings',
  'telegram',
  'user',
  'users',
  'user-save',
  'warning',
]

export default function UIIConsPage() {
  const { userData, api, session } = useSelector(getUserData)

  if (api.pending) {
    return <PageLoader />
  }

  if (!session.isAuthorised) {
    return <Bearer />
  }

  if (userData?.userType === UserType.client) {
    return <Bearer />
  }

  return (
    <PageLayout>
      <Flex direction="column" alignItems="start">
        {icons.map(el => (
          <Flex direction="row" key={el} gap={10}>
            <Flex
              direction="row"
              justify="center"
              styles={{ minWidth: '30px' }}
            >
              <Icon name={el} color="red" />
            </Flex>
            <Typography.Medium styles={{ fontWeight: 700 }}>
              {el}
            </Typography.Medium>
          </Flex>
        ))}
      </Flex>
    </PageLayout>
  )
}
