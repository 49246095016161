import SVG from '../SVG'
import { IconProps } from '../types'

const MobileIcons = (props: IconProps) => {
  return (
    <SVG {...props} width={props.width ?? 20} height={props.height ?? 20}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C4.89543 0 4 0.895432 4 2V18C4 19.1046 4.89543 20 6 20H14C15.1046 20 16 19.1046 16 18V2C16 0.89543 15.1046 0 14 0H6ZM6 2L14 2V18H6V2ZM10 15C9.44772 15 9 15.4477 9 16V16.002C9 16.5543 9.44772 17.002 10 17.002H10.002C10.5543 17.002 11.002 16.5543 11.002 16.002V16C11.002 15.4477 10.5543 15 10.002 15H10Z"
      />
    </SVG>
  )
}

export default MobileIcons
