import styled from 'styled-components/macro'
import { TextProps } from '../types'
import { getTextAlign } from './helpers'
import { getCommonProps, collectTypographyStyles } from '../../../types'
import { IndicatorStyles } from '..'
import Icon from '../../Icon'
import { useLink } from './useLink'

export const RublesMediumWrapper = styled.span<TextProps>`
  ${props => getTextAlign(props)};
  ${props => ({
    ...props.theme.typography.mediumRubles,
    ...props.styles,
  })};
  ${props => getCommonProps(props)};

  ${props => collectTypographyStyles(props)};
  padding: ${props => props.padding ?? 0};
  ${props => (props.color ? { color: props.color } : {})};
  ${props =>
    props.secondary ? { color: props.theme.colors.text.secondary } : {}};
  transition: ${props => (props.noTransition ? 'none' : 'all 200ms ease-in')};
`

export default function RublesMedium(props: TextProps) {
  const { navigate } = useLink()

  return (
    <RublesMediumWrapper
      {...props}
      onClick={props.onClick ?? navigate(props.link)}
    >
      {props.children}
      {props.withIndicator && (
        <Icon name="color-indicator" styles={IndicatorStyles} />
      )}
    </RublesMediumWrapper>
  )
}
