import { createGlobalStyle } from 'styled-components'
import { styled } from '../../helpers/styled'

export const GlobalStyles = createGlobalStyle`
    html {
        * {
            height: auto !important;
            max-height: auto !important;
            overflow: visible !important;
        }

    }
`

const Wrapper = styled.div`
  width: 100%;
  padding: 5% 5% 10%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  min-width: 1104px;
  margin-bottom: 5%;

  @media screen and (max-width: 1104px) {
    min-width: auto;
  }

  * {
    text-align: justify;
    max-width: 100%;
    width: auto !important;
    @media screen and (max-width: 510px) {
        font-size: 12px;
    }

  }

  @media print {
    position: absolute;
    min-width: auto;
    top: 0;
    left: 0;
    z-index: 10;
    width: 210mm;
    overflow: visible !important;
    padding: 0;
    box-sizing: border-box;
    page-break-inside: auto;

    button {
      display: none;
    }

    * {
      page-break-before: avoid !important;
    }

    footer {
      page-break-after: avoid !important;
    }

    * {
      font-size: 8pt;
      page-break-after: auto;
      overflow: visible;
    }

    table {
      width: calc(100% - 3cm);
      page-break-before: avoid !important;
    }

    li {
      margin: 1em 0;
      width: 100%;
    }

    span {
      margin: 2pt 0;
    }

    #page-break {
      page-break-before: always;
    }
  }

  li {
    margin: 5px 0;
  }

  ul {
    li {
      list-style-type: none;
    }
  }

  table {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    border-spacing: 0;
    border: 1px solid #000;

		* {
			border-spacing: 0;
			box-sizing: border-box;
		}
    @media print {
      margin-bottom: 2em;
      margin-top: 2em;
      border: 0.2mm solid #000;
			border-spacing: 0;
    }
    tr {
      display: grid;
      grid-template-columns: 2fr 2fr 1fr;
      border-bottom: 1px solid #000;

      @media screen and (max-width: 510px) {
        grid-template-columns: 1fr 1fr 0.7fr
      }

      @media print {
        border-bottom: 0.2mm solid #000;
				margin: 0;
        &:last-child {
          border-bottom: none;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
    td,
    th {
      display: flex;
      flex-direction: column;
      align-items: center;
			justify-content: center;
      padding: 5px;
      border-right: 1px solid #000;
      @media screen and (max-width: 510px) {
        font-size: 12px;
        
      }
      &:last-child {
        border-right: none;
      }

      @media print {
        padding: 2mm;
        border-right: 0.2mm solid #000;

        &:last-child {
          border-right: none;
        }
      }
    }

    tr:last-child > td {
      font-weight: bold;
    }
  }
`

const RowGrid = styled.div<{ gridCols: string; gap?: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => props.gridCols};
  ${props => (props.gap ? { gap: `${props.gap}mm` } : {})};

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }

  @media print {
    width: calc(210mm);
  }
`

const Title = styled.h2`
  width: 100%;
  text-align: center;
`

const Span = styled.span`
  text-align: start;
  margin: 2px 10px 2px 0;

  @media print {
    margin: 2pt 10pt 2pt 0;
  }
`

const P = styled.p`
  text-align: start;
  margin: 2px 5px;

  @media print {
    margin: 1pt 1pt;
  }
`

const SpanBold = styled.span`
  font-weight: bold;
  margin: 10px 0;

  @media print {
    margin: 2em 0;
  }
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const Contract = {
  Wrapper,
  Title,
  RowGrid,
  Span,
  SpanBold,
  FlexRow,
  P,
}
