import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { useAppDispatch } from '../store/hooks'
import { setSession } from '../store/userSlice'
import { Session, UserType } from '../store/userSlice/types'
import { bp } from '../theme/media'

export const useSession = () => {
  const { session, userData, api } = useSelector((state: RootState) => state.user)

  const dispatch = useAppDispatch();

  const isAdmin = useMemo(() => {
    return (
      userData?.userType === UserType.partner ||
      userData?.userType === UserType.employee
    )
  }, [userData])

  const userRole = useMemo(() => {
    return userData?.userType ?? UserType.client
  }, [userData])

	const bpTable = useMemo(() => {
		return isAdmin ? 1200 : bp.table;
	}, [isAdmin])

  const _setSession = useCallback((session: Session & any | null) => {
    dispatch(setSession(session))
  }, [dispatch])

  return {
    session,
    userRole,
    isAdmin,
    isLogged: !!userData,
		bpTable,
    noUser: !session.auth_param_value,
    pending: api.pending,
    setSession: _setSession,
    isCourer: userData?.userType === UserType.courier
  }
}
