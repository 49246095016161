import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { getProgressByStatus } from '../../helpers/getProgressByStatus'
import { OrderItem, OrderStatuses } from '../../store/orderSlice/types'
import OfferCard from '../Offer/OfferCard'
import OfferPrice from '../Offer/OfferPrice'
import OrderDevice from '../Offer/OrderDevice'
import { Button, Container } from '../ui'
import { useOrderContext } from './OrderContext'
import OrderDissent from './OrderModals/OrderDissent'

interface OrderShortItemProps {
  progress: number
  itemData: OrderItem
  currency: string
  showResult: boolean
  orderNumber: string
  withoutLoader?: true
  notOwner?: true
}

const OrderShortItem = ({
  itemData,
  currency,
  showResult,
  withoutLoader,
  notOwner,
  orderNumber,
}: OrderShortItemProps) => {
  const [resize, setResize] = useState(false)
  const [showQrCOde, setSHowQrCode] = useState(false)
  const [height, setHeight] = useState(0)

  const { showDissent, hideDissentModal, setShowShipmentModal, orderData } =
    useOrderContext()

  useEffect(() => {}, [showQrCOde, setResize])

  const contentRef = useRef<HTMLDivElement>(null)

  const _resize = () => {
    if (contentRef.current) {
      setResize(true)
      setHeight(contentRef.current.offsetHeight)
    }
  }

  const showSavePackButton = useMemo(() => {
    return !itemData.savePack && orderData?.status === OrderStatuses.Done
  }, [itemData, orderData])

  useEffect(_resize, [_resize])

  return (
    <Fragment>
      <OrderDissent
        data={itemData}
        hidden={!showDissent}
        hide={hideDissentModal}
        orderNumber={orderNumber}
      />
      <OfferCard
        isLoading={false}
        progress={getProgressByStatus(itemData.status, 0.5)}
        resize={resize}
        isOpen={showQrCOde}
        withoutLoader={withoutLoader}
      >
        <Container.Flex
          className="order-short-item"
          fullWidth
          styles={{
            height: `${height}px`,
            transition: 'height 200ms ease-in, opacity 150ms ease-in 50ms',
            overflow: 'hidden',
          }}
          onTransitionEnd={_resize}
        >
          <Container.Flex ref={contentRef} gap={showQrCOde ? 16 : 0} fullWidth>
            <OrderDevice
              notOwner={notOwner}
              data={itemData}
              hidingChars
              currency={currency}
              showResult={showResult}
              withDelete={true}
              resize={resize}
              setResize={setResize}
              onClose={() => {
                _resize()
                setSHowQrCode(false)
              }}
              onOpen={() => {
                _resize()
                setSHowQrCode(true)
              }}
            />
            <Container.Flex
              fullWidth
              styles={{
                transition: 'all 200ms ease-in',
                opacity: showQrCOde ? 1 : 0,
              }}
            >
              {!!showQrCOde && (
                <OfferPrice
                  hide={!showQrCOde}
                  itemNumber={itemData.itemNumber}
                  currency={currency}
                  amount={`${itemData.price}`}
                  status={itemData.status}
                />
              )}
            </Container.Flex>
          </Container.Flex>
        </Container.Flex>

        {showSavePackButton && (
          <Button
            fullWidth
            onClick={() => setShowShipmentModal(itemData.itemNumber)}
          >
            УПАКОВАТЬ В СЕЙФ-ПАКЕТ
          </Button>
        )}
      </OfferCard>
    </Fragment>
  )
}

export default OrderShortItem
