import { useTheme } from 'styled-components'
import { useState, useRef, useEffect, Fragment } from 'react'
import { Container, Typography } from '../ui'
import Okpng from '../../assets/ok.png'
import SimpleImage from '../ui/Image/SimpleImage'

interface TimerProps {
  resendCode: () => void
  codePending: boolean
  isError: boolean
  isSuccess: boolean
}

const Timer = ({ resendCode, codePending, isError, isSuccess }: TimerProps) => {
  const theme = useTheme()

  const [timer, setTimer] = useState(60)

  const timerRef = useRef<ReturnType<typeof setInterval>>()

  const changeTimer = () => {
    if (timer - 1 < 0) {
    } else {
      setTimer(timer - 1)
    }
  }

  const clearTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }


  const getCode = async () => {
    if (timer === 0) {
      resendCode()
    }
  }

  useEffect(() => {
    timerRef.current = setTimeout(changeTimer, 1000)

    return () => {
      clearTimer()
    }
  }, [timer])

  useEffect(() => {
    if (isSuccess) {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [isSuccess])

  return (
    <Container.Flex
      direction="row"
      horizontalGap={5}
      styles={{ padding: '0 4px 6px' }}
    >
      {!isSuccess && (
        <Fragment>
          <Typography.Small color={theme.colors.link.default} onClick={getCode}>
            Получить ещё раз код
          </Typography.Small>
          {timer > 0 && <Typography.Small>{`через ${timer} сек`}</Typography.Small>}
        </Fragment>
      )}

      {isSuccess && (
        <Container.Flex fullWidth direction="row" alignItems='start' padding="0">
          <SimpleImage src={Okpng} noBasePath width={16} height={16} alt="OK" margin={"0 4px 0 0"}/>
          <Typography.Small margin={0}>
            {'СМС со ссылкой успешно отправлено'}
          </Typography.Small>
        </Container.Flex>
      )}
    </Container.Flex>
  )
}

export default Timer
