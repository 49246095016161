import { Card, RadioSelect } from '../ui'
import Typography from '../ui/Typography'

const blends = ['Apple', 'Honor', 'Huawei', 'Realme']

const ThemeRadio = () => {
    return (
        <Card padding={20}>
            <Typography.Title>Radiobutton</Typography.Title>
            <RadioSelect
                styles={{}}
                items={blends.map((el, idx) => ({
                    value: idx,
                    label: el,
                }))}
            />
        </Card>
    )
}

export default ThemeRadio
