import SVG from '../SVG'
import { IconProps } from '../types'

const StatusChanged = (props: IconProps) => {
  const width = props.width ?? 16
  const height = props.height ?? 16
  return (
    <SVG
      {...props}
      width={width}
      height={height}
      styles={{
        color: props.color ?? '#FF9D00',
      }}
    >
      <g clipPath="url(#clip0_5337_23226)">
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.08037 12.3555C8.5222 12.3555 8.88037 11.9973 8.88037 11.5555L8.88037 11.3955C8.88037 10.9536 8.5222 10.5955 8.08037 10.5955L7.92037 10.5955C7.47854 10.5955 7.12037 10.9536 7.12037 11.3955L7.12037 11.5555C7.12037 11.9973 7.47854 12.3555 7.92037 12.3555L8.08037 12.3555ZM7.12012 9.00017C7.12012 9.48618 7.51411 9.88017 8.00012 9.88017C8.48613 9.88017 8.88012 9.48618 8.88012 9.00017L8.88012 4.50017C8.88012 4.01416 8.48613 3.62017 8.00012 3.62017C7.51411 3.62017 7.12012 4.01416 7.12012 4.50017L7.12012 9.00017Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5337_23226">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </SVG>
  )
}

export default StatusChanged
