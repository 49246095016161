import { useTheme } from 'styled-components'
import { AutoAvatar as Props } from '../../../store/userSlice/types'
import Container from '../Container'
import Typography from '../Typography'

export default function AutoAvatar(props: Props) {
  const { color, initials } = props

  const theme = useTheme();

  const HSL = `hsl(${color.h}, ${color.s}%, ${color.l}%)`



  return (
    <Container.Flex
      justify='center'
      alignItems='center'
      styles={{
        backgroundColor: HSL,
        height: '60px',
        width: '60px',
        borderRadius: '30px',
      }}
    >
      <Typography.Extra color={theme.colors.text.contrast}>
        {initials}
      </Typography.Extra>
    </Container.Flex>
  )
}
