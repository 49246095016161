import { memo } from 'react'
import LoginCard from '../components/Login/LoginCard'
import LoginContext from '../components/Login/LoginContext'
import { Container } from "../components/ui";

const LoginPage = () => {

  return <LoginContext>
    <Container.CardsGrid>
      <LoginCard />
    </Container.CardsGrid>
  </LoginContext>
}

export default memo(LoginPage)
