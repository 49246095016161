import { Fragment, memo } from 'react'
import { Button, Container, Typography } from '../../ui'
import { Order } from '../../../store/orderSlice/types'
import { bp } from '../../../theme/media'
import { useOrderContext } from '../OrderContext'

interface OrderToButtonProps {
  orderData?: Order
}

function OrderTopButton(props: OrderToButtonProps) {
  const { orderData } = props

  const { orderButton } = useOrderContext()

  if (!orderData) return null

  return (
    <Fragment>
      {orderButton && (
        <Typography.Medium
          onClick={orderButton.onClick}
          color={orderButton.color}
          hoverStyles={{
            color: orderButton.hoverColor
          }}
          styles={{
            position: 'absolute',
            right: 0,
            top: 0,
            display: 'block',
            margin: '0',
          }}
          breakpoints={{
            [bp.mobile]: {
              display: 'none',
            },
          }}
        >
          {orderButton.text}
        </Typography.Medium>
      )}
      <Container.Flex
        direction="column"
        fullWidth
        styles={{
          display: 'none',
        }}
        breakpoints={{
          [bp.mobile]: {
            display: 'flex',
            order: 1000
          },
        }}
      >
        {orderButton && !orderButton.notShowOnBottom && (
          <Button
            fullWidth
            onClick={orderButton.onClick}
            variant={
              orderButton.text.toUpperCase() === 'ОТМЕНИТЬ ЗАЯВКУ' ? 'danger' : 'primary'
            }
          >
            {orderButton.text.toUpperCase()}
          </Button>
        )}
      </Container.Flex>
    </Fragment>
  )
}

export default memo(OrderTopButton)
