import { MutableRefObject } from 'react'
import { setCaretPosition } from './setCaretPosition'

export const onChange = (
  e: any,
  mode: MutableRefObject<'input' | 'remove'>,
  afterChange?: (v: string) => void,
) => {
  const event = e.nativeEvent

  const mask = '+ 375 (__) ___ __ __'

  const maskIndexes = [0, 1, 2, 3, 4, 5, 6, 9, 10, 14, 17]
  const spacesLengths = [12, 15, 17]

  const getCounter = (n: number) => {
    if (n === 17) {
      return -1
    }
    if (n === 15) {
      return -2
    }

    if (n === 12) {
      return -4;
    }

    return 0
  }

  const { value }: { value: string } = event.target

  let trueValue: string


  if (mode.current === 'input') {
    trueValue = value.replace('+ 375 ', '').replace(/\D/g, '')
  } else {
    const withousUnderLine = value.replace(/_/g, '')
    const spaceCheckedValues = spacesLengths.includes(withousUnderLine.length)
      ? withousUnderLine.slice(0, getCounter(withousUnderLine.length))
      : withousUnderLine
    trueValue = spaceCheckedValues.replace('+ 375 ', '').replace(/\D/g, '')
  }

  const letters = trueValue.split('').reverse()

  if (trueValue.length === 0) {
    e.target.value = mask
    setCaretPosition(event.target)
    afterChange && afterChange(event.target.value)
  }

  const newValue = mask
    .split('')
    .map((l, i) => {
      if (maskIndexes.includes(i)) {
        return l
      } else {
        return letters.pop() ?? l
      }
    })
    .join('')

  e.target.value = newValue
  setCaretPosition(event.target)
  afterChange && afterChange(event.target.value)
}
