import SVG from "../SVG";
import { IconProps } from "../types";

const UserIcon = (props: IconProps) => {
    return (
        <SVG {...props} width={24} height={24}>
            <path fillRule="evenodd" clipRule="evenodd" d="M12 21C12.5155 21 13.0209 20.9567 13.5127 20.8734C13.2068 20.7024 13 20.3753 13 20L13 18.6344C13 16.052 10.9627 14 8.50001 14C6.667 14 5.07312 15.1324 4.37384 16.7815C5.96604 19.3156 8.78631 21 12 21ZM15 20C15 20.2001 14.9412 20.3864 14.84 20.5427C16.9489 19.842 18.7113 18.3822 19.8033 16.4873C18.9772 15.5679 17.8004 15 16.5 15C15.6935 15 14.937 15.2178 14.2816 15.6012C14.7412 16.5122 15 17.5443 15 18.6344L15 20ZM13.1194 13.9669C12.5893 13.4198 11.9663 12.9659 11.2755 12.6326C11.7298 12.0421 12 11.3026 12 10.5C12 8.567 10.433 7 8.5 7C6.567 7 5 8.567 5 10.5C5 11.3029 5.27033 12.0426 5.72493 12.6331C4.79728 13.0809 3.99169 13.7458 3.36956 14.5609C3.12909 13.7492 3 12.8897 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 12.8753 20.8751 13.7213 20.642 14.5214C20.2271 14.1708 19.7682 13.8715 19.2749 13.6334C19.7296 13.0428 20 12.303 20 11.5C20 9.567 18.433 8 16.5 8C14.567 8 13 9.567 13 11.5C13 12.303 13.2704 13.0428 13.7252 13.6334C13.5169 13.7339 13.3147 13.8453 13.1194 13.9669ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM8.5 9C7.67157 9 7 9.67157 7 10.5C7 11.3284 7.67157 12 8.5 12C9.32843 12 10 11.3284 10 10.5C10 9.67157 9.32843 9 8.5 9ZM15 11.5C15 10.6716 15.6716 10 16.5 10C17.3284 10 18 10.6716 18 11.5C18 12.3284 17.3284 13 16.5 13C15.6716 13 15 12.3284 15 11.5Z" />
        </SVG>
    );
};

export default UserIcon;
