import { Fragment, useEffect, useRef, useState, FormEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    DeviceInfo,
    ErrorFormData,
    ErrorFormField,
    GivenAnswers,
    SubmitErrorResponseData,
} from '../../../store/offerSlice/types'
import { Button, Card, Container, Info, Typography } from '../../ui'
import { useForm } from 'react-hook-form'
import { SendErrorForm } from '../../../store/offerSlice'
import OfferLoader from '../OfferLoader'
import { redirectRequest } from '../../../store/viewSlice'
import OfferDevice from '../OfferDevice'
import { RootState } from '../../../store'
import ErrorFormTextArea from './fields/TextArea'
import ErrorFormInput from './fields/TextInput'
import { bp } from "../../../theme/media";

type OfferErrorFormProps = {
    data: ErrorFormData
    pending: boolean
    submitData: SubmitErrorResponseData | null
    givenAnswers: GivenAnswers
}

const OfferErrorForm = ({
    data,
    pending,
    submitData,
    givenAnswers,
}: OfferErrorFormProps) => {
    const { externalText, fields, internalText, code, name } = data;

    const containerRef = useRef<HTMLDivElement>(null)
    const [height, setHeight] = useState('auto')

    const dispatch = useDispatch()

    const { deviceInfo, currentErrorMessage } = useSelector(
        (state: RootState) => ({
            deviceInfo: state.offer.deviceInfo,
            currentErrorMessage: state.offer.currentErrorMessage,
        }),
    )

    const {
        register,
        handleSubmit,
        setValue,
        formState: { isValid, errors },
    } = useForm({ mode: 'onChange' })

    const onClick = (data: any) => {
        if (!data) return
        const _fields = [] as any[]
        Object.entries(data)?.forEach(([code, value]) => {
            _fields.push({ code, value })
        })

        const deviceFieldData = fields.find(el => el.code === 'deviceInfo')

        const deviceField = deviceFieldData
            ? {
                  code: 'deviceInfo',
                  value:
                      givenAnswers.answers.find(
                          el =>
                              '' + el.questionId ===
                              '' + deviceFieldData.questionId,
                      )?.answerName || '',
              }
            : undefined

        if (deviceField) _fields.push(deviceField)

        const textError = fields.find(el => el.type === 'textInfo')

        if (textError) {
            _fields.push({ code: 'text-error', value: currentErrorMessage })
        }

        if (!pending) {
            dispatch(
                SendErrorForm.request({
                    code,
                    fields: deviceField ? [..._fields, deviceField] : _fields,
                }),
            )
        }
    }

    const onInputImei = (e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement
        const clearedValue = target.value.replace(/[^\d]/g, '').slice(0, 15);
        setValue('imei-tel', "" + clearedValue);
    }
    useEffect(() => {
        if (pending) {
            const newHeight = containerRef.current?.offsetHeight
                ? `${containerRef.current.offsetHeight}`
                : 'auto'
            setHeight(newHeight)
        }
    }, [pending])

    const onResultButtonClick = () => {
        dispatch(redirectRequest('/'))
    }

    const getFormField = (field: ErrorFormField) => {
        if (field.code === 'deviceInfo') return null

        const code = field.code as keyof DeviceInfo

        const defaultValue = field.deviceInfo
            ? deviceInfo?.[code] ?? ''
            : field.questionId
            ? givenAnswers.answers.find(el => el.questionId === field.questionId)
                  ?.answerName
            : ''
        if (field.hidden) return null;

        if (field.code === 'imei-tel') {
            return (
                <ErrorFormInput
                    {...field}
                    register={register}
                    pattern={{
                        value: /\d{15}/,
                        message: 'Неправильный формат IMEI',
                    }}
                    onInput={onInputImei}
                    defaultValue={
                        defaultValue
                            ? defaultValue
                            : field.hidden && !defaultValue && field.required
                            ? 'Значение не найдено'
                            : ''
                    }
                    error={errors[field.code]}
                />
            )
        }

        switch (field.type) {
            case 'submit':
                return (
                    <Button
                        disabled={!isValid || undefined}
                        fullWidth
                        onClick={onClick}
                        submit
                    >
                        ОТПРАВИТЬ
                    </Button>
                )
            case 'textarea':
                return (
                    <ErrorFormTextArea
                        {...field}
                        register={register}
                        defaultValue={
                            defaultValue
                                ? defaultValue
                                : field.hidden &&
                                  !defaultValue &&
                                  field.required
                                ? 'Значение не найдено'
                                : ''
                        }
                    />
                )
            default:
                return (
                    <ErrorFormInput
                        {...field}
                        register={register}
                        pattern={field.type === "email" ? {
                            value: /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/,
                            message: 'Введите корректный email',
                        } : ""}
                        defaultValue={
                            defaultValue
                                ? defaultValue
                                : field.hidden &&
                                  !defaultValue &&
                                  field.required
                                ? 'Значение не найдено'
                                : ''
                        }
                        error={errors[field.code]}
                    />
                )
        }
    }

    return (
        <>
        {!pending && !submitData && (
                <Fragment>
                    {externalText && (
                        <Card padding={0} styles={{
                            order: "1"
                        }}
                        breakpoints={{
                            [bp.mobile]: {
                                order: "0"
                            }
                        }}>
                            <Info padding="12px 12px 12px 40px">{externalText}</Info>
                        </Card>
                    )}

                    <Card padding={28} fullWidth>
                        {name && <Typography.TitleSecondary
                            margin={'0 0 24px 0'}
                            padding={'0 4px'}
                            fromStart
                        >
                            {name}
                        </Typography.TitleSecondary>}

                        {!!deviceInfo && (
                            <OfferDevice
                                deviceInfo={deviceInfo}
                                givenAnswers={givenAnswers.answers}
                            />
                        )}

                        <form onSubmit={handleSubmit(onClick)}>
                            <Container.Flex
                                fullWidth
                                styles={{
                                    marginBottom: internalText ? '16px' : '',
                                }}
                            >
                                {fields &&
                                    fields.map(el => {
                                        return (
                                            <Fragment key={el.code}>
                                                {getFormField(el)}
                                            </Fragment>
                                        )
                                    })}
                            </Container.Flex>
                        </form>

                        {internalText && <Info>{internalText}</Info>}
                    </Card>
                </Fragment>
            )}

            {pending && <OfferLoader minHeight={height} />}

            {!pending && !!submitData && (
                <Card padding={28}>
                    <Container.Flex justify="center" verticalGap={28}>
                        <Typography.Title>Мы свяжемся с вами</Typography.Title>
                        <Button fullWidth onClick={onResultButtonClick}>
                            ОК
                        </Button>
                    </Container.Flex>
                </Card>
            )}
        </>
    )
}

export default OfferErrorForm
