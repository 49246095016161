import SVG from "../SVG";
import { IconProps } from "../types";

const ExitIcon = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 24} height={props.height ?? 24}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0005 23.0024C17.1051 23.0024 18.0005 22.107 18.0005 21.0024V17.0024C18.0005 16.4502 17.5528 16.0024 17.0005 16.0024C16.4482 16.0024 16.0005 16.4502 16.0005 17.0024V21.0024H5.00049V3.00244H16.0005V7.00244C16.0005 7.55473 16.4482 8.00244 17.0005 8.00244C17.5528 8.00244 18.0005 7.55473 18.0005 7.00244V3.00244C18.0005 1.89787 17.1051 1.00244 16.0005 1.00244H5.00049C3.89592 1.00244 3.00049 1.89787 3.00049 3.00244V21.0024C3.00049 22.107 3.89592 23.0024 5.00049 23.0024H16.0005ZM20.7071 8.29289C20.3166 7.90237 19.6834 7.90237 19.2929 8.29289C18.9024 8.68342 18.9024 9.31658 19.2929 9.70711L20.5858 11L12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13H20.5858L19.2929 14.2929C18.9024 14.6834 18.9024 15.3166 19.2929 15.7071C19.6834 16.0976 20.3166 16.0976 20.7071 15.7071L23.7071 12.7071C23.8946 12.5196 24 12.2652 24 12C24 11.7348 23.8946 11.4804 23.7071 11.2929L20.7071 8.29289Z"
            />
        </SVG>
    );
};

export default ExitIcon;
