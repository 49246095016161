import { TransportApplicationItem } from "../components/Courier/TransportApplication/TransportApplication.types"
import { CounterItem } from "../components/ListData/ListData"
import { Filter } from "../components/Profile/OrdersList/OrderListContext"
import { UserType } from "../store/userSlice/types"

export type EmptyRequest = {}

export type CourierReserveStockData = {
  salePointId: string
}

// ORDER

export type GetOrderRequest = { number: string }

export type GetOrderFiltersResponse = Filter[]

export type CourierUnReserveStockData = CourierReserveStockData

// LOGIN

export type ClientLoginRequest = {
  phone: string
  code?: string
}

export type ClientLoginResponse = {
  isAuthorised: boolean
  auth_param_name?: string
  auth_param_value?: string
  codeSent: boolean
  userType: UserType
}

// TRANSPORT

type TransportFields = {
  savePackNumber: string
  appNumber: string
  reserved: string
  isSuccess: string
  applicationNumber: string
}


export type TransportCreateAppRequest = Pick<TransportFields, 'savePackNumber'>
export type TransportCreateAppResponse = Pick<TransportFields, 'isSuccess' | 'applicationNumber' >

export type TransportAddSavePackRequest = Pick<TransportFields, 'savePackNumber' | 'appNumber'>
export type TransportAddSavePackResponse = Pick<TransportFields, 'isSuccess' >

export type TransportGetActiveUserAppResponse = Pick<TransportFields, 'applicationNumber' >

export type TransportGetAppListRequest = Pick<TransportFields, 'appNumber'>

export type TransportDeleteSavePackRequest = TransportAddSavePackRequest;
export type TransportDeleteSavePackResponse = TransportAddSavePackResponse;

export type TransportConfirmApplicationRequest = Pick<TransportFields, 'appNumber'>;
export type TransportConfirmApplicationResponse = Pick<TransportFields, 'isSuccess' | 'reserved'>;

export type TransportReserveSalePointRequest = {
  salePointId: number
}

export type TransportReserveSalePointResponse = {
  data: []
}


export enum TransportAppStatuses {
  empty = "E",
  inProcess = "IP",
  complete = "CP",
  confimed = "CF"
}
export type TransportGetAppListResponse = {
  data: TransportApplicationItem[],
  counterItems: CounterItem[]
  application: {
    status: TransportAppStatuses
  }
}
