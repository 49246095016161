import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { styled } from '../../../helpers/styled'
import { zIndexes } from '../../../zIndexes'
import { Props } from '../../types'
import Container from '../Container'

type OverlayProps = Props<{
  children: ReactNode
  padding?: number | string
  inner?: true
  close?: () => void
}>

const Wrapper = styled.div<{
  padding?: number | string
  inner?: true
  height: string
}>`
  position: ${props => (!!props.inner ? 'absolute' : 'fixed')};
  z-index: ${zIndexes.overlay};
  overflow-y: 'scroll';
  width: ${props => (props.inner ? '100%' : '100vw')};
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  ${props => {
    if (props.padding) {
      if (typeof props.padding === 'number') {
        return {
          padding: `${props.padding}px`,
        }
      } else {
        return {
          padding: `${props.padding}`,
        }
      }
    }
  }}
`

const OverlayContext = createContext(
  {} as {
    viewPortHeight: number
  },
)

export const useOverlay = () => useContext(OverlayContext)

const Overlay = ({ children, padding, close, inner }: OverlayProps) => {
  const [viewPortHeight, setViewPortHeright] = useState<number>(window.visualViewport?.height ?? window.screen.availHeight)

  useEffect(() => {
    const resizeHandler = () => {
      const height = window.innerHeight
      setViewPortHeright(height)
    }
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', resizeHandler)
    }

    return () => {
      if (window.visualViewport) {
        window.removeEventListener('resize', resizeHandler)
      }
    }
  }, [])

  return (
    <OverlayContext.Provider value={{ viewPortHeight }}>
      <Wrapper padding={padding} inner={inner} height={`${viewPortHeight}px`}>
        <Container.Flex fullHeight fullWidth onClick={close}>
          <Container.Flex alignItems='center'
            onClick={event => {
              event.stopPropagation()
            }}
          >
            {children}
          </Container.Flex>
        </Container.Flex>
      </Wrapper>
    </OverlayContext.Provider>
  )
}

export default Overlay
