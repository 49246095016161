import SVG from '../SVG'
import { IconProps } from '../types'

const DissenseActive = (props: IconProps) => {
  const width = props.width ?? 16
  const height = props.height ?? 16
  return (
    <SVG
      {...props}
      width={width}
      height={height}
      styles={{
        color: props.color ?? '#7A7A7A',
      }}
    >
      <path
        d="M4.255 13.9151L4.77585 13.0942C4.52903 12.9376 4.22486 12.9003 3.94755 12.9928L4.255 13.9151ZM1 15.0001L0.0776626 14.6927C-0.0387893 15.042 0.0521359 15.4272 0.31253 15.6876C0.572925 15.948 0.95809 16.0389 1.30745 15.9224L1 15.0001ZM2.085 11.7451L3.00734 12.0525C3.09977 11.7752 3.06253 11.4711 2.90594 11.2242L2.085 11.7451ZM8.00005 15.9723C12.403 15.9723 15.9723 12.403 15.9723 8.00005H14.0279C14.0279 11.3291 11.3291 14.0279 8.00005 14.0279V15.9723ZM3.73414 14.736C4.96826 15.519 6.4327 15.9723 8.00005 15.9723V14.0279C6.81239 14.0279 5.7077 13.6854 4.77585 13.0942L3.73414 14.736ZM3.94755 12.9928L0.692554 14.0778L1.30745 15.9224L4.56244 14.8374L3.94755 12.9928ZM1.92234 15.3075L3.00734 12.0525L1.16266 11.4377L0.0776626 14.6927L1.92234 15.3075ZM0.027771 8.00005C0.027771 9.56739 0.481056 11.0318 1.26406 12.266L2.90594 11.2242C2.31472 10.2924 1.97223 9.18771 1.97223 8.00005H0.027771ZM8.00005 0.027771C3.59708 0.027771 0.027771 3.59708 0.027771 8.00005H1.97223C1.97223 4.67098 4.67098 1.97223 8.00005 1.97223V0.027771ZM15.9723 8.00005C15.9723 3.59708 12.403 0.027771 8.00005 0.027771V1.97223C11.3291 1.97223 14.0279 4.67098 14.0279 8.00005H15.9723Z"
        fill="currentColor"
      />
      <path
        d="M16 4C16 6.20914 14.2091 8 12 8C9.79086 8 8 6.20914 8 4C8 1.79086 9.79086 0 12 0C14.2091 0 16 1.79086 16 4Z"
        fill="#E7372D"
      />
    </SVG>
  )
}

export default DissenseActive
