import { useEffect, useState } from 'react'
import { Container, Typography } from '../../ui'
import { useOrderListData } from './OrderListContext'
import OrderListFilters from './filters/OrderListFilters'
import OrderListHeader from './OrderListHeader'
import OrderListBody from './OrderListBody'
import PageLoader from '../../Layout/PageLoading'
import OrderListBodyTop from './OrderListBodyTop'

const SLICE_COUNT = 10

export default function OrderList() {
  const { showFilters, orderList, orderListError, orderListPending } =
    useOrderListData()

  const data = !!orderList?.length ? [...orderList] : [];

  const [count, setCount] = useState({
    prev: 0,
    next: SLICE_COUNT,
  })
  const [hasMore, setHasMore] = useState(SLICE_COUNT < data.length)
  const [current, setCurrent] = useState(data.slice(count.prev, count.next));

  useEffect(() => {
      const newList = !!orderList?.length ? [...orderList] : [];
      setCurrent(newList.slice(0, SLICE_COUNT))
      setCount({prev: 0, next: SLICE_COUNT});
      setHasMore(SLICE_COUNT < data.length)
  }, [orderList])

  const getMoreData = () => {
    if (current.length === data.length) {
      setHasMore(false)
      return
    }
    setTimeout(() => {
      setCurrent(
        current.concat(
          data.slice(count.prev + SLICE_COUNT, count.next + SLICE_COUNT),
        ),
      )
    }, 500)
    setCount(prev => ({
      prev: prev.prev + SLICE_COUNT,
      next: prev.next + SLICE_COUNT,
    }))
  }

  return (
    <Container.Flex fullWidth>
      <OrderListHeader />
      <OrderListBodyTop data={orderList} />
      {showFilters && <OrderListFilters />}
      <OrderListBody current={current} hasMore={hasMore} getMoreData={getMoreData} />
      {!orderListPending && !current.length && <Typography.Error>Заказов не найдено</Typography.Error>}
      {orderListPending && <PageLoader />}
      {!!orderListError && (
        <Typography.Error>{orderListError}</Typography.Error>
      )}
    </Container.Flex>
  )
}
