import { forwardRef, useRef } from 'react'
import { getCommonProps, Props } from '../../types'
import styled, { keyframes } from 'styled-components/macro'
import { bounceInUp, fadeIn, merge } from 'react-animations'

type CardProps = Props<{
  noPadding?: boolean
  noShadow?: true
  animateHeight?: boolean
  animateWidth?: boolean
  isQuestion?: boolean
  minHeight?: number
  maxContent?: true
  common?: true
}>

const Wrapper = styled.div<CardProps>`
  position: relative;
  border-radius: 20px;
  flex-shrink: 0;
  width: 100%;
  height: ${props => (props.fullHeight ? '100%' : 'auto')};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
  overflow-y: ${props => (props.animateHeight ? 'hidden' : 'auto')};
  overflow-x: ${props => (props.animateWidth ? 'hidden' : 'auto')};
  overflow: hidden;
  ${props => getCommonProps(props)};
  padding: 0;
  background-color: ${props =>
    props.styles?.backgroundColor ?? props.theme.colors.background.contrast60};
  transition: all 200ms ease-in;
  /* & > * {
        opacity: ${props => (props.isHidden ? '0' : '1')};
        height: ${props => (props.isHidden ? '0' : 'auto')};
    } */

  ${props => {
    if (props.maxContent) {
      return { height: 'max-content' }
    }
  }}

  ${props => {
    if (props.isHidden) {
      if (props.animateHeight) {
        return {
          height: 0,
        }
      } else if (props.animateWidth) {
        return {
          width: 0,
        }
      }
    }
  }};
  ${props => (props.css ? props.css : null)}
`

const fadeInAnimation = keyframes`${merge(bounceInUp, fadeIn)}`

const ContentWrapper = styled.div<{ isQuestion?: boolean }>`
  animation: ${props => (props.isQuestion ? `${fadeInAnimation} 100ms` : '')};
`

const Inner = styled.div<{ padding: any }>`
  padding: ${props =>
    props.padding
      ? Number.isInteger(props.padding)
        ? `${props.padding}px`
        : props.padding
      : ''};
  border-radius: inherit;
  transition: 200ms ease-in;
`

const Card = forwardRef<HTMLDivElement, CardProps>((props: CardProps, ref) => {
  const { children, onAnimationEnd, padding } = props

  const contentRef = useRef<HTMLDivElement>(null)
  return (
    <Wrapper onAnimationEnd={onAnimationEnd} ref={ref} {...props}>
      <ContentWrapper ref={contentRef} isQuestion={props.isQuestion}>
        {!!children && <Inner padding={padding}>{children}</Inner>}
      </ContentWrapper>
    </Wrapper>
  )
})

export default Card
