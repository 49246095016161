import SVG from '../SVG'
import { IconProps } from '../types'

const FiltersOn = (props: IconProps) => {
  return (
    <SVG {...props} width={props.width ?? 20} height={props.height ?? 20}>
      <g clipPath="url(#clip0_4620_21748)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V4.58579C19 5.11463 18.7905 5.62188 18.4176 5.99663L13.0055 11.4944L13 11.5L13 11.5V17C13 17.7575 12.572 18.4501 11.8944 18.7889L9.89443 19.7889C8.56462 20.4538 7 19.4868 7 18L7 11.5L6.99444 11.4944L6.99446 11.4944L1.58254 5.99675C1.2095 5.62199 1 5.11469 1 4.58579V3ZM17 3H3V4.58579L3.00556 4.59134L3.00554 4.59137L8.41749 10.0891C8.79051 10.4638 9 10.9711 9 11.5V18L11 17V11.5C11 10.9711 11.2095 10.4639 11.5825 10.0891L16.9945 4.59137L17 4.58576L17 4.58579V3Z"
          fill="#7A7A7A"
        />
        <path
          d="M20 4.5C20 6.98528 17.9853 9 15.5 9C13.0147 9 11 6.98528 11 4.5C11 2.01472 13.0147 0 15.5 0C17.9853 0 20 2.01472 20 4.5Z"
          fill="#E7372D"
        />
      </g>
      <defs>
        <clipPath id="clip0_4620_21748">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SVG>
  )
}

export default FiltersOn
