import { Card, Select } from '../ui'

const ThemeSelect = () => {
    return (
        <Card padding={20}>
            <Select
                options={[
                    { label: 'Первый элемент', value: 'first' },
                    { label: 'Второй элемент', value: 'second' },
                    { label: 'Третий элемент', value: 'third' },
                ]}
            />
        </Card>
    )
}

export default ThemeSelect
