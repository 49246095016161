import {
  ChangeEvent,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Timer from './Timer'
import { classNames } from '../../styles/classNames'
import { Card, Container, PhoneInput, Typography, Button, Input } from '../ui'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSendOrderLinkData,
  getSMSLoginData,
  resetSendLink,
  resetSMSApi,
} from '../../store/userSlice'
import {
  CheckAuth,
  SendOrderLink,
  SMSLogin,
} from '../../store/userSlice/routines'
import { getErrorString } from '../../helpers/getErrorString'
import { useSession } from '../../hooks/useSession'
import { useReadOTP } from 'react-read-otp'
import { useOverlay } from '../ui/Overlay/Overlay'

type Steps =
  | 'phone-input'
  | 'input-code'
  | 'success'
  | 'autorized'
  | 'autorized-success'

interface SaveOrderFormProps {
  orderNumber: string
  hide: () => void
}

const SaveOrderForm = ({ orderNumber, hide }: SaveOrderFormProps) => {
  const dispatch = useDispatch()

  const { session } = useSession()

  const [step, setStep] = useState<Steps>(
    session.isAuthorised ? 'autorized' : 'phone-input',
  )
  const [phone, setPhone] = useState('')
  const [defaultPhone, setDefaultPhone] = useState('')
  const [otp, setOtp] = useState('')
  useReadOTP(setOtp)

  const { codeSent, coodeSentSuccess, pending, error } =
    useSelector(getSMSLoginData)
  const {
    status: sendLinkStatus,
    pending: sendLinkPending,
    error: sendLinkError,
  } = useSelector(getSendOrderLinkData)

  const [disableCodeSubmit, setDisableCodeSubmit] = useState<true | undefined>(
    true,
  )
  const [disablePhoneSubmit, setDisablePhoneSumbit] = useState<
    true | undefined
  >(true)
  const contentRef = useRef<HTMLDivElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const codeInputRef = useRef<HTMLInputElement>(null)

  const onChangeStep = (step: Steps) => () => {
    if (contentRef.current) {
      contentRef.current.classList.add(classNames.changeCardContent)

      setTimeout(() => {
        setStep(step)
      }, 300)
    }
  }

  useEffect(() => {
    if (codeSent) {
      onChangeStep('input-code')()
    }
  }, [codeSent])

  useEffect(() => {
    if (coodeSentSuccess) {
      dispatch(SendOrderLink.request(orderNumber))
    }
  }, [coodeSentSuccess, dispatch])

  useEffect(() => {
    if (sendLinkStatus === 'success') {
      dispatch(CheckAuth.request())
      onChangeStep(step === 'autorized' ? 'autorized-success' : 'success')()
    }
  }, [sendLinkStatus, dispatch])

  const afterPhoneChange = (value: string) => {
    dispatch(resetSMSApi())
    if (disablePhoneSubmit) {
      if (!value.match(/_/)) {
        setDefaultPhone(value)
        setDisablePhoneSumbit(undefined)
      }
    } else {
      if (value.match(/_/)) {
        setDefaultPhone('')
        setDisablePhoneSumbit(true)
      }
    }
  }

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(resetSMSApi())
    if (disableCodeSubmit) {
      if (e.target.value.length === 4) {
        setDisableCodeSubmit(undefined)
      }
    } else {
      if (e.target.value.length !== 4) {
        setDisableCodeSubmit(true)
      }
    }
  }

  const sendPhone = () => {
    const _phone = phone || phoneInputRef.current?.value || ''

    dispatch(
      SMSLogin.request({
        phone: _phone,
      }),
    )
    if (_phone) {
      setPhone(_phone)
    }
  }

  const sendCode = () => {
    dispatch(
      SMSLogin.request({
        phone,
        code: codeInputRef.current?.value ?? '',
      }),
    )
  }

  useEffect(() => {
    if (!disableCodeSubmit) {
      sendCode()
    }
  }, [disableCodeSubmit])

  useEffect(() => {
    setTimeout(() => {
      if (codeInputRef.current) {
        codeInputRef.current.focus()
      }
    }, 300)
  }, [step])

  useEffect(() => {
    if (/\d{4}/.test(otp)) {
      if (codeInputRef.current) {
        codeInputRef.current.value = otp
        setDisableCodeSubmit(undefined)
      }
    }
  }, [otp])

  const sendOrderLink = useCallback(() => {
    dispatch(SendOrderLink.request(orderNumber))
  }, [dispatch, SendOrderLink, orderNumber])

  const resendCode = useCallback(() => {
    setStep('phone-input')
  }, [])

  useEffect(() => {
    if (step === 'phone-input') {
      dispatch(resetSMSApi())
      dispatch(resetSendLink())
    }
  }, [step])

  const { viewPortHeight } = useOverlay()

  return (
    <Container.Flex
      styles={{
        overflowY: window.innerHeight < 344 ? 'scroll' : 'hidden',
        height: 'calc(var(--vh, 1vh) * 100);',
        width: '100vw',
        paddingTop: `${
          viewPortHeight - 344 >= 153 ? 154 : viewPortHeight - 344
        }px`,
      }}
    >
      <Card
        padding={28}
        styles={{
          backgroundColor: '#ffffff',
          maxWidth: '344px',
          width: 'calc(100% - 48px)',
          // transform: 'translate(-50%, -50%)',
        }}
      >
        <Container.Flex
          fullWidth
          ref={contentRef}
          verticalGap={16}
          alignItems="start"
          onAnimationEnd={() => {
            contentRef.current?.classList.remove(classNames.changeCardContent)
          }}
        >
          <Typography.TitleSecondary
            fromStart
            margin={0}
            styles={{ padding: '0 4px 8px' }}
          >
            Сохранить оценку
          </Typography.TitleSecondary>

          <Typography.Main
            fromStart
            margin={0}
            styles={{ padding: '0 4px 6px' }}
          >
            Для быстрого поиска вашей оценки мы отправим ссылку
            на неё с помощью СМС. Полная оценка будет доступна только вам.
          </Typography.Main>

          {step === 'phone-input' && (
            <Fragment>
              <PhoneInput
                afterChange={afterPhoneChange}
                ref={phoneInputRef}
                onEnter={sendPhone}
                error={getErrorString(error)}
                defaultValue={defaultPhone}
              />
              <Typography.Small styles={{ padding: '0 4px 6px' }}>
                На указанный номер придёт СМС-код
              </Typography.Small>
              <Container.Grid cols="1fr 1fr" fullWidth gap={10}>
                <Button variant="danger" onClick={hide}>
                  Отмена
                </Button>
                <Button
                  withLoader
                  pending={pending}
                  disabled={disablePhoneSubmit}
                  fullWidth
                  onClick={sendPhone}
                >
                  Далее
                </Button>
              </Container.Grid>
            </Fragment>
          )}

          {(step === 'input-code' ||
            step === 'success' ||
            step === 'autorized' ||
            step === 'autorized-success') && (
            <Fragment>
              {step !== 'autorized' && step !== 'autorized-success' && (
                <Input
                  id="single-factor-code-text-field"
                  autoComplete="one-time-code"
                  disabled={step === 'success' || undefined}
                  ref={codeInputRef}
                  placeholder="Код из СМС"
                  autoFocus
                  type="number"
                  maxLength={4}
                  onChange={onCodeChange}
                  error={getErrorString(error) || sendLinkError}
                />
              )}
              {step !== 'autorized' && (
                <Timer
                  resendCode={resendCode}
                  codePending={pending}
                  isError={!!error.length}
                  isSuccess={coodeSentSuccess || sendLinkStatus === 'success'}
                />
              )}
              <Container.Grid
                cols={
                  step === 'success' ||
                  step === 'autorized' ||
                  step === 'autorized-success'
                    ? '1fr'
                    : '1fr 1fr'
                }
                fullWidth
                gap={10}
              >
                {step === 'input-code' && (
                  <Button variant="danger" onClick={hide}>
                    Отмена
                  </Button>
                )}
                {(step === 'input-code' || step === 'autorized') && (
                  <Button
                    withLoader
                    pending={pending || sendLinkPending}
                    disabled={
                      step === 'autorized' ? undefined : disableCodeSubmit
                    }
                    fullWidth
                    onClick={step === 'autorized' ? sendOrderLink : sendCode}
                  >
                    Отправить
                  </Button>
                )}

                {(step === 'success' || step === 'autorized-success') && (
                  <Button fullWidth onClick={hide}>
                    Закрыть
                  </Button>
                )}
              </Container.Grid>
            </Fragment>
          )}
        </Container.Flex>
      </Card>
    </Container.Flex>
  )
}

export default memo(SaveOrderForm)
