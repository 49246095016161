import { Fragment } from "react";
import { ListComponentProps } from "../ListData";
import withListData from "../ListData/ListData";
import { TransportApplicationItem as ItemType } from "./TransportApplication/TransportApplication.types";
import TransportApplicationItem from "./TransportApplication/TransportApplicationtem";

function CourierPacks (props: ListComponentProps<ItemType>) {

  const { data, loading } = props;

  if (loading) return null;

  return (
    <Fragment>
      {data.map(el => (
        <TransportApplicationItem data={el} />
      ))}
    </Fragment>
  )
}

export default withListData(CourierPacks)