import axios from "axios"
import qs from 'qs'

export const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: "/api",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
  },
  })
  instance.defaults.paramsSerializer = params => qs.stringify(params);

  return instance
}