import { DeviceStatuses, ImageSrc } from '../../types'
import { ApiError } from '../types'

export enum ItemStatuses {
  Created = 'N',
  PhotoLoaded = 'F',
  Confirm = 'D',
}

export type CreateOrChangeOrderRequest = {
  combinationId?: string
  number?: string
  offerId?: string
  deviceId?: string
  questions: { [questionId: string]: string }
  productId?: string
  combination?: string
}

export type CreateOrderResponseData = {
  number: string
  itemNumber: string
  success: boolean
}

export type CreateOrderResponse = {
  status: 'success' | 'error'
  data?: CreateOrderResponseData
  errors: string[]
}

export type GetOrderRequest = {
  orderNumber: string
  itemNumber: string
  create?: true
  qrCode?: string
  code?: string
  checkList?: string
}

export type OrderRequest<T> = {
  orderNumber?: string
  itemNumber?: string
} & T

type ApiResponse<T> = {
  status: 'success' | 'error'
  data: T
  errors: string[]
}

export type DissentPopupInfo = {
  initialCondition: string
	initialPrise: string
  conditionImage: string[]
  deffectImage: string[]
  popupComment: string
  read: boolean
}

export type OrderItem = {
  price: number
  name: string
  image: ImageSrc
  itemNumber: string
  status: DeviceStatuses
  combinationCode?: string
  combinationId?: string
  condition: string
  answers: Array<{
    name: string
    value: string
  }>
  savePack?: string
  popupInfo?: DissentPopupInfo
}

export type OrderButton = {
  code: string
  description: string
  value: string
}

export type Partner = {
  code: string
  name: string
  cardColor: string
  cardDescription: string
  cardTitle: string
  companyLogo: string
}

export type Partners = {
  [k: number]: Partner
}

export type Order = {
  number: string
  amount: string
  amountRansom: string
  amountSelectOptionPrice: string
  button: OrderButton[]
  currency: string
  currencyLang: string
  status: OrderStatuses
  statusColor: string
  items: OrderItem[]
  registration?: string
  partners?: Partners
  savePacks?: string[]
  dateCreate: string
  notOwner?: true
}

export type DeleteItemResponseData = {
  item: boolean
  order: boolean
} | null

export type DeleteItemResponse = {
  status: 'success' | 'error' | null
  data: DeleteItemResponseData
  errors: string[]
}

export type GetOrderResponse = ApiResponse<Order>

export type SetRegistrationResponseData = {
  type: string
  success: boolean
}

export enum OrderStatuses {
  New = 'Новая',
  Checking = 'Проверяется',
  Confirm = 'Подтверждена',
  Done = 'Сделка совершена',
  Ready = 'Готова к отгрузке',
  Packed = 'Запакована',
  Accept = 'Принята',
  Paid = 'Оплачена',
  Canceled = 'Отменена',
  Deleted = "Удалена",
  Complete = "Выполнен"
}

export enum OrderStatusCodes {
  New = "N",
  Confirm = "CN",
  Done = "DC",
  Canceled = 'CL',
  ReadyForShipping = 'RD',
  Accept = "GT",
  Paid = 'PY',
  Deleted = "DL",
  Packed = 'U',
  Complete = "E"
}

export type OrderState = {
  order: {
    number: string
    itemNumber: string
    data: Order | null
    status: 'success' | 'error' | null
    loading: boolean
    errors: ApiError[]
  }
  deleteItem: {
    result: 'success' | 'error' | null
    loading: boolean
    errors: string[]
    data: DeleteItemResponseData
    itemNumber: string | null
  }
  setRegistrations: {
    loading: boolean
    data: SetRegistrationResponseData | null
    errors: string[]
    result: 'success' | 'error' | null
  }
  showResultFor: string
  qrCode?: string
}
