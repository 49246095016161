import { Container } from '../../ui'
import ListDataCounts from '../../ListData/ListDataCounts'
import { CounterItem } from '../../ListData/ListData'

export type ITransportApplicationHeader = {
  items: CounterItem[]
}

export default function TransportApplicationHeader(
  props: ITransportApplicationHeader,
) {
  return (
    <Container.Flex direction="row" fullWidth justify="between">
      <ListDataCounts items={props.items} />
    </Container.Flex>
  )
}
