import { useSession } from '../../hooks/useSession'
import { bp, cardSizes } from '../../theme/media'
import { Container } from '../ui'
import ProfileMenu from './ProfileMenu'
import ProfileRostokCount from './ProfileRostokCount'
import ProfileTop from './ProfileTop'

const Profile = () => {
  const { bpTable } = useSession()

  return (
    <Container.Flex
      alignItems="stretch"
      styles={{ width: cardSizes.big }}
      breakpoints={{
        [bpTable]: { width: cardSizes.short },
        [bp.mobile]: {
            width: '100%'
        }
      }}
    >
      <ProfileTop />
      <ProfileMenu />
      <ProfileRostokCount />
    </Container.Flex>
  )
}

export default Profile
