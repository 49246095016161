export const getShortFilterName = (name: string) => {
  if (/операции/.test(name)) {
    return "Операции"
  };

  if (/[Кк]атегории/.test(name)) {
    return "Категории"
  }

  return name
}