import { Container, Typography } from '../ui'
import { css, useTheme } from 'styled-components/macro'
import { usePartnerDetail } from '../../pages/Partner'
import { bp } from '../../theme/media'
import SimpleImage from '../ui/Image/SimpleImage'

const hexToRgb = (hex: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16,
      )}`
    : null
}

const ItemCSS = css`
  position: relative;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
`

const PartnerCard = ({ fullWidth }: { fullWidth?: true }) => {
  const { data } = usePartnerDetail()
  const theme = useTheme()

  if (!data) return null

  const { companyLogo, name, cardTitle, cardColor, cardDescription } = data;

  const rgb = hexToRgb(cardColor)

  return (
    <Container.Flex
      alignItems="stretch"
      padding={'24px 28px 18px'}
      styles={{
        backgroundColor: `rgba(${rgb}, 0.4)`,
        backgroundImage: `linear-gradient(90deg, rgba(${rgb}, 0.8) 30.13%, rgba(${rgb}, 0) 100%);`,
        cursor: 'pointer',
        flexShrink: 0,
      }}
      breakpoints={{
        [bp.table]: {
          width: "calc(50% - 18px)",
          marginRight: 0
        },
        [bp.mobile]: {
          width: "100%"
        }
      }}
      margin={'0 36px 24px 0'}
      css={ItemCSS}
    >
      <Container.Flex direction="row" padding="0 0 12px" margin="0 0 8px">
        <SimpleImage
          src={companyLogo}
          width={36}
          height={36}
          alt={name}
          styles={{ borderRadius: '50%', marginRight: '12px' }}
        />
        <Typography.Small
          margin={0}
          styles={{
            textTransform: 'uppercase',
            color: theme.colors.text.contrast,
          }}
        >
          {name}
        </Typography.Small>
      </Container.Flex>
      {cardTitle && (
        <Typography.Title
          textAlign="start"
          margin="0 0 8px"
          styles={{ color: theme.colors.text.contrast }}
        >
          {cardTitle}
        </Typography.Title>
      )}
      {cardDescription && (
        <Typography.Small
          textAlign="start"
          styles={{ color: theme.colors.text.contrast }}
        >
          {cardDescription}
        </Typography.Small>
      )}
    </Container.Flex>
  )
}

export default PartnerCard
