import { PATHS } from '../../pages/PATHS'
import { UserType } from '../../store/userSlice/types'
import { Icons } from '../ui/Icon/types'

export interface ProfileMenuItem {
    title: string
    icon: Icons
    href?: string
    onClick?: () => void
    show: Array<UserType>
}

const fields: ProfileMenuItem[] = [
    {
        title: "Настройка <span class='hidden-mobile'>партнёра</span>",
        icon: 'settings',
        href: '/profile/settings',
        show: [UserType.partner],
    },
    {
        title: 'Сотрудники',
        icon: 'employee',
        href: '/profile/employees',
        show: [UserType.partner],
    },
    {
        title: 'Список заявок',
        icon: 'order-list',
        href: '/profile/order-list/',
        show: [UserType.employee, UserType.partner],
    },
    {
        title: 'Новая заявка',
        icon: 'new-order',
        href: '/create/',
        show: [UserType.employee, UserType.partner],
    },
    {
        title: 'Помощь',
        icon: 'help',
        href: '/help',
        show: [UserType.employee, UserType.partner, UserType.client],
    },
    {
        title: "Список складов",
        icon: 'map',
        href: PATHS.stocks,
        show: [UserType.courier],
    },
    {
        title: "Новое перемещение",
        icon: 'add-plus-circle',
        href: PATHS.COURIER_TRANSPORT_APPLICATION,
        show: [UserType.courier],
    },
    {
        title: "Принятые пакеты",
        icon: 'user-save',
        href: PATHS.COURIER_RECEIVED_PACKETS,
        show: [UserType.courier],
    },
]

export const getProfileContent = (
    onLogout: () => void,
    userType: UserType,
): ProfileMenuItem[] => [
    ...fields.filter(el => el.show.includes(userType)),
    {
        title: 'Выйти',
        icon: 'exit',
        onClick: onLogout,
        show: [UserType.employee, UserType.partner, UserType.client],
    },
]
