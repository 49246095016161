import { useEffect, useState } from 'react'
import Button from '../ui/Button'
import Input from '../ui/Input'
import Container from '../ui/Container'
import Card from '../ui/Card'
import Typography from '../ui/Typography'
import { Info } from '../ui'
import styled, { useTheme } from 'styled-components/macro'
import { getErrorString } from '../../helpers/getErrorString'
import { useRestorePasswordMutation } from '../../store/api/api'

const InputWrapper = styled.div`
  margin-bottom: 16px;
`
const RestorePassword = () => {
  const [loginOrEmail, setLoginOrEmail] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const theme = useTheme()

  const [restorePassword, { data, isLoading, error, reset }] =
    useRestorePasswordMutation()

  const errors = error ?? ([] as any)

  const onSubmit = () => {
    restorePassword({ data: { loginEmail: loginOrEmail } })
  }

  useEffect(() => {
    if (/^\S+@\S+\.\S+$/.test(loginOrEmail)) {
      setButtonDisabled(false)
    } else {
      if (!buttonDisabled) {
        setButtonDisabled(true)
      }
    }
  }, [loginOrEmail])

  const onChange = (e: any) => {
    reset()
    setLoginOrEmail(e.target.value)
  }

  return (
    <Container.CardsGrid>
      <Card noShadow padding={28}>
        <Container.Flex
          fullWidth
          fullHeight
          alignItems="stretch"
          styles={{ opacity: isLoading ? 0 : 1 }}
        >
          <Typography.TitleSecondary
            margin={'0 0 24px 0'}
            padding="0 4px"
            textAlign="start"
          >
            Восстановление пароля
          </Typography.TitleSecondary>
          <InputWrapper>
            <Input
              value={loginOrEmail}
              onChange={onChange}
              fullWidth
              placeholder="Еmail"
              error={getErrorString(errors)}
              onEnter={onSubmit}
            />
          </InputWrapper>

          {!data && (
            <Button
              pending={isLoading}
              fullWidth
              onClick={onSubmit}
              styles={{ marginBottom: '16px' }}
              disabled={buttonDisabled}
            >
              ВОССТАНОВИТЬ
            </Button>
          )}

          <Info>
            <Typography.Main
              fromStart
              margin={0}
              color={
                data?.TYPE === 'ERROR'
                  ? theme.colors.info.error
                  : theme.colors.text.default
              }
            >
              {!!data
                ? data.MESSAGE.replace('<br>', '')
                : 'На email будет отправлена инструкция для восстановления пароля'}
            </Typography.Main>
          </Info>
        </Container.Flex>
      </Card>
    </Container.CardsGrid>
  )
}

export default RestorePassword
