import { useEffect, useRef } from 'react'
import Container from '../Container'
import Typography from '../Typography'
import { useTheme } from 'styled-components/macro'
import { useUploadImages } from './UploadImagesContext'
import Button from '../Button'
import Info from '../Info/Info'

export default function UploadSecondImage() {
  const theme = useTheme()

  const { addImage, resizeCard } = useUploadImages()

  const ref = useRef<HTMLButtonElement>(null)

  const onFileInput = (file: File) => {
    addImage(1, file)
    if (ref.current) {
      ref.current.blur()
    }
  }

  useEffect(() => {
    resizeCard()
  }, [resizeCard])

  return (
    <Container.Flex fullWidth gap={16}>
      <Typography.TitleSecondary
        styles={{ padding: '0 4px' }}
        margin={0}
        fromStart
      >
        Добавьте фото задней стороны (2 из 2)
      </Typography.TitleSecondary>

      <Typography.Main styles={{ padding: '0 4px' }} margin={0} fromStart>
        Сделайте фото задней стороны смартфона
        <Typography.Main tag="span" color={theme.colors.link.default} linkStyle>
          Пример фото
        </Typography.Main>
      </Typography.Main>

      <Info>
        Все надписи на корпусе и IMEI должны быть чётко различимы на фото
      </Info>

      <Button
        fileInput
        onFileInput={onFileInput}
        icon="photo"
        variant="primary"
        fullWidth
        ref={ref}
      >
        {'Выбрать/сделать фото'.toUpperCase()}
      </Button>
    </Container.Flex>
  )
}
