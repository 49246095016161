import { memo } from "react";
import { Container } from "../components/ui";
import step1 from "../assets/step_1.svg";
import step2 from "../assets/step_2.svg";
import step3 from "../assets/step_3.svg";
import MainSlider from "../components/ui/MainSlider";
import { bp } from "../theme/media";
import { LowerMenu } from "../components";

const slidesData = [
    {
        img: step1,
        title: "Куда деть старый смартфон?",
        text: "Быстро и выгодно обменяйте ваше старое устройство на новый товар или получите за него деньги"
    },
    {
        img: step2,
        title: "Оцените ваш смартфон из дома",
        text: "Легко оцените ваш смартфон, ответив на несколько простых вопросов. Мы 100% гарантируем самую высокую оценку"
    },
    {
        img: step3,
        title: "Выберите магазин-партнёр рядом",
        text: "Выберите партнёра у которого есть то, на что вы хотите поменять ваше устройство. Его примут по оценённой стоимости"
    }
];

const Home = () => {
    return (
        <>
            <Container.Flex
                alignItems="stretch"
                justify="start"
                fullWidth
                styles={{ minHeight: "calc(var(--vh, 1vh) * 100 - 182px)"}}
                breakpoints={{
                    [bp.mobile]: {
                        justifyContent: "space-between"
                    }
                }}
            >
                <MainSlider slides={slidesData} />
            </Container.Flex>
            <LowerMenu />
        </>
    );
};

export default memo(Home);
