import { DeviceStatuses } from "../types"

export const getProgressByStatus = (status: string, base?: number) => {
    switch (status) {
        case DeviceStatuses.Preliminary:
            return 0.5
        case DeviceStatuses.Confirming:
            return 0.75
        case DeviceStatuses.Confirmed:
            return 1
        case DeviceStatuses.ChangedAccepted:
            return 1
        default:
            return base ?? 0
    }
}