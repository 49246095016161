import { createSelector, PayloadAction } from "@reduxjs/toolkit";
import { createSlice, createAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ViewState } from './types';

const initialState: ViewState = {
    showMenu: false,
    animationOpen: false,
    animationClose: false,
    currentSlide: 0,
    redirectTo: null,
    replaceTo: null,
    offerCard: {
        opacity: "0",
        showLoader: false
    },
    pageVisible: true,
    popupContent: "",
    resizeCard: false,
    showImageContainer: false,
    lowerMenuSecure: false,
    showPageLoader: false
}

export const hideMenu = createAction("view/Hide-Menu");

const viewSlice = createSlice({
    name: 'view',
    initialState,
    reducers: {
        showMenu(state: ViewState) {
            state.showMenu = true
        },
        hideMenuFulfill(state: ViewState) {
            state.showMenu = false
        },
        animationOpen(state: ViewState) {
            state.animationOpen = true
        },
        animationClose(state: ViewState) {
            state.animationClose = true
        },
        animationClear(state: ViewState) {
            state.animationClose = false
            state.animationOpen = false
        },
        setSlide(state: ViewState, { payload }: PayloadAction<number>) {
            state.currentSlide = payload
        },
        redirectRequest(
            state: ViewState,
            { payload }: PayloadAction<string | null>,
        ) {
            state.pageVisible = false
        },
        redirectTo(
            state: ViewState,
            { payload }: PayloadAction<string | null>,
        ) {
            state.redirectTo = payload
            // state.offerCard.opacity = (payload ? "0" : "1")
        },
        replaceTo(state, { payload }: PayloadAction<string | null>) {
            state.replaceTo = payload;
        },
        setPageVisible(state: ViewState, { payload }: PayloadAction<boolean>) {
            state.pageVisible = payload
        },
        setOfferCardOpacity(
            state: ViewState,
            { payload }: PayloadAction<'0' | '1'>,
        ) {
            state.offerCard.opacity = payload
        },
        hideOfferCard(state: ViewState) {
            state.offerCard.opacity = '0'
            state.offerCard.showLoader = true
        },
        showOfferCard(state: ViewState) {
            state.offerCard.opacity = '1'
            state.offerCard.showLoader = false
        },
        setPopupContent(state: ViewState, { payload }: PayloadAction<string>) {
            state.popupContent = payload
        },
        setResizeCard(state: ViewState, { payload }: PayloadAction<boolean>) {
            state.resizeCard = payload
        },
        setShowImageContainer(state: ViewState, { payload }: PayloadAction<boolean>) {
            state.showImageContainer = payload
        },
        setLowerMenuSecure(state, { payload } : PayloadAction<boolean>) {
            state.lowerMenuSecure = payload
        },
        showPageLoader(state){
            state.showPageLoader = true
        },
        hidePageLoader(state) {
            state.showPageLoader = false
        }
    },
})

export const getViewData = createSelector(
    (state: RootState) => state.view,
    view => view
);

export const getMenuData = createSelector(
    (state: RootState) => ({view: state.view, order: state.order, saved: state.saved}),
    (data) => ({
        menuIsShown: data.view.showMenu,
        animationOpen: data.view.animationOpen,
        animationClose: data.view.animationClose,
        savedOrderNumber: data.saved.orderNumber
    })
)

export const getUploadImageData = createSelector(
    (state: RootState) => state.view,
    view => ({
        showImageContainer: view.showImageContainer
    })
)

export const getOfferCardData = createSelector(
    (state: RootState) => ({ view: state.view, offer: state.offer }),
    data => ({
        offerCard: data.view.offerCard,
        changeQuestionContent: data.offer.changeQuestionsContent,
        cardError: data.offer.cardError,
        resizeCard: data.view.resizeCard
    })
)

export const getPageVisible = createSelector(
    (state: RootState) => state.view,
    view => view.pageVisible
)

export const getPopupContent = createSelector(
    (state: RootState) => state.view,
    view => view.popupContent
);

export const getShowPageLoader = createSelector(
    (state: RootState) => state.view,
    view => view.showPageLoader
)

export const { 
    showMenu,
    animationOpen,
    animationClose,
    animationClear,
    setSlide,
    redirectTo,
    hideMenuFulfill,
    setOfferCardOpacity,
    hideOfferCard,
    showOfferCard,
    redirectRequest,
    setPageVisible,
    setPopupContent,
    setResizeCard,
    setShowImageContainer,
    setLowerMenuSecure,
    showPageLoader,
    hidePageLoader,
    replaceTo,
} = viewSlice.actions;

export default viewSlice.reducer;