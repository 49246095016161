import SVG from '../SVG'
import { IconProps } from '../types'

const CloseCircleIcon = (props: IconProps) => {
  return (
    <SVG {...props} width={props.width ?? 16} height={props.height ?? 16}>
      <g clipPath="url(#clip0_6404_20153)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.6 8C1.6 4.46538 4.46538 1.6 8 1.6C11.5346 1.6 14.4 4.46538 14.4 8C14.4 11.5346 11.5346 14.4 8 14.4C4.46538 14.4 1.6 11.5346 1.6 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM4.60697 4.60599C4.91939 4.29358 5.42592 4.29358 5.73834 4.60599L8.00117 6.86882L10.2638 4.60617C10.5762 4.29375 11.0828 4.29375 11.3952 4.60617C11.7076 4.91859 11.7076 5.42512 11.3952 5.73754L9.13254 8.0002L11.3952 10.2628C11.7076 10.5753 11.7076 11.0818 11.3952 11.3942C11.0828 11.7066 10.5762 11.7066 10.2638 11.3942L8.00117 9.13157L5.73834 11.3944C5.42592 11.7068 4.91939 11.7068 4.60697 11.3944C4.29455 11.082 4.29455 10.5754 4.60697 10.263L6.8698 8.0002L4.60697 5.73737C4.29455 5.42495 4.29455 4.91841 4.60697 4.60599Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6404_20153">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </SVG>
  )
}

export default CloseCircleIcon
