import { Card } from '../ui'
import Typography from '../ui/Typography'
import CheckboxSelect from '../ui/Input/CheckboxSelect'

const blends = ['Чекбокс 1', 'Чекбокс 2', 'Чекбокс 3', 'Чекбокс 4']

const ThemeCheckbox = () => {
    return (
        <Card padding={20}>
            <Typography.Title>Checkbox</Typography.Title>
            <CheckboxSelect
                styles={{}}
                items={blends.map((el, idx) => ({
                    value: idx,
                    label: el,
                    name: 'test',
                }))}
            />
        </Card>
    )
}

export default ThemeCheckbox
