import { memo, useEffect, useMemo, useRef } from 'react'
import { Container, Image, Typography } from '../ui'
import styled from 'styled-components/macro'
import { DeviceInfo, GivenAnswer } from '../../store/offerSlice/types'
import { getDeviceData } from './helpers/getDeviceData'

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87px;
  height: 87px;
  border-radius: 12px;
  background-color: white;
`

const MIN_HEIGHT = 90

const OfferDevice = ({
  deviceInfo,
  givenAnswers,
}: {
  deviceInfo: DeviceInfo
  givenAnswers: GivenAnswer[]
}) => {
  const { deviceImage, deviceName } = deviceInfo

  const categories = useMemo(() => getDeviceData(givenAnswers), [givenAnswers])
  const noLabel = categories.noLabel

  const contentRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current && contentRef.current) {
      const newHeight = contentRef.current.offsetHeight

      containerRef.current.style.height =
        (newHeight > MIN_HEIGHT ? newHeight : MIN_HEIGHT) + 'px'
    }
  })

  return (
    <Container.Flex
      ref={containerRef}
      fullWidth
      direction="row"
      alignItems="start"
      horizontalGap={16}
      justify="start"
      margin="0 0 16px"
      fullHeight
      styles={{
        // opacity,
        height: '100px',
        transition: 'height 200ms ease-in',
      }}
    >
      <ImgWrapper>
        <Image
          src={deviceImage}
          alt={deviceName}
          height={75}
          styles={{
            maxWidth: '75px',
            maxHeight: '75px',
          }}
        />
      </ImgWrapper>

      <Container.Flex
        verticalGap={4}
        alignItems="stretch"
        ref={contentRef}
        styles={{ flexGrow: 1 }}
      >
        <Typography.Title textAlign="start" styles={{ order: 0, margin: '0' }}>
          {deviceName}
        </Typography.Title>

        {noLabel && (
          <Container.Flex direction="row" alignItems="start">
            <Typography.Small textAlign="start">{noLabel}</Typography.Small>
          </Container.Flex>
        )}

        {Object.entries(categories).map(([groupName, value], key) =>
          groupName === 'noLabel' ? null : (
            <Container.Flex direction="row" key={key} alignItems="start">
              <Typography.Tertiary
                margin={'0 4px 0 0'}
                styles={{ width: 'max-content' }}
              >
                {groupName}
              </Typography.Tertiary>
              <Typography.Small textAlign="start">{value}</Typography.Small>
            </Container.Flex>
          ),
        )}
      </Container.Flex>
    </Container.Flex>
  )
}

export default memo(OfferDevice)
