import AddPlus from './icons/addPlus'
import AddPlusCircle from './icons/addPlusCircle'
import ColorIndicatorIcon from './icons/colorIndicator'
import CloseIcon from './icons/close'
import TelegramIcon from './icons/telegram'
import LoadingIcon from './icons/loading'
import { IconProps } from './types'
import UserIcon from './icons/user'
import UsersIcon from './icons/users'
import ReportsIcon from './icons/reports'
import HelpIcon from './icons/help'
import InfoIcon from './icons/info'
import PhotoIcon from './icons/photo'
import Hidden from './icons/hidden'
import Eye from './icons/eye'
import SettingsIcon from './icons/settings'
import EmployeeIcon from './icons/employee'
import OrderList from './icons/orderList'
import QrCodeIcon from './icons/qrCode'
import NewOrderIcon from './icons/newOrder'
import ExitIcon from './icons/exit'
import BellIcon from './icons/bell'
import EditIcon from './icons/edit'
import EyeClose from './icons/eye-close'
import Arrow from './icons/arrow'
import Search from './icons/search'
import List from './icons/List'
import ListAdd from './icons/ListAdd'
import FiltersOn from './icons/filtersOn'
import FiltersOff from './icons/filtersOff'
import Calendar from './icons/calendar'
import MobileIcon from './icons/mobile'
import MobileIcon24 from './icons/mobile24'
import LeafIcon from './icons/leaf'
import LeafIcon24 from './icons/leaf24'
import MapIcon from './icons/map'
import SaveORDER from './icons/save-order'
import AddDevice from './icons/add-device'
import StatusChanged from './icons/status-changed'
import StatusChangedAccepted from './icons/status-changed-accepted'
import StatusConfirmed from './icons/status-confirmed'
import StatusConfirming from './icons/status-confirming'
import StatusCorresponds from './icons/status-corresponds'
import StatusOutOfStock from './icons/status-out-of-stock'
import StatusPaid from './icons/status-paid'
import StatusPreliminary from './icons/status-preliminary'
import { DeviceStatuses } from '../../../types'
import Dissense from './icons/dissence'
import DissenseActive from './icons/dissence-active'
import DissenseRead from './icons/dissence-read'
import UserSaveIcon from './icons/user-save'
import CheckCircleIcon from './icons/check-circle'
import CloseCircleIcon from './icons/close-circle'
import WarningIcon from './icons/warning'
import LampIcon from './icons/lamp'

const Icon = (props: IconProps) => {
  switch (props.name) {
    case 'add-device':
      return <AddDevice {...props} />
    case 'add-plus':
      return <AddPlus {...props} />
    case 'add-plus-circle':
      return <AddPlusCircle {...props} />
    case 'arrow':
      return <Arrow {...props} />
    case 'check-circle':
      return <CheckCircleIcon {...props} />
    case 'close':
      return <CloseIcon {...props} />
    case 'close-circle':
      return <CloseCircleIcon {...props} />
    case 'color-indicator':
      return <ColorIndicatorIcon {...props} />
    case 'dissence':
      return <Dissense {...props} />
    case 'dissence-active':
      return <DissenseActive {...props} />
    case 'dissenct-read':
      return <DissenseRead {...props} />
    case 'filters-off':
      return <FiltersOff {...props} />
    case 'filters-on':
      return <FiltersOn {...props} />
    case 'telegram':
      return <TelegramIcon {...props} />
    case 'lamp':
      return <LampIcon {...props} />
    case 'leaf':
      return <LeafIcon {...props} />
    case 'leaf24':
      return <LeafIcon24 {...props} />
    case 'list':
      return <List {...props} />
    case 'list-add':
      return <ListAdd {...props} />
    case 'loading':
      return <LoadingIcon {...props} />
    case 'map':
      return <MapIcon {...props} />
    case 'mobile':
      return <MobileIcon {...props} />
    case 'mobile24':
      return <MobileIcon24 {...props} />
    case 'employee':
      return <EmployeeIcon {...props} />
    case 'exit':
      return <ExitIcon {...props} />
    case 'user':
      return <UserIcon {...props} />
    case 'users':
      return <UsersIcon {...props} />
    case 'reports':
      return <ReportsIcon {...props} />
    case 'help':
      return <HelpIcon {...props} />
    case 'info':
      return <InfoIcon {...props} />
    case 'new-order':
      return <NewOrderIcon {...props} />
    case 'order-list':
      return <OrderList {...props} />
    case 'photo':
      return <PhotoIcon {...props} />
    case 'qr-code':
      return <QrCodeIcon {...props} />
    case 'hidden':
      return <Hidden {...props} />
    case 'eye':
      return <Eye {...props} />
    case 'eye-close':
      return <EyeClose {...props} />
    case 'settings':
      return <SettingsIcon {...props} />
    case 'bell':
      return <BellIcon {...props} />
    case 'edit':
      return <EditIcon {...props} />
    case 'save-order':
      return <SaveORDER {...props} />
    case 'search':
      return <Search {...props} />
    case DeviceStatuses.Changed:
      return <StatusChanged {...props} />
    case DeviceStatuses.ChangedAccepted:
      return <StatusChangedAccepted {...props} />
    case DeviceStatuses.Confirmed:
      return <StatusConfirmed {...props} />
    case DeviceStatuses.Confirming:
      return <StatusConfirming {...props} />
    case DeviceStatuses.Corresponds:
      return <StatusCorresponds {...props} />
    case DeviceStatuses.OutOfStock:
      return <StatusOutOfStock {...props} />
    case DeviceStatuses.Paid:
      return <StatusPaid {...props} />
    case DeviceStatuses.Preliminary:
      return <StatusPreliminary {...props} />
    case 'calendar':
      return <Calendar {...props} />
    case 'user-save':
      return <UserSaveIcon {...props} />
    case 'warning':
      return <WarningIcon {...props} />
  }
}

export default Icon
