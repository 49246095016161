import axios, { AxiosInstance, AxiosResponse } from 'axios'
import qs from 'qs'
import { Order } from '../store/orderSlice/types'
import { ResponseData } from '../store/types'
import { Session } from '../store/userSlice/types'
import { collectFormData, collectParams, getErrorResponse } from './helpers'
import {
  ClientLoginRequest,
  GetOrderFiltersResponse,
  EmptyRequest,
  ClientLoginResponse,
  TransportAddSavePackRequest,
  TransportAddSavePackResponse,
  TransportCreateAppRequest,
  TransportCreateAppResponse,
  GetOrderRequest,
  TransportGetAppListRequest,
  TransportGetAppListResponse,
  TransportGetActiveUserAppResponse,
  TransportDeleteSavePackRequest,
  TransportDeleteSavePackResponse,
  TransportConfirmApplicationRequest,
  TransportConfirmApplicationResponse,
  TransportReserveSalePointRequest,
  TransportReserveSalePointResponse
} from './types'

axios.defaults.paramsSerializer = params => qs.stringify(params)
export const baseURL = '/api'

export enum URLS {
  //GENERAL
  getRecapchaKey = '/general/getrecaptchacode/',
  //USER/AUTH
  login = '/auth/login/',
  logout = '/auth/logout/',
  checkAuth = '/auth/checkauth/',
  changePassword = '/auth/changepassword/',
  restorePassword = '/auth/restorepassword/',
  addUserData = '/user/add/',
  updateUserData = '/user/update/',
  getUserDetail = '/user/getDetail/',
  getUsersList = '/user/getList/',
  SMSLogin = '/auth/clientlogin/',
  GetSession = '/auth/getsess/',
  //PARTNERS
  getPartners = '/partnersdata/getpartners/',
  getPartnersFilter = '/partnersdata/getpartnersfilter/',
  getPartnersDetail = '/partnersdata/getpartnerdetail/',
  //PAGE-FORM
  getPageForm = '/pageform/get/',
  sendPageForm = '/pageform/add/',
  //ORDER
  createOrder = '/orderrequest/create/',
  orderAddProperty = '/orderrequest/addproperty/',
  orderSetRegistration = '/orderrequest/setRegistration/',
  getOrder = '/orderrequest/getorder/',
  orderAddItem = '/orderrequest/additem/',
  orderDeleteItem = '/orderrequest/deleteitem/',
  orderGetItemStatus = '/orderrequest/getitemstatus/',
  orderAddFile = '/orderrequest/addfile/',
  getOrderList = '/orderrequest/getorderlist/',
  getOrderListFilters = '/orderrequest/getordersfilter/',
  deleteOrder = '/orderrequest/delete/',
  SendOrderLink = '/orderrequest/sendorderlink/',
  //DEVICEDATA
  getQuestions = '/devicedata/getquestions/',
  getItemInfo = '/orderrequest/getiteminfo/',
  addCustomerDevice = '/orderrequest/addcustomerdevice/',
  //THEME
  getTheme = '/theme/get/',
  //ORDERFINAL
  getSalePoints = '/orderrequest/getsalepoints/',
  getContractData = '/orderrequest/getcontractfieldsdata/',
  sendContractData = '/orderrequest/adduserdata/',
  GET_STOCKS = '/reservesalepoint/getsalepointslist/',
  GET_STOCKS_FILTERS = '/reservesalepoint/getsalepoitsfilters/',
  RELOCATION_ORDER_FIND_PACKAGE = '/findPackage',
  RESERVE_STOCK = '/reservesalepoint/reservesalepoint/',
  UNRESERVE_STOCK = 'reservesalepoint/unreservesalepoint/',
  TRANSPORT_APPLICATION_CREATE_APP = '/transportapplication/createapp/',
  TRANSPORT_APPLICATION_ADD_SAVEPACK = '/transportapplication/addsavepack/',
  TRANSPORT_APPLICATION_DELETE_SAVEPACK = '/transportapplication/deletesavepack/',
  TRANSPORT_APPLICATION_CONFIRM = '/transportapplication/confirmapplication/',
  TRANSPORT_APPLICATION_GET_USER_ACTIVE_APP = '/transportapplication/getuseractiveapplication/',
  TRANSPORT_APPLICATION_GET_APPLICATION_LIST = '/transportapplication/getapplicationlist/',
  TRANSPORT_APPLICATION_GET_ALL_COURIER_LIST = '/transportapplication/getallcurierlist/',
}

class Api {
  login
  logout
  checkAuth
  changePassword
  restorePassword
  addUserData
  updateUserData
  getUserDetail
  getUsersList
  getPartners
  getPageForm
  sendPageForm
  createOrder
  orderAddProperty
  orderSetRegistration
  getOrder
  orderAddItem
  orderDeleteItem
  orderGetItemStatus
  orderAddFile
  getQuestions
  getTheme
  getItemInfo
  getOrderListFilters
  smsLogin
  sendOrderLink
  getSession
  relocationOrderFindPackage
  reserveStock
  unreserveStock
  transport
  api: AxiosInstance
  constructor() {
    this.api = axios.create({
      baseURL,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      withCredentials: true,
    })
    this.login = this._post(URLS.login)
    this.logout = this._post(URLS.logout)
    this.checkAuth = this._post(URLS.checkAuth)
    this.changePassword = this._post(URLS.changePassword)
    this.restorePassword = this._post(URLS.restorePassword)
    this.addUserData = this._post(URLS.addUserData)
    this.updateUserData = this._post(URLS.updateUserData)
    this.getUserDetail = this._get(URLS.getUserDetail)
    this.getUsersList = this._get(URLS.getUsersList)
    this.getPartners = this._post(URLS.getPartners)
    this.getPageForm = this._post(URLS.getPageForm)
    this.sendPageForm = this._post(URLS.sendPageForm)
    this.createOrder = this._post(URLS.createOrder)
    this.orderAddProperty = this._post(URLS.orderAddProperty)
    this.orderSetRegistration = this._post(URLS.orderSetRegistration)
    this.getOrder = this._post<GetOrderRequest, Order>(URLS.getOrder)
    this.orderAddItem = this._post(URLS.orderAddItem)
    this.orderDeleteItem = this._post(URLS.orderDeleteItem)
    this.orderGetItemStatus = this._post(URLS.orderGetItemStatus)
    this.orderAddFile = this._post(URLS.orderAddFile)
    this.getQuestions = this._post(URLS.getQuestions)
    this.getTheme = this._get(URLS.getTheme)
    this.getItemInfo = this._post(URLS.getItemInfo)
    this.getOrderListFilters = this._post<
      EmptyRequest,
      GetOrderFiltersResponse
    >(URLS.getOrderListFilters)
    this.smsLogin = this._post<ClientLoginRequest, ClientLoginResponse>(
      URLS.SMSLogin,
    )
    this.sendOrderLink = this._post(URLS.SendOrderLink)
    this.getSession = this._get(URLS.GetSession)
    this.relocationOrderFindPackage = this._post(
      URLS.RELOCATION_ORDER_FIND_PACKAGE,
    )
    this.reserveStock = this._post<TransportReserveSalePointRequest, TransportReserveSalePointResponse>(URLS.RESERVE_STOCK)
    this.unreserveStock = this._post(URLS.UNRESERVE_STOCK)
    this.transport = {
      createApp: this._post<TransportCreateAppRequest,TransportCreateAppResponse>(URLS.TRANSPORT_APPLICATION_CREATE_APP),
      addSavePack: this._post<TransportAddSavePackRequest,TransportAddSavePackResponse>(URLS.TRANSPORT_APPLICATION_ADD_SAVEPACK),
      deleteSavePack: this._post<TransportDeleteSavePackRequest, TransportDeleteSavePackResponse>(URLS.TRANSPORT_APPLICATION_DELETE_SAVEPACK),
      getAppList: this._post<TransportGetAppListRequest, TransportGetAppListResponse>(URLS.TRANSPORT_APPLICATION_GET_APPLICATION_LIST),
      getActiveUserApp: this._post<{},  TransportGetActiveUserAppResponse>(URLS.TRANSPORT_APPLICATION_GET_USER_ACTIVE_APP),
      confirm: this._post<TransportConfirmApplicationRequest, TransportConfirmApplicationResponse>(URLS.TRANSPORT_APPLICATION_CONFIRM)
    }
  }

  _get(url: URLS) {
    return (session: Session, params = {}) => {
      return this.api
        .get(url, {
          params: collectParams(session, params),
        })
        .then((response: AxiosResponse<ResponseData<any>>) => response.data)
        .catch((error: any) => getErrorResponse(error.message))
    }
  }

  _post<T, R>(url: URLS) {
    return (data: T) => {
      const _data = data as unknown
      return this.api
        .post(url, collectFormData(_data as object), { timeout: 10 * 1000 })
        .then((response: AxiosResponse<ResponseData<R>>) => {
          return response.data
        })
        .catch((error: any) => {
          if (error.code === 'ECONNABORTED') {
            return getErrorResponse<R>('Время ожидания истекло')
          }
          return getErrorResponse<R>(error.message)
        })
    }
  }
}

export default Api
