import PageLayout from './PageLayout'
import CourierWrapper from './CourierWrapper'
import RelocationRequest from '../components/Courier/TransportApplication/TransportApplication'
import { useParams } from 'react-router-dom'

const CourierTransportApplication = () => {

  const { appNumber } = useParams()

  return (
    <PageLayout withProfile customLowerMenu>
      <CourierWrapper>
        <RelocationRequest appNumber={appNumber} />
      </CourierWrapper>
    </PageLayout>
  )
}

export default CourierTransportApplication
