import {
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './saga'
import userSlice from './userSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import offerSlice from './offerSlice'
import viewSlice from './viewSlice'
import orderSlice from './orderSlice'
import apiSlice from './apiSlice'
import themeSlice from './themeSlice'
import savedData from './savedData'
import contractSlice from './contractSlice'
import { siteApi } from './api/api'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import lowerMenuSlice from './lowerMenu'

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['login', 'session'],
}

const orderPersist = {
  key: 'order',
  storage,
  whitelist: ['orderNumber'],
}

const savePersist = {
  key: 'saved',
  storage,
  whitelist: ['orderNumber'],
}

const contractPersist = {
  key: 'contract',
  storage,
}

const sagaMiddleWare = createSagaMiddleware()

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userSlice),
  offer: offerSlice,
  order: persistReducer(orderPersist, orderSlice),
  view: viewSlice,
  lowerMenu: lowerMenuSlice,
  api: apiSlice,
  theme: themeSlice,
  saved: persistReducer(savePersist, savedData),
  contract: persistReducer(contractPersist, contractSlice),
  [siteApi.reducerPath]: siteApi.reducer
})

const persistedReducer = rootReducer

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      siteApi.middleware,
      sagaMiddleWare,
    ),
    devTools: process.env.NODE_ENV === 'production' ? false : true
})

setupListeners(store.dispatch)

sagaMiddleWare.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store
