import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { Icon, Typography } from '../ui'
import SaveOrder from './SaveOrder'
import { LowerMenuProps } from './types'
import { createPortal } from 'react-dom'

export const LowerMenuWrapper = styled.div<{margin?: string}>`
  display: flex;
  width: max-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 13px;
  padding: 8px 23px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
  border-radius: 39px;
  transition: all 200ms ease;
  background-color: ${props => props.theme.colors.background.black60};
`

const LowerMenuItem = styled.div<{ disabled?: true }>`
  color: ${props =>
    props.disabled
      ? props.theme.colors.text.secondary
      : props.theme.colors.text.contrast};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 64px;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    color: ${props =>
      props.disabled
        ? props.theme.colors.text.secondary
        : props.theme.colors.text.quaternary};
  }
`

const LowerMenuContent = (props: LowerMenuProps) => {
  const { items } = props
  const { orderNumber } = useParams()
  const theme = useTheme()

  const [showSaveForm, setShowSaveForm] = useState(false)

  const navigate = useNavigate()

  const onClick = (link: string) => () => {
    if (link.startsWith('handler')) {
      const action = link.replace('handler/', '')
      switch (action) {
        case 'add-device':
          return navigate(`/create/${orderNumber}/`)
        case 'save-order':
          return setShowSaveForm(true)
      }
    } else {
      navigate(link)
    }
  }

  const hideSaveOrder = useCallback(() => {
    setShowSaveForm(false)
  }, [setShowSaveForm])

  const root = document.getElementById('custom-lower-menu');

  if (!root || !items || !items.length) return null;

  return createPortal(
    <LowerMenuWrapper>
        {items.map((item, key) => (
          <LowerMenuItem
            key={key}
            onClick={
              item.disabled
                ? undefined
                : item.onClick
                ? item.onClick
                : item.link
                ? onClick(item.link)
                : undefined
            }
          >
            <Icon
              name={item.icon}
              color={item.disabled ? theme.colors.text.secondary : undefined}
            />
            <Typography.LowMenu
              noTransition
              color={item.disabled ? theme.colors.text.secondary : undefined}
            >
              {item.title}
            </Typography.LowMenu>
          </LowerMenuItem>
        ))}
      {showSaveForm && !!orderNumber && (
        <SaveOrder orderNumber={orderNumber} hide={hideSaveOrder} />
      )}
    </LowerMenuWrapper>, root
  )
}

export default LowerMenuContent
