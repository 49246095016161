import SVG from '../SVG'
import { IconProps } from '../types'

const WarningIcon = (props: IconProps) => {
  return (
    <SVG {...props} width={16} height={16}>
      <g clipPath="url(#clip0_6404_20165)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 11.5346 4.46538 14.4 8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM7.16016 10.7602C7.16016 10.3183 7.51833 9.96016 7.96016 9.96016H8.04016C8.48198 9.96016 8.84016 10.3183 8.84016 10.7602V10.8402C8.84016 11.282 8.48198 11.6402 8.04016 11.6402H7.96016C7.51833 11.6402 7.16016 11.282 7.16016 10.8402V10.7602ZM8.8 5.16016C8.8 4.71833 8.44183 4.36016 8 4.36016C7.55817 4.36016 7.2 4.71833 7.2 5.16016V8.36016C7.2 8.80198 7.55817 9.16016 8 9.16016C8.44183 9.16016 8.8 8.80198 8.8 8.36016V5.16016Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6404_20165">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </SVG>
  )
}

export default WarningIcon
