import {
  createContext,
  Fragment,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useSession } from '../../hooks/useSession'
import {
  useGetShowSetOrderReadyButtonQuery,
  useUpdateOrderStatusMutation,
} from '../../store/api/api'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { GetOrder } from '../../store/orderSlice'
import { Order, OrderStatusCodes, OrderStatuses } from '../../store/orderSlice/types'
import PageLoader from '../Layout/PageLoading'
import { Confirm, Typography } from '../ui'
import { OrderImegesProps } from './OrderModals/OrderImages'
import { useTheme } from 'styled-components'

interface OrderButton {
  text: string
  onClick?: () => void
  notShowOnBottom?: true
  color: string
  hoverColor: string
}

const OrderContext = createContext(
  {} as {
    orderData: Order | null
    showShipmentModal: string | null
    setShowShipmentModal: (v: string | null) => void
    orderButton: null | OrderButton
    updateOrderData: () => void
    showDissent: boolean
    showDissentModal: () => void
    hideDissentModal: () => void
    orderImages: OrderImegesProps
    showOrderImages: (data: OrderImegesProps) => void
    hideOrderImages: () => void
    setImage: (n: number) => void
  },
)

export const useOrderContext = () => useContext(OrderContext)

function OrderContextWrapper({ children }: PropsWithChildren<{}>) {

  const theme = useTheme()
  const { data: orderData } = useAppSelector(state => state.order.order)
  const [showShipmentModal, setShowShipmentModal] = useState<string | null>(null)
  const { isAdmin, session } = useSession()
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [showDissent, setDissentContent] = useState(false)
  const [orderImages, setOrderImages] = useState<OrderImegesProps>({
    images: [],
    currentImage: 0,
  })

  const {
    data: showReadyButton,
  } = useGetShowSetOrderReadyButtonQuery(
    session,
    { refetchOnMountOrArgChange: true },
  )

  const showDissentModal = useCallback(() => {
    setDissentContent(true)
  }, [])

  const hideDissentModal = useCallback(() => {
    setDissentContent(false)
  }, [])

  const showOrderImages = useCallback((data: OrderImegesProps) => {
    setOrderImages(data)
  }, [])

  const hideOrderImages = useCallback(() => {
    setOrderImages({ images: [], currentImage: 0 })
  }, [])

  const setImage = useCallback((n: number) => {
    setOrderImages(prev => ({ ...prev, currentImage: n }))
  }, [])

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const [
    changeOrderStatus,
    {
      isSuccess: updateOrderStatusSuccess,
      isError: updateOrderStatusIsError,
      error: updateOrderStatusError,
      data: updateOrderStatusData,
      isLoading,
      reset,
    },
  ] = useUpdateOrderStatusMutation()

  const error = updateOrderStatusError as undefined | { data: string }

  useEffect(() => {
    if (error) {
      setShowDeleteConfirm(false)
    }
  }, [error])

  const onErrorAnimationEnd = () => {
    reset()
  }

  useEffect(() => {
    if (updateOrderStatusData?.newStatus === 'Отменена') {
      navigate('/profile/order-list/')
    }
  }, [updateOrderStatusData, navigate])

  useEffect(() => {
    if (updateOrderStatusSuccess || updateOrderStatusIsError) {
      dispatch(GetOrder.request({ orderNumber: orderData?.number }))
    }
  }, [dispatch, updateOrderStatusSuccess, updateOrderStatusIsError])

  const deleteOrder = (number: string) => {
    changeOrderStatus({
      data: {
        number,
        statusCode: OrderStatusCodes.Canceled,
      },
    })
  }

  const updateOrderData = useCallback(() => {
    const orderNumber = orderData?.number

    if (orderNumber) {
      dispatch(GetOrder.request({ orderNumber }))
    }
  }, [dispatch, orderData])

  const orderButton = useMemo(() => {
    if (showReadyButton?.viewUiComplite) {

      if (isAdmin && orderData?.status === OrderStatuses.Done) {
        return {
          text: "Укажите номер Сейф-пакета",
          notShowOnBottom: true,
          color: theme.colors.info.error,
          hoverColor: theme.colors.info.error
        } as OrderButton
      }

      if (isAdmin && orderData?.status === OrderStatuses.Packed) {
        return {
          text: 'Заявка готова к отгрузке?',
          onClick: () =>
            changeOrderStatus({
              data: {
                statusCode: OrderStatusCodes.ReadyForShipping,
                number: orderData.number,
              },
            }),
            notShowOnBottom: true,
            color: theme.colors.link.default,
            hoverColor: theme.colors.link.hover
        } as OrderButton
      }
    }
    

    if (isAdmin && orderData?.status && orderData.number) {
      if (orderData.status === OrderStatuses.Ready) {
        return null
      }

      if ([
        OrderStatuses.Done,
        OrderStatuses.Ready,
        OrderStatuses.Packed,
        OrderStatuses.Accept,
        OrderStatuses.Paid,
        OrderStatuses.Paid,
        OrderStatuses.Canceled,
        OrderStatuses.Complete,
        OrderStatuses.Deleted,
        OrderStatuses.Confirm
      ].includes(orderData.status)) {
        return null
      }
      return {
        text: 'Отменить заявку',
        onClick: () => setShowDeleteConfirm(true),
        color: theme.colors.info.error,
        hoverColor: theme.colors.info.error
      }
    }
    return null
  }, [orderData, showReadyButton, isAdmin])

  return (
    <OrderContext.Provider
      value={{
        orderData,
        showShipmentModal,
        setShowShipmentModal,
        orderButton,
        updateOrderData,
        showDissent,
        hideDissentModal,
        showDissentModal,
        orderImages,
        showOrderImages,
        hideOrderImages,
        setImage,
      }}
    >
      <Fragment>
        {isLoading && <PageLoader />}
        {orderData && (
          <Confirm
            styles={{
              width: 'calc(100vw - 48px)',
              maxWidth: '344px',
              margin: 'auto',
            }}
            question={`Отменить заявку ${orderData.number}?`}
            onYes={() => deleteOrder(orderData.number)}
            yesText={'Да'}
            noText="Нет"
            hide={() => setShowDeleteConfirm(false)}
            visible={showDeleteConfirm}
            yesVariant={'danger'}
            noVariant={'primary'}
            questionTitle="Отмена"
          />
        )}
        {children}

        {error && (
          <Typography.Error
            onAnimationEnd={onErrorAnimationEnd}
            className="error-dissapear"
          >
            {error.data}
          </Typography.Error>
        )}
      </Fragment>
    </OrderContext.Provider>
  )
}

export default memo(OrderContextWrapper)
