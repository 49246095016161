import { Container, Typography } from '../ui'
import { CounterItem } from './ListData'
import styled, { useTheme } from 'styled-components'
import { bp } from '../../theme/media'
import { getFormatRubles } from '../../helpers/getrFormatRubles'

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
`

export default function ListDataCounts<T>(props: { items: CounterItem[] }) {
  const theme = useTheme()
  const { items } = props

  const colorSmall = theme.colors.text.tertiary

  return (
    <Container.Flex
      fullWidth
      direction="row"
      justify="center"
      margin={'24px 0'}
      padding={'0 8px'}
      breakpoints={{
        [bp.mobile]: {
          justifyContent: 'space-between',
        },
      }}
    >
      {items.map((el, idx) => (
        <Item key={idx}>
          <Typography.Small
            padding={0}
            margin="0 4px 0 0"
            color={colorSmall}
            textAlign="start"
          >
            {el.title}:
          </Typography.Small>

          <Typography.RublesMedium secondary margin={0} textAlign="start">
            {el.type === 'price' ? getFormatRubles(el.count).rubles : el.count}
          </Typography.RublesMedium>

          {el.type === 'price' && (
            <Typography.RublesCentes secondary margin={0} textAlign="start">
              {getFormatRubles(el.count).centes}
            </Typography.RublesCentes>
          )}
        </Item>
      ))}
    </Container.Flex>
  )
}
