import SVG from "../SVG";
import { IconProps } from "../types";

const ListAdd = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 24} height={props.height ?? 24}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 3C20 2.44772 19.5523 2 19 2C18.4477 2 18 2.44772 18 3V6H15C14.4477 6 14 6.44772 14 7C14 7.55228 14.4477 8 15 8H18V11C18 11.5523 18.4477 12 19 12C19.5523 12 20 11.5523 20 11V8H23C23.5523 8 24 7.55228 24 7C24 6.44772 23.5523 6 23 6H20V3ZM3 5C2.44772 5 2 5.44772 2 6V18C2 18.5523 2.44772 19 3 19H19C19.5523 19 20 18.5523 20 18V14C20 13.4477 20.4477 13 21 13C21.5523 13 22 13.4477 22 14V18C22 19.6569 20.6569 21 19 21H3C1.34315 21 0 19.6569 0 18V6C0 4.34315 1.34315 3 3 3H13C13.5523 3 14 3.44772 14 4C14 4.55228 13.5523 5 13 5H3ZM4 10C4 9.44772 4.44772 9 5 9H9.57143C10.1237 9 10.5714 9.44772 10.5714 10C10.5714 10.5523 10.1237 11 9.57143 11H5C4.44772 11 4 10.5523 4 10ZM4 15C4 14.4477 4.44772 14 5 14H13C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H5C4.44772 16 4 15.5523 4 15Z"
            />
        </SVG>
    );
};

export default ListAdd;
