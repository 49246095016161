import type { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import type { AxiosRequestConfig } from "axios";
import { createAxiosInstance } from '../../apinew/createAxiosInstance';
import { getErrorString } from "../../helpers/getErrorString";
import { ResponseData } from "../types";

const axiosInstance = createAxiosInstance();

export const axiosBaseQuery = ( { baseUrl } : { baseUrl: string } = { baseUrl: ""}) : BaseQueryFn<{
  url: string;
  method: AxiosRequestConfig['method'],
  data?: AxiosRequestConfig["data"],
  params?: AxiosRequestConfig["params"]
}, ResponseData<any>, unknown>  => async ( { url, method, data, params }) => {
  try {
    const result = await axiosInstance({
      url: url,
      method,
      data,
      params
    });

    if (result.data.errors.length) {
      throw new Error(getErrorString(result.data.errors))
    }

    return result.data
  } catch (axiosError) {
    let err = axiosError as any;
    if (err.data) {
      return err.data
    }
    return {
      
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }
    }
  }
}

