import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import PageLoader from "../components/Layout/PageLoading"
import PageLayout from "./PageLayout"
import Profile from "../components/Profile"
import { useAuthData } from "../contexts/auth"
import { useAppSelector } from "../store/hooks"

const ProfilePage = () => {

    const { getUserDetail, session, pending, userData } = useAuthData()
    const login = useAppSelector(state => state.user.login);
    const navigate = useNavigate()

    useEffect(() => {
        if (!session.isAuthorised) {
            navigate("/")
        } else {
            if (login && !userData) {
                getUserDetail()
            }
        }

    }, [getUserDetail, session, navigate, login])

    if (pending) return <PageLoader />

    return <PageLayout secure><Profile /></PageLayout>
}

export default ProfilePage