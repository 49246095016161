import { useTheme } from 'styled-components'
import { Order } from '../../../store/orderSlice/types'
import OrderDevice from './OrderDevice'
import { Card, Container, Price, Typography } from '../../ui'
import { priceToNumber } from '../../../helpers/priceToNumber'
import { DeviceStatuses } from '../../../types'

interface OrderItemsProps {
  order: Order
  editOrder: () => void
}

const OrderItems = ({ order }: OrderItemsProps) => {
  const theme = useTheme()

  const { items, currencyLang, registration, amount, amountSelectOptionPrice, currency } =
    order

  const readyItems = items.filter(el => el.status === DeviceStatuses.Confirmed)

  const readyItemsAmout = priceToNumber(amountSelectOptionPrice ?? amount);

  return (
    <Card padding={28}>
      <Container.Flex verticalGap={16}>
        {readyItems.map(item => (
          <OrderDevice
            data={item}
            key={item.itemNumber}
            currency={currencyLang ?? currency}
          />
        ))}

        {!!registration && (
          <Container.Flex direction="row" fullWidth padding={'0 4px'}>
            <Typography.Medium
              color={theme.colors.text.tertiary}
              margin={'0 4px 0 0'}
            >
              {`${registration}: `}
            </Typography.Medium>
            <Price
              amount={readyItemsAmout}
              currency={currencyLang ?? currency}
            />
          </Container.Flex>
        )}
      </Container.Flex>
    </Card>
  )
}

export default OrderItems
