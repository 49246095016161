import { ReactNode } from "react";
import { bp } from "../../theme/media";
import { Container } from "../ui";

const PageLayout = ({ children }: { children: ReactNode }) => {
    return (
        <Container.Flex
            gap={36}
            fullWidth
            direction="row"
            alignItems="stretch"
            breakpoints={{
                [bp.mobile]: {
                    flexDirection: "column",
                    alignItems: "center",
                },
            }}
        >
            {children}
        </Container.Flex>
    );
};

export default PageLayout;
