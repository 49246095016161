import { useTheme } from 'styled-components'
import { Container, Typography } from '../ui'
import { useFiltersData } from './FiltersContext'
import { useCallback, useRef, forwardRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { getShortFilterName } from './getShortIFilterName'
import cls from 'classnames'

const classes = {
  hide: 'hide-filter-header-content',
  show: 'show-filter-header-content',
  hidden: 'filters-hidden',
}

const FiltersHeader = forwardRef<HTMLDivElement>((_, ref) => {
  const { currentFilter, resetFilters, activeFilter, filtersOn } =
    useFiltersData()
  const theme = useTheme()

  const headerRef = useRef<HTMLDivElement>(null)

  const onReset = useCallback(() => {
    if (currentFilter) {
      if (activeFilter(currentFilter.propertyName)) {
        resetFilters(currentFilter)
      }
    } else {
      if (filtersOn) {
        resetFilters(currentFilter)
      }
    }
  }, [filtersOn, resetFilters, currentFilter])

  return (
    <Container.Flex
      fullWidth
      direction="row"
      ref={mergeRefs([headerRef, ref])}
      styles={{
        position: 'relative',
        height: '20px',
      }}
    >
      <Container.Flex
        fullWidth
        className={cls({
          [classes.hide]: !!currentFilter,
          [classes.show]: !currentFilter,
        })}
        direction="row"
        justify="between"
        styles={{ height: '20px', position: 'absolute', top: 0, left: 0 }}
      >
        <Typography.Title noTransition secondary fromStart margin={0} styles={{
            padding: "0 4px"
        }}>
          Фильтр
        </Typography.Title>
        <Typography.Medium
          onClick={onReset}
          margin={0}
          hoverStyles={{ cursor: 'pointer' }}
          color={
            filtersOn
              ? theme.colors.link.default
              : theme.colors.text.quaternary
          }
        >
          Сбросить всё
        </Typography.Medium>
      </Container.Flex>

        {!!currentFilter && (
          <Container.Flex
              fullWidth
              className={cls({
                  [classes.hide]: !currentFilter,
                  [classes.show]: !!currentFilter,
              })}
              direction="row"
              justify="between"
              styles={{ height: '20px', position: 'absolute', top: 0, left: 0 }}
          >
            <Typography.Title noTransition secondary fromStart margin={0} styles={{
                padding: "0 4px"
            }}>
              {getShortFilterName(currentFilter.name)}
            </Typography.Title>

            <Typography.Medium
              onClick={onReset}
              margin={0}
              hoverStyles={{ cursor: 'pointer' }}
              color={
                filtersOn
                  ? theme.colors.link.default
                  : theme.colors.text.quaternary
              }
            >
              Сбросить
            </Typography.Medium>
          </Container.Flex>
        )}
    </Container.Flex>
  )
})

export default FiltersHeader
