import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from '../store/hooks'
import { resetSavedOrderNumber } from '../store/savedData'
import { ApiError, N } from '../store/types'
import { getUserData, setUserType } from '../store/userSlice'
import {
  CheckAuth,
  GetUserDetail,
  Login,
  Logout,
} from '../store/userSlice/routines'
import {
  DetailUser,
  LoginRequestData,
  Session,
  UserType,
} from '../store/userSlice/types'
import { redirectTo } from '../store/viewSlice'

interface IAuthContext {
  session: Session
  showLoginForm: boolean
  userData: N<DetailUser>
  toggleLoginForm: () => void
  hideLoginForm: () => void
  login: (data: LoginRequestData) => void
  logout: () => void
  checkAuth: () => void
  getUserDetail: () => void
  pending: boolean
  errors: {
    login: ApiError[]
    logout: ApiError[]
    checkAuth: ApiError[]
  }
  userPending: boolean
}

const AuthContext = createContext({
  session: {
    isAuthorised: false,
    auth_param_name: null,
    auth_param_value: null,
    userType: 'default',
  },
  showLoginForm: false,
  hideLoginForm: () => {},
  isAuthorised: false,
  userData: null,
  pending: false,
  toggleLoginForm: () => {},
  getUserDetail: () => {},
  login: (data: LoginRequestData) => {},
  logout: () => {},
  checkAuth: () => {},
  errors: {
    login: [],
    logout: [],
    checkAuth: [],
  },
  userPending: false,
} as IAuthContext)

export const useAuthData = () => {
  return useContext(AuthContext)
}

export default function AuthWrapper({ children }: PropsWithChildren<{}>) {
  const [showLoginForm, setShowLoginForm] = useState(false)

  const sessionInterval = useRef<ReturnType<typeof setInterval>>()

  const dispatch = useDispatch()

  const user = useSelector(getUserData)
  const userApi = useAppSelector(state => state.user.api)
  const userData = useAppSelector(state => state.user.userData)

  const userPending =
    userApi.login.pending ||
    userApi.login.pending ||
    userApi.getDetail.pending ||
    userApi.updateUser.pending

  const toggleLoginForm = useCallback(() => {
    setShowLoginForm(prev => !prev)
  }, [])

  const logout = useCallback(() => {
    dispatch(Logout.request())
    dispatch(resetSavedOrderNumber())
    dispatch(redirectTo('/'))
  }, [dispatch])

  const login = useCallback(
    (data: LoginRequestData) => {
      dispatch(Login.request(data))
    },
    [dispatch],
  )

  const checkAuth = useCallback(() => {
    if (process.env.NODE_ENV === 'development') {
      const { session } = user
      const { auth_param_name, auth_param_value } = session

      if (auth_param_name && auth_param_value) dispatch(CheckAuth.request())
    } else {
      dispatch(CheckAuth.request())
    }
  }, [])

  const getUserDetail = useCallback(() => {
    dispatch(GetUserDetail.request())
  }, [dispatch])

  const pending = user.api.pending

  const hideLoginForm = useCallback(() => {
    setShowLoginForm(false)
  }, [setShowLoginForm])

  useEffect(() => {
    if (user.session.isAuthorised) {
      checkAuth()
    }
  }, [user.session.isAuthorised])

  useEffect(() => {
    if (user.login) {
      getUserDetail()
    }
  }, [getUserDetail, user.login])

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (!user.session.auth_param_value || !user.session.auth_param_name) {
        dispatch(Login.request())
      }
    }
  }, [user.session.auth_param_value])

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      sessionInterval.current = setInterval(checkAuth, 1000 * 60 * 5)

      return () => {
        if (sessionInterval.current) {
          clearInterval(sessionInterval.current)
        }
      }
    } else {
      dispatch(CheckAuth.request())
    }
  }, [])

  useEffect(() => {
    if (!user.session.isAuthorised) {
      dispatch(setUserType(UserType.default))
    }
  }, [user.session.isAuthorised])

  return (
    <AuthContext.Provider
      value={{
        showLoginForm,
        session: user.session,
        userData: userData,
        toggleLoginForm,
        hideLoginForm,
        logout,
        login,
        pending,
        errors: {
          login: user.api.login.errors,
          logout: user.api.logout.errors,
          checkAuth: user.api.checkAuth.errors,
        },
        checkAuth,
        getUserDetail,
        userPending,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
