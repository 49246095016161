import SVG from '../SVG'
import { IconProps } from '../types'

const StatusCorresponds = (props: IconProps) => {
  const width = props.width ?? 16
  const height = props.height ?? 16
  return (
    <SVG
      {...props}
      width={width}
      height={height}
      styles={{
        color: props.color ?? '#78C25E',
      }}
    >
      <g clipPath="url(#clip0_5337_23228)">
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.58769 6.78805C9.90011 6.47563 9.90011 5.9691 9.58769 5.65668C9.27527 5.34426 8.76874 5.34426 8.45632 5.65668L5.46645 8.64655L4.25436 7.43446C3.94194 7.12204 3.43541 7.12204 3.12299 7.43446C2.81057 7.74688 2.81057 8.25341 3.12299 8.56583L4.90076 10.3436C5.05079 10.4936 5.25428 10.5779 5.46645 10.5779C5.67862 10.5779 5.88211 10.4936 6.03214 10.3436L9.58769 6.78805ZM12.7876 6.78805C13.1001 6.47563 13.1001 5.9691 12.7876 5.65668C12.4752 5.34426 11.9687 5.34426 11.6563 5.65668L8.10072 9.21223C7.7883 9.52465 7.7883 10.0312 8.10072 10.3436C8.41314 10.656 8.91967 10.656 9.23209 10.3436L12.7876 6.78805Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5337_23228">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </SVG>
  )
}

export default StatusCorresponds
