import { Card, Container, Typography } from '../ui'
import RostokImage from '../../assets/rostock60.png'
import { useTheme } from 'styled-components'
import { useAuthData } from '../../contexts/auth'
import SimpleImage from '../ui/Image/SimpleImage'

const ProfileRostokCount = () => {
  const { userData } = useAuthData()

  const theme = useTheme()

  return (
    <Card
      padding={28}
      styles={{
        backgroundColor: theme.colors.background.first50,
        visibility: 'hidden',
      }}
    >
      <Container.Flex direction="row">
        <SimpleImage
          src={RostokImage}
          alt="rostok"
          width={60}
          height={60}
          margin={'0 12px 0 0'}
          noBasePath
        />

        <Container.Flex alignItems="start">
          <Typography.Small margin={0} textAlign="start">
            Всего собрано ростков
          </Typography.Small>
          <Typography.Title margin={0} textAlign="start">
            {'x '}
            {userData?.rostokCount ?? 0}
          </Typography.Title>
        </Container.Flex>
      </Container.Flex>
    </Card>
  )
}

export default ProfileRostokCount
