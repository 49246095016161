import { Link } from 'react-router-dom'
import { MenuItemData } from '../../types'
import styled from 'styled-components'
import { Icon, Typography } from '../ui'
import { FC, PropsWithChildren } from 'react'
import { useAuthData } from '../../contexts/auth'

const MenuLink = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: ${props => props.theme.colors.link.contrast};
  text-decoration: none;
  transition: color 200ms;

  * {
    text-decoration: none;
  }
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.link.hover};
  }
  &:active {
    color: ${props => props.theme.colors.link.pressed};
  }

  &:focus {
    color: ${props => props.theme.colors.link.default};
  }
`

const linkStyle = {
  width: '100%',
  textDecoration: 'none',
}

const Text: FC<PropsWithChildren<any>> = ({ children }) => {
  return (
    <Typography.Button
      noTransition
      styles={{
        color: 'currentcolor',
        marginLeft: '21px',
      }}
    >
      {children}
    </Typography.Button>
  )
}

export default function MenuItem({ text, svgSprite, link }: MenuItemData) {
  const { logout } = useAuthData()

  if (!link) {
    return (
      <MenuLink
        className="menu-link menu-link__disabled"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <Icon name={svgSprite} />
        <Text>{text}</Text>
      </MenuLink>
    )
  }

  if (link.startsWith('http')) {
    return (
      <MenuLink
        className="menu-link"
        onClick={() => {
          window.location.replace(link)
        }}
      >
        <Icon name={svgSprite} />
        <Text>{text}</Text>
      </MenuLink>
    )
  }

  switch (link) {
    case 'logout':
      return (
        <MenuLink className="menu-link" onClick={logout}>
          <Icon name={svgSprite} />
          <Text>{text}</Text>
        </MenuLink>
      )
    default:
      return (
        <Link style={linkStyle} to={link}>
          <MenuLink className="menu-link">
            <Icon name={svgSprite} />
            <Text>{text}</Text>
          </MenuLink>
        </Link>
      )
  }
}
