import { ComponentType, ReactElement, useState } from 'react'
import { useReadOTP } from 'react-read-otp'

export interface OtpProps {
  otp: string
}

export const withOtp =
  <T extends object>(Component: ComponentType<T & OtpProps>) =>
  (props: T): ReactElement => {
    const [otp, _setOtp] = useState('')
    useReadOTP(_setOtp)

    return <Component {...props} otp={otp} />
  }
