import InputMask from 'react-input-mask'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Container, Typography } from '../'

interface ISafePackageInput {
  afterChange?: (v: string) => any
  onComplete?: SafePackageOnComplete
  pending?: boolean
}

export type SafePackageOnComplete = ({ value, reset } : { value: string, reset: () => void }) => void;

export default function SafePackageInput (props: ISafePackageInput) {
  const [value, setValue] = useState('');
  const [labelValue, setLabelValue] = useState("0/6")

  const inputRef = useRef<any>(null)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const returnCaret = useCallback(() => {
    if (inputRef.current) {
      const value = inputRef.current.value;
      const numbers = value.match(/\d/g);
      const lastNumber = numbers?.pop();
      const index = lastNumber ? value.lastIndexOf(lastNumber) : 0;
      const plusOneIndexes = [1, 4];
      const add = plusOneIndexes.includes(index) ? 2 : 1;
      const processIndex = index ? index + add : 0;
      inputRef.current.setSelection(processIndex, processIndex)
    }
  }, [])

  const reset = useCallback(() => {
    setValue('')
  }, [setValue])

  useEffect(() => {
    const cleanValue = value.replace(/ /g, '');
    props.afterChange && props.afterChange(cleanValue);
    setLabelValue(`${cleanValue.length}/6`);
    if (cleanValue.length === 6) {
      props.onComplete && props.onComplete({ value: cleanValue, reset });
    }
    if (value.length === 0) {
      returnCaret()
    }
  }, [value])

  return (
    <Container.Flex fullWidth className="relocation-input-wrapper">
      <InputMask
        ref={inputRef}
        mask={'99 99 99'}
        value={value}
        onChange={onChange}
        maskChar=" "
        className='relocation-input'
        placeholder="Номер сейф-пакета"
        onClick={returnCaret}
      />
      <Typography.Tertiary className='relocation-input-label'>{labelValue}</Typography.Tertiary>
    </Container.Flex>
  )
}
