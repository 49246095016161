import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Card from '../Card'
import Container from '../Container'
import { Order } from '../../../store/orderSlice/types'
import Loader from '../Loader/Loader'
import Typography from '../Typography'
import Api from '../../../apinew'
import { getErrorString } from '../../../helpers/getErrorString'
import Progress from '../Progress/Progress'
import OfferDevice from '../../Offer/OrderDevice'

type ImageState = [File | null, File | null]

interface IUploadImagesContext {
  itemNumber: string
  orderNumber: string
  images: ImageState
  addImage: (index: 0 | 1, file: File) => void
  removeImage: (index: 0 | 1) => void
  resizeCard: () => void
  submitState: {
    pending: boolean
    error: string
    success: boolean
  }
  submit: () => void
}

const UploadImagesContext = createContext({} as IUploadImagesContext)

export const useUploadImages = () => useContext(UploadImagesContext)

export const UploadImagesContextWrapper = ({
  children,
  itemNumber,
  orderNumber,
}: PropsWithChildren<{ itemNumber: string; orderNumber: string }>) => {
  const [_itemNumber] = useState(itemNumber)
  const [_orderNumber] = useState(orderNumber)
  const [images, setImages] = useState<ImageState>([null, null])
  const [order, setOrder] = useState<Order | null>(null)
  const [getOrderPending, setGetOrderPending] = useState(false)
  const [getOrderError, setGetOrderError] = useState('')
  const [submitState, setSubmitState] = useState({
    pending: false,
    error: '',
    success: false,
  })

  useEffect(() => {
    const getData = async () => {
      setGetOrderPending(true)
      const api = new Api()

      try {
        const response = await api.getOrder({ number: orderNumber })
        if (response.status === 'success' && response.data) {
          setOrder(response.data)
        } else if (response.errors.length) {
          setGetOrderError(getErrorString(response.errors))
        }
      } catch (err: any) {
        setGetOrderError(err.message)
      } finally {
        setGetOrderPending(false)
      }
    }

    getData()
  }, [])

  const submit = useCallback(() => {
    const addFiles = async () => {
      setSubmitState(prev => ({ ...prev, pending: true }))
      const api = new Api()

      try {
        const response = await api.orderAddFile(
          {
            images: { ...images },
            number: _orderNumber,
            itemNumber: _itemNumber,
          },
        )

        if (response.errors.length) {
          setSubmitState(prev => ({
            ...prev,
            error: getErrorString(response.errors),
          }))
        } else if (response.status === 'success') {
          setSubmitState(prev => ({ ...prev, success: true }))
        }
      } catch (err: any) {
        console.log(err.code)
        setSubmitState(prev => ({ ...prev, error: err.message }))
      } finally {
        setSubmitState(prev => ({ ...prev, pending: false }))
      }
    }

    if (images[0] && images[1]) {
      addFiles()
    }
  }, [Api, images])

  const cardRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const addImage = useCallback(
    (index: 0 | 1, file: File) => {
      setImages(prev => {
        const newImages = [...prev] as ImageState
        newImages[index] = file
        return newImages
      })
    },
    [setImages],
  )

  const removeImage = useCallback(
    (index: 0 | 1) => {
      setImages(prev => {
        const newImages = [...prev] as ImageState
        newImages[index] = null
        return newImages
      })
    },
    [setImages],
  )

  const resize = useCallback(() => {
    if (contentRef.current && cardRef.current) {
      cardRef.current.style.height =
        contentRef.current.offsetHeight + 28 * 2 + 'px'
    }
  }, [])

  const orderItem = useMemo(() => {
    if (order) {
      const item = order.items.find(el => el.itemNumber === itemNumber)
      return item || null
    }
    return null
  }, [order, _itemNumber])

  const currency = useMemo(() => {
    if (order) {
      return order.currency
    }
    return null
  }, [order])

  const progress = useMemo(() => {
    if (!images[0]) {
      return 0
    }
    if (images[0] && !images[1]) {
      return 0.5
    }

    return 1
  }, [images])

  if (getOrderPending) {
    return <Loader />
  }

  if (getOrderError) {
    return <Typography.Error>{getOrderError}</Typography.Error>
  }

  return (
    <UploadImagesContext.Provider
      value={{
        itemNumber: _itemNumber,
        orderNumber: _orderNumber,
        images,
        addImage,
        removeImage,
        resizeCard: resize,
        submit,
        submitState,
      }}
    >
      <Card padding={28} ref={cardRef} maxWidth={344}>
        <Container.Flex ref={contentRef} fullWidth gap={16}>
          <Progress progress={progress} />
          {!!orderItem && !!currency && (
            <OfferDevice
              data={orderItem}
              givenAnswers={orderItem.answers as any}
              currency={currency}
              withDelete={false}
              resize={true}
              setResize={(v: boolean) => {resize()}}
              showResult={false}
            />
          )}

          {children}
        </Container.Flex>
      </Card>
    </UploadImagesContext.Provider>
  )
}
