import Container from '../Container'
import Typography from '../Typography'
import styled, { useTheme } from 'styled-components/macro'
import { usePopupImage } from '../../../hooks/usePopupImage'
import SimpleImage from './SimpleImage'

interface IImagesContainer {
  images: string[]
  title: string
}

const ImageContainer = styled.div`
  height: 64.5px;
  width: 48.77px;
  background-color: #ffffff;
  margin-right: 12px;
  position: relative;
  transition: 100ms ease;
  opacity: 1;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

export default function ImagesContainer(props: IImagesContainer) {
  const theme = useTheme()

  const { images, title } = props

  const { setImage } = usePopupImage()

  return (
    <Container.Flex
      fullWidth
      padding={12}
      alignItems="start"
      verticalGap={8}
      styles={{
        border: `1px dashed ${theme.colors.icon.quaternary}`,
        borderRadius: '12px',
      }}
    >
      <Typography.Small fromStart>{title}</Typography.Small>

      <Container.Flex fullWidth direction="row">
        {images.map((el, key) => {
          return (
            <ImageContainer key={key}>
              <SimpleImage
                styles={{ width: '100%', height: '100%' }}
                noBasePath={el.startsWith('/') ? undefined : true}
                src={`${el.replace('blob:http://localhost:3000', '')}`}
                alt={`image${key}`}
                onClick={() => setImage(el)}
              />
            </ImageContainer>
          )
        })}
      </Container.Flex>
    </Container.Flex>
  )
}
