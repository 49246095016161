import styled from "styled-components"

export const CardWrapper = styled.div`
  position: relative;
  border-radius: 20px;
  flex-shrink: 0;
  width: 100%;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  padding: 28px 32px;
  background-color: ${props =>  props.theme.colors.background.contrast60};
  transition: all 200ms ease-in;
`