import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Bearer from "../components/Layout/Bearer";
import PageLoader from "../components/Layout/PageLoading";
import ReadyItem from "../components/ReadyItem";
import { getUserData } from "../store/userSlice";
import { UserType } from "../store/userSlice/types";
import PageLayout from "./PageLayout";
import { Container } from "../components/ui";

export default function ReadyPage () {
    const { itemNumber } = useParams();
    const { userData, api, session } = useSelector(getUserData);

    if (api.pending) {
        return <PageLoader />
    }

    if (!session.isAuthorised) {
        return <Bearer />
    }

    if (userData?.userType === UserType.client) {
        return <Bearer />
    }

    return (<PageLayout withProfile>
        <Container.CardsGrid twoColumns={userData?.userType === UserType.partner || userData?.userType === UserType.employee}>
            <ReadyItem itemNumber={itemNumber} />
        </Container.CardsGrid>
    </PageLayout>)

    

}