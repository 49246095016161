import { ResponseData } from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { takeLeading, call, put, select } from 'redux-saga/effects'
import Api from '../../apinew'
import {
  AddUserRequestData,
  AddUserResponseData,
  ChangePasswordRequestData,
  ChangePasswordResponseData,
  CheckAuthRequestData,
  CheckAuthResponseData,
  LoginRequestData,
  LoginResponseData,
  LogoutRequestData,
  LogoutResponseData,
  RestorePasswordRequestData,
  RestorePasswordResponseData,
  UpdateUserResponseData,
  UpdateUserRequestData,
  SMSLoginRequestData,
  SMSLoginResponseData,
} from './types'
import {
  Login,
  Logout,
  CheckAuth,
  ChangePassword,
  AddUserData,
  UpdateUserData,
  GetUserDetail,
  GetUsersList,
  RestorePassword,
  SMSLogin,
  SendOrderLink,
} from './routines'
import { RootState } from '..'
import { replaceTo } from '../viewSlice'

const api = new Api()

function* loginRequestWorker({
  payload,
}: PayloadAction<LoginRequestData | undefined>) {
  const state: RootState = yield select()
  const session = state.user.session
  const response: ResponseData<LoginResponseData> = yield payload
    ? call(api.login, payload)
    : call(api.getSession, session)
  if (response.status === 'success') {
    yield put(Login.success(response.data))
    if (payload && !payload.noRedirect) {
      yield put(replaceTo(response.data?.redirectLink ?? '/'))
    }
  }
  if (response.status === 'error') {
    yield put(Login.failure(response.errors))
  }

  yield put(Login.fulfill())
}

function* logoutRequestWorker({ payload }: PayloadAction<LogoutRequestData>) {
  const response: ResponseData<LogoutResponseData> = yield call(
    api.logout,
    payload,
  )
  if (response.status === 'success') {
    yield put(Logout.success(response.data))
  }
  if (response.status === 'error') {
    yield put(Logout.failure(response.errors))
  }

  yield put(Logout.fulfill())
}

function* checkAuthRequestWorker({
  payload,
}: PayloadAction<CheckAuthRequestData>) {
  const state: RootState = yield select()
  const userData = state.user.userData
  const response: CheckAuthResponseData = yield call(api.checkAuth, payload)
  if (response.status === 'success') {
    yield put(CheckAuth.success(response.data))
  }
  if (response.status === 'error') {
    yield put(CheckAuth.failure(response.errors))
  }

  yield put(CheckAuth.fulfill())

  if (!userData && response.data?.isAuthorised) {
    yield put(GetUserDetail.request())
  }
}

function* changePasswordRequestWorker({
  payload,
}: PayloadAction<ChangePasswordRequestData>) {
  const response: ResponseData<ChangePasswordResponseData> = yield call(
    api.changePassword,
    payload,
  )
  if (response.status === 'success') {
    yield put(ChangePassword.success(response.data))
  }
  if (response.status === 'error') {
    yield put(ChangePassword.failure(response.errors))
  }

  yield put(ChangePassword.fulfill())
}

function* restorePasswordRequestWorker({
  payload,
}: PayloadAction<RestorePasswordRequestData>) {
  const response: ResponseData<RestorePasswordResponseData> = yield call(
    api.restorePassword,
    payload,
  )
  if (response.status === 'success') {
    yield put(RestorePassword.success(response.data))
  }
  if (response.status === 'error') {
    yield put(RestorePassword.failure(response.errors))
  }

  yield put(RestorePassword.fulfill())
}

function* addUserDataRequestWorker({
  payload,
}: PayloadAction<AddUserRequestData>) {
  const response: ResponseData<AddUserResponseData> = yield call(
    api.addUserData,
    payload,
  )
  if (response.status === 'success') {
    yield put(AddUserData.success(response.data))
  }
  if (response.status === 'error') {
    yield put(AddUserData.failure(response.errors))
  }

  yield put(AddUserData.fulfill())
}

function* updateUserDataRequestWorker({
  payload,
}: PayloadAction<UpdateUserRequestData>) {
  const response: ResponseData<UpdateUserResponseData> = yield call(
    api.updateUserData,
    payload,
  )
  if (response.status === 'success') {
    yield put(UpdateUserData.success(response.data))
  }
  if (response.status === 'error') {
    yield put(UpdateUserData.failure(response.errors))
  }

  yield put(UpdateUserData.fulfill())
}

function* getUserDetailWorker() {
  const state: RootState = yield select()
  const session = state.user.session
  const response: ResponseData<LoginResponseData> = yield call(
    api.getUserDetail,
    session,
    { login: state.user.login },
  )
  if (response.status === 'success') {
    yield put(GetUserDetail.success(response.data))
  }
  if (response.status === 'error') {
    yield put(GetUserDetail.failure(response.errors))
  }

  yield put(GetUserDetail.fulfill())
}

function* getUsersListWorker() {
  const state: RootState = yield select()
  const session = state.user.session
  const response: ResponseData<LoginResponseData> = yield call(
    api.getUsersList,
    session,
  )
  if (response.status === 'success') {
    yield put(GetUsersList.success(response.data))
  }
  if (response.status === 'error') {
    yield put(GetUsersList.failure(response.errors))
  }

  yield put(GetUsersList.fulfill())
}

function* SMSLoginWorker({ payload }: PayloadAction<SMSLoginRequestData>) {
  const response: ResponseData<SMSLoginResponseData> = yield call(
    api.smsLogin,
    payload,
  )

  if (response.status === 'success') {
    yield put(SMSLogin.success(response.data))
  }

  if (response.status === 'error') {
    yield put(SMSLogin.failure(response.errors))
  }

  yield put(SMSLogin.fulfill())
}

function* sendOrderLinkWorker({ payload }: PayloadAction<string>) {
  const response: ResponseData<{ status: 'success' | 'error' }> = yield call(
    api.sendOrderLink,
    { number: payload },
  )

  if (response.status === 'success') {
    yield put(SendOrderLink.success())
  }

  if (response.status === 'error') {
    yield put(SendOrderLink.failure(response.errors))
  }

  yield put(SendOrderLink.fulfill())
}

export function* userSagas() {
  yield takeLeading(Login.REQUEST, loginRequestWorker)
  yield takeLeading(Logout.REQUEST, logoutRequestWorker)
  yield takeLeading(CheckAuth.REQUEST, checkAuthRequestWorker)
  yield takeLeading(ChangePassword.REQUEST, changePasswordRequestWorker)
  yield takeLeading(RestorePassword.REQUEST, restorePasswordRequestWorker)
  yield takeLeading(AddUserData.REQUEST, addUserDataRequestWorker)
  yield takeLeading(UpdateUserData.REQUEST, updateUserDataRequestWorker)
  yield takeLeading(GetUserDetail.REQUEST, getUserDetailWorker)
  yield takeLeading(GetUsersList.REQUEST, getUsersListWorker)
  yield takeLeading(SMSLogin.REQUEST, SMSLoginWorker)
  yield takeLeading(SendOrderLink.REQUEST, sendOrderLinkWorker)
}
