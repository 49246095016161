import { withTheme } from './hooks/withTheme'
import styled, { createGlobalStyle } from 'styled-components/macro'
import UploadFiles from './contexts/uploadFiles'
import Routes from './pages/Routes'
import { withLayout } from './components/Layout/withLayout'
import { withAuth } from './components/Layout/withAuth'
import { useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'
import OTPWrapper from './contexts/OPT'

const GlobalStyles = createGlobalStyle`
body::-webkit-scrollbar {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: ${props => props.theme.colors.scrollBar.background};
}

body::-webkit-scrollbar-thumb {
  background-color: ${props => props.theme.colors.scrollBar.slider};
}

body::-webkit-scrollbar-thumb:hover {

}

`

const LowerMenuWrapper = styled.div<{margin?: string}>`
  position: sticky;
  bottom: 24px;
  gap: 16px;
  height: 64px;
  margin: ${props => props.margin || "0 auto 0"};
  bottom: 24px;
  width: max-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(var(--vh, 1vh) * 100 - 112px);
`

function App() {
  useEffect(() => {
    const resize = () => {
      const newVh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${newVh}px`)
    }

    window.addEventListener('resize', resize)
    resize()
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  return (
    <CookiesProvider>
      <OTPWrapper>
        <UploadFiles>
          <GlobalStyles />
          <AppWrapper>
            <Routes />
            <LowerMenuWrapper id="custom-lower-menu" />
          </AppWrapper>
        </UploadFiles>
      </OTPWrapper>
    </CookiesProvider>
  )
}

export default withTheme(withAuth(withLayout(App)))
