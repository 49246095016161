import SVG from '../SVG'
import { IconProps } from '../types'

const CheckCircleIcon = (props: IconProps) => {
  return (
    <SVG {...props} width={props.width ?? 16} height={props.height ?? 16}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6 8C1.6 4.46538 4.46538 1.6 8 1.6C11.5346 1.6 14.4 4.46538 14.4 8C14.4 11.5346 11.5346 14.4 8 14.4C4.46538 14.4 1.6 11.5346 1.6 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM10.9657 6.96569C11.2781 6.65327 11.2781 6.14673 10.9657 5.83431C10.6533 5.5219 10.1467 5.5219 9.83431 5.83431L7.2 8.46863L6.16569 7.43431C5.85327 7.1219 5.34673 7.1219 5.03431 7.43431C4.7219 7.74673 4.7219 8.25327 5.03431 8.56569L6.63431 10.1657C6.94673 10.4781 7.45327 10.4781 7.76569 10.1657L10.9657 6.96569Z"
        fill="currentColor"
      />
    </SVG>
  )
}

export default CheckCircleIcon
