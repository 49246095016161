import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useCallback } from 'react'
import {
  GetQuestions,
  setStep,
  restoreOffer,
  getOfferData,
  setTreeProps,
  makeAdditionAction,
  uploadImage,
  giveAnswerRequest,
  setAdditionalAction,
  addNewDevice,
  setQuestionOrder,
  setCombinationCode,
  setQuestionsTree,
  setStepRequest,
  clearLastAnswer,
  setTreeQuestions,
  setPauseQuestions,
} from '../store/offerSlice'
import {
  GivenAnswer,
  ImageFile,
  OfferSteps,
  SetTreeDataProps,
  TreeQuestion,
} from '../store/offerSlice/types'
import { getFromTree } from '../components/Offer/helpers/getFromTree'
import { useUploadFiles } from '../contexts/uploadFiles'
import { GetItemStatus } from '../store/orderSlice'
import { getViewData, hideOfferCard } from '../store/viewSlice'
import { useParams } from 'react-router-dom'
import { getAddImagesData } from '../helpers/getAddImagesData'

interface UseOfferDataProps {
  orderNumber?: string
  itemNumber?: string
  order?: true
}

export const useOfferData = (props: UseOfferDataProps) => {
  const { orderNumber, itemNumber } = useParams()
  const { order } = props
  const data = useSelector(getOfferData)
  const { redirectTo } = useSelector(getViewData)

  const {
    offer,
    orderData,
    isLoading,
    showResultFor,
    maxCombinationLength,
    maxProgressPosition,
    minProgressPosition,
    errorForm,
    savedOrderNumber,
  } = data

  const {
    questionsTree,
    questionsData,
    givenAnswers,
    step,
    questionOrder,
    treeQuestions,
  } = offer
  const _getQuestionsResult = offer.getQuestions.result
  const changeContent = offer.changeQuestionsContent
  const additionalAction = offer.givenAnswers.additionalAction
  const combinationCode = offer.givenAnswers.combinationCode

  const dispatch = useDispatch()
  const { files } = useUploadFiles()

  const changeStep = useCallback(
    (step: OfferSteps) => {
      dispatch(setStepRequest(step))
    },
    [dispatch],
  )

  const resetQr = useCallback(() => {
    if (typeof window !== 'undefined') {
      dispatch(setCombinationCode())
      dispatch(setQuestionsTree(null))
      dispatch(clearLastAnswer())
      dispatch(setStep('questions'))
    }
  }, [window])

  const getItemStatus = useCallback(() => {
    dispatch(GetItemStatus.request({ orderNumber, itemNumber }))
  }, [orderNumber, itemNumber])

  const currentItem = useMemo(() => {
    if (orderData) {
      return orderData.items.find(item => item.itemNumber === itemNumber)
    }
  }, [orderData, itemNumber])

  const getQuestion = () => {
    if (step !== 'questions') return null
    if (order) return null
    if (redirectTo) return null
    if (orderNumber && !orderData) return null
    if (isLoading) return null
    if (_getQuestionsResult === 'error') {
      return null
    }

    if (!questionsTree) {
      fetchQuestions()
      return null
    }

    const { answers } = givenAnswers

    if (!questionsTree || !questionsData) return null

    const _setQuestionOrder = (order: number) =>
      dispatch(setQuestionOrder(order))

    const question = getFromTree(
      questionsTree,
      answers,
      _setTreeProps,
      _setQuestionOrder,
      (questions: TreeQuestion[]) => dispatch(setTreeQuestions(questions)),
      treeQuestions,
    )

    if (!question) return null
    const { questionId } = question

    const found = Object.entries(questionsData).find(([key, val]) => {
      return key === questionId || val.questionId === questionId
    })

    if (!found) return null

    const [questionKey, _question] = found

    return {
      ..._question,
      questionKey,
    }
  }

  const _setTreeProps = (props: SetTreeDataProps) => {
    const { combinationId, offerId, combinationCode } = props
    if (!Object.values(props).filter(el => !!el).length) return
    if (combinationId && combinationId !== givenAnswers.combinationId) {
      dispatch(setTreeProps({ combinationId }))
    }
    if (combinationCode && combinationCode !== givenAnswers.combinationCode) {
      dispatch(setTreeProps({ combinationCode }))
    }
    if (offerId && offerId !== givenAnswers.offerId) {
      dispatch(setTreeProps({ offerId }))
    }
    if (props.additionalAction && props.additionalAction !== additionalAction) {
      dispatch(setAdditionalAction(props.additionalAction))
    }
  }

  const _giveAnswer = (answer: GivenAnswer) => {
    dispatch(giveAnswerRequest(answer))
  }

  const _restoreOffer = () => {
    dispatch(restoreOffer())
  }

  const _setStep = useCallback(
    (step: OfferSteps) => () => {
      dispatch(hideOfferCard())
      dispatch(setStep(step))
    },
    [dispatch],
  )

  const fetchQuestions = () => {
    if (isLoading) return null
    if (order) return null

    if (additionalAction) {
      if (!(itemNumber && orderNumber && additionalAction === 'createOrder')) {
        dispatch(
          makeAdditionAction({
            action: additionalAction,
            images: getAddImagesData(files),
            itemNumber,
            orderNumber,
          }),
        )
        return
      }
    }

    if (!isLoading && !redirectTo)  {
      dispatch(GetQuestions.request({ orderNumber, itemNumber }))
    }
  }

  const _addNewDevice = useCallback(() => {
    dispatch(addNewDevice())
  }, [])

  const question = useMemo(getQuestion, [
    givenAnswers,
    offer.getQuestions.result,
    step,
    itemNumber,
    offer.givenAnswers.combinationCode,
    offer.givenAnswers.combinationId,
    offer.changeQuestionsContent,
    offer.pauseQuestions,
    redirectTo,
  ])

  const progress = useMemo(() => {
    if (currentItem?.status === 'F') return 0.75

    if (step === 'prePrice') return 0.5

    const base = !!itemNumber || step === 'summary' ? maxProgressPosition : 0

    const length = maxProgressPosition - minProgressPosition

    if (question && questionsData) {
      if (!maxCombinationLength) return base
      return questionOrder
        ? minProgressPosition + (length * questionOrder) / maxCombinationLength
        : minProgressPosition
    } else {
      return base
    }
  }, [
    question,
    itemNumber,
    questionsData,
    questionOrder,
    step,
    maxCombinationLength,
    maxProgressPosition,
    minProgressPosition,
    currentItem,
  ])

  const _uploadImage = (image: ImageFile) => {
    dispatch(uploadImage(image))
  }

  useEffect(() => {
    if (!question && !isLoading && step === 'questions') {
      fetchQuestions()
    }
  }, [question, step, savedOrderNumber])

  useEffect(() => {
    if (orderNumber && step !== 'questions' && step !== 'summary') {
      // changeStep("questions");
    }
  }, [step, orderNumber])

  useEffect(() => {
    if (additionalAction === 'addPhoto') {
      dispatch(setPauseQuestions(true))
    }
  }, [additionalAction, setPauseQuestions, dispatch])


  const formError = useMemo(() => {
    if (!errorForm) return null
    const { data, code } = errorForm
    if (!data) return null
    return data[code] ?? null
  }, [errorForm])

  const errorFormPending = useMemo(() => {
    return errorForm?.pending
  }, [errorForm])

  const returned = {
    ...offer,
    question,
    changeStep,
    giveAnswer: useCallback(_giveAnswer, []),
    restoreOffer: _restoreOffer,
    fetchQuestions,
    progress,
    setStep: _setStep,
    isLoading: isLoading,
    getQuestion,
    uploadImage: _uploadImage,
    changeContent,
    combinationCode,
    getItemStatus,
    currentItem,
    orderData,
    resetQr,
    addNewDevice: _addNewDevice,
    showResultFor,
    errorForm: formError,
    errorFormPending,
    pauseQuestions: offer.pauseQuestions,
    errorFormSubmitData: errorForm.submitFormData,
  }

  return returned
}
