import { ListDataProps } from './ListData'
import { useTheme } from 'styled-components'
import { Container, Typography, Icon, Input } from '../ui'
import styled from 'styled-components/macro'
import { bp } from '../../theme/media'
import { ChangeEvent, forwardRef } from 'react'
import SafePackageInput from '../ui/Input/SafePackageInput'

const Filter = styled.button`
  background-color: transparent;
  border: none;
  padding: 10px;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  flex-shrink: 0;
  margin: 0 2px;

  @media (max-width: 768px) {
    padding-right: 8px;
    margin-right: 0;
  }
`

const SearchWrapper = styled.div`
  width: 100%;
  max-width: 300px;

  @media (max-width: 768px) {
    max-width: none;
  }
`

type Props = Pick<
  ListDataProps<any>,
  'topButton' | 'title' | 'searchPlaceholder' | 'inputType'
> & {
  onSearch: () => void
  openFilters: null | (() => void)
  anyFiltersOn: boolean
  topButtonDisabled: boolean
  topButtonHref?: string
  setFrontFilterString?: (v: string | undefined) => void;
}

const ListDataTop = forwardRef<HTMLInputElement, Props>((props: Props, inputRef) => {

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/ /g, '')

    e.target.value = value
  }

  const theme = useTheme()
  return (
    <Container.Flex
      fullWidth
      direction="row"
      justify="between"
      breakpoints={{
        [bp.mobile]: {
          flexFlow: 'column',
          alignItems: 'stretch',
        },
      }}
    >
      <Container.Flex
        direction="row"
        styles={{ flexShrink: 0, alignItems: "baseline"}}
        gap={16}
        breakpoints={{
          [bp.mobile]: {
            marginBottom: '12px',
            justifyContent: 'space-between',
          },
        }}
      >
        <Typography.Title textAlign="start" margin={0} styles={{paddingRight: 0}}>{props.title}</Typography.Title>

        {!!props.topButtonHref && (
          <>
            {!props.topButtonDisabled && (
              <Typography.Link
                href={props.topButtonHref}
                onClick={props.topButton.onClick}
                noSelection
                padding={0}
                textAlign="start"
                styles={{
                  margin: '0',
                  fontSize: '14px',
                  lineHeight: '16px',
                }}
              >
                {props.topButton.title}
              </Typography.Link>
            )}
            {props.topButtonDisabled && (
              <Typography.Medium
                noSelection
                padding={0}
                textAlign="start"
                styles={{
                  margin: '0',
                  fontSize: '14px',
                  lineHeight: '16px',
                }}
                color={theme.colors.text.secondary}
              >
                {props.topButton.title}
              </Typography.Medium>
            )}
          </>
        )}

        {!props.topButtonHref && (
          <Typography.Medium
            noSelection
            padding={0}
            textAlign="start"
            styles={{
              margin: '0',
              fontSize: '14px',
              lineHeight: '16px',
            }}
            onClick={
              !props.topButtonDisabled ? props.topButton.onClick : undefined
            }
            color={
              props.topButtonDisabled
                ? theme.colors.text.secondary
                : theme.colors.link.default
            }
          >
            {props.topButton.title}
          </Typography.Medium>
        )}
      </Container.Flex>

      <Container.Flex direction="row" justify="end" styles={{ flexGrow: 1 }}>
        <SearchWrapper>
          {props.inputType !== 'pack' && <Input
            ref={inputRef}
            placeholder={props.searchPlaceholder}
            icon="search"
            iconPosition="right"
            onChange={onChange}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                props.onSearch()
              }
            }}
            iconClick={props.onSearch}
            styles={{
              width: '100%',
            }}
          />}
          {props.inputType === 'pack' && <SafePackageInput afterChange={props.setFrontFilterString}/>}
        </SearchWrapper>
        {props.openFilters && <Filter onClick={props.openFilters}>
          <Icon
            name={props.anyFiltersOn ? 'filters-on' : 'filters-off'}
            color={theme.colors.text.secondary}
          />
        </Filter>}
      </Container.Flex>
    </Container.Flex>
  )
})

export default ListDataTop