import {
  forwardRef,
  Fragment,
  MouseEvent,
  useEffect,
  useRef,
  ChangeEvent,
} from 'react'
import { styled } from '../../../helpers/styled'
import { getCommonProps, Props } from '../../types'
import Icon from '../Icon'
import { Icons } from '../Icon/types'
import { collectButtonStyles } from './styles'
import { useNavigate } from 'react-router-dom'
import { collectErrors } from '../../../helpers/collectFileErrors'
import { useDispatch } from 'react-redux'
import { setCardError } from '../../../store/offerSlice'

export type ButtonVariants = 'primary' | 'disabled' | 'outline' | 'danger' | 'purple'

const Root = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${props => collectButtonStyles(props)}
  ${props => getCommonProps(props)};
  ${props => {
    if (props.disabled) {
      return {
        backgroundColor: props.theme.colors.button.disable,
        color: props.theme.colors.text.contrast,
        borderColor: props.theme.colors.button.disable,
        '&:hover': {
          backgroundColor: props.theme.colors.button.disable,
          color: props.theme.colors.text.contrast,
          borderColor: props.theme.colors.button.disable,
        },
      }
    }
  }}
`

const Input = styled.input`
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: none;
`

export type ButtonProps = Props<{
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void
  onFileInput?: (file: File) => void
  onFocus?: () => void
  variant?: ButtonVariants
  withLoader?: true
  pending?: boolean
  icon?: Icons
  square?: true
  fileInput?: true
  link?: string
  disabled?: boolean
  submit?: true
  _onError?: (errors: string[]) => void
}>

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    const {
      children,
      withLoader,
      pending,
      icon,
      fileInput,
      onFileInput,
      link,
      _onError,
    } = props
    const buttonProps = { ...props }
    delete buttonProps.onFileInput
    delete buttonProps._onError
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const buttonRef = useRef<HTMLButtonElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (buttonRef.current) {
        buttonRef.current.blur()
      }
      if (inputRef.current) {
        inputRef.current.blur()
      }
    })

    const onClick = () => {
      if (link) {
        navigate(link)
      }
      if (fileInput) {
        if (inputRef.current) {
          inputRef.current.click()
        }
      }
      if (!props.pending) {
        props.onClick && props.onClick()
      }
    }

    const onChange = (e: ChangeEvent<any>) => {
      const file = e.target.files?.[0]

      if (file) {
        const errors = collectErrors([file])
        if (errors.length) {
          dispatch(setCardError(errors.join('. ')))
          _onError && _onError(errors)
        } else {
          e.target.value = null
          dispatch(setCardError(''))
          onFileInput && onFileInput(file)
        }
      }
    }

    return (
      <Fragment>
        <Root
          onFocus={props.onFocus}
          disabled={props.disabled}
          ref={ref ?? buttonRef}
          {...buttonProps}
          onClick={onClick}
          type={props.submit ? 'submit' : 'button'}
        >
          {withLoader && pending && (
            <Icon
              name="loading"
              styles={{
                marginRight: '4px',
                verticalAlign: 'middle',
                transform: 'translateY(-2px)',
              }}
              width={20}
              height={20}
            />
          )}
          {icon && !(withLoader && pending) && (
            <Icon
              name={icon}
              styles={{
                marginRight: '4px',
                verticalAlign: 'middle',
                transform: 'translateY(-2px)',
              }}
              width={20}
              height={20}
            />
          )}
          {!!children && children}
        </Root>

        {fileInput && (
          <Input
            type="file"
            ref={inputRef}
            onChange={onChange}
            hidden
            autoFocus={true}
          />
        )}
      </Fragment>
    )
  },
)

export default Button
