import { styled } from '../../helpers/styled'
import { RadioBox } from '../Profile/OrdersList/filters/PeriodFilters'
import { Container, Typography } from '../ui'
import { FiltersPart, useFiltersData } from './FiltersContext'
import { RowCss } from './FiltersMain'

const Checkbox = styled.div<{ checked: boolean }>`
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  &::after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: ${props =>
      props.checked
        ? `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='18' height='18' rx='2' fill='%2378C25E'/%3E%3Cpath d='M4.5 10.5L8 14L15 6' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A")`
        : `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='2' width='16' height='16' rx='1' stroke='%237A7A7A' stroke-width='2'/%3E%3C/svg%3E%0A")`};
    background-size: cover;
    background-repeat: no-repeat;
  }
`

export default function FiltersList({ filter }: { filter: FiltersPart }) {
  const { isChecked, onFilterChange, onRadioFiltersChange } = useFiltersData()

  if (filter?.valueType === 'list') {
    return (
      <Container.Flex
        fullWidth
        verticalGap={24}
        styles={{ paddingLeft: '4px' }}
      >
        {filter.items.map(item => {
          const value = isChecked(item.value)
          return (
            <Container.Flex
              fullWidth
              direction="row"
              justify="between"
              css={RowCss}
              key={item.value}
              onClick={() => onFilterChange(item.value)(!value)}
            >
              <Typography.Main
                margin={0}
                styles={{ color: 'currentColor' }}
                noTransition
              >
                {item.name}
              </Typography.Main>
              <Checkbox checked={value} />
            </Container.Flex>
          )
        })}
      </Container.Flex>
    )
  }

  if (filter?.valueType === 'radio') {
    return (
      <Container.Flex
        fullWidth
        verticalGap={24}
        styles={{ paddingLeft: '4px' }}
      >
        {filter.items.map(item => {
          const value = isChecked(item.value)
          return (
            <Container.Flex
              fullWidth
              direction="row"
              justify="between"
              css={RowCss}
              key={item.value}
              onClick={() =>
                onRadioFiltersChange(
                  item.value,
                  filter.items
                    .filter(el => el.value !== item.value)
                    .map(el => el.value),
                )(!value)
              }
            >
              <Typography.Main
                margin={0}
                styles={{ color: 'currentColor' }}
                noTransition
              >
                {item.name}
              </Typography.Main>
              <RadioBox checked={value} />
            </Container.Flex>
          )
        })}
      </Container.Flex>
    )
  }

  return null
}
