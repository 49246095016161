import { ChangeEvent, Fragment, useCallback, useEffect, useState } from 'react'
import { Button, Container, Typography } from '../../../ui'
import InputInfo from '../FormComponents/InputInfo'
import { SelectInput, SelectInputWrapper } from '../FormComponents/SelectInput'
import SMSCode from '../FormComponents/SMSCode'
import { TextInput } from '../FormComponents/TextInput'
import { useOrderingData } from './OrderingContext'
import OrderFormPagination from './OrderingFormPagination'
import { TRANSITION_TIME } from './OrderingFormWrapper'

interface Props {
  resize: () => void
}

export default function OrderingFormPart({ resize }: Props) {
  const {
    formPart,
    register,
    watch,
    setValue,
    step,
    getValues,
    className,
    opacity,
    errors,
    smsStatus
  } = useOrderingData()

  const { title } = formPart
  const [isValidated, setIsValidated] = useState(false)

  useEffect(resize)

  const validate = useCallback(
    (data: any) => {
      const _isValidated = formPart.fields.every(field => {
        if (field.pattern) {
          const fieldValue = data[field.key]
          return field.pattern.test(fieldValue)
        }
        return true
      })

      if (isValidated !== _isValidated) {
        setIsValidated(_isValidated)
      }
    },
    [isValidated, setIsValidated, formPart, step],
  )

  useEffect(() => {
    const sub = watch(validate)
    return () => {
      sub.unsubscribe()
    }
  }, [watch, formPart, setIsValidated, isValidated])

  useEffect(() => {
    const values = getValues()
    validate(values)
  }, [getValues, step])

  return (
    <Container.Flex
      className={className}
      fullWidth
      alignItems="start"
      verticalGap={16}
      styles={{
        height: 'max-content',
        opacity: 0,
      }}
    >
      <Container.Flex
        fullWidth
        alignItems="start"
        verticalGap={16}
        styles={{
          opacity,
          height: 'max-content',
          transition: `${TRANSITION_TIME * 2}ms`,
        }}
      >
        <Typography.Title secondary margin="0 0 8px" padding="0 4px">
          {title}
        </Typography.Title>

        {formPart.fields.map(field => {
          const {
            key,
            defaultValue,
            length,
            pattern,
            placeHolder,
            onChange,
            onFocus,
            type,
            options,
          } = field

          const _onChange = (e: ChangeEvent<any>) => {
            if (onChange) {
              const value = onChange(e)
              setValue(key.toString(), value)
            }
            setValue(key.toString(), e.target.value)
          }

          if (type === 'text' && key !== 'phone') {
            return (
              <Fragment>
                <Container.Flex
                  fullWidth
                  alignItems="end"
                  relative
                  styles={{
                    order: key === 'location' ? '6' : 'unset',
                  }}
                >
                  <TextInput
                    key={key.toString()}
                    type={type}
                    {...register(key.toString(), {
                      pattern,
                      maxLength: length,
                    })}
                    autoComplete={"false"}
                    defaultValue={defaultValue}
                    length={length}
                    placeholder={placeHolder}
                    onFocus={onFocus}
                    onChange={_onChange}
                    onClick={onFocus}
                    error={errors[field.key.toString()]}
                  />
                  <InputInfo error={errors[field.key.toString()]} />
                </Container.Flex>
              </Fragment>
            )
          }

          if (type === 'select') {
            return (
              <SelectInputWrapper open={false}>
                <SelectInput
                  key={key.toString()}
                  {...register(key.toString(), {
                    pattern,
                    maxLength: length,
                  })}
                  defaultValue={defaultValue?.id}
                  placeholder={placeHolder}
                  onFocus={onFocus}
                  onChange={_onChange}
                  onClick={(e: any) => {
                    e.target.open()
                    onFocus && onFocus(e)
                  }}
                >
                  {!!options &&
                    options.map(el => (
                      <option value={el.value} key={el.name}>
                        {el.name.length > 27
                          ? `${el.name.slice(0, 27)}...`
                          : el.name}
                      </option>
                    ))}
                </SelectInput>
              </SelectInputWrapper>
            )
          }
        })}

        {step === "clientInfo" && <SMSCode />}

        <OrderFormPagination />

        <Container.Flex
          fullWidth
          direction="row"
          wrapped
          horizontalGap={16}
          verticalGap={16}
          styles={{ order: 8 }}
        >
          {formPart.buttons.map((button, idx) => {
            const { text, variant, onClick, canBeDisabled, fullWidth, submit } =
              button

            const disabled = (step === "clientInfo" && smsStatus !== 'code-aproved') ? true : canBeDisabled
              ? !isValidated
                ? true
                : undefined
              : undefined

            return (
              <Button
                key={`button-${idx}`}
                variant={variant}
                onClick={onClick}
                disabled={disabled}
                styles={{
                  width: fullWidth ? '100%' : 'calc(50% - 16px)', order: 8
                }}
                submit={submit}
              >
                {text}
              </Button>
            )
          })}
        </Container.Flex>
      </Container.Flex>
    </Container.Flex>
  )
}
