import { Fragment } from 'react'
import { ListComponentProps } from '../ListData'
import withListData from '../ListData/ListData'
import { Loader } from '../ui'
import StockCard from './StockCard'
import { Stock } from './types'

function StockList(props: ListComponentProps<Stock>) {
  const { data, loading, refetch } = props

  if (loading) {
    return <Loader />
  }
  return (
    <Fragment>
      {data.map(el => (
        <StockCard {...el} key={el.id} refetch={refetch} />
      ))}
    </Fragment>
  )
}

export default withListData(StockList)
