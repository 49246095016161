import { Container } from '../ui'
import PartnerDesctiptionCard from './ParnterDescriptionCard'
import PartnerCard from './PartnerCard'
import PartnerDetailHeader from './PartnerDetailHeader'
import { bp } from "../../theme/media";

export default function ParnterDetail() {
  return (
    <Container.Flex fullWidth padding='0 0 24px 0'>
      <PartnerDetailHeader />

      <Container.Flex
        direction="row"
        fullWidth
        justify="between"
        alignItems="start"
        breakpoints={{
            [bp.table]: {
                flexDirection: "column"
            },

        }}
      >
        <PartnerCard/>
        <PartnerDesctiptionCard />
      </Container.Flex>
    </Container.Flex>
  )
}
