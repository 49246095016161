import { ChangeEvent, Fragment, useRef, useState, useEffect, memo } from 'react'
import Timer from './Timer'
import { classNames } from '../../styles/classNames'
import { Container, PhoneInput, Typography, Button, Input } from '../ui'
import { useTheme } from 'styled-components'
import { useLoginData } from './LoginContext'
import { LoginItemProps, Steps } from './types'
import { useReadOTP } from "react-read-otp";

const LoginSMS = ({ changeLoginType, resize, noRedirect }: LoginItemProps) => {
  const {
    setStep,
    step,
    clientLogin,
    clientLoginApi,
    phone,
    hide,
    setClientLoginError,
    defaultPhone,
    setDefaultPhone,
  } = useLoginData()
  const theme = useTheme()

  const [otp, setOtp] = useState("");
  useReadOTP(setOtp)

  const [disableCodeSubmit, setDisableCodeSubmit] = useState<true | undefined>(
    true,
  )
  const [disablePhoneSubmit, setDisablePhoneSumbit] = useState<
    true | undefined
  >(true)

  const contentRef = useRef<HTMLDivElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const codeInputRef = useRef<HTMLInputElement>(null)

  const onChangeStep = (step: Steps) => () => {
    if (contentRef.current) {
      contentRef.current.classList.add(classNames.changeCardContent)

      setTimeout(() => {
        setStep(step)
      }, 300)
    }
  }

  const afterPhoneChange = (value: string) => {
    setClientLoginError('')
    if (disablePhoneSubmit) {
      if (!value.match(/_/)) {
        setDisablePhoneSumbit(undefined)
        setDefaultPhone(value)
      }
    } else {
      if (value.match(/_/)) {
        setDefaultPhone('')
        setDisablePhoneSumbit(true)
      }
    }
  }

  const resendCode = () => {
    onChangeStep('sendPhone')()
  }

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disableCodeSubmit) {
      if (e.target.value.length === 4) {
        setDisableCodeSubmit(undefined)
      }
    } else {
      if (e.target.value.length !== 4) {
        setDisableCodeSubmit(true)
      }
    }
  }

  const sendPhone = () => {
    if (disablePhoneSubmit) return
    const phone = phoneInputRef.current?.value
    clientLogin({ phone: phone ?? "" })
  }

  const sendCode = () => {
    const code = codeInputRef.current?.value ?? ''
    clientLogin({
      phone,
      code,
    })
  }

  useEffect(() => {
    if (/\d{4}/.test(otp)) {
      if (codeInputRef.current) {
        codeInputRef.current.value = otp
        setDisableCodeSubmit(undefined)
      }
    }
  }, [otp])

  useEffect(() => {
    if (!disableCodeSubmit) {
      sendCode()
    }
  }, [disableCodeSubmit])

  useEffect(() => {
    if (step === 'sendCode' && clientLoginApi.error) {
      setDisableCodeSubmit(true)
    }
  }, [step, clientLoginApi])

  useEffect(() => {
    if (clientLoginApi.status === 'error') {
      setDisablePhoneSumbit(true)
    }
  }, [clientLoginApi])

  useEffect(() => {
    if (resize && clientLoginApi.error) {
      resize()
    }
  }, [clientLoginApi.error, resize])

  useEffect(() => {
    const input = document.getElementById('single-factor-code-text-field')
    if (input) {
      input.focus()
    }
    if (step === 'success') {
      setOtp("");
      hide(noRedirect)
    }
  }, [step])

  

  return (
    <Container.Flex
      fullWidth
      ref={contentRef}
      verticalGap={16}
      alignItems="stretch"
      onAnimationEnd={() => {
        contentRef.current?.classList.remove(classNames.changeCardContent)
      }}
    >
      <Container.Flex
        direction="row"
        justify="between"
        alignItems="baseline"
        styles={{ padding: '0 4px' }}
      >
        <Typography.TitleSecondary margin={'0 0 8px 0'}>
          Вход
        </Typography.TitleSecondary>
        {step !== 'sendCode' && (
          <Typography.Small
            onClick={() => changeLoginType('partner')}
            color={theme.colors.link.default}
          >
            Партнёрам
          </Typography.Small>
        )}
      </Container.Flex>
      {step === 'sendPhone' && (
        <Fragment>
          <PhoneInput
            afterChange={afterPhoneChange}
            ref={phoneInputRef}
            onEnter={sendPhone}
            error={clientLoginApi.error}
            defaultValue={defaultPhone}
          />
          <Typography.Small
            fromStart
            styles={{ paddingLeft: '4px', paddingRight: '4px' }}
          >
            На указанный номер придёт СМС-код
          </Typography.Small>
          <Button
            onClick={sendPhone}
            disabled={disablePhoneSubmit}
            fullWidth
            withLoader
            pending={clientLoginApi.pending}
          >
            ПОЛУЧИТЬ КОД
          </Button>
        </Fragment>
      )}

      {step === 'sendCode' && (
        <Fragment>
          <Input
            id="single-factor-code-text-field"
            autoComplete="one-time-code"
            ref={codeInputRef}
            placeholder="Код из СМС"
            inputMode="numeric"
            type="oneTimeCode"
            maxLength={4}
            onChange={onCodeChange}
            error={clientLoginApi.error}
            autoFocus
          />

          <Timer
            resendCode={resendCode}
            codePending={clientLoginApi.pending}
            isError={!!clientLoginApi.error}
            isSuccess={false}
          />
          <Button
            disabled={disableCodeSubmit}
            fullWidth
            withLoader
            pending={clientLoginApi.pending}
            onClick={sendCode}
          >
            ВОЙТИ
          </Button>
        </Fragment>
      )}
    </Container.Flex>
  )
}

export default memo(LoginSMS)
