import { useMemo, useRef, useEffect } from "react";
import { Box, Container, } from "../../ui";
import QRCode from "react-qr-code";
import useQuery from "../../../hooks/useQuery";
import OfferLoader from "../OfferLoader";
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { GetItemStatus } from '../../../store/orderSlice'

interface QrCodeProps {
    combinationCode?: string
    combinationId?: string
}

const OfferQR = ({ combinationCode, combinationId } : QrCodeProps) => {

    const { makePath } = useQuery();

    const link = makePath();

    const value = useMemo(() => link + "?qrcode=" + (combinationCode ? combinationCode : combinationId), [link, combinationCode, combinationId]);

    const { itemNumber, orderNumber } = useParams();

    const dispatch = useDispatch();

    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);


    useEffect(() => {

        intervalRef.current = setInterval(() => {
            if (orderNumber) {
                dispatch(GetItemStatus.request({ itemNumber, orderNumber }))
            }
        }, 10000);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    return (
        <Container.Flex fullWidth alignItems="start" verticalGap={16}>
            <Box
                padding={10}
                styles={{
                    alignSelf: "center",
                    backgroundColor: "#fff",
                    borderRadius: "12px",
                    fontSize: "0",
                    lineHeight: "0"
                }}
            >
                {(combinationCode || combinationId) ? <QRCode value={value} size={140} /> : <OfferLoader minHeight="100%" />}
            </Box>

        </Container.Flex>
    );
};

export default OfferQR;
