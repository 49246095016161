import * as CSS from 'csstype'
import {
  Title,
  TitleSecondary,
  Small,
  RublesLarge,
  RublesSmall,
  RublesMedium,
  Big,
  Medium,
  Main,
  Link,
  Button,
  Error,
  Tertiary,
  Micro,
  RublesCentes,
  LowMenu,
  Extra
} from './components'

export const IndicatorStyles: CSS.Properties = {
  flexShrink: 0,
  height: '8px',
  width: '8px',
  verticalAlign: 'top',
  marginLeft: '4px',
}

const Typography = {
  Title,
  TitleSecondary,
  Small,
  RublesLarge,
  RublesSmall,
  RublesMedium,
  Big,
  Medium,
  Main,
  Link,
  Button,
  Error,
  Tertiary,
  Micro,
  RublesCentes,
  LowMenu,
  Extra
}

export default Typography
