import { useEffect, useRef } from 'react'
import Container from '../Container'
import Typography from '../Typography'
import { useUploadImages } from './UploadImagesContext'
import Button from '../Button'
import Info from '../Info/Info'
import { useRedirect } from '../../../hooks/useRedirect'

export default function UploadImageConfirm() {
  const { resizeCard, submit, submitState, orderNumber, itemNumber } = useUploadImages()

  const { redirectToOrder } = useRedirect()

  const ref = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    resizeCard()
  }, [resizeCard, submitState.error])

  useEffect(() => {
    if (submitState.success) {
      redirectToOrder(orderNumber, itemNumber)
    }
  }, [submitState.success, redirectToOrder])

  return (
    <Container.Flex fullWidth gap={16}>
      <Typography.TitleSecondary
        styles={{ padding: '0 4px' }}
        margin={0}
        fromStart
      >
        Фото готовы к отправке
      </Typography.TitleSecondary>

      <Info>После проверки фото, отобразится финальная стоимость</Info>

      <Button
        pending={submitState.pending}
        withLoader
        variant="primary"
        fullWidth
        ref={ref}
        onClick={submit}
      >
        ОТПРАВИТЬ
      </Button>

      {submitState.error && (
        <Typography.Error padding={'0 4px'}>
          {submitState.error}
        </Typography.Error>
      )}
    </Container.Flex>
  )
}
