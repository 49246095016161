import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Offer from '../components/Offer'
import PageLayout from './PageLayout'
import {
  addSeveralAnswers,
  resetAnswers,
  setCombinationCode,
  setCombinationsId,
  setStep,
} from '../store/offerSlice'
import { GetOrder, getOrderItemData, setQrCode } from '../store/orderSlice'
import { useUploadFiles } from '../contexts/uploadFiles'
import { RootState } from '../store'
import { getErrorString } from '../helpers/getErrorString'
import { Typography } from '../components/ui'
import Order from '../components/Order/Order'
import { useSession } from '../hooks/useSession'
import { DeviceStatuses } from '../types'

const OrderItemPage = () => {
  const { itemNumber, orderNumber, checkList } = useParams()
  const { search } = useLocation()

  const navigate = useNavigate()
  const { isLoading, orderData, errors } = useSelector(getOrderItemData)
  const { givenAnswers, step } = useSelector((state: RootState) => state.offer)
  const dispatch = useDispatch()
  const { remoteFiles, setRemoteFiles } = useUploadFiles()

  const { isAdmin } = useSession()

  const qrCode = useMemo(() => {
    const params = new URLSearchParams(search)

    return params.get('qrcode')
  }, [search])

  useEffect(() => {
    if (orderData) {
      const currentItem = orderData.items.find(
        item => item.itemNumber === itemNumber,
      )

      if (currentItem?.status === DeviceStatuses.Preliminary) {
        if (isAdmin) {
          dispatch(setStep('questions'))
        } else {
          navigate(`/order/${orderNumber}/`)
        }
      }
      if (currentItem?.status === DeviceStatuses.Confirming) {
        dispatch(setStep('summary'))
      } else if (currentItem?.status === 'D') {
        navigate(`/order/${orderNumber}/`)
      } else if (!!checkList) {
        dispatch(setStep('questions'))
      } else {
        if (!qrCode && step !== 'questions') {
          dispatch(setStep('questions'))
        }
      }
    }
  }, [orderData, itemNumber, orderNumber, dispatch, qrCode, step])

  const getOrder = useCallback(() => {
    if (!isLoading) {
      dispatch(GetOrder.request({ itemNumber, orderNumber, checkList }))
    }
  }, [isLoading])

  useEffect(() => {
    if (qrCode) {
      dispatch(setQrCode(qrCode))
      dispatch(setStep('questions'))
    }
  }, [qrCode])

  const currentItem = useMemo(() => {
    return orderData?.items.find(item => item.itemNumber === itemNumber)
  }, [itemNumber, orderData])

  useEffect(() => {
    if (currentItem) {
      dispatch(resetAnswers())
      dispatch(setCombinationCode(currentItem.combinationCode))
      dispatch(setCombinationsId(currentItem.combinationId))
    }
  }, [currentItem])

  useEffect(getOrder, [])

  useEffect(() => {
    if (Object.keys(remoteFiles).length) {
      dispatch(addSeveralAnswers(remoteFiles))
      setRemoteFiles({})
    }
  }, [remoteFiles, givenAnswers, dispatch])

  const showOffer = useMemo(() => {
    if (isLoading) return false
    if (!currentItem) return false
    if (!itemNumber && !orderNumber) return true
    return true
  }, [currentItem, itemNumber, orderNumber])

  const error = useMemo(() => getErrorString(errors), [errors])

  if (error) {
    return (
      <PageLayout>
        <Typography.Error>{error}</Typography.Error>
      </PageLayout>
    )
  }

  return (
    <PageLayout withProfile>
        {!!orderNumber && (
          <Order
            orderNumber={orderNumber}
            itemNumber={itemNumber}
            withoutHeader
            withoutLoader
            withoutDelete
          >
            {showOffer && <Offer />}
          </Order>
        )}
    </PageLayout>
  )
}

export default OrderItemPage
