import { useSession } from '../hooks/useSession'
import { Container, Loader } from '../components/ui'
import { UserType } from '../store/userSlice/types'
import NotFound from './404'
import { PropsWithChildren } from 'react'

const CourierWrapper = ({ children }: PropsWithChildren<{}>) => {
  const { userRole, pending } = useSession()


  if (pending) {
    return <Loader />
  }

  if (userRole !== UserType.courier) {
    return <NotFound />
  }

  return <Container.Flex fullWidth>{children}</Container.Flex>
}

export default CourierWrapper
