import { Fragment, ReactNode } from 'react'
import Container from '../Container'
import Icon from '../Icon'
import Typography from '../Typography'
import { useTheme } from 'styled-components'

interface InfoProps {
  children?: ReactNode
  parts?: string[]
  html?: string
  padding?: string
}

const Info = ({ children, parts, html, padding }: InfoProps) => {
  const theme = useTheme()

  return (
    <Container.Flex fullWidth padding={padding ?? '4px 0 0 28px'} relative>
      <Icon
        name="info"
        color={theme.colors.icon.secondary}
        absolute
        styles={{ left: padding ? '13px' : 0, top: padding ? '13px' : "4px" }}
      />
      {children && (
        <Typography.Main fullWidth styles={{ margin: '0' }} textAlign="start">
          {children}
        </Typography.Main>
      )}

      {html && (
        <Typography.Main
          fullWidth
          styles={{ margin: '0' }}
          textAlign="start"
          html={html}
        />
      )}

      {parts && (
        <Container.Flex>
          {parts.map((part, idx) => (
            <Fragment key={idx}>
              <Typography.Main
                fullWidth
                styles={{ margin: '0' }}
                textAlign="start"
              >
                {part}
              </Typography.Main>
              {idx < parts.length - 1 && <br />}
            </Fragment>
          ))}
        </Container.Flex>
      )}
    </Container.Flex>
  )
}

export default Info
