import { getFormatRubles } from "../../../helpers/getrFormatRubles"
import Container from "../Container"
import Typography from "../Typography"

export default function RublesWithCentes ({ price, currency } : {
  price: string
  currency: string
}) {
  const { rubles, centes } = getFormatRubles(+price)

  return (
    <Container.Flex direction="row">
      <Typography.RublesMedium>{rubles}</Typography.RublesMedium>
      <Typography.RublesSmall margin={'0 1px'} styles={{
        fontSize: '8px',
        lineHeight: "inherit",
        position: 'relative',
        top: '-2px'
      }}>{centes}</Typography.RublesSmall>
      <Typography.RublesMedium margin={'0 0 0 2px'}>{currency}</Typography.RublesMedium>
    </Container.Flex>
  )
}