import { DeviceStatuses } from '../../../types'
import { Container, Icon } from '../../ui'


type Props = {
    status: DeviceStatuses
    result?: true
    noMargin?: true
}

export default function StatusImage (props: Props) {

    const { status } = props;

    return (
        <Container.Flex direction="row" gap={4}>
        {status && <Icon name={status} />}
        {[DeviceStatuses.ChangedAccepted].includes(status) && <Icon name={DeviceStatuses.Confirmed} />}
      </Container.Flex>
    )
}