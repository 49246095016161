import styled, { useTheme } from 'styled-components'
import { forwardRef, KeyboardEvent, MouseEvent, useEffect, useRef } from 'react'
import { setCaretPosition } from './setCaretPosition'
import { onChange } from './onChange'
import { defaultPhone } from '.'
import Typography from '../../Typography'
import Container from '../../Container'
import { mergeRefs } from 'react-merge-refs'

const Input = styled.input`
  border: none;
  border-radius: 12px;
  padding: 10px 16px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.theme.colors.text.default};
  background: ${props => props.theme.colors.background.contrast70};
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
  outline: none;

  &::placeholder {
    color: ${props => props.theme.colors.info.error};
  }
`

type PhoneInputProps = {
  afterChange?: (value: string) => void
  onEnter?: () => void
  error: string
  defaultValue: string
}

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  (props, ref) => {
    const mode = useRef<'input' | 'remove'>('input')

    const componentRef = useRef<HTMLInputElement>(null)

    const onClick = (e: MouseEvent<HTMLInputElement>) => {
      setCaretPosition(e.target)
      e.preventDefault()
      e.stopPropagation()
    }

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        props.onEnter && props.onEnter()
      }

      if (e.key === 'Backspace' || e.keyCode === 229) {
        mode.current = 'remove'
      } else {
        mode.current = 'input'
      }
    }

    useEffect(() => {
      if (componentRef.current) {
        if (props.defaultValue) {
          componentRef.current.value = props.defaultValue
        }
        componentRef.current.focus()
      }
    }, [props.defaultValue])

    const theme = useTheme()
    const errorRef = useRef<HTMLDivElement>(null)

    return (
      <Container.Flex
        fullWidth
        styles={{ position: 'relative' }}
        key={defaultPhone}
        gap={5}
      >
        <Input
          ref={mergeRefs([componentRef, ref])}
          type="tel"
          onChange={e => onChange(e, mode, props.afterChange)}
          onKeyDown={onKeyDown}
          onClick={onClick}
          onFocus={e => setCaretPosition(e.target)}
          defaultValue={props.defaultValue || defaultPhone}
          autoFocus={true}
        />
        {!!props.error && (
          <Container.Flex ref={errorRef}>
            <Typography.Micro
              fromStart
              color={theme.colors.info.error}
            >
              {props.error}
            </Typography.Micro>
          </Container.Flex>
        )}
      </Container.Flex>
    )
  },
)

export default PhoneInput
