import { useCallback, useState } from 'react'
import { Container, Typography, Button, Image } from '../ui'
import { CardWrapper } from '../ui/Wrappers'
import { Stock } from './types'
import { bp } from '../../theme/media'
import styled from 'styled-components/macro'
import { useTheme } from 'styled-components'
import Api from '../../apinew'
import { CourierUnReserveStockData } from '../../apinew/types'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../pages/PATHS'
import ErrorPopup from '../ErrorPopup/ErrorPopup'

const StockCardButton = ({
  status,
  changeStatus,
  pending,
}: {
  status: Stock['status']
  changeStatus: (s: any) => void
  pending: boolean
}) => {
  const props = {
    pending,
    withLoader: true,
    styles: { padding: '9px 15px' },
    onClick: changeStatus,
  } as any

  switch (status) {
    case 'empty':
      return (
        <Button {...props} variant="outline">
          Заберу
        </Button>
      )
    case 'reserved':
      return (
        <Button {...props} variant="primary">
          В работе
        </Button>
      )
    case 'blocked':
      return (
        <Button {...props} variant="primary" disabled>
          В работе
        </Button>
      )
    case 'inprogress':
      return (
        <Button {...props} variant="purple">
          Продолжить
        </Button>
      )
    default:
      return null
  }
}

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: minmax(20px, max-content);
  align-items: start;
  grid-gap: 8px 36px;

  @media (max-width: 768px) {
    grid-template-columns: auto 1fr 36%;
    grid-gap: 16px;
  }
`

export default function StockCard(props: Stock & { refetch: () => void }) {
  const {
    id,
    appNumber,
    date,
    packages,
    image,
    title,
    address,
    schedule,
    status,
    refetch,
    idreserve,
  } = props

  const theme = useTheme()
  const [pending, setPending] = useState(false)
  const navigate = useNavigate()

  const [showErrorPopupMessage, setShowErrorPopup] = useState("")

  const changeStatus = useCallback(() => {
    const api = new Api()
    setPending(true)

    if (status === 'inprogress') {
      navigate(PATHS.COURIER_TRANSPORT_APPLICATION + appNumber)
    }

    if (status === 'empty') {
      api
        .reserveStock({
          salePointId: +idreserve,
        })
        .then(response => {
          if (response.status === 'success') {
            refetch()
          }

          if (response.errors.length) {
            const errorPopup = response.errors.find(el => el.customData?.showForm);

            if (errorPopup) {
              setShowErrorPopup(errorPopup.message)
            }
          }
        })
        .finally(() => {
          setPending(false)
        })
    }

    if (status === 'reserved') {
      api
        .unreserveStock({
          salePointId: idreserve,
        } as CourierUnReserveStockData)
        .then(() => {
          refetch()
        })
        .finally(() => {
          setPending(false)
        })
    }
  }, [status])

  return (
    <>
      <CardWrapper style={{ paddingBottom: '28px' }}>
        <ItemGrid>
          <Typography.TitleSecondary
            margin="0"
            padding="0"
            fromStart
            styles={{
              width: 'fit-content',
            }}
            breakpoints={{
              [bp.mobile]: {
                padding: '0 4px',
              },
            }}
          >
            {id}
          </Typography.TitleSecondary>

          <Container.Flex
            alignItems="stretch"
            direction="row"
            justify="end"
            styles={{
              padding: '0 4px',
              gridColumn: '3 / 4',
              gridRow: '1 / 2',
            }}
            breakpoints={{
              [bp.mobile]: {
                gridColumn: '2 / 4',
                gridRow: '1 / 2',
                marginRight: '4px',
                alignSelf: 'center',
              },
            }}
          >
            <Typography.Medium
              color={theme.colors.text.tertiary}
              margin={0}
              padding={0}
              textAlign="start"
            >
              Пакетов:
            </Typography.Medium>
            <Typography.Medium
              color={theme.colors.text.default}
              margin="0 0 0 4px"
              padding={0}
              textAlign="start"
              styles={{ fontWeight: 700 }}
            >
              {packages} шт
            </Typography.Medium>
          </Container.Flex>

          <Container.Flex
            direction="row"
            alignItems="start"
            styles={{
              gridColumn: '2 / 3',
              gridRow: '1 / 3',
            }}
            breakpoints={{
              [bp.mobile]: {
                gridColumn: '1 / 4',
                gridRow: '2 / 3',
              },
            }}
          >
            <Image
              src={image}
              alt="логотип"
              styles={{
                width: '40px',
                height: '40px',
                marginRight: '16px',
                flexShrink: 0,
              }}
            />
            <Container.Flex
              direction="column"
              alignItems="start"
              styles={{
                flexGrow: 1,
              }}
            >
              <Typography.Title margin="0 0 4px" padding={0}>
                {title}
              </Typography.Title>
              <Container.Flex
                direction="row"
                alignItems="start"
                margin="0 0 4px"
              >
                <Typography.Tertiary fromStart padding={0} margin="0 4px 0 0">
                  Адрес:
                </Typography.Tertiary>
                <Typography.Small fromStart padding={0}>
                  {address}
                </Typography.Small>
              </Container.Flex>
              <Container.Flex direction="row" alignItems="start">
                <Typography.Tertiary fromStart padding={0} margin="0 4px 0 0">
                  График:
                </Typography.Tertiary>
                <Typography.Small fromStart padding={0}>
                  {schedule}
                </Typography.Small>
              </Container.Flex>
            </Container.Flex>
          </Container.Flex>

          <Container.Flex
            direction="row"
            justify="end"
            styles={{
              gridColumn: '3 / 4',
              gridRow: '2 / 3',
              marginTop: '12px',
            }}
            breakpoints={{
              [bp.mobile]: {
                marginTop: 0,
                gridColumn: '2 / 4',
                gridRow: '3 / 4',
                marginLeft: '4px',
              },
            }}
          >
            <StockCardButton
              status={status}
              changeStatus={changeStatus}
              pending={pending}
            />
          </Container.Flex>

          <Typography.Micro
            secondary
            margin={0}
            padding={0}
            textAlign="start"
            styles={{
              gridColumn: '1 / 2',
              gridRow: '2 / 3',
            }}
            breakpoints={{
              [bp.mobile]: {
                gridColumn: '1 / 3',
                gridRow: '3 / 4',
                alignSelf: 'center',
              },
            }}
          >
            {date}
          </Typography.Micro>
        </ItemGrid>
      </CardWrapper>
      <ErrorPopup
        title="Упс! Склад уже в работе"
        textBlocks={[showErrorPopupMessage]}
        visible={!!showErrorPopupMessage}
        buttons={[{ text: 'Понятно', onClick: () => setShowErrorPopup("") }]}
      />
    </>
  )
}
