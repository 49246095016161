import { memo, useCallback, useEffect, useRef } from 'react'
import { keyFramesClasses } from '../../styles/animations'
import { Card, Container } from '../ui'
import Login from './Login'
import { useLoginData } from './LoginContext'
import LoginSMS from './LoginSMS'
import { LoginTypes } from './types'

function LoginCard({ noRedirect } : { noRedirect?: true }) {

  const { mode, setMode } = useLoginData()

  const cardRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const currentLoginType = useRef<LoginTypes>('client')

  const changeLoginType = useCallback((v: LoginTypes) => {
    currentLoginType.current = v
    contentRef.current?.classList.remove(keyFramesClasses.showFromRight)
    contentRef.current?.classList.remove(keyFramesClasses.showFromLeft)
    if (v === 'partner') {
      contentRef.current?.classList.add(keyFramesClasses.hideToLeft)
      setTimeout(() => {
        setMode(currentLoginType.current)
        contentRef.current?.classList.remove(keyFramesClasses.hideToLeft)
        contentRef.current?.classList.add(keyFramesClasses.showFromRight)
      }, 200)
    } else {
      contentRef.current?.classList.add(keyFramesClasses.hideToRight)
      setTimeout(() => {
        setMode(currentLoginType.current)
        contentRef.current?.classList.remove(keyFramesClasses.hideToRight)
        contentRef.current?.classList.add(keyFramesClasses.showFromLeft)
      }, 200)
    }
  }, [])

  const onAnimationEnd = () => {
    contentRef.current?.classList.remove('login-content-change')
  }

  const resize = () => {
    if (cardRef.current && contentRef.current) {
      cardRef.current.style.height = `${contentRef.current.offsetHeight}px`
    }
  }

  useEffect(resize)

  return (
    <Card
      padding={28}
      styles={{
        overflow: 'hidden',
        height: '234px',
        transition: 'height 200ms ease',
      }}
      ref={cardRef}
    >
      <Container.Flex
        onAnimationEnd={onAnimationEnd}
        fullWidth
        padding={28}
        ref={contentRef}
        styles={{ position: 'absolute', top: 0, left: 0 }}
      >
        {mode === 'client' ? (
          <LoginSMS changeLoginType={changeLoginType} resize={resize} noRedirect={noRedirect}/>
        ) : (
          <Login changeLoginType={changeLoginType} resize={resize} noRedirect={noRedirect}/>
        )}
      </Container.Flex>
    </Card>
  )
}

export default memo(LoginCard)
