import Container from "../ui/Container";
import ThemeButtons from "./Buttons";
import ThemeInputs from "./Inputs";
import ThemeSelect from "./ThemeSelect";
import ThemeRadio from "./Radio";
import ThemeCheckbox from "./Checkbox";

const Theme = () => {

    return (
        <Container.CardsGrid twoColumns={true}>
            <ThemeButtons />
            <ThemeInputs />
            <ThemeRadio />
            <ThemeSelect />
            <ThemeCheckbox />
        </Container.CardsGrid>
    );
};

export default Theme;
