import { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { GetItemStatus, getOrderItemData } from '../../store/orderSlice'
import { Container, Typography } from '../ui'
import styled from 'styled-components/macro'
import { DeviceStatuses } from '../../types'

const Dots = styled.span`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0.75em;

    font-size: inherit;
    line-height: inherit;

    ::after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ellipsis steps(4, end) 2s infinite;
        content: '\\2026';
        width: 0;
    }
`

const OfferSummary = () => {
    const theme = useTheme()

    const { itemNumber, orderNumber } = useParams()
    const { orderData } = useSelector(getOrderItemData)

    const item = orderData?.items.find(el => el.itemNumber === itemNumber)

    const dispatch = useDispatch()

    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)

    const status = item?.status

    useEffect(() => {
        dispatch(GetItemStatus.request({ itemNumber, orderNumber }))
        intervalRef.current = setInterval(() => {
            if (orderNumber) {
                dispatch(GetItemStatus.request({ itemNumber, orderNumber }))
            }
        }, 10000)

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [])

    return (
        <Container.Flex alignItems="stretch" margin={'16px 0 0 0'}>
            {status !== DeviceStatuses.Confirmed && (
                <Typography.Title
                    fullWidth
                    textAlign="start"
                    color={theme.colors.text.secondary}
                    styles={{
                        paddingLeft: '4px',
                        paddingRight: '4px',
                        marginBottom: '24px',
                        marginTop: '0',
                    }}
                >
                    Подтверждение стоимости
                </Typography.Title>
            )}

            {status !== DeviceStatuses.Confirmed && (
                <Typography.Main
                    textAlign="start"
                    styles={{
                        paddingLeft: '4px',
                        paddingRight: '4px',
                        marginBottom: '22px',
                        marginTop: '0',
                    }}
                >
                    Сейчас происходит проверка предоставленных вами данных.
                    Время проверки не превышает 5 минут
                </Typography.Main>
            )}

            {status !== DeviceStatuses.Confirmed && (
                <Typography.Title
                    textAlign="start"
                    color={theme.colors.info.error}
                    styles={{
                        margin: '0 auto',
                        position: 'relative',
                        paddingRight: '0.75em',
                        transition: 'opacity 100ms ease-in',
                    }}
                >
                    Ожидайте пожалуйста
                    <Dots />
                </Typography.Title>
            )}

        </Container.Flex>
    )
}

export default OfferSummary
