import { OrderItem } from '../../../store/orderSlice/types'
import { Container, Icon, Image, Typography } from '../../ui'
import styled from 'styled-components/macro'

type Props = {
  data: OrderItem
  currency: string
}

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87px;
  height: 87px;
  border-radius: 12px;
  background-color: white;
`

const OrderDevice = ({ data, currency }: Props) => {
  const { image, name, price, answers, status, savePack } = data
  const IMEI = answers.find(el => el.name === 'IMEI')?.value

  return (
    <Container.Grid
      fullWidth
      cols="87px 1fr"
      rows="1fr"
      gap={16}
      justify="start"
    >
      <ImgWrapper>
        <Image
          src={image}
          alt={name}
          height={75}
          styles={{
            maxWidth: '75px',
            maxHeight: '75px',
          }}
        />
      </ImgWrapper>

      <Container.Flex
        verticalGap={4}
        alignItems="stretch"
        styles={{ flexGrow: 1 }}
      >
        <Typography.Title textAlign="start" styles={{ order: 0, margin: '0' }}>
          {name}
        </Typography.Title>

        {!!IMEI && (
          <Container.Flex direction="row" alignItems="start">
            <Typography.Tertiary margin={'0 4px 0 0'}>IMEI</Typography.Tertiary>
            <Typography.Small textAlign="start">{IMEI}</Typography.Small>
          </Container.Flex>
        )}

        {!!savePack && (
          <Container.Flex direction="row" alignItems="start">
            <Typography.Tertiary margin={'0 4px 0 0'}>Сейф</Typography.Tertiary>
            <Typography.Small textAlign="start">{(savePack.match(/.{1,2}/g) || []).join(' ')}</Typography.Small>
          </Container.Flex>
        )}

        {!!price && !!currency && (
          <Container.Flex direction={'row'} gap={4}>
            <Icon name={status} />
            <Typography.Small>{`${price} ${currency}`}</Typography.Small>
          </Container.Flex>
        )}
      </Container.Flex>
    </Container.Grid>
  )
}

export default OrderDevice
