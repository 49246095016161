import { Fragment, useEffect, useState } from 'react'
import { useSendOrderPacksMutation } from '../../../store/api/api'
import { Button, Container, Info, Typography } from '../../ui'
import SafePackageInput from '../../ui/Input/SafePackageInput'
import { useOrderContext } from '../OrderContext'
import { OrderModalProps, withOrderModal } from './WithOrderModal'
import { SafePackageOnComplete } from '../../ui/Input/SafePackageInput'

const Modal = withOrderModal((props: OrderModalProps) => {
  const { hide } = props
  const { showShipmentModal } = useOrderContext()

  const [sendPackNumbers, { isSuccess, error, isLoading, reset }] =
    useSendOrderPacksMutation()

  const [packNumber, setPackNumber] = useState<string>('')

  const sendPackNumbersError = error as undefined | { data: string }

  const { orderData, updateOrderData } = useOrderContext()

  const afterChange = () => {
    if (isSuccess || error) {
      reset()
    }
    setPackNumber('')
  }

  const onComplete: SafePackageOnComplete = ({ value }) => {
    setPackNumber(value)
  }

  useEffect(() => {
    if (isSuccess) {
      hide()
      updateOrderData()
    }
  }, [isSuccess])

  const sendNumbers = () => {
    if (orderData?.number && packNumber && showShipmentModal) {
      const data = {
        number: orderData.number,
        packNumber: packNumber,
        itemNumber: showShipmentModal,
      }
      sendPackNumbers({
        data,
      })
    }
  }

  return (
    <Fragment>
      <Typography.TitleSecondary
        margin="0 0 8px"
        padding="0 4px"
        textAlign="start"
      >
        Укажите номер сейф-пакета
      </Typography.TitleSecondary>

      <Info>
        <Typography.Main fromStart margin="0 0 8px">
          Номер указан на лицевой стороне в верхнем левом углу.
        </Typography.Main>
        <Typography.Main fromStart margin={0}>
          Одно устройство - один сейф-пакет
        </Typography.Main>
      </Info>

      <Container.Flex direction="row">
        <SafePackageInput onComplete={onComplete} afterChange={afterChange} />
      </Container.Flex>

      <Container.Grid cols="1fr 1fr" rows="1fr" gap={16} fullWidth>
        <Button variant="danger" fullWidth onClick={hide}>
          Отмена
        </Button>
        <Button
          variant="primary"
          fullWidth
          disabled={!packNumber}
          onClick={sendNumbers}
          withLoader={true}
          pending={isLoading}
        >
          Сохранить
        </Button>
      </Container.Grid>

      {sendPackNumbersError && (
        <Typography.Error>{sendPackNumbersError.data}</Typography.Error>
      )}
    </Fragment>
  )
})

export default function OrderPackNumbers() {
  const { setShowShipmentModal, showShipmentModal } = useOrderContext()
  return (
    <Modal
      hidden={!showShipmentModal}
      hide={() => setShowShipmentModal(null)}
    />
  )
}
