import { ContractProps } from './types'
import { Contract, GlobalStyles } from './ContractComponents'
import { getContractDate } from './helpers/getContractDate'
import { getClientFullName } from './helpers/getClientFullName'
import { getClientDocumentData } from './helpers/getClientDocumentData'
import { Button } from '../ui'

export default function ContractPrintPage(props: ContractProps) {
  const { formData, contractData } = props

  const print = () => {
    if (typeof window !== 'undefined') {
      window.print()
    }
  }

  return (
    <Contract.Wrapper>
      <GlobalStyles />
      <Contract.Title>{`Договор купли-продажи № ${contractData.number}`}</Contract.Title>

      <Contract.RowGrid gridCols="2fr 1fr" style={{ marginBottom: '20px' }}>
        <Contract.Span>{contractData.storeAddress}</Contract.Span>
        <Contract.Span>{getContractDate()}</Contract.Span>
      </Contract.RowGrid>

      <Contract.SpanBold>Продавец:</Contract.SpanBold>

      <Contract.FlexRow>
        <Contract.Span>{getClientFullName(formData)}</Contract.Span>
      </Contract.FlexRow>

      <Contract.FlexRow>
        <Contract.Span style={{ marginRight: '5px' }}>
          Данные документа, удостоверяющего личность:{' '}
        </Contract.Span>
        <Contract.Span>{getClientDocumentData(formData)}</Contract.Span>
      </Contract.FlexRow>

      <Contract.FlexRow>
        <Contract.Span style={{ marginRight: '5px' }}>Адрес:</Contract.Span>
        <Contract.Span>{formData.location}</Contract.Span>
      </Contract.FlexRow>

			<Contract.FlexRow>
        <Contract.Span style={{ marginRight: '5px' }}>Контактный телефон:</Contract.Span>
        <Contract.Span>{formData.phone}</Contract.Span>
      </Contract.FlexRow>

      <Contract.SpanBold>Покупатель:</Contract.SpanBold>
      <Contract.P>{`${contractData.company}, в лице ${contractData.employeeName}, действующего в интересах ${contractData.serviceCompany}, во исполнение ${contractData.serviceContract}, заключили настоящий Договор о нижеследующем:`}</Contract.P>

      <ol>
        <li>
          Продавец передал в собственность Покупателю, а Покупатель принял и
          оплачивает следующий бывший в употреблении товар, именуемый в
          дальнейшем «БУ товар»:
        </li>
        {contractData.items &&
          contractData.items.map((item, idx) => (
            <ul key={`item.${idx}`}>
              <li>{`Изготовитель (Марка), Модель: ${item.itemName}`}</li>
              <li>{`Серийный номер: ${item.itemSerial}`}</li>
              <li>{`Состояние: ${item.itemCondition}`}</li>
            </ul>
          ))}
        <li>
          Продавец гарантирует, что БУ товар принадлежит ему на праве
          собственности и свободно от прав и притязаний третьих лиц, под
          залогом, в споре и аресте не состоит, не включено в реестр уведомлений
          о залоге движимого имущества.
        </li>
        <li>
          Подписывая настоящий договор, Продавец гарантирует, что БУ товар
          принадлежит Продавцу на законных основаниях, не является предметом
          какого-либо преступления и Продавец имеет законное право на продажу БУ
          товара по своему усмотрению.
        </li>
        <li>
          Подписывая настоящий договор, Продавец подтверждает, что не лишен и не
          ограничен в дееспособности и полностью понимает все условия договора и
          осознает последствия заключения договора.
        </li>
        <li>
          Продавец обязуется самостоятельно и за свой счет разрешать любые
          претензии, которые могут возникнуть у третьих лиц, в связи с
          заключением Продавцом настоящего договора.
        </li>
        <li>
          БУ товар передается в техническом состоянии соответствующему состоянию
          оценки.
        </li>
        <li>{`Стоимость БУ товара составляет ${contractData.price} (${contractData.priceText}). Указанная стоимость БУ товара является согласованным условием и не подлежит изменению, за исключением случаев выявления технических неисправностей БУ товара, которые не могли быть выявлены при заключении договора.`}</li>
        {contractData.type === 'RANSOM' ? (
          <li>
            Оплата стоимости БУ товара производится путем выдачи наличных
            денежных средств из кассы Покупателя в торговом объекте, что
            подтверждается чеком из кассового аппарата, который остается у
            Покупателя. Внешний осмотр БУ товара и проверка по открытым данным
            утерянных/украденных телефонов осуществлен в момент передачи БУ
            товара.
          </li>
        ) : (
          <li>
            Оплата стоимости БУ товара производится путем зачета встречных
            требований, возникших между Покупателем и Продавцом, в связи с
            приобретением Продавцом у Покупателя нового товара, в размере,
            превышающем стоимость БУ товара.
          </li>
        )}
        <li>
          Передача БУ товара Покупателю осуществляется одновременно с
          подписанием настоящего договора. Оформление каких-либо документов,
          помимо настоящего договора, в подтверждение передачи Продавцом БУ
          товара и приемки его Покупателем, не требуется.
        </li>
        <li>
          В случае если недостатки БУ товара не были оговорены Продавцом,
          Покупатель, которому передан БУ товар ненадлежащего качества, вправе
          потребовать от Продавца, возврата части покупной цены, либо
          расторжения договора и возврата полной покупной цены, а также
          компенсации расходов на диагностику БУ товара.
        </li>
        <li>
          Право собственности на БУ товара и риск его случайной гибели переходят
          с момента подписания договора.
        </li>
        <li>
          Взаимоотношения Сторон, не оговоренные настоящим договором,
          регулируются действующим законодательством Республики Беларусь.
        </li>
        <li>
          Споры, возникающие из договора, рассматриваются в суде общей
          юрисдикции по месту нахождения Покупателя. Соблюдение претензионного
          порядка при рассмотрении споров не требуется.
        </li>
        <li>
          Договор составлен в двух экземплярах, имеющих одинаковую юридическую
          силу, один из которых находится у Продавца, второй - у Покупателя.
          Покупатель оставляет за собой право предоставлять копию настоящего
          договора в налоговые органы при подаче налоговых деклараций и
          отчетности.
        </li>
        <li>
          Продавец подписанием настоящего Договора подтверждает, что:
          <ol>
            <li>Личные данные предоставлены мною добровольно.</li>
            <li>
              Данные, указанные в договоре корректны и соответствуют
              действительности.
            </li>
            <li>
              Даю согласие на получение информации об акциях и новинках по
              каналам связи (sms - рассылка, viber - рассылка, e-mail -
              рассылка).
            </li>
            <li>
              {`Даю согласие на бессрочную обработку (любое действие или совокупность действий, совершаемых с персональными данными, 
									включая сбор, систематизацию, хранение, копирование, сканирование, изменение, обновление, использование, обезличивание,  
									блокирование, распространение, предоставление, удаление, уничтожение, уточнение и передачу (в т.ч. трансграничную))
									${contractData.company} всех вышеуказанных мной персональных данных в целях, указанных в Политике ${contractData.company} в отношении 
									обработки персональных данных, в т.ч., но не ограничиваясь, в целях сохранения информации о заказах, маркетинга, рекламы и 
									информирования покупателя о предложениях продавца.`}
            </li>
            <li>
              {`Уведомлен, что оператором при обработке персональных данных выступает ${contractData.company}, ${contractData.companyAddress}.`}
            </li>
            <li>
              {`Ознакомлен с правами, связанными с обработкой персональных данных, механизмом реализации прав, последствиями дачи    
									согласия на предоставление и обработку персональных данных и отказа от дачи такого согласия (указаны в Политике ${contractData.company}
									в отношении обработки персональных данных, копия которой находится в магазине). Понимаю, что последствием отказа в даче 
									согласия на предоставление Персональных данных может явиться невозможность совершения отдельных действий оператором.`}
            </li>
          </ol>
        </li>
        <li>
          Реквизиты Продавца:
          <ul>
            <li>{`${getClientFullName(formData)}, ${getClientDocumentData(
              formData,
            )}`}</li>
            <li>{`${formData.location}, ${formData.phone}`}</li>
          </ul>
        </li>

        <li>
          Реквизиты Покупателя:
          <ul>
            <li>{`${contractData.company}, УНП: ${contractData.companyUNP}, Адрес: ${contractData.companyAddress}`}</li>
            <li>{`${contractData.companyBankAccount}`}</li>
          </ul>
        </li>
      </ol>

      <Contract.SpanBold id="page-breack">
        Определение цены товара
      </Contract.SpanBold>

      <table>
        <tr>
          <th>Номенклатура</th>
          <th>Оценка</th>
          <th>Итоговая цена по итогам оценки, бел. руб.</th>
        </tr>

        {contractData.items &&
          contractData.items.map((item, idx) => (
            <tr key={`row${idx}`}>
              <td>{item.itemName}</td>
              <td>{item.itemCondition}</td>
              <td>{item.itemPrice}</td>
            </tr>
          ))}
        <tr>
          <td>X</td>
          <td>X</td>
          <td>{contractData.price}</td>
        </tr>
      </table>

      <Contract.Span style={{ marginBottom: '20px' }}>
        Подписи сторон
      </Contract.Span>

      <Contract.RowGrid gridCols="1fr 1fr" gap={10} style={{ width: '100%'}}>
        <div>
          <Contract.P style={{ marginBottom: '20px' }}>Продавец</Contract.P>
          <Contract.P style={{ marginRight: '10mm'}}>
            {`________________________`}
          </Contract.P>
          <Contract.P>
            {`${getClientFullName(formData)}`}
          </Contract.P>
        </div>

        <div >
          <Contract.P style={{ marginBottom: '20px' }}>Покупатель</Contract.P>
          <Contract.P style={{ marginRight: '10mm'}}>
            {`________________________`}
          </Contract.P>
          <Contract.P>
            {`${contractData.employeeName}`}
          </Contract.P>
        </div>
      </Contract.RowGrid>

      <Button
        onClick={print}
        margin={'5% 0 0 0'}
        styles={{
          alignSelf: 'flex-end',
        }}
      >
        РАСПЕЧАТАТЬ
      </Button>
    </Contract.Wrapper>
  )
}
