import { useTheme } from "styled-components";
import { styled } from "../../helpers/styled";
import { usePartnerDetail } from "../../pages/Partner";
import { Container, Typography } from "../ui";
import { bp } from "../../theme/media";

const Label = styled.div`
  background-color: ${props => props.theme.colors.accent.default};
  padding: 4px 8px;
  border-radius: 20px;
`

export default function PartnerSpecify () {
  const { data } = usePartnerDetail();
  const theme = useTheme()

  if (!data) return null;

  return (
    <Container.Flex alignItems="start" styles={{
        width: "calc(50% - 18px)"
    }}
        breakpoints={{
            [bp.mobile]: {
                width: "100%"
            }
        }}>
      <Typography.Title secondary margin={"0 4px 16px"}>Категории товаров</Typography.Title>

      <Container.Flex direction="row" fullWidth wrapped gap={8} margin="0 0 32px">
        {data.goodsCategories.map(cat => (
          <Label key={cat}>
            <Typography.Medium margin={0} color={theme.colors.text.contrast}>
              {cat}
            </Typography.Medium>
          </Label>
        ))}
      </Container.Flex>

      <Typography.Title margin={"0 4px 16px"} secondary>Проводимые операции</Typography.Title>

      <Container.Flex direction="row" fullWidth wrapped gap={8} margin="0 0 32px">
        {data.registrationTypes.map(type => (
          <Label key={type}>
            <Typography.Medium margin={0} color={theme.colors.text.contrast}>
              {type}
            </Typography.Medium>
          </Label>
        ))}
      </Container.Flex>
    </Container.Flex>
  )
}