import { Container } from '../../ui'
import SafePackageInput, {
  SafePackageOnComplete,
} from '../../ui/Input/SafePackageInput'

interface ITransportApplicationInput {
  search: (v: string) => void
  pending: boolean
  afterChange?: () => void
}

export default function TransportApplicationInput(
  props: ITransportApplicationInput,
) {
  const onComplete: SafePackageOnComplete = ({ value, reset }) => {
    if (value.length === 6) {
      props.search(value)
      reset()
    }
  }

  return (
    <Container.Flex fullWidth className="relocation-input-wrapper">
      <SafePackageInput
        onComplete={onComplete}
        pending={props.pending}
        afterChange={props.afterChange}
      />
    </Container.Flex>
  )
}
