import { memo, useMemo, useEffect, Fragment, PropsWithChildren } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  GetOrder,
  getOrderItemData,
  resetOrderErrors,
} from '../../store/orderSlice'
import { OrderItem, OrderStatuses } from '../../store/orderSlice/types'
import { Typography } from '../ui'
import OrderFinal from './OrderFinal'
import OrderShortItem from './OrderShortItem'
import PageLoader from '../Layout/PageLoading'
import OrderBottomCard from './OrderBottomCard'
import { useSession } from '../../hooks/useSession'
import { DeviceStatuses } from '../../types'
import OrderTopButton from './OrderFinal/OrderTopButton'
import OrderPackNumbers from './OrderModals/OrderPackNumbers'
import OrderImages from './OrderModals/OrderImages'
import CardsGrid from '../ui/Container/CardsGrid'
import { useOrderContext } from './OrderContext'

interface OrderProps {
  orderNumber: string
  itemNumber?: string
  withoutHeader?: true
  withoutLoader?: true
  withoutDelete?: true
}

const getProgress = (item: OrderItem) => {
  switch (item.status) {
    case DeviceStatuses.Confirming:
      return 0.75
    case DeviceStatuses.Confirmed:
      return 1
    default:
      return 1
  }
}

const Order = (props: PropsWithChildren<OrderProps>) => {
  const { orderNumber, itemNumber, withoutLoader } = props
  const dispatch = useDispatch()
  const { orderButton } = useOrderContext()

  const { isAdmin } = useSession()

  const { isLoading, orderData, showResultFor, errors } =
    useSelector(getOrderItemData)

  const error = useMemo(() => {
    if (!errors?.length) return ''

    return errors.reduce((acc, next) => acc + next.message, '')
  }, [errors])

  useEffect(() => {
    dispatch(GetOrder.request({ orderNumber }))

    return () => {
      dispatch(resetOrderErrors)
    }
  }, [])

  const showOrderBottomCard = useMemo(() => {
    if (!orderData?.status) return false

    if (
      orderData.status === OrderStatuses.Confirm &&
      !!orderData.registration
    ) {
      return false
    }
    if (
      [
        OrderStatuses.Done,
        OrderStatuses.Ready,
        OrderStatuses.Packed,
        OrderStatuses.Accept,
        OrderStatuses.Confirm,
      ].includes(orderData.status)
    ) {
      return true
    }

    if (orderData.registration) return false

    if (orderData.notOwner && !isAdmin) return false

    if (orderData.items.some(el => el.status === DeviceStatuses.Confirmed)) {
      return true
    }
    return false
  }, [orderData?.status])

  const showOrderFinal = useMemo(() => {
    if (!orderData) return false
    if (orderData.status === OrderStatuses.Done) {
      return false
    }
    if (orderData.status === OrderStatuses.Ready) {
      return false
    }
    if (orderData.registration) {
      return true
    } else {
      return false
    }
  }, [orderData])

  const showItemList = useMemo(() => {
    if (!orderData) return false
    if (orderData.status === OrderStatuses.Done) return true
    if (orderData.status === OrderStatuses.Ready) return true

    if (!orderData.registration) {
      return true
    }
  }, [orderData])


  return (
    <Fragment>
      <CardsGrid twoColumns={isAdmin}>
        {props.children}
        <OrderPackNumbers />
        <OrderImages />
        {!!error && <Typography.Error>{error}</Typography.Error>}
        {!!orderData && !isLoading && (
          <Fragment>
            {!!orderButton && <OrderTopButton orderData={orderData} />}

            {showOrderFinal && <OrderFinal data={orderData} />}
            {showItemList && (
              <Fragment>
                {!!orderData && (
                  <Fragment>
                    {!!orderData &&
                      !isLoading &&
                      orderData?.items
                        .filter(el => el.itemNumber !== itemNumber)
                        .map(item => (
                          <OrderShortItem
                            notOwner={orderData.notOwner}
                            orderNumber={orderData.number}
                            itemData={item}
                            key={item.itemNumber}
                            progress={getProgress(item)}
                            currency={
                              orderData.currencyLang || orderData.currency
                            }
                            showResult={item.itemNumber === showResultFor}
                            withoutLoader={withoutLoader}
                          />
                        ))}
                  </Fragment>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </CardsGrid>
      {showOrderBottomCard && !!orderData && (
        <OrderBottomCard orderData={orderData} />
      )}

      {isLoading && <PageLoader />}
    </Fragment>
  )
}

export default memo(Order)
