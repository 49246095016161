import PageLayout from './PageLayout'
import CourierWrapper from './CourierWrapper'
import { StockList } from '../components'
import { URLS } from '../apinew'
import { PATHS } from './PATHS'

const CourierStockList = () => {

  return (
    <PageLayout withProfile>
      <CourierWrapper>
        <StockList
          fultersUrl={URLS.GET_STOCKS_FILTERS}
          dataURL={URLS.GET_STOCKS}
          title={'Список складов'}
          topButton={{
            title: 'Новое перемещение',
            href: PATHS.COURIER_TRANSPORT_APPLICATION,
          }}
          searchPlaceholder={'Поиск склада'}
          />
      </CourierWrapper>
    </PageLayout>
  )
}

export default CourierStockList
