import { ComponentType, useCallback, useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ListComponentProps } from '.'
import Api, { URLS } from '../../apinew'
import { filterArrayByKey } from '../../helpers/filterArrayByKey'
import { getErrorString } from '../../helpers/getErrorString'
import { ResponseData } from '../../store/types'
import Filters, { FiltersContext, FiltersPart } from '../Filters/FiltersContext'
import LowerMenuContent from '../LowerMenu/LowerMenuContent'
import { LowerMenuItem } from '../LowerMenu/types'
import { Container, Typography } from '../ui'
import ListDataCounts from './ListDataCounts'
import ListDataTop from './ListDataTop'

export type CounterItem = {
  title: string
  count: number | string
  type: 'price' | 'number'
}

export type ListDataResponse<T> = {
  data: T[]
  counterItems: CounterItem[]
}

export type ListDataProps<T> = {
  fultersUrl?: URLS
  getMockFilters?: () => Promise<ResponseData<FiltersPart[]>>
  getMockData?: () => Promise<
    ResponseData<{ data: T[]; counterItems: CounterItem[] }>
  >
  dataURL: URLS
  title: string
  topButton: {
    title: string
    onClick?: () => void
    href?: string
  }
  searchPlaceholder: string
  sliceCount?: number
  lowerMenuItems?: LowerMenuItem[]
  inputType?: 'default' | 'pack'
  filterBy?: {
    key: keyof T
    innerKey?: string
  }
}

const SLICE_COUNT = 10

const withListData =
  <T extends object>(Component: ComponentType<ListComponentProps<T>>) =>
  (props: ListDataProps<T>) => {
    const [data, setData] = useState<T[]>([])
    const [filtredData, setFiltredData] = useState<T[]>([])
    const [frontFilterString, setFrontFilterString] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [counerItems, setCounterItems] = useState<CounterItem[]>([])
    const [topButtonDisabled, setTopButtonDisabled] = useState(false)

    const inputRef = useRef<HTMLInputElement>(null)

    const sliceCount = props.sliceCount ?? SLICE_COUNT

    const [count, setCount] = useState({
      prev: 0,
      next: sliceCount,
    })

    const [hasMore, setHasMore] = useState(SLICE_COUNT < data.length)
    const [current, setCurrent] = useState(data.slice(count.prev, count.next))

    useEffect(() => {
      const newList = [...filtredData]
      setCurrent(newList.slice(0, SLICE_COUNT))
      setCount({ prev: 0, next: SLICE_COUNT })
      setHasMore(SLICE_COUNT < filtredData.length)
    }, [filtredData])

    const getMoreData = () => {
      if (current.length === data.length) {
        setHasMore(false)
        return
      }
      setTimeout(() => {
        setCurrent(
          current.concat(
            data.slice(count.prev + SLICE_COUNT, count.next + SLICE_COUNT),
          ),
        )
      }, 200)
      setCount(prev => ({
        prev: prev.prev + SLICE_COUNT,
        next: prev.next + SLICE_COUNT,
      }))
    }

    const fetchData = useCallback(async (filters?: any) => {
      setLoading(true)
      setError('')
      const api = new Api()
      const searchValue = inputRef.current?.value.replace(/ /g, '')
      try {
        const requestData = {
          ...filters,
        } as any

        if (searchValue) {
          requestData.search = searchValue
        }
        const response = props.getMockData
          ? await props.getMockData()
          : await api._post(props.dataURL)(requestData)
        if (response.status === 'success' && response.data) {
          const data = response.data as ListDataResponse<T>
          setData(data.data ?? [])
          setFiltredData(data.data ?? [])
          setCounterItems(data.counterItems)
        } else if (response.errors.length) {
          setError(getErrorString(response.errors))
        }
      } catch (err: any) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }, [])

    useEffect(() => {
      if (props.getMockData || !props.fultersUrl) {
        fetchData()
      }
    }, [props.getMockData, props.fultersUrl])

    useEffect(() => {
      if (!frontFilterString) {
        setFiltredData(data)
      } else if (props.filterBy) {
        setFiltredData(
          filterArrayByKey(
            data,
            frontFilterString,
            props.filterBy.key,
            props.filterBy.innerKey,
          ),
        )
      }
    }, [frontFilterString])

    return props.fultersUrl ? (
      <Filters
        getFiltersURL={props.fultersUrl}
        getData={fetchData}
        getMockFilters={props.getMockFilters}
      >
        <FiltersContext.Consumer>
          {value => {
            return (
              <Container.Flex fullWidth className="list-data">
                <ListDataTop
                  ref={inputRef}
                  title={props.title}
                  topButton={props.topButton}
                  topButtonHref={props.topButton.href}
                  searchPlaceholder={props.searchPlaceholder}
                  anyFiltersOn={value.filtersOn}
                  onSearch={() => fetchData(value.getFiltersData)}
                  openFilters={() => value.setShowFilters(true)}
                  topButtonDisabled={topButtonDisabled}
                />
                <ListDataCounts items={counerItems} />
                {error && <Typography.Error>{error}</Typography.Error>}
                <InfiniteScroll
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 24,
                  }}
                  dataLength={current.length}
                  next={getMoreData}
                  hasMore={hasMore}
                  loader={null}
                >
                  <Component
                    data={current}
                    loading={loading}
                    setTopButtonDisabled={setTopButtonDisabled}
                    refetch={fetchData}
                  />
                </InfiniteScroll>

                {props.lowerMenuItems && (
                  <LowerMenuContent items={props.lowerMenuItems} />
                )}
              </Container.Flex>
            )
          }}
        </FiltersContext.Consumer>
      </Filters>
    ) : (
      <Container.Flex fullWidth className="list-data">
        <ListDataTop
          ref={inputRef}
          title={props.title}
          topButton={props.topButton}
          searchPlaceholder={props.searchPlaceholder}
          anyFiltersOn={false}
          onSearch={fetchData}
          openFilters={null}
          inputType={props.inputType}
          topButtonDisabled={topButtonDisabled}
          setFrontFilterString={setFrontFilterString}
        />
        <ListDataCounts items={counerItems} />
        {error && <Typography.Error>{error}</Typography.Error>}
        <InfiniteScroll
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
          }}
          dataLength={current.length}
          next={getMoreData}
          hasMore={hasMore}
          loader={null}
        >
          <Component
            data={current}
            loading={loading}
            setTopButtonDisabled={setTopButtonDisabled}
            refetch={fetchData}
          />
        </InfiniteScroll>

        {props.lowerMenuItems && (
          <LowerMenuContent items={props.lowerMenuItems} margin="24px 0 0 0"/>
        )}
      </Container.Flex>
    )
  }

export default withListData
