import SVG from "../SVG";
import { IconProps } from "../types";

const OrderList = (props: IconProps) => {
    return (
        <SVG {...props} width={props.width ?? 24} height={props.height ?? 24}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.82111 2.31651C5.10852 1.52614 5.85969 1 6.7007 1H17.2998C18.1409 1 18.892 1.52613 19.1794 2.31651L22.6566 11.8786C22.8678 12.19 22.9935 12.5641 23 12.9671C23.0006 12.9858 23.0007 13.0045 23.0003 13.0231V20C23.0003 21.6569 21.6571 23 20.0003 23H4.00027C2.34342 23 1.00027 21.6569 1.00027 20V13.0232C0.999838 13.0046 0.999922 12.9861 1.00053 12.9674C1.00697 12.5643 1.1327 12.1901 1.34399 11.8786L4.82111 2.31651ZM6.7007 3H17.2998L20.2089 11H18.0003C16.8577 11 16.1 11.9097 15.9213 12.796C15.5527 14.6239 13.9361 16 12.0003 16C10.0645 16 8.44782 14.6239 8.07927 12.796C7.90058 11.9097 7.14283 11 6.00027 11H3.79161L6.7007 3ZM3.06434 13L3.00027 13.1762V20C3.00027 20.5523 3.44799 21 4.00027 21H20.0003C20.5526 21 21.0003 20.5523 21.0003 20V13.1762L20.9362 13H18.0008L18.0001 13.0003C17.9971 13.0016 17.9861 13.0069 17.9701 13.0219C17.9349 13.0551 17.8971 13.1156 17.8818 13.1913C17.3288 15.9342 14.9069 18 12.0003 18C9.09366 18 6.67177 15.9342 6.11873 13.1913C6.10347 13.1156 6.06562 13.0551 6.0304 13.0219C6.01445 13.0069 6.00349 13.0016 6.0004 13.0003L5.99972 13H3.06434ZM8.00027 5C7.44799 5 7.00027 5.44772 7.00027 6C7.00027 6.55228 7.44799 7 8.00027 7H16.0003C16.5526 7 17.0003 6.55228 17.0003 6C17.0003 5.44772 16.5526 5 16.0003 5H8.00027ZM9.00027 9C8.44799 9 8.00027 9.44772 8.00027 10C8.00027 10.5523 8.44799 11 9.00027 11H15.0003C15.5526 11 16.0003 10.5523 16.0003 10C16.0003 9.44772 15.5526 9 15.0003 9H9.00027Z"
            />
        </SVG>
    );
};

export default OrderList;
