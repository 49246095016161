import { useTheme } from 'styled-components'
import {
  Card,
  Container,
  Icon,
  Image,
  RublesWithCentes,
  Typography,
} from '../../ui'
import StatusImage from '../../ui/Image/StatusImage'
import {
  OrderListData,
  OrderListItem as OrderListItemType,
} from './OrderListContext'
import styled from 'styled-components/macro'
import { priceToNumber } from '../../../helpers/priceToNumber'
import { bp } from '../../../theme/media'

interface OrderListItemProps {
  data: OrderListData
}

interface ItemProps {
  data: OrderListItemType
  currency: string
}

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: minmax(20px, max-content);
  align-items: start;
  grid-gap: 8px 36px;

  @media (max-width: 768px) {
    grid-template-columns: auto 1fr 36%;
    grid-gap: 16px;
  }
`

const Item = ({ data, currency }: ItemProps) => {
  const theme = useTheme()

  return (
    <Container.Flex
      direction="row"
      alignItems="start"
      fullWidth
      styles={{
        marginBottom: "12px"
      }}

      breakpoints={{
        [bp.mobile]: {
          marginBottom: "16px"
        }
      }}
    >
      <Container.Flex
        styles={{ width: '40px', height: '40px', flexShrink: 0, fontSize: "12px" }}
        margin={'0 16px 0 0'}
        breakpoints={{
          [bp.mobile]: {
            width: '60px',
            height: '60px',
          },
        }}
      >
        {data.image && (
          <Image
            src={data.image}
            alt={data.itemNumber}
            fullWidth
            fullHeight
            styles={{ borderRadius: '8px' }}
          />
        )}
        {!data.image && <Icon name="photo" fullHeight fullWidth />}
      </Container.Flex>
      <Container.Flex alignItems="stretch" styles={{ width: 'calc(100% - 56px)'}} verticalGap={4}>
        <Typography.Title fromStart margin={'0 0 0 0'}>
          {data.name}
        </Typography.Title>

        <Container.Flex
          direction="row"
          alignItems="stretch"
          verticalGap={4}
          breakpoints={{
            [bp.mobile]: {
              flexFlow: 'column',
            },
          }}
        >
          {data.answers &&
            Object.entries(data.answers).map(([key, value]) => (
              <Container.Flex
                direction={'row'}
                styles={{ marginRight: '12px' }}
                key={key}
                alignItems="stretch"
                horizontalGap={4}
                breakpoints={{
                  [bp.mobile]: {
                    marginRight: '0',
                  },
                }}
              >
                <Typography.Small
                  fromStart
                  margin={'0 0 0 0'}
                  styles={{
                    color: theme.colors.text.tertiary,
                  }}
                >
                  {key}
                </Typography.Small>
                <Typography.Small fullHeight styles={{ minWidth: '120px' }} fromStart margin={0}>
                  {value}
                </Typography.Small>
              </Container.Flex>
            ))}
          <Container.Flex direction="row" horizontalGap={4}>
            <StatusImage status={data.status} noMargin/>
            <Typography.Small textAlign="start">{`${data.price} ${currency}`}</Typography.Small>
          </Container.Flex>
        </Container.Flex>
      </Container.Flex>
    </Container.Flex>
  )
}

export default function OrderListItem({ data }: OrderListItemProps) {
  const theme = useTheme()

  return (
    <Card padding={28} margin={'0 0 24px 0'}>
      <ItemGrid>
        <Typography.Title
          link={`/order/${data.number}/`}
          margin="0"
          fromStart
          styles={{
            width: "fit-content",
            color: theme.colors.text.secondary,
            borderBottom: '2px dotted currentColor',
            textTransform: 'lowercase',
            textRendering: 'optimizeSpeed'
          }}
          breakpoints={{
            [bp.mobile]: {
              margin: "0 4px"
            }
          }}
        >
          {data.number}
        </Typography.Title>

        <Typography.Medium
          withIndicator
          margin={0}
          indicatorColor={data.statusColor}
          color={data.statusColor}
          styles={{
            gridColumn: '3 / 4',
            gridRow: '1 / 2',
            justifyContent: 'flex-end',
          }}
          breakpoints={{
            [bp.mobile]: {
              gridColumn: '2 / 4',
              gridRow: '1 / 2',
              marginRight: '4px',
            },
          }}
        >
          {data.status}
        </Typography.Medium>
        <Container.Flex
          styles={{
            gridColumn: '2 / 3',
            gridRow: '1 / 3',
            marginBottom: "-12px"
          }}
          breakpoints={{
            [bp.mobile]: {
              gridColumn: '1 / 4',
              gridRow: '2 / 3',
              marginBottom: "-16px"
            },
          }}
        >
          {data.items &&
            data.items.map(item => (
              <Item
                data={item}
                key={item.itemNumber}
                currency={data.currency}
              />
            ))}
        </Container.Flex>
        <Container.Flex
          direction="row"
          justify="end"
          styles={{
            gridColumn: '3 / 4',
            gridRow: '2 / 3',
            marginTop: '-8px',
          }}
          breakpoints={{
            [bp.mobile]: {
              justifyContent: 'flex-start',
              marginTop: 0,
              gridColumn: '1 / 3',
              gridRow: '3 / 4',
              marginLeft: '4px',
            },
          }}
        >
          {!!data.registration && (
            <Typography.Medium
              margin={'0 4px 0 0'}
              textAlign="start"
              styles={{
                color: theme.colors.text.tertiary,
              }}
            >
              {data.registration}:
            </Typography.Medium>
          )}
          <RublesWithCentes price={"" + priceToNumber(data.amount)} currency={data.currency} />
        </Container.Flex>
        <Typography.Micro
          secondary
          margin={0}
          styles={{
            gridColumn: '1 / 2',
            gridRow: '2 / 3',
          }}
          breakpoints={{
            [bp.mobile]: {
              gridColumn: '3 / 4',
              gridRow: '3 / 4',
              textAlign: 'right',
              marginRight: '4px',
              alignSelf: 'center'
            },
          }}
        >
          {data.dateCreate}
        </Typography.Micro>
      </ItemGrid>
    </Card>
  )
}
