import PageLayout from './PageLayout'
import useQuery from '../hooks/useQuery'
import NotFound from './404'
import { Container, Typography } from '../components/ui'
import { bp } from '../theme/media'
import { useTheme } from 'styled-components'
import UploadImages from '../components/ui/FilesContainer/UploadImages'
import LoginCard from '../components/Login/LoginCard'
import { useSession } from '../hooks/useSession'
import LoginContext from '../components/Login/LoginContext'

const isString = (n: any) => typeof n === 'string' || typeof n === 'number'

const UploadPage = () => {
  const { getParams } = useQuery()
  const theme = useTheme()

  const itemNumber = getParams['itemNumber'] as string
  const orderNumber = getParams['orderNumber'] as string

  const { session } = useSession()

  if (![itemNumber, orderNumber].every(isString)) {
    return (
      <PageLayout>
        <NotFound />
      </PageLayout>
    )
  }

  if (!session.isAuthorised) {
    return (
      <PageLayout>
        <LoginContext>
          <Container.CardsGrid>
            <LoginCard noRedirect />
          </Container.CardsGrid>
        </LoginContext>
      </PageLayout>
    )
  }

  return (
    <PageLayout withProfile>
      <Container.Flex fullWidth>
        <Container.Flex
          fullWidth
          direction="row"
          justify="start"
          styles={{
            position: 'relative',
            marginBottom: '12px',
          }}
          breakpoints={{
            [bp.mobile]: {
              justifyContent: 'space-between',
            },
          }}
        >
          <Typography.Title
            styles={{
              textRendering: 'optimizeSpeed',
            }}
            hoverStyles={{
              cursor: 'pointer',
              color: theme.colors.link.hover,
            }}
            margin={'0 16px 0 0'}
            padding={'0 0 0 4px'}
          >{`Заявка ${orderNumber.toLowerCase()}`}</Typography.Title>
        </Container.Flex>
        <Container.CardsGrid twoColumns>
          <UploadImages itemNumber={itemNumber} orderNumber={orderNumber} />
        </Container.CardsGrid>
      </Container.Flex>
    </PageLayout>
  )
}

export default UploadPage
