import { ReactNode, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { LowerMenu } from '../components'
import Profile from '../components/Profile'
import { Container, Typography } from '../components/ui'
import { styled } from '../helpers/styled'
import { useSession } from '../hooks/useSession'
import { RootState } from '../store'
import { motion, AnimatePresence } from 'framer-motion'
import useQuery from '../hooks/useQuery'
import OrderHeader from '../components/Order/OrderHeader'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { OrderStatuses } from '../store/orderSlice/types'
import {
  useUpdateOrderStatusMutation,
} from '../store/api/api'
import { GetOrder } from '../store/orderSlice'
import { bp } from '../theme/media'
import OrderContext from '../components/Order/OrderContext'

const LeftPart = styled.div`
  display: flex;
  margin-right: 17px;
  flex-shrink: 0;

  @media screen and (max-width: 1280px) {
    display: none;
  }
`

const PageSeparator = styled.div`
  width: 1px;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    #d8d8d8 15%,
    transparent 85%
  );
  margin-left: 18px;
`

export default function PageLayout({
  withProfile,
  secure,
  children,
  customLowerMenu
}: {
  children: ReactNode
  width?: string
  withProfile?: true
  secure?: true
  customLowerMenu?: true
}) {
  const userData = useSelector((state: RootState) => state.user.userData)

  const orderData = useAppSelector(state => state.order.order.data)
  const dispatch = useAppDispatch()
  const { orderNumber } = useQuery()

  const { userRole, isAdmin, session, isCourer } = useSession()

  const [
    updateOrderStatus,
    { isSuccess: updateOrderStatusSuccess, error: updateOrderErrror },
  ] = useUpdateOrderStatusMutation()

  const showDeleteButton = useMemo(() => {
    if (!orderData?.status) return false
    if (!isAdmin) return false
    const withDeleteStatuses: OrderStatuses[] = [
      OrderStatuses.New,
      OrderStatuses.Accept,
      OrderStatuses.Checking,
      OrderStatuses.Confirm,
    ]
    if (!withDeleteStatuses.includes(orderData.status)) return false

    return true
  }, [orderData?.status, isAdmin])

  const setOrderReady = () => {
    updateOrderStatus({
      data: { number: orderNumber ?? '', statusCode: 'RD' },
      session,
    })
  }

  useEffect(() => {
    if (updateOrderStatusSuccess) {
      dispatch(GetOrder.request({ orderNumber }))
    }
  }, [updateOrderStatusSuccess])

  const location = useLocation()

  if (!isAdmin && secure)
    return (
      <Typography.Title>
        У вас нет прав для просмотра этой страницы
      </Typography.Title>
    )

  const updateOrderStatusError = updateOrderErrror as
    | { data: string }
    | undefined

  return (
    <OrderContext>
      <AnimatePresence key={location.pathname}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Container.Flex fullWidth gap={24}>
            <Container.Flex
              className="page-layout"
              fullWidth
              fullHeight
              direction="row"
              alignItems="stretch"
              justify="center"
              margin={'auto'}
              styles={{
                flexShrink: 0,
                minHeight: 'calc(100vh - 202px)',
              }}
            >
              {withProfile &&
                !!userData &&
                (isAdmin || isCourer) && (
                  <LeftPart>
                    <Profile />
                    <PageSeparator />
                  </LeftPart>
                )}
              <Container.Flex fullWidth gap={24} relative>
                {!!updateOrderStatusError?.data && (
                  <Typography.Error>
                    {updateOrderStatusError.data}
                  </Typography.Error>
                )}
                {orderNumber && isAdmin && (
                  <OrderHeader
                    orderNumber={orderNumber}
                    userRole={userRole}
                    date={orderData?.dateCreate}
                    orderStatus={orderData?.status}
                    orderStatusColor={orderData?.statusColor}
                    setOrderReady={setOrderReady}
                    showDeleteButton={showDeleteButton}
                  />
                )}

                <Container.Flex
                  direction="row"
                  alignItems="start"
                  justify="start"
                  wrapped
                  fullHeight
                  gap={36}
                  styles={{
                    width: '100%',
                  }}
                  breakpoints={{
                    [bp.mobile]: {
                      minHeight: 'auto',
                      gap: '24px',
                    },
                  }}
                >
                  {children}
                </Container.Flex>
              </Container.Flex>
            </Container.Flex>
            {!customLowerMenu && <LowerMenu />}
          </Container.Flex>
        </motion.div>
      </AnimatePresence>
    </OrderContext>
  )
}
