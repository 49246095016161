import SVG from '../SVG'
import { IconProps } from '../types'

const EyeClose = (props: IconProps) => {
  return (
    <SVG {...props} width={props.width ?? 22} height={props.height ?? 20}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.497 9.095a1.035 1.035 0 0 1 1.405.398l.001.001.013.022.068.108c.066.1.17.25.318.434.294.37.754.872 1.398 1.377 1.28 1.001 3.31 2.03 6.3 2.03 2.99 0 5.02-1.029 6.3-2.03a8.46 8.46 0 0 0 1.398-1.377 6.04 6.04 0 0 0 .386-.542l.013-.022a1.035 1.035 0 0 1 1.808 1.009L20 10c.905.503.904.503.904.504l-.002.003-.004.007-.01.018a3.947 3.947 0 0 1-.144.23 8.068 8.068 0 0 1-.427.586 10.1 10.1 0 0 1-.733.822l1.123 1.123a1 1 0 1 1-1.414 1.414l-1.25-1.25a11.383 11.383 0 0 1-2.848 1.42l.286 1.427a1 1 0 1 1-1.961.392l-.268-1.34c-.698.115-1.447.179-2.252.179-.805 0-1.554-.064-2.252-.178l-.267 1.34a1 1 0 1 1-1.962-.393l.286-1.426a11.383 11.383 0 0 1-2.848-1.421l-1.25 1.25a1 1 0 1 1-1.414-1.414l1.123-1.123c-.297-.3-.54-.58-.733-.822a8.101 8.101 0 0 1-.54-.763 4.388 4.388 0 0 1-.031-.054l-.01-.017-.004-.007-.002-.002v-.001L2 10l-.905.503c-.277-.5-.097-1.13.402-1.408Z"
        clipRule="evenodd"
      />
    </SVG>
  )
}

export default EyeClose
