import { memo, useEffect, useMemo, useState } from 'react'
import { Typography, Container } from '../../../ui'
import { useTheme } from 'styled-components'
import './FiltersAnimations.css'
import { PERIOD_FILTER_KEY, useOrderListData } from '../OrderListContext'
import cls from 'classnames'

const classes = {
  hide: 'hide-filter-header-content',
  show: 'show-filter-header-content',
}

const FiltersHeader = () => {
  const theme = useTheme()
  const {
    getFilterIsActive,
    filtersOn,
    dateValue,
    activeFilter,
    filters,
    resetAllFilters,
    resetPeriodFilters,
    resetOneFilter,
  } = useOrderListData()

  const [lastItemTitle, setLastItemTitle] = useState('')

  const disable = useMemo(() => {
    if (activeFilter) {
      if (activeFilter === 'Период') {
        return !(dateValue.from || dateValue.to)
      } else {
        return !getFilterIsActive(activeFilter ?? '')
      }
    } else {
      return !filtersOn && !(dateValue.from || dateValue.to)
    }
  }, [activeFilter, getFilterIsActive, dateValue])

  useEffect(() => {
    const getHeaderTitle = () => {
      if (activeFilter === PERIOD_FILTER_KEY) return PERIOD_FILTER_KEY
      if (!activeFilter) return 'Фильтр'
      const filter = filters.find(el => el.propertyName === activeFilter)
      if (filter) return filter.name
      return ''
    }
    if (activeFilter) {
      setLastItemTitle(getHeaderTitle())
    }
  }, [activeFilter])

  const headerAction = !activeFilter
    ? resetAllFilters
    : activeFilter === PERIOD_FILTER_KEY
    ? resetPeriodFilters
    : resetOneFilter(activeFilter)

  return (
    <Container.Flex
      fullWidth
      margin={'0 0 32px 0'}
      styles={{ height: '20px', flexShrink: 0, position: 'relative' }}
    >
      <Container.Flex
        fullWidth
        direction="row"
        justify="between"
        className={cls({
          [classes.hide]: !!activeFilter,
          [classes.show]: !activeFilter,
        })}
        styles={{ height: '20px', position: 'absolute', top: 0, left: 0 }}
      >
        <Typography.Title
          color={theme.colors.text.secondary}
          margin={0}
          styles={{
            paddingLeft: "4px"
          }}
        >
          Фильтр
        </Typography.Title>

        <Typography.Medium
          margin={0}
          hoverStyles={{
            cursor: 'pointer',
          }}
          color={
            !!headerAction && !disable
              ? theme.colors.link.default
              : theme.colors.text.quaternary
          }
          onClick={!disable ? headerAction : undefined}
        >
          Сбросить всё
        </Typography.Medium>
      </Container.Flex>

      <Container.Flex
        fullWidth
        direction="row"
        justify="between"
        className={cls({
          [classes.hide]: !activeFilter,
          [classes.show]: !!activeFilter,
        })}
        styles={{ height: '20px', position: 'absolute', top: 0, left: 0 }}
      >
        <Typography.Title color={theme.colors.text.secondary} margin={0} styles={{
          paddingLeft: "4px"
        }}>
          {lastItemTitle}
        </Typography.Title>

        <Typography.Medium
          margin={0}
          hoverStyles={{
            cursor: 'pointer',
          }}
          color={
            !!headerAction && !disable
              ? theme.colors.link.default
              : theme.colors.text.quaternary
          }
          onClick={!disable ? headerAction : undefined}
        >
          Сбросить
        </Typography.Medium>
      </Container.Flex>
    </Container.Flex>
  )
}

export default memo(FiltersHeader)
