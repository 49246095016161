import SVG from '../SVG'
import { IconProps } from '../types'

const UserSaveIcon = (props: IconProps) => {
  return (
    <SVG {...props} width={21} height={20}>
      <g clipPath="url(#clip0_6245_15958)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 6C4.5 4.34315 5.84315 3 7.5 3C9.15685 3 10.5 4.34315 10.5 6C10.5 7.65685 9.15685 9 7.5 9C5.84315 9 4.5 7.65685 4.5 6ZM7.5 1C4.73858 1 2.5 3.23858 2.5 6C2.5 8.76142 4.73858 11 7.5 11C10.2614 11 12.5 8.76142 12.5 6C12.5 3.23858 10.2614 1 7.5 1ZM20.2071 7.29289C20.5976 7.68342 20.5976 8.31658 20.2071 8.70711L16.2071 12.7071C15.8166 13.0976 15.1834 13.0976 14.7929 12.7071L12.7929 10.7071C12.4024 10.3166 12.4024 9.68342 12.7929 9.29289C13.1834 8.90237 13.8166 8.90237 14.2071 9.29289L15.5 10.5858L18.7929 7.29289C19.1834 6.90237 19.8166 6.90237 20.2071 7.29289ZM3.81206 15.0036C2.90506 15.6083 2.5 16.3338 2.5 17C2.5 17.5523 2.05228 18 1.5 18C0.947715 18 0.5 17.5523 0.5 17C0.5 15.457 1.43809 14.1826 2.70266 13.3395C3.97422 12.4918 5.67326 12 7.5 12C9.32674 12 11.0258 12.4918 12.2973 13.3395C13.5619 14.1826 14.5 15.457 14.5 17C14.5 17.5523 14.0523 18 13.5 18C12.9477 18 12.5 17.5523 12.5 17C12.5 16.3338 12.0949 15.6083 11.1879 15.0036C10.2879 14.4036 8.98697 14 7.5 14C6.01303 14 4.71207 14.4036 3.81206 15.0036Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6245_15958">
          <rect
            width="20"
            height="20"
            fill="currentColor"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </SVG>
  )
}

export default UserSaveIcon
