import styled from 'styled-components/macro'
import { getCommonProps, collectTypographyStyles } from '../../../types'
import { TextProps } from '../types'
import { getTextAlign } from './helpers'
import { IndicatorStyles } from '..'
import Icon from '../../Icon'
import { useLink } from './useLink'

export const ExptraWrapper = styled.p<TextProps>`
  color: ${props => props.theme.colors.text.default};
  ${props => getTextAlign(props)};
  ${props => ({
    ...props.theme.typography.extra,
    ...props.styles,
  })};
  ${props => getCommonProps(props)};
  ${props => collectTypographyStyles(props)};
  ${props => (props.color ? { color: props.color } : {})};
  ${props =>
    props.secondary ? { color: props.theme.colors.text.secondary } : {}};
  transition: ${props => (props.noTransition ? 'none' : 'all 200ms ease-in')};
`

export default function Extra(props: TextProps) {
  const { navigate } = useLink()

  return props.html ? (
    <ExptraWrapper {...props} dangerouslySetInnerHTML={{__html: props.html}}/>
  ) : (
    <ExptraWrapper
      {...props}
      onClick={props.onClick ?? navigate(props.link)}
    >
      {props.children}
      {props.withIndicator && (
        <Icon
          name="color-indicator"
          styles={IndicatorStyles}
          color={props.indicatorColor}
        />
      )}
    </ExptraWrapper>
  )
}
