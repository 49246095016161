import { Props } from '../types'

type ImageProps = Props<{
  src: string
  width: string
  height: string
  borderRadius?: string
  objectFit?: any
}>

const Image = (props: ImageProps) => {
  const base = process.env.REACT_APP_IMAGE_BASE ?? ''

  return (
  <img
    src={base + props.src}
    style={{
      width: props.width,
      height: props.height,
      borderRadius: props.borderRadius,
      objectFit: props.objectFit,
    }}
  />
  )
}

export default Image
