import { Button, Container } from "../../ui";
import { OfferQuestionProps } from "./OfferQuestion";
import { collectAnswerData } from "../helpers/collectAnswerData";
import { Answer } from "../../../store/offerSlice/types";
import { isString } from "../../../store/types";

const FromList = (props: OfferQuestionProps) => {

    const { questionData, giveAnswer } = props;
    const { answers } = questionData;

    const onClick = (answerName: string, answerId: string, alternateName: isString) => {
        giveAnswer(
            collectAnswerData({questionData, answerName, answerId, alternateName}),
        );
    };

    const sorter = (acc: [string, Answer], next: [string, Answer]) => acc[1].order - next[1].order;

    return (
        <>
            {!!answers &&
                <Container.Flex
                    fullWidth
                    direction="row"
                    justify="between"
                    wrapped
                    gap={16}
                    alignItems="center"
                >
                    {Object.entries(answers).sort(sorter).map(([answerId, answer], idx) => {
                        const { answerName, alternateName } = answer;
                        return (
                            <Button
                                styles={{ width: "calc(50% - 8px)", height: "max-content" }}
                                key={idx}
                                variant="outline"
                                onClick={() => onClick(answerName, answerId, alternateName)}
                                withLoader
                            >
                                {answerName}
                            </Button>
                        );
                    })}
                </Container.Flex>
            }
        </>
    );
};

export default FromList;
