export const filterArrayByKey = <T>(data: T[], value: string, key: keyof T, innerKey?: string) => {

  return data.filter(el => {
    const target = el[key];

    if (typeof target === 'string') {
      return target.match(value)
    }

    if (Array.isArray(target)) {
      return target.some(el => {
        if (typeof el === 'string') {
          return el.match(value)
        }
        if (typeof el === 'object' && innerKey) {
          const innerValue = el[innerKey];
          if (typeof innerValue === 'string') {
            return innerValue.match(value)
          }
        }
      })

      return true
    }

    return true

  })

}