import { usePartnerDetail } from '../../pages/Partner'
import { Card, Container } from "../ui";
import PartnerAbout from './PartnerAbout'
import PartnerAddresses from './PartnerAdresses'
import PartnerSpecify from './PartnerSpecify'


export default function PartnerDesctiptionCard() {
  const { data } = usePartnerDetail()

  if (!data) return null

  return (
    <Card padding={28} styles={{flexShrink: 1}}>
      <Container.Flex
        direction="row"
        justify="between"
        alignItems="start"
        wrapped
      >
        <PartnerAbout />
        <PartnerSpecify />
        <PartnerAddresses />
      </Container.Flex>
    </Card>
  )
}
