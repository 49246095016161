import styled, { css } from 'styled-components'
import { TextInputProps } from './types'

export const commonCSS = css`
  width: 100%;
  height: 40px;
  position: relative;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  padding: 10px 12px 10px 16px;
  outline: none;
  &::placeholder {
    color: ${props => props.theme.colors.text.secondary};
  }
  &:focus {
    border: none;
  }
  &:active {
    border: none;
  }
`

export const TextInput = styled.input<TextInputProps>`
  ${commonCSS};
  background-color: ${props =>
    props.error
      ? props.theme.colors.background.error70
      : props.success
      ? props.theme.colors.background.verified70
      : '#fff'};
  color: ${props => props.theme.colors.text.default};
`
