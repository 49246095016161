import { Fragment } from "react";
import { useTheme } from "styled-components";
import { Typography, Box, Info } from '../ui'
import QRCode from "react-qr-code";

interface OfferQrCodeProps {
  itemNumber: string
}

export default function OfferQrCode ({ itemNumber } : OfferQrCodeProps ) {

  const theme = useTheme()

  return (
    <Fragment>
    <Typography.Title fullWidth secondary fromStart padding="0 4px" margin="0 0 8px">QR-код и номер устройства</Typography.Title>
    <Box
      padding={10}
      styles={{
        alignSelf: 'center',
        backgroundColor: '#fff',
        borderRadius: '12px',
        fontSize: '0',
        lineHeight: '0',
      }}
    >
      <QRCode size={108} value={itemNumber} />
    </Box>
    <Typography.Title secondary fullWidth margin={'0'}>
      {itemNumber}
    </Typography.Title>
    <Info>
      <Typography.Main margin={0} fromStart color={theme.colors.text.secondary}>
        Покажите этот QR-код партнёру для быстрого поиска
      </Typography.Main>
    </Info>
    </Fragment>

  )
}