import { createPortal } from 'react-dom'
import { Card, Container, Typography, Button } from '../ui'
import { ButtonVariants } from '../ui/Button/Button'
import { useEffect, useRef } from 'react'
import { classNames } from '../../styles/classNames'

interface IErrorPopup {
  visible: boolean
  title?: string
  textBlocks?: string[]
  buttons?: Array<{
    text: string
    onClick: () => void
    variant?: ButtonVariants
  }>
  hide?: () => void
}

export default function ErrorPopup({
  visible,
  title,
  textBlocks = [],
  buttons,
  hide,
}: IErrorPopup) {
  const root = document.getElementById('custom-error-portal')

  const conteinerRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)

  const onAnimationEnd = () => {
    if (!conteinerRef.current) return
    conteinerRef.current.style.display = visible ? 'flex' : 'none'
  }

  useEffect(() => {
    if (!ref.current) return
    if (conteinerRef.current) {
      if (visible) conteinerRef.current.style.display = 'flex'
    }
    if (visible) {
      ref.current.classList.remove(classNames.hideToRight)
      ref.current.classList.add(classNames.showFromLeft)
    } else {
      ref.current.classList.remove(classNames.showFromLeft)
      ref.current.classList.add(classNames.hideToRight)
    }
  }, [visible])

  if (!root) return null

  return createPortal(
    <Container.Flex
      ref={conteinerRef}
      fullWidth
      fullHeight
      className="popup-wrapper"
      styles={{ display: 'none' }}
    >
      <Card
        maxWidth={344}
        ref={ref}
        onAnimationEnd={onAnimationEnd}
        className="card"
        styles={{ backgroundColor: "#FFFFFF"}}
        padding={28}
      >
        <Container.Flex gap={24} alignItems='start' fullWidth>
          {title && (
            <Typography.TitleSecondary margin={0} fromStart>{title}</Typography.TitleSecondary>
          )}

          {textBlocks.map((el, idx) => (
            <Typography.Main margin={0} fromStart key={idx}>{el}</Typography.Main>
          ))}

          {!buttons && (
            <Button fullWidth onClick={hide}>
              Закрыть
            </Button>
          )}

          {buttons && (
            <Container.Grid fullWidth rows="1fr" cols={`repeat(${buttons.length}, 1fr)`} gap={16}>
              {buttons.map((el, idx) => (
                <Button
                  fullWidth
                  onClick={el.onClick}
                  variant={el.variant ?? 'primary'}
                  key={'button' + idx}
                >
                  {el.text}
                </Button>
              ))}
            </Container.Grid>
          )}
        </Container.Flex>
      </Card>
    </Container.Flex>,
    root,
  )
}
