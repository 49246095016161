import { OrderModalProps, withOrderModal } from './WithOrderModal'
import { Box, Container, Typography } from '../../ui'
import { useOrderContext } from '../OrderContext'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from 'styled-components'
import cls from 'classnames'
import SimpleImage from '../../ui/Image/SimpleImage'

export interface OrderImegesProps {
  images: OrderImage[]
  currentImage: number
}

export interface OrderImage {
  src: string
  name: string
  idx: number
}

export const ShowImage = (props: OrderImage & { active: boolean }) => {
  const theme = useTheme()
  return (
    <Container.Flex
      gap={24}
      alignItems="center"
      className={cls({
        'image-slider-item': true,
        'image-slider-item-active': props.active,
      })}
    >
      <Typography.Title color={theme.colors.text.contrast}>
        {props.name}
      </Typography.Title>
      <SimpleImage
        noBasePath
        src={props.src}
        alt={`image ${props.name}`}
        styles={{ maxWidth: '100%', height: 'auto', maxHeight: '80%' }}
      />
    </Container.Flex>
  )
}

export const ShowImages = withOrderModal(
  (props: OrderModalProps & OrderImegesProps) => {

    const disableKeys = useRef(false)
    const { images, currentImage } = props
    const [currentSlide, setCurrentSlide] = useState(currentImage)
    const menuRef = useRef<HTMLDivElement>(null)

    const onkeydown = useCallback(
      (e: any) => {
        if (disableKeys.current) return
        e.stopPropagation()
        if (e.key === 'ArrowRight') {
          if (currentSlide + 1 <= images.length - 1) {
            setCurrentSlide(currentSlide + 1)
          }
        }

        if (e.key === 'ArrowLeft') {
          if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1)
          }
        }

        if (['ArrowRight', 'ArrowLeft'].includes(e.key)) {
          disableKeys.current = true
          setTimeout(() => {
            disableKeys.current = false
          }, 300)
        }
      },
      [currentSlide, images],
    )

    useEffect(() => {
      if (!props.hidden) {
        window.addEventListener('keydown', onkeydown)
      } else {
        window.removeEventListener('keydown', onkeydown)
      }
      return () => {
        window.removeEventListener('keydown', onkeydown)
      }
    }, [props.hidden, onkeydown])

    return (
      <Fragment>
        <Container.Flex
          alignItems="start"
          styles={{
            position: 'fixed',
            width: '100vw',
            height: '90vh',
          }}
          onClick={props.hideWrapper}
        >
          <Container.Flex
            direction="row"
            alignItems="start"
            justify="around"
            absolute
            fullHeight
            styles={{
              top: 0,
              transition: 'left 600ms linear',
              width: '100vw',
            }}
          >
            {images.map((image, idx) => (
              <ShowImage
                {...image}
                key={image.name}
                active={currentSlide === idx}
              />
            ))}
          </Container.Flex>
          <Container.Flex
            direction="row"
            fullWidth
            styles={{ position: 'absolute', bottom: 0 }}
            gap={16}
            justify="center"
            ref={menuRef}
          >
            {' '}
            {images.map((el, idx) => (
              <Box
                key={`box${idx}`}
                onClick={e => {
                  e.stopPropagation()
                  setCurrentSlide(idx)
                }}
                style={{
                  // zIndex: 15,
                  backgroundImage: `url(${el.src})`,
                }}
                className={cls({
                  'image-slider-control-item': true,
                  'image-slider-control-item-current': currentSlide === idx,
                })}
              ></Box>
            ))}
          </Container.Flex>
        </Container.Flex>
      </Fragment>
    )
  },
)

const OrderImages = () => {
  const { orderImages, hideOrderImages } = useOrderContext()

  return (
    <ShowImages
      hidden={!orderImages.images.length}
      hide={hideOrderImages}
      images={orderImages.images}
      currentImage={orderImages.currentImage}
      noContainer
      zIndexAdd={1}
      noMargin
    />
  )
}

export default OrderImages
