import { useMemo } from 'react'
import { OfferSteps } from '../../store/offerSlice/types'
import { OrderStatuses } from '../../store/orderSlice/types'
import { Container, Typography } from '../ui'
import OfferPrePrice from './OfferPrePrice'
import OfferQrCode from './OfferQrCode'

interface OfferPriceProps {
  itemNumber: string
  hide: boolean
  status: string
  amount: string
  currency: string
  step?: OfferSteps
}

export default function OfferPrice({
  hide,
  status,
  amount,
  currency,
  step,
  itemNumber
}: OfferPriceProps) {

  const showFinalCount = useMemo(() => {
    if (step === 'summary') return false
    if (step === 'questions') return false

    return true
  }, [step, status])

  const showPrePrice = useMemo(() => {
    return status === OrderStatuses.Checking || status === "N"
  }, [status])

  return (
    <Container.Flex
      fullWidth
      alignItems="start"
      justify="start"
      className={hide ? 'hide-content' : 'show-content'}
    >
      {showFinalCount && (
        <Container.Flex fullWidth gap={16}>
          <Typography.Title fullWidth fromStart secondary padding="0 4px" margin="0 0 8px">
            Стоимость устройства
          </Typography.Title>
          <Typography.RublesLarge
            fullWidth
            margin={0}
          >{`${amount.replace(".", ",")} ${currency}`}</Typography.RublesLarge>
          {showPrePrice && <OfferPrePrice itemNumber={itemNumber}/>}
          <OfferQrCode itemNumber={itemNumber}/>
        </Container.Flex>
      )}
    </Container.Flex>
  )
}
